import { AxiosRequestConfig } from 'axios';
import { firstValueFrom, map, Observable } from 'rxjs';
import { AuthService } from './auth.service';

/**
 * Base concrete implementation of the AuthService. If access control is required, set an "x-auth-scope" request
 * header to refine the grant type. This header will be used in acquiring the desired access token from the IDP.
 */
export abstract class AbstractAuthService implements AuthService {
  abstract fetchAuthUser(): Observable<any>;

  abstract getAccessToken(scope?: string): Observable<string>;

  abstract isAuthenticated(): Observable<boolean>;

  abstract logout();

  setAuthorizationHeader(requestConfig: any): Promise<any> {
    const clonedConfig = { ...requestConfig } as AxiosRequestConfig;

    // Handle Auth0 scopes
    let scope: string | undefined = undefined;
    if (requestConfig.headers && requestConfig.headers['x-auth-scope']) {
      scope = String(requestConfig.headers['x-auth-scope']);
      delete requestConfig.headers['x-auth-scope'];
    }

    const obs$ = this.getAccessToken(scope).pipe(
      map((accessToken) => {
        if (accessToken) {
          const clonedHeaders = { ...(clonedConfig.headers || {}) };
          clonedHeaders['Authorization'] = `Bearer ${accessToken}`;
          clonedConfig.headers = clonedHeaders;
        }
        return clonedConfig;
      })
    );
    return firstValueFrom(obs$);
  }
}
