import { DateTime } from 'luxon';

export interface CurrentUser {
  appUser?: User;
  authUser: AuthUser;
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INVITED = 'inviteSent',
  EXPIRED = 'inviteExpired',
}

// TODO These need to be synchronized with the values stored at the IDP
export enum UserRole {
  ADMINISTRATOR = 'administrator',
  USER = 'user',
}

/**
 * Describes a user of this application. In this application, the user is managed by the identity provider.
 * @property emailVerified  Flag indicating that the user's email address was verified when the invitation was accepted.
 * @property phoneVerified  Flag indicating that the user's phone number was verified when the invitation was accepted.
 * @property status         The user status within the context of both this application and the identity provider.
 */
export interface User {
  userId: string;
  firstName: string;
  lastName: string;
  division?: string;
  department?: string;
  email: string;
  emailVerified?: boolean;
  phoneNumber?: string;
  phoneVerified?: boolean;
  status: UserStatus;

  createdAt: DateTime;
  updatedAt: DateTime;
  multifactor?: string[];
  lastIp?: string;
  lastLogin?: DateTime;
  lastPasswordReset?: DateTime;
  loginsCount?: number;
  roles: string[];
}

/**
 * Describes the information needed by the server to create an application user.
 */
export interface CreateUserRequest {
  firstName?: string;
  lastName?: string;
  division?: string;
  department?: string;
  email?: string;
  emailVerified?: boolean;
  verifyEmail?: boolean;
  password?: string;
  status?: UserStatus;
  roles?: string[];
  mfaRequired?: boolean;
  sendChangePasswordEmail?: boolean;
}

/**
 * Describes the information needed by the server to update an application user.
 */
export interface UpdateUserRequest extends CreateUserRequest {
  picture?: string;
  phoneNumber?: string;
  phoneVerified?: boolean;
}

/**
 * Describes the resource returned by the server from an API request.
 */
export interface UserResource {
  userId: string;
  firstName: string;
  lastName: string;
  division?: string;
  department?: string;
  email: string;
  emailVerified?: boolean;
  phoneNumber?: string;
  phoneVerified?: boolean;
  status: UserStatus;

  createdAt: string;
  updatedAt: string;
  multifactor?: string[];
  lastIp?: string;
  lastLogin?: string;
  lastPasswordReset?: string;
  loginsCount?: number;
  roles: string[];
}

/**
 * Enumerates possible properties included in the JWT access-token.
 */
export enum JwtClaimProperty {
  ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  USERNAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
  MFA_REQUIRED = 'https://apps.perkinelmercloud.net/mfaRequired',
  CUSTOM_CLAIMS = 'https://apps.perkinelmercloud.net/custom',
}

/**
 * Describes the decoded JWT access-token identifying user attributes common to both Auth0 and Cognito, many of
 * which are optional depending of the scopes required on Authentication. Additional properties that are IDP or
 * application specific are accepted.
 *
 * Scopes sent with the token request include:
 * - openId   (Required) Returns the subclaim, which uniquely identifies the user. In an IDToken, iss, aud, exp,
 *            iat, and at_hash claims will also be present.
 *
 * - profile  Returns claims that represent basic profile information, including name, family_name, given_name,
 *            middle_name, nickname, picture, and updated_at.
 *
 * - email    Returns the email claim, which contains the user's email address, and email_verified, which is a
 *            boolean indicating whether the email address was verified by the user.
 *
 * @property sub            The user identifier in the IDP system
 * @property family_name    The user's last name
 * @property given_name     The user's first name
 * @property name           The user's formatted name
 * @property nickname       The user's optional nickname
 * @property picture        The user's optional avatar, typically from a public url
 * @property updated_at     The timestamp of the last user update in the IDP
 * @property email          The user's email address
 * @property email_verified True if the user has confirmed their IDP invitation
 */
export interface AuthUserClaims {
  sub?: string;
  family_name?: string;
  given_name?: string;
  name?: string;
  nickname?: string;
  picture?: string;
  updated_at?: string;
  email?: string;
  email_verified?: string;
  [JwtClaimProperty.ROLE]?: string;
  [JwtClaimProperty.USERNAME]?: string;
  [JwtClaimProperty.MFA_REQUIRED]?: boolean;
  customClaims?: UserCustomClaims;

  [key: string]: any;
}

export interface AuthUser extends AuthUserClaims {
  id?: string; // The IDP User identifier (the `sub` claim)
  email?: string;
  roles?: string[];
  mfaRequired?: boolean;
}

/**
 * Describes user properties as parsed from the JWT token `custom` claims
 */
export interface UserCustomClaims {
  tenantId?: string;
  productId?: string;
  componentId?: string;
  connectionName?: string;
  user?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    ipAddress?: string;
    roles?: string[];
  };
}

/**
 * Returns true if the given user is an Administrator
 * @param user
 */
export const userHasAdministratorRole = (user: any): boolean => {
  const customClaims: UserCustomClaims = user ? user.customClaims : undefined;
  const { roles = [] } = customClaims?.user || {};
  return roles.length > 0 && ['administrator', 'Administrator'].some((role) => roles.includes(role));
};

/**
 * Returns true if the given user is a User
 * @param user
 */
export const userHasUserRole = (user: any): boolean => {
  const customClaims: UserCustomClaims = user ? user.customClaims : undefined;
  const { roles = [] } = customClaims?.user || {};
  return roles?.length > 0 && ['user', 'User'].some((role) => roles.includes(role));
};
