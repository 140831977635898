import { NotificationServiceFactory } from '@nx-workspace/shared/notification';
import { getI18n } from 'react-i18next';
import { Reducer } from 'redux';
import { EnvironmentAction, EnvironmentActionType } from '../actions/environment.actions';
import { EnvironmentState, initialEnvironmentState } from '../state/environment.state';

export const environmentReducer: Reducer<EnvironmentState, EnvironmentAction> = (
  state = initialEnvironmentState,
  action
) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case EnvironmentActionType.FETCH_ENVIRONMENT:
    case EnvironmentActionType.FETCH_ENVIRONMENTS:
      return { ...state, isLoading: true };

    case EnvironmentActionType.CREATE_ENVIRONMENT:
      return { ...state, currentResource: null, isProcessing: true, error: null };

    case EnvironmentActionType.UPDATE_ENVIRONMENT:
    case EnvironmentActionType.DELETE_ENVIRONMENT:
      return { ...state, isProcessing: true, error: null };

    case EnvironmentActionType.FETCH_ENVIRONMENT_SUCCEEDED:
      return { ...state, currentResource: action.payload![0], isLoading: false };

    case EnvironmentActionType.FETCH_ENVIRONMENTS_SUCCEEDED:
      return { ...state, list: action.payload || [], isLoading: false };

    case EnvironmentActionType.CREATE_ENVIRONMENT_SUCCEEDED: {
      const resource = action.payload![0];
      const clonedList = [...state.list];
      clonedList.push(resource);
      NotificationServiceFactory.getInstance().show(getI18n().t('environments.form.createSuccess'), {
        type: 'success',
      });
      return { ...state, list: clonedList, currentResource: resource, isProcessing: false };
    }

    case EnvironmentActionType.UPDATE_ENVIRONMENT_SUCCEEDED: {
      const resource = action.payload![0];
      const clonedList = [...state.list];
      const index = clonedList.findIndex((e) => e.id === action.meta!.id);
      if (index > -1) {
        clonedList[index] = resource;
        NotificationServiceFactory.getInstance().show(getI18n().t('environments.form.updateSuccess'), {
          type: 'success',
        });
      }
      return { ...state, list: clonedList, currentResource: resource, isProcessing: false };
    }

    case EnvironmentActionType.DELETE_ENVIRONMENT_SUCCEEDED: {
      const clonedList = [...state.list];
      const index = clonedList.findIndex((e) => e.id === action.meta!.id);
      if (index > -1) {
        clonedList.splice(index, 1);
        NotificationServiceFactory.getInstance().show(getI18n().t('environments.form.deleteSuccess'), {
          type: 'success',
        });
      }
      return { ...state, list: clonedList, currentResource: null, isProcessing: false };
    }

    case EnvironmentActionType.FETCH_ENVIRONMENT_FAILED:
    case EnvironmentActionType.FETCH_ENVIRONMENTS_FAILED:
      return { ...state, error: action.error, isLoading: false };

    case EnvironmentActionType.CREATE_ENVIRONMENT_FAILED:
    case EnvironmentActionType.UPDATE_ENVIRONMENT_FAILED:
    case EnvironmentActionType.DELETE_ENVIRONMENT_FAILED:
      return { ...state, error: action.error, isProcessing: false };

    default:
      return state;
  }
};
