/**
 * Describes a mapper that maps between data entities in this application and resources required by the
 * server. Typically, these entities and resources are object literals made up of name-value pairs and not
 * instantiated class objects. Therefore, the mapping process is fairly straightforward.
 * @typeParam R The resource provided by or required by the server
 * @typeParam E The entity in use in this application
 */
export interface Mapper<R extends object, E extends object> {
  /**
   * Returns an entity representing an object entity mapped from the given `resource` received from a server response.
   * @param resource
   */
  from(resource: R): E;

  /**
   * Maps the given `entity` to a resource required by a server request.
   * @param entity
   */
  to(entity: E): R;
}
