import { StandardAction } from '@nx-workspace/shared/models';
import { Tenant, TenantRequest } from '@software-platforms/tenant-manager-ui/models';

type MetaData = {
  id?: string;
  currentResource?: Tenant;
  formData?: TenantRequest;
  sortKey?: string;
  sortDirection?: string;
};

export type TenantAction = StandardAction<Tenant[], MetaData>;

export enum TenantActionType {
  FETCH_TENANTS = 'tenants/FETCH_TENANTS',
  FETCH_TENANTS_SUCCEEDED = 'tenants/FETCH_TENANTS_SUCCEEDED',
  FETCH_TENANTS_FAILED = 'tenants/FETCH_TENANTS_FAILED',
  FETCH_TENANT = 'tenants/FETCH_TENANT',
  FETCH_TENANT_SUCCEEDED = 'tenants/FETCH_TENANT_SUCCEEDED',
  FETCH_TENANT_FAILED = 'tenants/FETCH_TENANT_FAILED',
  CREATE_TENANT = 'tenants/CREATE_TENANT',
  CREATE_TENANT_SUCCEEDED = 'tenants/CREATE_TENANT_SUCCEEDED',
  CREATE_TENANT_FAILED = 'tenants/CREATE_TENANT_FAILED',
  UPDATE_TENANT = 'tenants/UPDATE_TENANT',
  UPDATE_TENANT_SUCCEEDED = 'tenants/UPDATE_TENANT_SUCCEEDED',
  UPDATE_TENANT_FAILED = 'tenants/UPDATE_TENANT_FAILED',
  DELETE_TENANT = 'tenants/DELETE_TENANT',
  DELETE_TENANT_SUCCEEDED = 'tenants/DELETE_TENANT_SUCCEEDED',
  DELETE_TENANT_FAILED = 'tenants/DELETE_TENANT_FAILED',
  DEACTIVATE_TENANT = 'tenants/DEACTIVATE_TENANT',
  DEACTIVATE_TENANT_SUCCEEDED = 'tenants/DEACTIVATE_TENANT_SUCCEEDED',
  DEACTIVATE_TENANT_FAILED = 'tenants/DEACTIVATE_TENANT_FAILED',
  REACTIVATE_TENANT = 'tenants/REACTIVATE_TENANT',
  REACTIVATE_TENANT_SUCCEEDED = 'tenants/REACTIVATE_TENANT_SUCCEEDED',
  REACTIVATE_TENANT_FAILED = 'tenants/REACTIVATE_TENANT_FAILED',
  FLUSH = 'tenants/FLUSH',
}

/* ---------- Action Creators ---------- */

export const TenantActions = {
  // Fetch resource list
  fetchTenants: (): TenantAction => ({
    type: TenantActionType.FETCH_TENANTS,
  }),
  fetchTenantsSucceeded: (payload: Tenant[]): TenantAction => ({
    type: TenantActionType.FETCH_TENANTS_SUCCEEDED,
    payload,
  }),
  fetchTenantsFailed: (error: any): TenantAction => ({
    type: TenantActionType.FETCH_TENANTS_FAILED,
    error,
  }),

  // Fetch resource
  fetchTenant: (id: string): TenantAction => ({
    type: TenantActionType.FETCH_TENANT,
    meta: { id },
  }),
  fetchTenantSucceeded: (id: string, payload: Tenant[]): TenantAction => ({
    type: TenantActionType.FETCH_TENANT_SUCCEEDED,
    meta: { id },
    payload,
  }),
  fetchTenantFailed: (id: string, error: any): TenantAction => ({
    type: TenantActionType.FETCH_TENANT_FAILED,
    meta: { id },
    error,
  }),

  // Create resource
  createTenant: (formData: TenantRequest): TenantAction => ({
    type: TenantActionType.CREATE_TENANT,
    meta: { formData },
  }),
  createTenantSucceeded: (formData: TenantRequest, payload: Tenant[]): TenantAction => ({
    type: TenantActionType.CREATE_TENANT_SUCCEEDED,
    meta: { formData },
    payload,
  }),
  createTenantFailed: (formData: TenantRequest, error: any): TenantAction => ({
    type: TenantActionType.CREATE_TENANT_FAILED,
    meta: { formData },
    error,
  }),

  // Update resource
  updateTenant: (id: string, formData: TenantRequest): TenantAction => ({
    type: TenantActionType.UPDATE_TENANT,
    meta: { id, formData },
  }),
  updateTenantSucceeded: (id: string, formData: TenantRequest, payload: Tenant[]): TenantAction => ({
    type: TenantActionType.UPDATE_TENANT_SUCCEEDED,
    meta: { id, formData },
    payload,
  }),
  updateTenantFailed: (id: string, formData: TenantRequest, error: any): TenantAction => ({
    type: TenantActionType.UPDATE_TENANT_FAILED,
    meta: { id, formData },
    error,
  }),

  // Delete resource
  deleteTenant: (id: string): TenantAction => ({
    type: TenantActionType.DELETE_TENANT,
    meta: { id },
  }),
  deleteTenantSucceeded: (id: string): TenantAction => ({
    type: TenantActionType.DELETE_TENANT_SUCCEEDED,
    meta: { id },
  }),
  deleteTenantFailed: (id: string, error: any): TenantAction => ({
    type: TenantActionType.DELETE_TENANT_FAILED,
    meta: { id },
    error,
  }),

  // Deactivate resource
  deactivateTenant: (id: string): TenantAction => ({
    type: TenantActionType.DEACTIVATE_TENANT,
    meta: { id },
  }),
  deactivateTenantSucceeded: (id: string, payload: Tenant[]): TenantAction => ({
    type: TenantActionType.DEACTIVATE_TENANT_SUCCEEDED,
    meta: { id },
    payload,
  }),
  deactivateTenantFailed: (id: string, error: any): TenantAction => ({
    type: TenantActionType.DEACTIVATE_TENANT_FAILED,
    meta: { id },
    error,
  }),

  // Reactivate resource
  reactivateTenant: (id: string): TenantAction => ({
    type: TenantActionType.REACTIVATE_TENANT,
    meta: { id },
  }),
  reactivateTenantSucceeded: (id: string, payload: Tenant[]): TenantAction => ({
    type: TenantActionType.REACTIVATE_TENANT_SUCCEEDED,
    meta: { id },
    payload,
  }),
  reactivateTenantFailed: (id: string, error: any): TenantAction => ({
    type: TenantActionType.REACTIVATE_TENANT_FAILED,
    meta: { id },
    error,
  }),

  // Other
  flush: (): TenantAction => ({ type: TenantActionType.FLUSH }),
};
