import { CurrentUser } from '@software-platforms/tenant-manager-ui/models';

export interface AuthState {
  currentUser: CurrentUser | null;
  isLoading: boolean;
  error: any;
}

export const initialAuthState: AuthState = {
  currentUser: null,
  isLoading: false,
  error: null,
};
