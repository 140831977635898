import { PkiButton } from '@software-platforms/design-system-components';
import { UserForm } from '@software-platforms/tenant-manager-ui/models';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserEntry } from '../user-entry/user-entry';
import styles from './user-view.module.scss';

type OwnProps = {
  form: UserForm;
  isAdministrator: boolean;
  onCancel: (event: React.MouseEvent) => void;
  onEditUser: (event: React.MouseEvent) => void;
  onDeactivateUser: (event: React.MouseEvent) => void;
  onReactivateUser: (event: React.MouseEvent) => void;
  onRenewInviteUser: (event: React.MouseEvent) => void;
  userStatus: string;
};

export const UserView: React.FunctionComponent<OwnProps> = (props) => {
  const { form, isAdministrator, userStatus } = props;
  const { t } = useTranslation();

  return (
    <section className={cx('item', 'form', styles.item)}>
      <div className="form-header">
        <div className="form-title">{t('users.form.title')}</div>
        <div className="form-actions">
          {isAdministrator && (
            <>
              <PkiButton
                id="cancel-btn"
                label={t('pki:form.cancel')}
                onClick={props.onCancel}
                size="small"
                variant="secondary"
              />
              {userStatus === 'active' && (
                <PkiButton
                  id="deactivate-btn"
                  label={t('users.form.deactivateBtnLabel')}
                  onClick={props.onDeactivateUser}
                  size="small"
                  variant="secondary"
                />
              )}
              {userStatus === 'inactive' && (
                <PkiButton
                  id="reactivate-btn"
                  label={t('users.form.reactivateBtnLabel')}
                  onClick={props.onReactivateUser}
                  size="small"
                  variant="secondary"
                />
              )}
              {userStatus === 'inviteExpired' && (
                <PkiButton
                  id="rene-invite-btn"
                  label={t('users.form.renewInviteBtnLabel')}
                  onClick={props.onRenewInviteUser}
                  size="small"
                  variant="secondary"
                />
              )}
              <PkiButton
                id="edit-btn"
                label={t('pki:form.edit')}
                onClick={props.onEditUser}
                size="small"
                variant="primary"
              />
            </>
          )}
          {!isAdministrator && (
            <PkiButton id="ok-btn" label={t('pki:form.ok')} onClick={props.onCancel} size="small" variant="secondary" />
          )}
        </div>
      </div>
      <UserEntry
        form={form}
        isProcessing={false}
        onBlur={() => {
          // Noop
        }}
        onChange={() => {
          // Noop
        }}
        viewMode="view"
      />
    </section>
  );
};
UserView.displayName = 'UserView';
