import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { PkiBadge, PkiMenu, PkiMenuItem } from '@software-platforms/design-system-components';
import { ellipsisHorizontal, PkiIcon } from '@software-platforms/design-system-icons';
import { Environment, Tenant, TenantStatus } from '@software-platforms/tenant-manager-ui/models';
import { AppState, EnvironmentActions } from '@software-platforms/tenant-manager-ui/store';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../tenants/tenant-list-grid/tenant-list-grid.module.scss';

const getBadgeStyle = (status: TenantStatus) => {
  let badgeStyle;
  switch (status) {
    case TenantStatus.ACTIVE:
      badgeStyle = styles.active;
      break;
    case TenantStatus.DEPLOYING:
      badgeStyle = styles.deploying;
      break;
    case TenantStatus.DESTROYING:
      badgeStyle = styles.destroying;
      break;
    case TenantStatus.FAILED:
      badgeStyle = styles.failed;
      break;
    default:
      badgeStyle = styles.inactive;
      break;
  }
  return badgeStyle;
};

/* ---------- Component Definition ---------- */

export type OwnProps = {
  isAdministrator: boolean;
  isLoading?: boolean;
  list: Tenant[];
  onAction: (item: Tenant) => void;
  onDelete: (item: Tenant) => void;
  onView: (item: Tenant) => void;
};

/**
 * A child component that renders the list of tenants for a given subscription. This list renders different columns
 * than the TenantListGrid, and so must be a separate component.
 * @param props
 * @constructor
 */
export const SubscriptionTenantList: React.FunctionComponent<OwnProps> = (props) => {
  const { isAdministrator, isLoading, list } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const environments: Environment[] = useSelector((state: AppState) => state.environments.list);
  useEffect(
    () => {
      if (!environments?.length) {
        dispatch(EnvironmentActions.fetchEnvironments());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'tenantName', sort: 'asc' }]);
  const columns: GridColDef[] = [
    { field: 'id', hide: true },
    { field: 'tenantName', flex: 3, headerName: t('tenants.list.tenantName') },
    { field: 'companyName', flex: 2, headerName: t('tenants.list.companyName') },
    { field: 'dnsLabel', flex: 2, headerName: t('tenants.list.dnsLabel') },
    { field: 'environmentId', hide: true },
    {
      field: 'environment',
      flex: 2,
      headerName: t('tenants.list.environment'),
      renderCell: (params: GridRenderCellParams<string, Tenant>) => {
        const found = environments?.find((e) => e.id === params.row.environmentId);
        return found ? found.name : '';
      },
    },
    {
      field: 'status',
      flex: 2,
      headerName: t('tenants.list.status'),
      renderCell: (params: GridRenderCellParams<string, Tenant>) => {
        const itemStatus = params.row.status || 'inactive';
        const badgeStyle = getBadgeStyle(itemStatus);
        return <PkiBadge classNames={cx(styles.pkiBadge, badgeStyle)} label={t(`tenants.status.${itemStatus}`)} />;
      },
    },
    {
      field: 'action',
      cellClassName: 'more-cell',
      headerName: '',
      renderCell: (params: GridRenderCellParams<Tenant>) => {
        const itemAction = params.row.status === 'inactive' ? 'activate' : 'deactivate';
        return (
          <PkiMenu
            classNames="menu-right"
            name={`tenant-action-${params.id}`}
            icon={<PkiIcon icon={ellipsisHorizontal} />}
            iconSize="16px 16px"
          >
            {(params.row.status === 'inactive' || params.row.status === 'active' || !params.row.status) && (
              <>
                <PkiMenuItem onClick={() => props.onView(params.row)}>
                  {t(isAdministrator ? 'tenants.list.viewAndEdit' : 'tenants.list.view')}
                </PkiMenuItem>
                {isAdministrator && (
                  <PkiMenuItem onClick={() => props.onAction(params.row)}>
                    {t(`tenants.${itemAction}BtnLabel`)}
                  </PkiMenuItem>
                )}
              </>
            )}
            {isAdministrator && (
              <PkiMenuItem onClick={() => props.onDelete(params.row)}>{t('tenants.deleteBtnLabel')}</PkiMenuItem>
            )}
          </PkiMenu>
        );
      },
      sortable: false,
      width: 30,
    },
  ];

  return (
    <div className="pki-data-grid">
      <DataGrid
        autoPageSize
        columns={columns}
        density="compact"
        disableColumnMenu
        loading={isLoading}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
        pagination
        rows={list}
        sortModel={sortModel}
      />
    </div>
  );
};
SubscriptionTenantList.displayName = 'SubscriptionTenantList';
