import {
  PkiDialog,
  PkiDialogContent,
  PkiDialogFooter,
  PkiDialogHeader,
} from '@software-platforms/design-system-components';
import { User } from '@software-platforms/tenant-manager-ui/models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './deactivate-user.module.scss';

const formatName = (user?: User): string => {
  if (user) {
    if (user.lastName) {
      return user.firstName ? `${user.firstName} ${user.lastName}` : user.lastName;
    }
    return user.email;
  }
  return '';
};

type OwnProps = {
  selectedUser?: User;
  onClose: (isConfirmed: boolean) => void;
  open: boolean;
};

export const DeactivateUser: React.FunctionComponent<OwnProps> = (props) => {
  const { selectedUser, open } = props;
  const { t } = useTranslation();
  return (
    <PkiDialog disableBackdropClick onClose={props.onClose} open={open} size="sm" type="warning">
      <PkiDialogHeader title={t('users.deactivate.title')} type="warning" />
      <PkiDialogContent>
        <div>{t('users.deactivate.message1')}</div>
        <div className={styles.dialogList}>
          <div>{formatName(selectedUser)}</div>
        </div>
        <div>{t('users.deactivate.message2')}</div>
      </PkiDialogContent>
      <PkiDialogFooter
        confirmation
        type="warning"
        onClose={props.onClose}
        cancelBtnLabel={t('pki:form.cancel')}
        confirmBtnLabel={t('users.deactivate.confirmBtnLabel')}
      />
    </PkiDialog>
  );
};
DeactivateUser.displayName = 'DeactivateUser';
