import { SearchFilter } from '@software-platforms/tenant-manager-ui/models';
import { DateTime } from 'luxon';

const v4 = new RegExp(/[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i);
export const testGuid = (raw: string): RegExpMatchArray | null => {
  return raw ? raw.match(v4) : null;
};

/**
 * Returns the given date as a string displayed with the given format in the local timezone, or an empty string if no
 * date is given or the date is invalid. If no format is given, the date will be displayed based on its locale. For
 * example, a date displayed for en-US locales would be `MM/DD/YYY hh:mm A`, or `09/04/1986 8:30 PM`.
 * @param value
 * @param format
 */
export const formatLocalDate = (
  value: any,
  format: Intl.DateTimeFormatOptions = DateTime.DATETIME_SHORT_WITH_SECONDS
): string => {
  if (value) {
    if (value instanceof DateTime) {
      return (value as DateTime).toLocaleString(format);
    }
    return !DateTime.invalid(value.toString()) ? DateTime.fromISO(String(value)).toLocaleString(format) : value;
  }
  return '';
};

/**
 * Returns a formatted string for the given filter.
 * @param filter
 */
export const formatFilterLabel = (filter: SearchFilter): string => {
  return filter ? `${filter.field.toUpperCase()}: ${filter.label}` : '';
};
