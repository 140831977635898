import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { i18n } from 'i18next';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { PkiAuth0Application } from './pki-auth0.application';

export interface PkiAuth0ProviderProps extends Auth0ProviderOptions {
  children: ReactNode;
  i18n?: i18n;
}

/**
 * A component that wraps the Auth0Provider to enable common functions such as:
 * - Application session history via React Router,
 * - Automatic loading spinner
 * - Automatic login behavior
 * - Automatic Auth0 error handling
 * @param props
 * @constructor
 */
export const PkiAuth0Provider: React.FunctionComponent<PkiAuth0ProviderProps> = (props) => {
  const { children, i18n, ...rest } = props;

  // Override any existing callback with this one so that we maintain router state. We use a try/catch block in case
  // the consumer of this component doesn't wrap it in with a React Router.
  // @see https://auth0.com/blog/complete-guide-to-react-user-authentication/
  // @see https://stackoverflow.com/questions/63471931/using-history-with-react-router-dom-v6
  let providerProps: Auth0ProviderOptions = { ...rest };
  try {
    const navigate = useNavigate();
    const onRedirectCallback = (state?: AppState | undefined) => {
      navigate(state?.returnTo || window.location.pathname);
    };
    providerProps = { ...rest, onRedirectCallback };
  } catch (err) {
    // Drop through
  }

  return (
    <Auth0Provider {...providerProps}>
      <PkiAuth0Application i18n={i18n}>{children}</PkiAuth0Application>
    </Auth0Provider>
  );
};
PkiAuth0Provider.displayName = 'PkiAuth0Provider';
