import { close, key, personOutline, PkiIcon } from '@software-platforms/design-system-icons';
import cx from 'classnames';
import { i18n } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Observable } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import {
  CurrentUser,
  CurrentUserFormData,
  ProfileElementProps,
  ProfileFeature,
  ProfileFeatureProps,
  ProfileMessages,
} from './models';
import { ProfileDetails } from './profile-details/profile-details';
import { ProfileSecurity } from './profile-security/profile-security';
import { ProfileSidebar } from './profile-sidebar/profile-sidebar';
import styles from './user-profile.module.scss';

/* ---------- Component Definition ---------- */

export type UserProfileProps = {
  currentUser?: CurrentUser | null;
  features: ProfileFeature[];
  customElements?: ProfileElementProps[];
  i18n?: i18n;
  logoutBtnLabel?: string;
  onEscapeKeyDown?: (event: React.KeyboardEvent) => void;
  onToggleProfile?: (flag: boolean) => void;
  open: boolean;
  onLogout: () => void;
  onUpdateCurrentUser?: (formData: CurrentUserFormData, messages?: ProfileMessages) => Observable<any>;
  title?: string;
};

export const UserProfile: React.FunctionComponent<UserProfileProps> = (props) => {
  const {
    currentUser,
    customElements = [],
    features = [],
    i18n,
    logoutBtnLabel = 'Log Out',
    open,
    title = 'Account Management',
  } = props;
  const mouseDownTarget = React.useRef<any>();

  /* ---------- Dialog functions ---------- */

  const [openProfile, setOpenProfile] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const showUserProfile = searchParams.get('userProfile');

  // Controls the visibility of the User Profile modal.
  useEffect(() => {
    setOpenProfile(showUserProfile === 'true');
  }, [showUserProfile]);

  useEffect(() => {
    if (props.onToggleProfile) {
      // inform parent the dialog is now open or close
      props.onToggleProfile(openProfile);
    }
  }, [openProfile, props]);

  // Manages the URL query parameter that triggers the User Profile.
  const toggleUserProfile = () => {
    const updatedParams = searchParams;
    if (openProfile) {
      updatedParams.delete('userProfile');
    } else {
      updatedParams.set('userProfile', 'true');
    }
    setSearchParams(updatedParams);
    setOpenProfile(!openProfile);
  };

  const handleBackdropClick = (event: React.MouseEvent) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    toggleUserProfile();
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== 'Escape') {
      return;
    }
    if (props.onEscapeKeyDown) {
      props.onEscapeKeyDown(event);
    }
    toggleUserProfile();
  };
  const handleMouseDown = (event: React.MouseEvent) => {
    mouseDownTarget.current = event.target;
  };
  const handleMouseUp = (event: React.MouseEvent) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    if (event.target !== mouseDownTarget.current) {
      return;
    }
    mouseDownTarget.current = null;
    toggleUserProfile();
  };

  /* ---------- Actions ---------- */

  const [currentTab, setCurrentTab] = useState<string>('details');
  const handleTabSelection = (selectedTab: string) => {
    setCurrentTab(selectedTab);
  };

  /* ---------- Configuration ---------- */

  const profileFeatures = React.useMemo(
    () => {
      // Configure the User Details tab. Use a custom feature if specified.
      let detailsFeature: ProfileFeature;
      let index = features.findIndex((feature) => feature.props.tab === 'details');
      if (index > -1) {
        detailsFeature = features.splice(index, 1)[0];
      } else {
        detailsFeature = {
          component: ProfileDetails,
          icon: personOutline,
          title: i18n ? i18n.t('pki:userProfile.details.title') : 'User Information',
          props: {
            currentUser,
            customElements,
            onUpdateCurrentUser: props.onUpdateCurrentUser,
            currentTab,
            i18n,
            tab: 'details',
          },
        };
      }
      // Configure the Security tab. Use a custom feature if specified.
      let securityFeature: ProfileFeature;
      index = features.findIndex((feature) => feature.props.tab === 'security');
      if (index > -1) {
        securityFeature = features.splice(index, 1)[0];
      } else {
        securityFeature = {
          component: ProfileSecurity,
          icon: key,
          title: i18n ? i18n.t('pki:userProfile.security.title') : 'Security',
          props: {
            currentUser,
            customElements,
            onUpdateCurrentUser: props.onUpdateCurrentUser,
            currentTab,
            i18n,
            tab: 'security',
          },
        };
      }
      // Configure custom tabs
      const list: ProfileFeature[] = [detailsFeature, securityFeature];
      if (features.length) {
        features.forEach((feature) => {
          const newProps: Partial<ProfileFeatureProps> = {
            currentUser,
            onUpdateCurrentUser: props.onUpdateCurrentUser,
            currentTab,
            i18n,
          };
          const clone = { ...feature, props: { ...feature.props, ...newProps } } as ProfileFeature;
          list.push(clone);
        });
      }
      return list;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [features, currentTab, currentUser, i18n, props.onUpdateCurrentUser]
  );

  /* ---------- Rendering ---------- */

  const renderFeature = (feature: ProfileFeature) => {
    if (feature.props.tab === currentTab && feature.component) {
      const Component = feature.component;
      return <Component key={uuidV4().substring(0, 8)} {...feature.props} />;
    }
    return null;
  };

  return (
    <div
      className={cx(styles.backdropRoot, { [styles.openRoot]: open })}
      onClick={handleBackdropClick}
      role="presentation"
      aria-hidden={!open}
    >
      <div
        className={cx(styles.backdrop, { [styles.openBackdrop]: open })}
        onClick={handleBackdropClick}
        aria-hidden={!open}
      />
      <div
        className={cx(styles.container, { [styles.openContainer]: open })}
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        role="presentation"
        aria-hidden={!open}
      >
        <div className={styles.dialog}>
          <header className={styles.header}>
            <div className={styles.title}>{title}</div>
            <div className={styles.closeBtn} onClick={toggleUserProfile} role="button">
              <PkiIcon icon={close} />
            </div>
          </header>
          <div className={styles.contentContainer}>
            <ProfileSidebar
              currentTab={currentTab}
              features={profileFeatures}
              logoutBtnLabel={logoutBtnLabel}
              onLogout={props.onLogout}
              onTabSelection={handleTabSelection}
            />
            {profileFeatures.map((e) => renderFeature(e))}
          </div>
        </div>
      </div>
    </div>
  );
};
UserProfile.displayName = 'UserProfile';
