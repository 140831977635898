export { renderIcon } from './render-icon';

/**
 * Returns true if the given `child` element is equal to or a descendant of the given `parent` element, false otherwise.
 * @param parent
 * @param child
 */
export const isDescendant = (parent: HTMLElement, child: HTMLElement): boolean => {
  if (parent === child) {
    return true;
  }
  let node = child.parentNode;
  while (node) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

/**
 * Returns true if the given inputs are equal, false otherwise.
 * @param a
 * @param b
 */
export const isEqual = (a: any, b: any): boolean => {
  if (typeof b === 'object' && b !== null) {
    return a === b;
  }
  return String(a) === String(b);
};

/* ---------- Authorization ---------- */

/**
 * Describes the user attributes common to both Auth0 and Cognito.
 */
export interface GenericIDPUser {
  family_name?: string;
  given_name?: string;
  name?: string;
  nickname?: string;
  phone_number?: string;
  picture?: string;
  updated_at?: string;

  [key: string]: any;
}

/**
 * Describes a facade object wrapping a User entity provided by an IDP with a different User entity provided by an
 * application. This interface follows the Separated Interface pattern as defined by Martin Fowler. Note that the
 * two properties are typed as `any` types: It is intended that the developers of the consuming application be
 * responsible for defining these types.
 * @see Fowler, Martin, "Patterns of Enterprise Application Architecture." Pearson Education, Inc., Boston, 2003,
 * pp. 476-479.
 */
export interface CurrentUser {
  authUser?: any;
  appUser?: any;
}

/**
 * Returns the roles of the given user
 * @param currentUser
 */
export const getUserRoles = (currentUser: CurrentUser | undefined | null): string[] => {
  return unique([...(currentUser?.authUser?.roles || []), ...(currentUser?.appUser?.roles || [])]);
};

/**
 * Returns the Locale roles of the given user as a concatenated string, or an empty string if no roles are found.
 * @param currentUser
 */
export const getUserLocaleRoles = (currentUser: CurrentUser | undefined | null): string => {
  const allRoles = unique([
    ...(currentUser?.authUser?.localeRoles || []),
    ...(currentUser?.appUser?.localeRoles || []),
  ]);
  return allRoles.join(', ');
};

function unique(arr) {
  const len = arr.length;
  let i = -1;
  while (i++ < len) {
    let j = i + 1;
    for (; j < arr.length; ++j) {
      if (arr[i] === arr[j]) {
        arr.splice(j--, 1);
      }
    }
  }
  return arr;
}
