import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid';
import { DropDownOption } from '@nx-workspace/shared/models';
import {
  PkiBadge,
  PkiButton,
  PkiChip,
  PkiMenu,
  PkiMenuItem,
  PkiSelect,
  PkiSelectOption,
} from '@software-platforms/design-system-components';
import { addCircle, ellipsisHorizontal, PkiIcon, subscriptions } from '@software-platforms/design-system-icons';
import {
  SearchFilter,
  isFilterSelected,
  Subscription,
  SubscriptionStatus,
  Tenant,
  TenantQueryRequest,
  userHasAdministratorRole,
} from '@software-platforms/tenant-manager-ui/models';
import { ServiceFactory } from '@software-platforms/tenant-manager-ui/services';
import { AppState, SubscriptionActions } from '@software-platforms/tenant-manager-ui/store';
import cx from 'classnames';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { useTypeAhead } from '../../components/hooks/use-typeahead';
import { formatFilterLabel, formatLocalDate } from '../../utils';
import { ActivationSubscriptionModal } from '../subscription-modal/activation-subscription-modal';
import { DeleteSubscriptionModal } from '../subscription-modal/delete-subscription-modal';
import { SubscriptionLocationState } from '../subscriptions';
import styles from './subscription-list.module.scss';

const SEARCH_FIELDS = ['license', 'orgSubscriptionId', 'companyName'];

type OwnProps = {
  list: Subscription[];
  isLoading: boolean;
};

export const SubscriptionList: React.FunctionComponent<OwnProps> = (props) => {
  const { list, isLoading } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector((state: AppState) => state.auth.currentUser);
  const isAdministrator = userHasAdministratorRole(currentUser?.authUser);

  const SUBSCRIPTION_STATUS_OPTIONS: DropDownOption[] = useMemo<DropDownOption[]>(
    () =>
      Object.keys(SubscriptionStatus).map((key) => ({
        value: SubscriptionStatus[key],
        label: t(`subscriptions.status.${SubscriptionStatus[key]}`),
      })),
    [t]
  );

  const handleCreateSubscription = (event: React.MouseEvent) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    dispatch(SubscriptionActions.flush());
    navigate('/subscriptions/create', { state: { viewMode: 'create' } as SubscriptionLocationState });
  };

  /* ---------- Filtering ---------- */

  const [search, setSearch] = useState<string>('');
  const [searchToken, setSearchToken] = useState<string>('');
  // Create the subscriber for the wildcard search.
  const search$ = useMemo(() => {
    return new BehaviorSubject('').pipe(
      map((token) => token.trim()),
      distinctUntilChanged(),
      debounceTime(200)
    );
  }, []);
  useTypeAhead(search$, setSearchToken);

  const [filters, setFilters] = useState<SearchFilter[]>([]);
  const handleFilter = (event: React.ChangeEvent<{}>) => {
    const target = (event as React.ChangeEvent<HTMLDivElement>).target;
    const label: string = target.innerText;
    const { group, value } = target.dataset;
    if (group && value) {
      const hasFilter = filters.some((e) => e.field === group && e.value === value);
      if (!hasFilter) {
        setFilters([...filters, { field: group, value, label }]);
      }
    }
  };
  const handleDeleteFilter = (filter: SearchFilter) => {
    const updatedFilters = [...filters];
    const index = updatedFilters.findIndex((e) => e === filter);
    if (index > -1) {
      updatedFilters.splice(index, 1);
      setFilters(updatedFilters);
    }
  };
  const handleClearFilters = () => {
    setFilters([]);
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearch(event.target.value || '');
    (search$ as BehaviorSubject<string>).next(event.target.value || '');
  };

  /* ---------- Grid Row Actions ---------- */

  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null);
  const [openConfirmActivation, setOpenConfirmActivation] = useState<boolean>(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [tenantList, setTenantList] = useState<Tenant[]>([]);

  const handleActivation = (subscription: Subscription) => {
    setSelectedSubscription(subscription);
    if (!openConfirmActivation) {
      // Include tenants assigned to this subscription.
      const query: TenantQueryRequest = { subscriptionId: subscription.id, attributesToGet: 'id,name' };
      ServiceFactory.getServices()
        .tenantService.fetchTenants(query)
        .subscribe((tenants: Tenant[]) => {
          setTenantList(tenants || []);
          setOpenConfirmActivation(true);
        });
    }
  };
  const handleConfirmActivation = (isConfirmed: boolean) => {
    setOpenConfirmActivation(false);
    if (isConfirmed && selectedSubscription) {
      if (selectedSubscription?.status === SubscriptionStatus.INACTIVE) {
        dispatch(SubscriptionActions.reactivateSubscription(selectedSubscription.id));
      } else {
        dispatch(SubscriptionActions.deactivateSubscription(selectedSubscription.id));
      }
    }
  };

  const handleDelete = (subscription: Subscription) => {
    setSelectedSubscription(subscription);
    if (!openConfirmDelete) {
      // Include tenants assigned to this subscription.
      const query: TenantQueryRequest = { subscriptionId: subscription.id, attributesToGet: 'id,name' };
      ServiceFactory.getServices()
        .tenantService.fetchTenants(query)
        .subscribe((tenants: Tenant[]) => {
          setTenantList(tenants || []);
          setOpenConfirmDelete(true);
        });
    }
  };
  const handleConfirmDelete = (isConfirmed: boolean) => {
    setOpenConfirmDelete(false);
    if (isConfirmed && selectedSubscription) {
      dispatch(SubscriptionActions.deleteSubscription(selectedSubscription.id, !!(tenantList || []).length));
    }
  };

  const handleView = (subscription: Subscription) => {
    navigate(subscription.id, {
      state: {
        viewMode: 'view',
        id: subscription.id,
      } as SubscriptionLocationState,
    });
  };

  /* ---------- Data ---------- */

  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'companyName', sort: 'asc' }]);
  const columns: GridColDef[] = [
    { field: 'id', hide: true },
    { field: 'license', flex: 2, headerName: t('subscriptions.list.licenseId') },
    { field: 'orgSubscriptionId', flex: 2, headerName: t('subscriptions.list.subscriptionId') },
    {
      field: 'status',
      headerName: t('subscriptions.list.status'),
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<string, Subscription>) => {
        const itemStatus = params.row.status || SubscriptionStatus.INACTIVE;
        const badgeStyle = itemStatus === SubscriptionStatus.ACTIVE ? styles.active : styles.inactive;
        return (
          <PkiBadge classNames={cx(styles.pkiBadge, badgeStyle)} label={t(`subscriptions.status.${itemStatus}`)} />
        );
      },
    },
    { field: 'companyName', flex: 2, headerName: t('subscriptions.list.companyName') },
    {
      field: 'numTenants',
      flex: 1,
      headerName: t('subscriptions.list.numTenants'),
      renderCell: (params: GridRenderCellParams<Subscription>) => {
        const allowed = params.row.allowedTenants;
        const qty = params.row.numTenants;
        return t('subscriptions.list.numTenantsValue', { qty, allowed });
      },
    },
    {
      field: 'createdAt',
      flex: 2,
      headerName: t('subscriptions.list.createdAt'),
      valueFormatter: (params: GridValueFormatterParams) => formatLocalDate(params.value, DateTime.DATETIME_SHORT),
    },
    {
      field: 'action',
      cellClassName: 'more-cell',
      headerName: '',
      renderCell: (params: GridRenderCellParams<Subscription>) => {
        if (
          !params.row.status ||
          [SubscriptionStatus.INACTIVE, SubscriptionStatus.ACTIVE].includes(params.row.status)
        ) {
          const action = params.row.status === SubscriptionStatus.INACTIVE ? 'activate' : 'deactivate';
          return (
            <PkiMenu
              classNames="menu-right"
              name={`subscription-action-${params.id}`}
              icon={<PkiIcon icon={ellipsisHorizontal} />}
              iconSize="16px 16px"
            >
              <PkiMenuItem onClick={() => handleView(params.row)}>
                {t(isAdministrator ? 'subscriptions.list.viewAndEdit' : 'subscriptions.list.view')}
              </PkiMenuItem>
              {isAdministrator && (
                <>
                  <PkiMenuItem onClick={() => handleActivation(params.row)}>
                    {t(`subscriptions.${action}BtnLabel`)}
                  </PkiMenuItem>
                  {params.row.status === SubscriptionStatus.INACTIVE && (
                    <PkiMenuItem onClick={() => handleDelete(params.row)}>
                      {t('subscriptions.deleteBtnLabel')}
                    </PkiMenuItem>
                  )}
                </>
              )}
            </PkiMenu>
          );
        }
        return <div />;
      },
      sortable: false,
      width: 30,
    },
  ];

  let filteredList: Subscription[] = [...(list || [])];
  if (searchToken.length) {
    const term = searchToken.toLowerCase();
    filteredList = filteredList.filter((e) =>
      SEARCH_FIELDS.some((field) => String(e[field]).toLowerCase().includes(term))
    );
  }
  if (filters.length) {
    filteredList = filteredList.filter((e) => filters.some((filter) => e[filter.field] === filter.value));
  }

  /* ---------- Rendering ---------- */

  return (
    <>
      <header>
        <div className="pki-header-slot">
          <div className="pki-page-title">{t('subscriptions.title')}</div>
          {isAdministrator && (
            <PkiButton
              id="add-btn"
              label={t('subscriptions.addBtnLabel')}
              leftIcon={<PkiIcon icon={addCircle} />}
              onClick={handleCreateSubscription}
              size="small"
              variant="primary"
            />
          )}
        </div>
        <div className="pki-header-slot end">
          <PkiSelect
            disabled={list.length === 0}
            name="filter"
            onClose={handleFilter}
            placeholder={t('subscriptions.filter.title')}
            style={{ width: '160px' }}
            value={filters}
          >
            <div style={{ margin: '0 2px 2px' }}>
              <div className="pki-select-group">{t('subscriptions.filter.status')}</div>
              {SUBSCRIPTION_STATUS_OPTIONS.map((e) => (
                <PkiSelectOption
                  key={e.value}
                  className="filter"
                  group="status"
                  role="option"
                  selected={isFilterSelected('status', e.value, filters)}
                  aria-selected={isFilterSelected('status', e.value, filters) ? 'true' : undefined}
                  value={e.value}
                >
                  {e.label}
                </PkiSelectOption>
              ))}
            </div>
          </PkiSelect>
          <div className={cx('form-control', 'search')} style={{ width: '250px' }}>
            <input
              type="text"
              disabled={list.length === 0}
              placeholder={t('pki:form.searchPlaceholder')}
              onChange={handleSearch}
              value={search || ''}
            />
          </div>
        </div>
      </header>
      {filters.length > 0 && (
        <section className={styles.filters}>
          {filters.map((filter) => (
            <div key={`filter-${filter.field}${filter.value}`}>
              <PkiChip
                className={styles.pkiChip}
                label={formatFilterLabel(filter)}
                rightDeleteIcon
                onClick={() => handleDeleteFilter(filter)}
                onDelete={() => handleDeleteFilter(filter)}
              />
            </div>
          ))}
          <PkiButton
            classNames={styles.clearFilterBtn}
            label={t('clearFiltersBtnLabel')}
            onClick={handleClearFilters}
            variant="text-only"
          />
        </section>
      )}
      <div className={cx('content-inner-container', styles.contentInnerContainer)}>
        <section className={styles.list}>
          {list.length > 0 || isLoading ? (
            <div className="pki-data-grid">
              <DataGrid
                autoPageSize
                columns={columns}
                density="compact"
                disableColumnMenu
                loading={isLoading}
                onSortModelChange={(model: GridSortModel) => setSortModel(model)}
                pagination
                rows={filteredList}
                sortModel={sortModel}
              />
            </div>
          ) : (
            <div className={styles.noDataContainer}>
              <div className={styles.captionContainer}>
                <PkiIcon icon={subscriptions} />
                <div className={styles.caption}>{t('subscriptions.caption')}</div>
                {isAdministrator && (
                  <PkiButton
                    id="add-btn-2"
                    label={t('subscriptions.addBtnLabel')}
                    leftIcon={<PkiIcon icon={addCircle} />}
                    onClick={handleCreateSubscription}
                    size="small"
                    variant="primary"
                  />
                )}
              </div>
            </div>
          )}
        </section>
      </div>
      {isAdministrator && selectedSubscription && (
        <>
          <ActivationSubscriptionModal
            currentResource={selectedSubscription!}
            onClose={handleConfirmActivation}
            open={openConfirmActivation}
            operation={selectedSubscription.status === SubscriptionStatus.ACTIVE ? 'deactivate' : 'activate'}
            tenantList={tenantList}
          />
          <DeleteSubscriptionModal
            currentResource={selectedSubscription!}
            onClose={handleConfirmDelete}
            open={openConfirmDelete}
            tenants={tenantList}
          />
        </>
      )}
    </>
  );
};
SubscriptionList.displayName = 'SubscriptionList';
