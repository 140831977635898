import i18n, { InitOptions } from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import pki_en from './pki.en.json';
import pki_es from './pki.es.json';
import pki_de from './pki.de.json';
import pki_fi from './pki.fi.json';
import pki_jp from './pki.ja.json';

export interface LocaleResource {
  name: string;
  lang: string;
  props: object;
}

/**
 * Returns an {@link InitOptions} configuration object for use in initializing the i18n framework with the given
 * application-specific locale resources.
 *
 * @example
 * import i18n, { getInitOptions, LocaleResource} from '@pki-workflow-3/shared/lang';
 * import my_en from '...';
 * import my_de from '...';
 * import my_jp from '...';
 *
 * const myLocaleResources: LocaleResource[] = [
 *  { name: 'common', lang: 'en', props: my_en },
 *  { name: 'common', lang: 'de', props: my_de },
 *  { name: 'common', lang: 'jp', props: my_jp },
 * ];
 * const options = getOptions(myLocaleResources);
 * i18n.init(options).then();
 *
 * @param localeResources
 */
export const getInitOptions = (localeResources: LocaleResource[]): InitOptions => {
  const resources = {
    en: { pki: pki_en },
    es: { pki: pki_es },
    de: { pki: pki_de },
    fi: { pki: pki_fi },
    jp: { pki: pki_jp },
  };
  localeResources.forEach((each) => {
    // @ts-ignore
    resources[each.lang] = { ...(resources[each.lang] || {}), [each.name]: each.props };
  });

  return {
    debug: false,
    ns: ['common'],
    defaultNS: 'common',
    fallbackLng: 'en',
    resources,
    load: 'languageOnly',
    interpolation: { escapeValue: false },
    react: {
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      nsMode: 'default',
      transSupportBasicHtmlNodes: true,
      useSuspense: true,
    },
  } as InitOptions;
};

i18n.use(Backend);
i18n.use(LanguageDetector);
i18n.use(initReactI18next);

export default i18n;
