import cx from 'classnames';
import React from 'react';

export type PkiSvg = { name: string; svgPath: typeof import('*.svg') };

type PkiIconProps = {
  className?: string;
  icon: PkiSvg;
  id?: string;
  style?: React.CSSProperties;
};

/**
 * Returns the given SVG icon as a React component with the given optional className, id and style props, with a
 * fallback as an IMG element.
 * @param props
 */
export const PkiIcon: React.FunctionComponent<React.PropsWithChildren<PkiIconProps>> = (props) => {
  const { className, icon, ...rest } = props;
  if (icon?.svgPath) {
    const qaIdentifier = `qa-${icon.name}`;
    if (typeof (icon.svgPath as unknown) === 'string') {
      // Fallback if the imported image is not transformed by the typing file image.d.ts
      return (
        <img
          src={String(icon.svgPath)}
          title={icon.name}
          alt={icon.name}
          className={cx(className, qaIdentifier)}
          {...rest}
        />
      );
    }
    // Return the JSX element
    const Component = icon.svgPath.ReactComponent;
    return <Component name={icon.name} className={cx(className, qaIdentifier)} {...rest} />;
  }
  return null;
};
PkiIcon.displayName = 'PkiIcon';
