import { useAuth0 } from '@auth0/auth0-react';
import { PkiAuth0AuthService } from '@nx-workspace/shared/auth';
import { CurrentUserFormData, ProfileMessages } from '@nx-workspace/shared/models';
import { NotificationServiceFactory } from '@nx-workspace/shared/notification';
import { UserProfile } from '@nx-workspace/shared/user-management';
import {
  FeatureConfig,
  PkiLayout,
  PkiLayoutSidebar,
  PkiNavigation,
} from '@software-platforms/design-system-components';
import {
  home,
  libraryBooks,
  peopleOutline,
  PkiIcon,
  subscriptions,
  webPage,
} from '@software-platforms/design-system-icons';
import { FeatureModule } from '@software-platforms/tenant-manager-ui/features';
import { CurrentUser, JwtClaimProperty } from '@software-platforms/tenant-manager-ui/models';
import { ServiceFactory } from '@software-platforms/tenant-manager-ui/services';
import { AppState, AuthActions } from '@software-platforms/tenant-manager-ui/store';
import React, { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { catchError, map } from 'rxjs';

export const Application: React.FunctionComponent = () => {
  const currentUser = useSelector((state: AppState) => state.auth.currentUser);
  const auth = useAuth0();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const [openProfile, setOpenProfile] = useState<boolean>(false);

  const productFeatures: FeatureConfig[] = [
    {
      id: '',
      feature: 'subscriptions',
      Icon: <PkiIcon icon={subscriptions} />,
      isAdminFeature: false,
      label: t('sidebar.subscriptions'),
      path: '/subscriptions',
      persona: '',
    },
    {
      id: '',
      feature: 'tenants',
      Icon: <PkiIcon icon={home} />,
      isAdminFeature: false,
      label: t('sidebar.tenants'),
      path: '/tenants',
      persona: '',
    },
    {
      id: '',
      feature: 'users',
      Icon: <PkiIcon icon={peopleOutline} />,
      isAdminFeature: false,
      label: t('sidebar.users'),
      path: '/users',
      persona: '',
    },
  ];

  const coreFeatures: FeatureConfig[] = [
    {
      id: '',
      feature: 'environments',
      Icon: <PkiIcon icon={webPage} />,
      isAdminFeature: false,
      label: t('sidebar.environments'),
      path: '/environments',
      persona: '',
    },
    {
      id: '',
      feature: 'audit-trail',
      Icon: <PkiIcon icon={libraryBooks} />,
      isAdminFeature: false,
      label: t('sidebar.auditTrail'),
      path: '/audit-trail',
      persona: '',
    },
  ];

  const { authService } = ServiceFactory.getServices();
  useEffect(() => {
    if (auth.isAuthenticated && auth.user) {
      if (authService && currentUser?.authUser.sub !== auth.user.sub) {
        // We must inject the auth object into our authService in order to maintain the same context.
        (authService as PkiAuth0AuthService).setContext(auth);

        const authUser = auth.user;
        if (authUser[JwtClaimProperty.CUSTOM_CLAIMS]) {
          try {
            authUser.customClaims = JSON.parse(authUser[JwtClaimProperty.CUSTOM_CLAIMS]);
            delete authUser[JwtClaimProperty.CUSTOM_CLAIMS];
          } catch (err) {
            // Drop through
          }
        }
        dispatch(AuthActions.setCurrentUser({ authUser } as CurrentUser));
      }
    }
  }, [auth, authService, currentUser, dispatch]);

  /* ---------- Actions ---------- */

  /**
   * Callback used in the User Profile feature to update the current user.
   * @param formData
   * @param displayMessages
   */
  const handleUpdateCurrentUser = (formData: CurrentUserFormData, displayMessages?: ProfileMessages) => {
    // Rename some fields to match what the backend is expecting
    if (formData.familyName) {
      formData.lastName = formData.familyName;
      delete formData.familyName;
    }
    if (formData.givenName) {
      formData.firstName = formData.givenName;
      delete formData.givenName;
    }
    const messages = displayMessages || { success: true, failure: true };
    return ServiceFactory.getServices()
      .userService.updateCurrentUser(formData)
      .pipe(
        map((result: any) => {
          if (messages) {
            const msg =
              typeof messages.success === 'string' ? messages.success : t('pki:currentUser.succeededToUpdate');
            NotificationServiceFactory.getInstance().show(msg, { type: 'success' });
            dispatch(AuthActions.updateCurrentUser(result));
          }
          return result;
        }),
        catchError((error) => {
          if (messages) {
            const msg = typeof messages.failure === 'string' ? messages.failure : t('pki:currentUser.failedToUpdate');
            NotificationServiceFactory.getInstance().showError(msg);
          }
          throw error;
        })
      );
  };

  const handleLogout = () => {
    AuthActions.flush();
    auth.logout({ returnTo: window.location.origin });
  };

  /* ---------- Rendering ---------- */

  const renderSidebar = () => (
    <PkiLayoutSidebar>
      <PkiNavigation
        productFeatures={productFeatures}
        coreFeatures={coreFeatures}
        coreFeaturesTitle={t('sidebar.coreFeatures')}
        currentUser={currentUser}
        productName={t('appName')}
        userProfilePath={`${location.pathname}?userProfile=true`}
      />
    </PkiLayoutSidebar>
  );

  return (
    <PkiLayout sidebar={renderSidebar()} style={{ paddingTop: '12px', backgroundColor: '#f2f3f8' }}>
      <div className="content">
        <FeatureModule />
      </div>
      <UserProfile
        currentUser={currentUser}
        features={[]}
        i18n={getI18n()}
        logoutBtnLabel={t('userProfile.logoutBtnLabel')}
        onToggleProfile={setOpenProfile}
        onLogout={handleLogout}
        onUpdateCurrentUser={handleUpdateCurrentUser}
        open={openProfile}
        title={t('userProfile.title')}
      />
    </PkiLayout>
  );
};
Application.displayName = 'Application';
