import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PkiAvatar } from '../pki-avatar/pki-avatar';
import { CurrentUser, getUserLocaleRoles } from '../pki-utils';
import './pki-user-profile.scss';

export interface PkiUserProfileProps {
  className?: string;
  path?: string; // Path to a user-profile page
  style?: React.CSSProperties;
  currentUser?: CurrentUser | null;
}

export const PkiUserProfile: React.FunctionComponent<React.PropsWithChildren<PkiUserProfileProps>> = (props) => {
  const { className, path = '', style, currentUser } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const clickable = !!path;

  const [isActive, setIsActive] = useState<boolean>(false);
  useLayoutEffect(() => {
    setIsActive(path === location.pathname);
  }, [location.pathname]);

  let name = currentUser?.authUser?.name || 'Unknown';
  if (currentUser?.appUser?.givenName || currentUser?.appUser?.familyName) {
    name = `${currentUser?.appUser?.givenName || ''} ${currentUser?.appUser?.familyName || ''}`.trim();
  } else if (currentUser?.authUser?.given_name || currentUser?.authUser?.family_name) {
    name = `${currentUser?.authUser?.given_name || ''} ${currentUser?.authUser?.family_name || ''}`.trim();
  }
  const localeRoles = getUserLocaleRoles(currentUser);

  const handleClick = () => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <div className={cx('pki-user-profile', className)} style={style}>
      <PkiAvatar
        firstName={currentUser?.appUser?.givenName || currentUser?.authUser?.given_name || ''}
        surname={currentUser?.appUser?.familyName || currentUser?.authUser?.family_name || ''}
        picture={currentUser?.authUser?.picture}
      />
      <div
        className={cx('user-container', { clickable, active: isActive })}
        onClick={handleClick}
        role={clickable ? 'button' : undefined}
      >
        <div className="user-name">{name}</div>
        <div className="user-role">{localeRoles}</div>
      </div>
    </div>
  );
};
PkiUserProfile.displayName = 'PkiUserProfile';
