import { NotificationServiceFactory } from '@nx-workspace/shared/notification';
import { Tenant } from '@software-platforms/tenant-manager-ui/models';
import { Services } from '@software-platforms/tenant-manager-ui/services';
import { getI18n } from 'react-i18next';
import { Epic, ofType } from 'redux-observable';
import { catchError, map, of, switchMap } from 'rxjs';
import { TenantAction, TenantActions, TenantActionType } from '../actions/tenant.actions';
import { AppState } from '../model.state';

export const fetchTenantsEpic: Epic<TenantAction, TenantAction, AppState, Services> = (
  action$,
  state$,
  { tenantService }
) =>
  action$.pipe(
    ofType(TenantActionType.FETCH_TENANTS),
    switchMap(() =>
      tenantService.fetchTenants().pipe(
        map((list: Tenant[]) => TenantActions.fetchTenantsSucceeded(list)),
        catchError((error: any) => of(TenantActions.fetchTenantsFailed(error)))
      )
    )
  );

export const fetchTenantEpic: Epic<TenantAction, TenantAction, AppState, Services> = (
  action$,
  state$,
  { tenantService }
) =>
  action$.pipe(
    ofType(TenantActionType.FETCH_TENANT),
    switchMap((action: TenantAction) =>
      tenantService.fetchTenant(action.meta!.id!).pipe(
        map((resource: Tenant) => TenantActions.fetchTenantSucceeded(action.meta!.id!, [resource])),
        catchError((error: any) => of(TenantActions.fetchTenantFailed(action.meta!.id!, error)))
      )
    )
  );

export const createTenantEpic: Epic<TenantAction, TenantAction, AppState, Services> = (
  action$,
  state$,
  { tenantService }
) =>
  action$.pipe(
    ofType(TenantActionType.CREATE_TENANT),
    switchMap((action: TenantAction) =>
      tenantService.createTenant(action.meta!.formData!).pipe(
        map((resource) => {
          NotificationServiceFactory.getInstance().show(getI18n().t('tenants.form.createSuccess'), { type: 'success' });
          return TenantActions.createTenantSucceeded(action.meta!.formData!, [resource]);
        }),
        catchError((error: any) => {
          NotificationServiceFactory.getInstance().show(error.message, { type: 'error' });
          return of(TenantActions.createTenantFailed(action.meta!.formData!, error));
        })
      )
    )
  );

export const updateTenantEpic: Epic<TenantAction, TenantAction, AppState, Services> = (
  action$,
  state$,
  { tenantService }
) =>
  action$.pipe(
    ofType(TenantActionType.UPDATE_TENANT),
    switchMap((action: TenantAction) =>
      tenantService.updateTenant(action.meta!.id!, action.meta!.formData!).pipe(
        map((resource) => {
          NotificationServiceFactory.getInstance().show(getI18n().t('tenants.form.updateSuccess'), { type: 'success' });
          return TenantActions.updateTenantSucceeded(action.meta!.id!, action.meta!.formData!, [resource]);
        }),
        catchError((error: any) => {
          NotificationServiceFactory.getInstance().show(error.message, { type: 'error' });
          return of(TenantActions.updateTenantFailed(action.meta!.id!, action.meta!.formData!, error));
        })
      )
    )
  );

export const deleteTenantEpic: Epic<TenantAction, TenantAction, AppState, Services> = (
  action$,
  state$,
  { tenantService }
) =>
  action$.pipe(
    ofType(TenantActionType.DELETE_TENANT),
    switchMap((action: TenantAction) =>
      tenantService.deleteTenant(action.meta!.id!).pipe(
        map(() => {
          NotificationServiceFactory.getInstance().show(getI18n().t('tenants.form.deleteSuccess'), { type: 'success' });
          return TenantActions.deleteTenantSucceeded(action.meta!.id!);
        }),
        catchError((error: any) => {
          NotificationServiceFactory.getInstance().show(error.message, { type: 'error' });
          return of(TenantActions.deleteTenantFailed(action.meta!.id!, error));
        })
      )
    )
  );

export const deactivateTenantEpic: Epic<TenantAction, TenantAction, AppState, Services> = (
  action$,
  state$,
  { tenantService }
) =>
  action$.pipe(
    ofType(TenantActionType.DEACTIVATE_TENANT),
    switchMap((action: TenantAction) =>
      tenantService.deactivateTenant(action.meta!.id!).pipe(
        map((resource) => {
          NotificationServiceFactory.getInstance().show(getI18n().t('tenants.deactivate.success'), { type: 'success' });
          return TenantActions.deactivateTenantSucceeded(action.meta!.id!, [resource]);
        }),
        catchError((error: any) => {
          NotificationServiceFactory.getInstance().show(error.message, { type: 'error' });
          return of(TenantActions.deactivateTenantFailed(action.meta!.id!, error));
        })
      )
    )
  );

export const reactivateTenantEpic: Epic<TenantAction, TenantAction, AppState, Services> = (
  action$,
  state$,
  { tenantService }
) =>
  action$.pipe(
    ofType(TenantActionType.REACTIVATE_TENANT),
    switchMap((action: TenantAction) =>
      tenantService.reactivateTenant(action.meta!.id!).pipe(
        map((resource) => {
          NotificationServiceFactory.getInstance().show(getI18n().t('tenants.activate.success'), { type: 'success' });
          return TenantActions.reactivateTenantSucceeded(action.meta!.id!, [resource]);
        }),
        catchError((error: any) => {
          NotificationServiceFactory.getInstance().show(error.message, { type: 'error' });
          return of(TenantActions.reactivateTenantFailed(action.meta!.id!, error));
        })
      )
    )
  );
