import React from 'react';
import { getI18n } from 'react-i18next';

interface OwnProps {
  onError: (error: Error, errorInfo: React.ErrorInfo) => void;
  children?: React.ReactNode;
}

interface OwnState {
  hasError: boolean;
}

/**
 * UI Error handler component. Can be introduced for any logical bounded context.
 * @see https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component<OwnProps, OwnState> {
  constructor(props: OwnProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.onError(error, errorInfo);
  }

  render() {
    const { t } = getI18n();
    if (this.state.hasError) {
      return <h3 style={{ textAlign: 'center', marginTop: '50%' }}>{String(t('pki:error.unexpectedError'))}</h3>;
    }
    return this.props.children;
  }
}
