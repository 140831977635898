import { Direction } from '@nx-workspace/shared/models';
import { UserStatus } from '@software-platforms/tenant-manager-ui/models';
import { Reducer } from 'redux';
import { UserAction, UserActionType } from '../actions/user.actions';
import { initialUserState, UserState } from '../state/user.state';
import { getComparator } from '../utils/user.comparator';

export const userReducer: Reducer<UserState, UserAction> = (state = initialUserState, action): UserState => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case UserActionType.FETCH_USERS:
    case UserActionType.FETCH_USER:
      return { ...state, isLoading: true };

    case UserActionType.FETCH_USERS_SUCCEEDED:
      return { ...state, list: action.payload || [], filteredList: action.payload || [], isLoading: false };

    case UserActionType.FETCH_USER_SUCCEEDED:
      return { ...state, currentResource: action.payload![0], isLoading: false };

    case UserActionType.CREATE_USER:
    case UserActionType.UPDATE_USER:
    case UserActionType.DELETE_USER:
    case UserActionType.DEACTIVATE_USER:
    case UserActionType.REACTIVATE_USER:
      return { ...state, isProcessing: true };

    case UserActionType.CREATE_USER_SUCCEEDED: {
      const clonedList = [...state.list];
      const clonedFilteredList = [...state.filteredList];
      const currentResource = action.payload?.length ? action.payload[0] : null;
      if (currentResource) {
        const isDescending = state.sortDirection === 'desc';
        clonedList.push(currentResource);
        clonedFilteredList.push(currentResource);
        clonedFilteredList.sort(getComparator(state.sortKey, isDescending));
      }
      return { ...state, list: clonedList, filteredList: clonedFilteredList, isProcessing: false };
    }

    case UserActionType.UPDATE_USER_SUCCEEDED: {
      const currentResource = action.payload![0];
      const clonedList = [...state.list];
      let index = clonedList.findIndex((e) => e.userId === currentResource.userId);
      if (index > -1) {
        clonedList[index] = currentResource;
      }
      const clonedFilteredList = [...state.filteredList];
      index = clonedFilteredList.findIndex((e) => e.userId === currentResource.userId);
      if (index > -1) {
        clonedFilteredList[index] = currentResource;
      }
      return { ...state, list: clonedList, filteredList: clonedFilteredList, isProcessing: false };
    }

    case UserActionType.DEACTIVATE_USER_SUCCEEDED: {
      const clonedList = [...state.list];
      let index = clonedList.findIndex((e) => e.userId === action.meta!.id);
      if (index > -1) {
        clonedList[index].status = UserStatus.INACTIVE;
      }
      const clonedFilteredList = [...state.filteredList];
      index = clonedFilteredList.findIndex((e) => e.userId === action.meta!.id);
      if (index > -1) {
        clonedFilteredList[index].status = UserStatus.INACTIVE;
      }
      const clonedResource = state.currentResource;
      if (clonedResource) {
        clonedResource.status = UserStatus.INACTIVE;
      }
      return {
        ...state,
        list: clonedList,
        filteredList: clonedFilteredList,
        currentResource: clonedResource,
        isProcessing: false,
      };
    }

    case UserActionType.REACTIVATE_USER_SUCCEEDED: {
      const clonedList = [...state.list];
      let index = clonedList.findIndex((e) => e.userId === action.meta!.id);
      if (index > -1) {
        clonedList[index].status = UserStatus.ACTIVE;
      }
      const clonedFilteredList = [...state.filteredList];
      index = clonedFilteredList.findIndex((e) => e.userId === action.meta!.id);
      if (index > -1) {
        clonedFilteredList[index].status = UserStatus.ACTIVE;
      }
      const clonedResource = state.currentResource;
      if (clonedResource) {
        clonedResource.status = UserStatus.ACTIVE;
      }
      return {
        ...state,
        list: clonedList,
        filteredList: clonedFilteredList,
        currentResource: clonedResource,
        isProcessing: false,
      };
    }

    case UserActionType.DELETE_USER_SUCCEEDED: {
      const clonedList = [...state.list];
      let index = clonedList.findIndex((e) => e.userId === action.meta!.id);
      if (index > -1) {
        clonedList.splice(index, 1);
      }
      const clonedFilteredList = [...state.filteredList];
      index = clonedFilteredList.findIndex((e) => e.userId === action.meta!.id);
      if (index > -1) {
        clonedFilteredList.splice(index, 1);
      }
      return {
        ...state,
        list: clonedList,
        filteredList: clonedFilteredList,
        isProcessing: false,
      };
    }

    case UserActionType.FETCH_USERS_FAILED:
    case UserActionType.FETCH_USER_FAILED:
      return { ...state, error: action.error, isLoading: false };

    case UserActionType.CREATE_USER_FAILED:
    case UserActionType.UPDATE_USER_FAILED:
    case UserActionType.DELETE_USER_FAILED:
    case UserActionType.DEACTIVATE_USER_FAILED:
    case UserActionType.REACTIVATE_USER_FAILED:
      return { ...state, error: action.error, isProcessing: false };

    case UserActionType.SORT_LIST: {
      const sortKey = action.meta!.sortKey || 'none';
      const sortDirection = action.meta!.sortDirection || Direction.ASC;
      const isDescending = sortDirection === Direction.DESC;
      const clonedFilteredList = [...state.filteredList].sort(getComparator(sortKey, isDescending));
      return { ...state, filteredList: clonedFilteredList };
    }

    default:
      return state;
  }
};
