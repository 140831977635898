import { Observable } from 'rxjs';

export interface AuthService {
  /**
   * Returns the current authenticated user from the id_token
   */
  fetchAuthUser(): Observable<any>;

  /**
   * Returns an access token from the underlying IDP, or an empty observable if no IDP is defined.
   * @param scope
   */
  getAccessToken(scope?: string): Observable<string>;

  /**
   * Returns true of th current user is authenticated.
   */
  isAuthenticated(): Observable<boolean>;

  /**
   * Clears the application session and redirects to the underlying IDP logout url.
   */
  logout(): void;

  /**
   * Sets an authorization header on the given request configuration with an access token from the underlying IDP.
   * @param requestConfig
   */
  setAuthorizationHeader<P>(requestConfig: P): Promise<P>;
}
