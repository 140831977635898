import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React from 'react';
import { PkiDialogContent } from './pki-dialog-content';
import { PkiDialogFooter } from './pki-dialog-footer';
import { PkiDialogHeader } from './pki-dialog-header';
import './pki-dialog.scss';

type OnCloseReason = 'backdropClick' | 'escapeKeyDown';

export type PkiDialogProps = {
  className?: string;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  onBackdropClick?: (event: React.MouseEvent) => void;
  onClose: {
    bivarianceHack(event: Object, reason?: OnCloseReason): void;
  }['bivarianceHack'];
  onEscapeKeyDown?: (event: React.KeyboardEvent) => void;
  open: boolean;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  // Header props
  header?: JSX.Element;
  title?: string;
  type?: 'warning' | 'info';

  // Content props
  message?: string | JSX.Element;

  // Footer props
  cancelBtnLabel?: string;
  cancelBtnVariant?: 'primary' | 'secondary' | 'tertiary' | '';
  confirmation?: boolean;
  confirmBtnLabel?: string;
  footer?: JSX.Element;
};

/**
 * Renders a modal dialog.
 *
 * For convenience, this component can be used as follows:
 * - <strong>Simple modals</strong>: Provide string values for `title` and `message`. Optionally, set the `confirmation`
 * flag to `true` to automatically add Cancel and OK buttons. Further, the buttons can be passed custom labels and
 * variants.
 *
 * - <strong>Named slots</strong>: Provide React components as `header`, `message` and `footer` properties.
 *
 * - <strong>Complex modals</strong>: Provide all internal components as children.
 *
 * <p>To render the modal, set the `open` prop to true. To close the modal, set the property to `false`.</p>
 *
 * <p>By default, this modal is shipped without a `close` button. Closing the modal is accomplished by either clicking
 * the backdrop outside the modal, pressing the escape key, or clicking on of the user-provided buttons. In all
 * cases, an `onClose` function must be passed to set the `open` property to false. To prevent dismissing the modal
 * without operating any of the user-defined buttons, the backdrop click and escape key can be disabled.</p>
 * @param props
 * @constructor
 */
export const PkiDialog: React.FunctionComponent<React.PropsWithChildren<PkiDialogProps>> = (props) => {
  const {
    cancelBtnLabel,
    cancelBtnVariant,
    children,
    className,
    confirmation,
    confirmBtnLabel,
    disableBackdropClick,
    disableEscapeKeyDown,
    open,
    size = 'md',
    title,
    type = 'warning',
  } = props;
  const mouseDownTarget = React.useRef<any>();

  /* ---------- Container functions ---------- */

  const handleBackdropClick = (event: React.MouseEvent) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    if (props.onBackdropClick) {
      props.onBackdropClick(event);
    }
    if (!disableBackdropClick && props.onClose) {
      props.onClose(event, 'backdropClick');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== 'Escape') {
      return;
    }
    if (props.onEscapeKeyDown) {
      props.onEscapeKeyDown(event);
    }
    if (!disableEscapeKeyDown) {
      event.stopPropagation();
    }
    if (props.onClose) {
      props.onClose(event, 'escapeKeyDown');
    }
  };

  /* ---------- Dialog functions ---------- */

  const handleMouseDown = (event: React.MouseEvent) => {
    mouseDownTarget.current = event.target;
  };

  const handleMouseUp = (event: React.MouseEvent) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    if (event.target !== mouseDownTarget.current) {
      return;
    }
    mouseDownTarget.current = null;
    if (props.onBackdropClick) {
      props.onBackdropClick(event);
    }
    if (!disableBackdropClick && props.onClose) {
      props.onClose(event, 'backdropClick');
    }
  };

  return (
    <div
      className={cx('pki-dialog-root', { open })}
      role="presentation"
      onClick={handleBackdropClick}
      aria-hidden={!open}
    >
      <div className={cx('pki-dialog-backdrop', { open })} onClick={handleBackdropClick} aria-hidden={!open} />
      <div
        className={cx('pki-dialog-container', { open })}
        role="presentation"
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        aria-hidden={!open}
      >
        <div
          className={cx('pki-dialog', className, size)}
          role="dialog"
          aria-describedby="pki-dialog-content"
          aria-hidden={!open}
          aria-labelledby={title ? 'pki-dialog-header' : undefined}
        >
          {(title || props.header) && (
            <PkiDialogHeader title={title} type={type}>
              {props.header}
            </PkiDialogHeader>
          )}
          {props.message && <PkiDialogContent>{props.message}</PkiDialogContent>}
          {children}
          {(confirmation || props.footer) && (
            <PkiDialogFooter
              confirmation={confirmation}
              onClose={props.onClose}
              confirmBtnLabel={confirmBtnLabel}
              cancelBtnLabel={cancelBtnLabel}
              cancelBtnVariant={cancelBtnVariant}
              type={type}
            >
              {props.footer}
            </PkiDialogFooter>
          )}
        </div>
      </div>
    </div>
  );
};
PkiDialog.displayName = 'PkiDialog';

export default PkiDialog;
