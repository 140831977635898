import { User } from '@software-platforms/tenant-manager-ui/models';

export interface UserState {
  list: User[];
  filteredList: User[];
  currentResource: User | null;
  isLoading: boolean;
  isProcessing: boolean;
  error: any;
  sortKey: string;
  sortDirection: string;
}

export const initialUserState: UserState = {
  list: [],
  filteredList: [],
  currentResource: null,
  isLoading: false,
  isProcessing: false,
  error: null,
  sortKey: 'lastName',
  sortDirection: 'asc',
};
