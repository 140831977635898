import { axiosErrorHandler } from '@nx-workspace/shared/services';
import {
  Tenant,
  TenantQueryRequest,
  TenantRequest,
  TenantResource,
} from '@software-platforms/tenant-manager-ui/models';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { from, Observable } from 'rxjs';
import { TenantService } from '../interfaces';
import { TenantMapper } from '../mappers/tenant.mapper';

/**
 * Concrete implementation of a {@link TenantService} for Axios.
 */
export class AxiosTenantService implements TenantService {
  offboardConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'offboard:tenant' } };
  onboardConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'onboard:tenant' } };
  readConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'read:tenant' } };
  writeConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'write:tenant' } };

  constructor(private readonly http: AxiosInstance) {}

  fetchTenants(query?: TenantQueryRequest): Observable<Tenant[]> {
    const url = query ? `/tenants?${qs.stringify(query)}` : '/tenants';
    return from(
      this.http
        .get<TenantResource[]>(url, this.readConfig)
        .then((response) => response.data.map((e) => TenantMapper.from(e)))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  fetchTenant(id: string): Observable<Tenant> {
    const url = `/tenants/${id}`;
    return from(
      this.http
        .get<TenantResource>(url, this.readConfig)
        .then((resource) => TenantMapper.from(resource.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  createTenant(formData: TenantRequest): Observable<Tenant> {
    const url = '/tenants/onboard';
    return from(
      this.http
        .post<TenantResource>(url, formData, this.onboardConfig)
        .then((resource) => TenantMapper.from(resource.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  deleteTenant(id: string): Observable<any> {
    const url = `/tenants/${id}/offboard`;
    return from(
      this.http
        .post<TenantResource>(url, {}, this.offboardConfig)
        .then(() => null)
        .catch((error) => axiosErrorHandler(error))
    );
  }

  deactivateTenant(id: string): Observable<Tenant> {
    const url = `/tenants/${id}/deactivate`;
    return from(
      this.http
        .post<TenantResource>(url, {}, this.writeConfig)
        .then((resource) => TenantMapper.from(resource.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  reactivateTenant(id: string): Observable<Tenant> {
    const url = `/tenants/${id}/activate`;
    return from(
      this.http
        .post<TenantResource>(url, {}, this.writeConfig)
        .then((resource) => TenantMapper.from(resource.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  updateTenant(id: string, formData: TenantRequest): Observable<Tenant> {
    const url = `/tenants/${id}`;
    return from(
      this.http
        .patch<TenantResource>(url, formData, this.writeConfig)
        .then((resource) => TenantMapper.from(resource.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }
}
