import { AuditService } from '@nx-workspace/shared/audit-trail';
import { AuthService } from '@nx-workspace/shared/auth';
import { EnvironmentService } from './environment.service';
import { SubscriptionService } from './subscription.service';
import { TenantService } from './tenant.service';
import { UserService } from './user.service';

/**
 * Describes the service provider for HTTP client operations.
 * @property auditService         Manages audit operations
 * @property authService          Manages communication with the identity provider
 * @property environmentService   Manages deployment environments
 * @property subscriptionService  Manages customer subscription operations
 * @property tenantService        Manages tenant operations
 * @property userService          Manages application user operations
 */
export interface Services {
  auditService: AuditService;
  authService: AuthService;
  environmentService: EnvironmentService;
  subscriptionService: SubscriptionService;
  tenantService: TenantService;
  userService: UserService;
}
