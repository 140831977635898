import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { CSSProperties } from 'react';
import './pki-layout.scss';

export type PkiLayoutHeaderProps = {
  showShadow?: boolean;
  showBorder?: boolean;
  className?: string;
  style?: CSSProperties;
};

export const PkiLayoutHeader: React.FunctionComponent<React.PropsWithChildren<PkiLayoutHeaderProps>> = (props) => {
  const { className, showBorder, showShadow, style } = props;
  return (
    <header
      className={cx('pki-layout-header', className, { bottomBorder: showBorder, shadow: showShadow })}
      style={style}
    >
      {props.children}
    </header>
  );
};
PkiLayoutHeader.displayName = 'PkiLayoutHeader';

export default PkiLayoutHeader;
