import { FormControl } from '@software-platforms/design-system-components';

export interface TenantForm {
  tenantName: FormControl;
  companyName: FormControl;
  dnsLabel: FormControl;
  environmentId: FormControl;
  orderNumber: FormControl;
  description: FormControl;
  userEmail: FormControl;
  subscriptionId: FormControl;
}
