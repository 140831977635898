import { Mapper } from '@nx-workspace/shared/models';
import { User, UserResource } from '@software-platforms/tenant-manager-ui/models';
import { DateTime } from 'luxon';

export const UserMapper: Mapper<UserResource, User> = {
  from(resource: UserResource): User {
    return {
      ...resource,
      createdAt: DateTime.fromISO(resource.createdAt),
      updatedAt: DateTime.fromISO(resource.updatedAt || resource.createdAt),
      lastLogin: resource.lastLogin ? DateTime.fromISO(resource.lastLogin) : undefined,
      lastPasswordReset: resource.lastPasswordReset ? DateTime.fromISO(resource.lastPasswordReset) : undefined,
    };
  },

  to(entity: User): UserResource {
    return {
      ...entity,
      createdAt: entity.createdAt.toISO(),
      updatedAt: entity.updatedAt.toISO(),
      lastLogin: entity.lastLogin ? entity.lastLogin.toISO() : '',
      lastPasswordReset: entity.lastPasswordReset ? entity.lastPasswordReset.toISO() : '',
    };
  },
};
