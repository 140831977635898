import { Comparator } from '@nx-workspace/shared/models';
import { Subscription } from '@software-platforms/tenant-manager-ui/models';

const licenseAscComparator: Comparator<Subscription> = (a, b): number => a.license?.localeCompare(b.license);
const licenseDescComparator: Comparator<Subscription> = (a, b): number => b.license?.localeCompare(a.license);

const subscriptionIdAscComparator: Comparator<Subscription> = (a, b): number =>
  a.orgSubscriptionId.localeCompare(b.orgSubscriptionId);
const subscriptionIdDescComparator: Comparator<Subscription> = (a, b): number =>
  b.orgSubscriptionId.localeCompare(a.orgSubscriptionId);

const statusAscComparator: Comparator<Subscription> = (a, b): number => a.status.localeCompare(b.status);
const statusDescComparator: Comparator<Subscription> = (a, b): number => b.status.localeCompare(a.status);

const companyNameAscComparator: Comparator<Subscription> = (a, b): number => a.companyName.localeCompare(b.companyName);
const companyNameDescComparator: Comparator<Subscription> = (a, b): number =>
  b.companyName.localeCompare(a.companyName);

const createdAtAscComparator: Comparator<Subscription> = (a, b): number =>
  a.createdAt.toMillis() < b.createdAt.toMillis() ? -1 : a.createdAt.toMillis() > b.createdAt.toMillis() ? 1 : 0;
const createdAtDescComparator: Comparator<Subscription> = (a, b): number =>
  b.createdAt.toMillis() < a.createdAt.toMillis() ? -1 : b.createdAt.toMillis() > a.createdAt.toMillis() ? 1 : 0;

/**
 * Returns a {@link Comparator} for a list of {@link Subscription} instances.
 * @param property      The property name. Options are 'license', 'subscriptionId', 'status', 'companyName',
 *                      'numTenants' and 'createdAt'.
 * @param isDescending
 */
export const getComparator = (property: string, isDescending?: boolean): Comparator<Subscription> => {
  switch (property) {
    case 'subscriptionId':
      return isDescending ? subscriptionIdDescComparator : subscriptionIdAscComparator;
    case 'status':
      return isDescending ? statusDescComparator : statusAscComparator;
    case 'companyName':
      return isDescending ? companyNameDescComparator : companyNameAscComparator;
    case 'createdAt':
      return isDescending ? createdAtDescComparator : createdAtAscComparator;
    default:
      return isDescending ? licenseDescComparator : licenseAscComparator;
  }
};
