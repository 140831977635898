import {
  PkiDialog,
  PkiDialogContent,
  PkiDialogFooter,
  PkiDialogHeader,
} from '@software-platforms/design-system-components';
import { Subscription, Tenant } from '@software-platforms/tenant-manager-ui/models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './delete-subscription-modal.module.scss';

type OwnProps = {
  currentResource: Subscription;
  onClose: (isConfirmed: boolean) => void;
  open: boolean;
  tenants: Tenant[];
};

export const DeleteSubscriptionModal: React.FunctionComponent<OwnProps> = (props) => {
  const { open, tenants } = props;
  const { t } = useTranslation();

  return (
    <PkiDialog disableBackdropClick onClose={props.onClose} open={open} size="sm" type="warning">
      <PkiDialogHeader type="warning" title={t(`subscriptions.delete.title`)} />
      <PkiDialogContent>
        <div>{t(`subscriptions.delete.message1`)}</div>
        <div className={styles.dialogList}>
          {tenants.length > 0 ? (
            tenants.map((e) => <div key={e.id}>{e.tenantName}</div>)
          ) : (
            <div>{t(`subscriptions.delete.noTenants`)}</div>
          )}
        </div>
        <div>{t(`subscriptions.delete.message2`)}</div>
      </PkiDialogContent>
      <PkiDialogFooter
        confirmation
        type="warning"
        onClose={props.onClose}
        cancelBtnLabel={t('pki:form.cancel')}
        confirmBtnLabel={t(`subscriptions.deleteBtnLabel`)}
      />
    </PkiDialog>
  );
};
DeleteSubscriptionModal.displayName = 'DeleteSubscriptionModal';
