import { AuditService } from './audit.service';
import { AxiosAuditService } from './axios-audit.service';
import { AxiosInstance } from 'axios';

/**
 * Returns a singleton instance of an AuditService.
 */
export class AuditServiceFactory {
  private static instance: AuditService;

  public static getInstance(httpClient?: AxiosInstance): AuditService {
    if (!AuditServiceFactory.instance) {
      AuditServiceFactory.instance = new AxiosAuditService(httpClient);
    }
    return AuditServiceFactory.instance;
  }
}
