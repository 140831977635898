import { showInvalidState } from '@software-platforms/design-system-components';
import { PkiIcon, subscriptions, warning } from '@software-platforms/design-system-icons';
import { SubscriptionForm } from '@software-platforms/tenant-manager-ui/models';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionViewMode } from '../subscriptions';

type OwnProps = {
  form?: SubscriptionForm;
  numTenants: number;
  onBlur: (controlName: string) => void;
  onChange: (controlName: string, newValue: any) => void;
  isProcessing: boolean;
  viewMode: SubscriptionViewMode;
};

export const SubscriptionEntry: React.FunctionComponent<OwnProps> = (props) => {
  const { form, isProcessing, numTenants, viewMode } = props;
  const { t } = useTranslation();
  if (!form) {
    return null;
  }

  const isReadOnly = viewMode === 'view';
  const nonEditable = viewMode !== 'create';
  // From user requirements: Do not allow the number to be made lower than the existing connected tenants.
  const minAllowedTenants = numTenants === 0 ? 1 : numTenants;

  return (
    <div className="form-body">
      <div className="form-lede">
        <PkiIcon icon={subscriptions} />
        <div>{t('subscriptions.form.lede')}</div>
      </div>
      <div className="col-container">
        <div className="col form-group">
          <label htmlFor="license" className={cx({ required: !nonEditable })}>
            {t('subscriptions.form.licenseId')}
          </label>
          <div
            className={cx('form-control', {
              disabled: isProcessing || isReadOnly || nonEditable,
              invalid: showInvalidState(form.license, isReadOnly),
            })}
          >
            <input
              type="text"
              id="license"
              autoComplete="off"
              autoFocus
              disabled={isProcessing || nonEditable}
              onBlur={() => props.onBlur('license')}
              onChange={(event) => props.onChange('license', event.target.value)}
              readOnly={isReadOnly || nonEditable}
              value={form.license.value}
            />
          </div>
          <div className="form-message">
            {showInvalidState(form.license, isReadOnly) && (
              <>
                {form.license.errors!.required && (
                  <div className="error">
                    <PkiIcon icon={warning} />
                    <span>{t('subscriptions.form.requiredLicenseId')}</span>
                  </div>
                )}
                {form.license.errors!.duplicate && (
                  <div className="error">
                    <PkiIcon icon={warning} />
                    <span>{t('subscriptions.form.duplicateLicenseId')}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="col form-group">
          <label htmlFor="orgSubscriptionId" className={cx({ required: !nonEditable })}>
            {t('subscriptions.form.subscriptionId')}
          </label>
          <div
            className={cx('form-control', {
              disabled: isProcessing || isReadOnly || nonEditable,
              invalid: showInvalidState(form.orgSubscriptionId, isReadOnly),
            })}
          >
            <input
              type="text"
              id="orgSubscriptionId"
              autoComplete="off"
              disabled={isProcessing || nonEditable}
              onBlur={() => props.onBlur('orgSubscriptionId')}
              onChange={(event) => props.onChange('orgSubscriptionId', event.target.value)}
              readOnly={isReadOnly || nonEditable}
              value={form.orgSubscriptionId.value}
            />
          </div>
          <div className="form-message">
            {showInvalidState(form.orgSubscriptionId, isReadOnly) && (
              <>
                {form.orgSubscriptionId.errors!.required && (
                  <div className="error">
                    <PkiIcon icon={warning} />
                    <span>{t('subscriptions.form.requiredSubscriptionId')}</span>
                  </div>
                )}
                {form.orgSubscriptionId.errors!.duplicate && (
                  <div className="error">
                    <PkiIcon icon={warning} />
                    <span>{t('subscriptions.form.duplicateSubscriptionId')}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="col-container">
        <div className="col form-group">
          <label htmlFor="companyName" className={cx({ required: !isReadOnly })}>
            {t('subscriptions.form.companyName')}
          </label>
          <div
            className={cx('form-control', {
              disabled: isProcessing || isReadOnly,
              invalid: showInvalidState(form.companyName, isReadOnly),
            })}
          >
            <input
              type="text"
              id="companyName"
              autoComplete="off"
              disabled={isProcessing || isReadOnly}
              min="3"
              max="200"
              onBlur={() => props.onBlur('companyName')}
              onChange={(event) => props.onChange('companyName', event.target.value)}
              readOnly={isReadOnly}
              value={form.companyName.value}
            />
          </div>
          <div className="form-message">
            {showInvalidState(form.companyName, isReadOnly) && (
              <>
                {form.companyName.errors!.required && (
                  <div className="error">
                    <PkiIcon icon={warning} />
                    <span>{t('subscriptions.form.requiredCompanyName')}</span>
                  </div>
                )}
                {form.companyName.errors!.duplicate && (
                  <div className="error">
                    <PkiIcon icon={warning} />
                    <span>{t('subscriptions.form.duplicateCompanyName')}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="col form-group">
          <label htmlFor="allowedTenants">{t('subscriptions.form.allowedTenants')}</label>
          <div
            className={cx('form-control', {
              disabled: isProcessing || isReadOnly,
              invalid: showInvalidState(form.allowedTenants, isReadOnly),
            })}
          >
            <input
              type="number"
              id="allowedTenants"
              min={minAllowedTenants}
              step="1"
              max="999"
              disabled={isProcessing || isReadOnly}
              onBlur={() => props.onBlur('allowedTenants')}
              onChange={(event) => props.onChange('allowedTenants', event.target.value)}
              readOnly={isReadOnly}
              value={form.allowedTenants.value}
            />
          </div>
          <div className="form-message">
            {showInvalidState(form.allowedTenants, isReadOnly) && (
              <div className="error">
                <PkiIcon icon={warning} />
                <span>{t('subscriptions.form.invalidLimit', { min: numTenants || 1 })}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
SubscriptionEntry.displayName = 'SubscriptionEntry';
