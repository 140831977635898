import { PkiButton, PkiDialog } from '@software-platforms/design-system-components';
import { addCircle, arrowBack, PkiIcon } from '@software-platforms/design-system-icons';
import {
  Subscription,
  SubscriptionForm,
  SubscriptionStatus,
  Tenant,
  TenantStatus,
} from '@software-platforms/tenant-manager-ui/models';
import { ServiceFactory } from '@software-platforms/tenant-manager-ui/services';
import { AppState, SubscriptionActions, TenantActions } from '@software-platforms/tenant-manager-ui/store';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { TenantActionModal } from '../../tenants/tenant-modal/tenant-action-modal';
import { TenantLocationState } from '../../tenants/tenants';
import { SubscriptionEntry } from '../subscription-entry/subscription-entry';
import { ActivationSubscriptionModal } from '../subscription-modal/activation-subscription-modal';
import { SubscriptionLocationState, SubscriptionViewMode } from '../subscriptions';
import { SubscriptionTenantList } from './subscription-tenant-list';
import styles from './subscription-view.module.scss';

type OwnProps = {
  currentResource: Subscription | null;
  error: any;
  form?: SubscriptionForm;
  isAdministrator: boolean;
  isProcessing: boolean;
  onBlur: (controlName: string) => void;
  onChange: (controlName: string, newValue: any) => void;
  viewMode: SubscriptionViewMode;
};

export const SubscriptionView: React.FunctionComponent<OwnProps> = (props) => {
  const { currentResource, error, form, isAdministrator, isProcessing, viewMode } = props;
  const { t } = useTranslation();
  const activateAction = currentResource?.status === 'active' ? 'deactivate' : 'activate';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [tenantList, setTenantList] = useState<Tenant[]>([]);
  const fetchSubscriptionTenants = (subscriptionId) => {
    ServiceFactory.getServices()
      .tenantService.fetchTenants({ subscriptionId })
      .subscribe((list: Tenant[]) => {
        setTenantList(list);
      });
  };

  useEffect(() => {
    if (location.state) {
      const locationState = location.state as SubscriptionLocationState;
      const subscriptionId = locationState.id;
      dispatch(SubscriptionActions.fetchSubscription(subscriptionId));
      ServiceFactory.getServices()
        .tenantService.fetchTenants({ subscriptionId })
        .subscribe((list: Tenant[]) => {
          setTenantList(list);
        });
    }
  }, [dispatch, location.state]);

  // Navigate back to the subscription list after a successful deactivate/reactivation
  const [isUpdating, setUpdating] = useState<boolean>(false);
  useEffect(() => {
    if (!isProcessing && isUpdating) {
      setUpdating(false);
      if (!error) {
        navigate('/subscriptions');
      }
    }
  }, [error, isProcessing, isUpdating, navigate]);

  // Refresh the tenant list after a tenant has changed
  const [isTenantUpdating, setTenantUpdating] = useState<boolean>(false);
  const isTenantProcessing = useSelector((appState: AppState) => appState.tenants.isProcessing);
  useEffect(() => {
    if (location.state && !isTenantProcessing && isTenantUpdating) {
      const locationState = location.state as SubscriptionLocationState;
      const subscriptionId = locationState.id;
      setTenantUpdating(false);
      fetchSubscriptionTenants(subscriptionId);
    }
  }, [location.state, isTenantProcessing, isTenantUpdating]);

  /* ---------- Deactivate/Activate Actions ---------- */

  const [openConfirmAction, setOpenConfirmAction] = useState<boolean>(false);
  const handleAction = (event: React.MouseEvent) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    if (activateAction === 'deactivate') {
      setOpenConfirmAction(true);
    } else {
      // TODO Do we need a confirmation to reactivate a subscription?
      setUpdating(true);
      dispatch(SubscriptionActions.reactivateSubscription(currentResource!.id));
    }
  };
  const handleConfirmAction = (isConfirmed: any) => {
    setOpenConfirmAction(false);
    if (typeof isConfirmed === 'boolean' && isConfirmed) {
      setUpdating(true);
      dispatch(SubscriptionActions.deactivateSubscription(currentResource!.id));
    }
  };

  /* ---------- Other Subscription Actions ---------- */

  const handleCancel = (event: React.MouseEvent) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    navigate('/subscriptions');
  };
  const handleEdit = () => {
    const state: SubscriptionLocationState = { id: currentResource!.id, viewMode: 'edit' };
    navigate(`/subscriptions/${currentResource!.id}/edit`, { state });
  };

  /* ---------- Tenant Actions ---------- */

  const [openTenantLimit, setOpenTenantLimit] = useState<boolean>(false);
  const handleCreateTenant = () => {
    if (
      currentResource!.numTenants >= currentResource!.allowedTenants ||
      tenantList.length >= currentResource!.allowedTenants
    ) {
      setOpenTenantLimit(true);
    } else {
      dispatch(TenantActions.flush());
      navigate(`/tenants/create`, { state: { currentResource: currentResource!.id, viewMode: 'create' } });
    }
  };
  const handleConfirmTenantLimit = () => {
    setOpenTenantLimit(false);
  };

  const [openConfirmDeleteTenant, setOpenConfirmDeleteTenant] = useState<boolean>(false);
  const handleDeleteTenant = (tenant: Tenant) => {
    setSelectedTenant(tenant);
    if (!openConfirmDeleteTenant) {
      setOpenConfirmDeleteTenant(true);
    }
  };
  const handleConfirmDeleteTenant = (isConfirmed: boolean) => {
    setOpenConfirmDeleteTenant(false);
    if (isConfirmed && selectedTenant) {
      setTenantUpdating(true);
      dispatch(TenantActions.deleteTenant(selectedTenant.id));
    }
  };

  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
  const [openConfirmTenantAction, setOpenConfirmTenantAction] = useState<boolean>(false);
  const handleTenantAction = (selectedItem: Tenant) => {
    if ([TenantStatus.ACTIVE.toString(), TenantStatus.INACTIVE.toString()].includes(selectedItem.status)) {
      setSelectedTenant(selectedItem);
      if (!openConfirmTenantAction) {
        setOpenConfirmTenantAction(true);
      }
    }
  };
  const handleConfirmTenantAction = (isConfirmed: boolean) => {
    setOpenConfirmTenantAction(false);
    if (isConfirmed && selectedTenant) {
      setTenantUpdating(true);
      if (selectedTenant?.status === 'inactive') {
        dispatch(TenantActions.reactivateTenant(selectedTenant.id));
      } else {
        dispatch(TenantActions.deactivateTenant(selectedTenant.id));
      }
    }
  };
  const handleViewTenant = (selectedItem: Tenant) => {
    setSelectedTenant(selectedItem);
    const state: TenantLocationState = { id: selectedItem.id, viewMode: 'view' };
    navigate(`/tenants/${selectedItem.id}`, { state });
  };

  /* ---------- Rendering ---------- */

  const tenantAction = selectedTenant ? (selectedTenant.status === 'inactive' ? 'activate' : 'deactivate') : undefined;
  return (
    <>
      <header>
        <div className="pki-header-slot">
          <div className="pki-back-btn" onClick={handleCancel} role="button">
            <PkiIcon icon={arrowBack} />
          </div>
          <div className="pki-page-title">
            {t(isAdministrator ? 'subscriptions.page.viewAndEdit' : 'subscriptions.page.view')}
          </div>
        </div>
        <div className="pki-header-slot end">
          {isAdministrator && (
            <>
              <PkiButton
                id="cancel-btn"
                label={t('pki:form.cancel')}
                onClick={handleCancel}
                size="small"
                variant="secondary"
              />
              <PkiButton
                id="action-btn"
                label={t(`subscriptions.${activateAction}BtnLabel`)}
                onClick={handleAction}
                size="small"
                variant="secondary"
              />
              <PkiButton id="edit-btn" label={t('pki:form.edit')} onClick={handleEdit} size="small" variant="primary" />
            </>
          )}
          {!isAdministrator && (
            <PkiButton id="ok-btn" label={t('pki:form.ok')} onClick={handleCancel} size="small" variant="secondary" />
          )}
        </div>
      </header>
      <div className={cx('content-inner-container', 'form', styles.contentInnerContainer)}>
        <section className={cx('item', styles.item)}>
          <SubscriptionEntry
            form={form}
            isProcessing={isProcessing}
            numTenants={currentResource?.allowedTenants || 0}
            onBlur={props.onBlur}
            onChange={props.onChange}
            viewMode={viewMode}
          />
        </section>
      </div>
      <header>
        <div className="pki-header-slot">
          <div className="pki-page-title">{t('subscriptions.page.tenantTitle')}</div>
          {isAdministrator && (
            <PkiButton
              id="add-btn"
              label={t('tenants.addBtnLabel')}
              leftIcon={<PkiIcon icon={addCircle} />}
              onClick={handleCreateTenant}
              size="small"
              variant="primary"
            />
          )}
        </div>
      </header>
      {tenantList.length > 0 && (
        <div className={cx('content-inner-container', styles.listContentContainer)}>
          <section className="list">
            <div className="pki-data-grid">
              <SubscriptionTenantList
                list={tenantList}
                onAction={handleTenantAction}
                onDelete={handleDeleteTenant}
                onView={handleViewTenant}
                isAdministrator={isAdministrator}
              />
            </div>
          </section>
        </div>
      )}
      {isAdministrator && (
        <>
          {openConfirmAction && currentResource && (
            <ActivationSubscriptionModal
              currentResource={currentResource}
              onClose={handleConfirmAction}
              open={openConfirmAction}
              operation={currentResource.status === SubscriptionStatus.ACTIVE ? 'deactivate' : 'activate'}
              tenantList={tenantList}
            />
          )}
          {openConfirmTenantAction && (
            <TenantActionModal
              action={tenantAction!}
              open={openConfirmTenantAction}
              onClose={handleConfirmTenantAction}
              selectedTenant={selectedTenant!}
            />
          )}
          {openConfirmDeleteTenant && (
            <PkiDialog
              confirmation
              confirmBtnLabel={t('tenants.confirmDelete.confirmBtnLabel')}
              message={t('tenants.confirmDelete.message')}
              onClose={handleConfirmDeleteTenant}
              open={openConfirmDeleteTenant}
              size="sm"
              title={t('tenants.confirmDelete.title')}
            />
          )}
          {openTenantLimit && (
            <PkiDialog
              confirmation
              message={t('subscriptions.tenantLimit.message')}
              onClose={handleConfirmTenantLimit}
              open={openTenantLimit}
              size="sm"
              title={t('subscriptions.tenantLimit.title')}
            />
          )}
        </>
      )}
    </>
  );
};
SubscriptionView.displayName = 'SubscriptionView';
