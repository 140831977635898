import {
  FormControl,
  PkiButton,
  PkiComboBox,
  PkiComboBoxFilter,
  PkiComboBoxOption,
  showInvalidState,
} from '@software-platforms/design-system-components';
import { PkiIcon, search } from '@software-platforms/design-system-icons';
import { Subscription } from '@software-platforms/tenant-manager-ui/models';
import cx from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './subscription-picker.module.scss';

const customFilter = {
  getFilterValue: (item: Subscription) =>
    `${item?.companyName || ''}${item?.license || ''}${item?.orgSubscriptionId || ''}`,
} as PkiComboBoxFilter;

export type TenantSubscriptionPickerProps = {
  control: FormControl;
  isProcessing: boolean;
  name: string;
  onBlur: (controlName: string) => void;
  onChange: (controlName: string, newValue: any) => void;
  subscriptions: Subscription[];
  viewMode: string;
};

export const TenantSubscriptionPicker: React.FunctionComponent<TenantSubscriptionPickerProps> = (props) => {
  const { control, isProcessing, name, subscriptions, viewMode } = props;
  const { t } = useTranslation();
  const isReadOnly = viewMode !== 'create' && viewMode !== 'edit';
  const ref = useRef<HTMLInputElement>(null);

  /* ---------- Actions ---------- */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(name, event.target.value);
    // Validate the entry
    props.onBlur(name);
  };

  const handleReset = () => {
    props.onChange(name, '');
    // Set the focus to the combobox's input element
    window.setTimeout(() => {
      ref.current?.focus();
    }, 0);
  };

  /* ---------- Rendering ---------- */

  const renderMenuHeader = () => (
    <PkiComboBoxOption key={0} className={cx(styles.subscriptionOption, styles.header)} disabled value={null}>
      <span>{t('tenants.form.subscriptionOption.companyName')}</span>
      <span>{t('tenants.form.subscriptionOption.license')}</span>
      <span>{t('tenants.form.subscriptionOption.subscriptionId')}</span>
    </PkiComboBoxOption>
  );

  const renderValue = (item: string | Subscription) => {
    let found;
    if (typeof item === 'string') {
      found = subscriptions?.find((e) => e.id === item);
    } else {
      found = subscriptions?.find((e) => e.id === item.id);
    }
    if (found) {
      const disabledStyle = isProcessing || isReadOnly ? styles.renderedValueDisabled : '';
      return (
        <PkiComboBoxOption key={found.id} className={cx(styles.renderedValue, disabledStyle)} value={found}>
          <span>{found.companyName || ''}</span>
          <span>{found.license || ''}</span>
          <span>{found.orgSubscriptionId || ''}</span>
        </PkiComboBoxOption>
      );
    }
    return null;
  };

  return (
    <div className={styles.subscriptionPickerContainer}>
      <PkiComboBox
        ref={ref}
        className={cx(styles.subscriptionPicker, {
          disabled: isProcessing || isReadOnly,
          invalid: showInvalidState(control, isReadOnly),
          selected: !!control.value,
        })}
        customFilter={customFilter}
        disabled={isProcessing || isReadOnly}
        disableInputAfterSelection
        icon={<PkiIcon icon={search} />}
        name={name.replace('Id', '')} // Turns off many browser plugins that perform autocomplete
        onBlur={() => props.onBlur(name)}
        onChange={handleChange}
        placeholder={t('tenants.form.subscriptionPlaceholder')}
        readOnly={isReadOnly}
        renderValue={renderValue}
        value={control.value || ''}
        valueClassName={cx(styles.selectedOption, { disabled: isProcessing || isReadOnly })}
      >
        {renderMenuHeader()}
        {subscriptions.length > 0 &&
          subscriptions.map((e) => (
            <PkiComboBoxOption key={e.id} className={styles.subscriptionOption} value={e}>
              <span>{e.companyName || ''}</span>
              <span>{e.license || ''}</span>
              <span>{e.orgSubscriptionId || ''}</span>
            </PkiComboBoxOption>
          ))}
      </PkiComboBox>
      <PkiButton
        disabled={isProcessing || isReadOnly}
        label={t('pki:form.reset')}
        onClick={handleReset}
        size="small"
        style={{ visibility: control.value ? 'visible' : 'hidden' }}
        variant="secondary"
      />
    </div>
  );
};
TenantSubscriptionPicker.displayName = 'TenantSubscriptionPicker';
