import { PkiSelect, PkiSelectOption, showInvalidState } from '@software-platforms/design-system-components';
import {
  homeOutline,
  infoOutline,
  PkiIcon,
  subscriptions as subscriptionSvg,
  warning,
} from '@software-platforms/design-system-icons';
import { Subscription, TenantForm } from '@software-platforms/tenant-manager-ui/models';
import { AppState } from '@software-platforms/tenant-manager-ui/store';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TenantSubscriptionPicker } from './subscription-picker';
import styles from './tenant-entry.module.scss';

type OwnProps = {
  form?: TenantForm;
  isProcessing: boolean;
  onBlur: (controlName: string) => void;
  onChange: (controlName: string, newValue: any) => void;
  subscriptions: Subscription[];
  viewMode: string;
};

export const TenantEntry: React.FunctionComponent<OwnProps> = (props) => {
  const { form, isProcessing, subscriptions = [], viewMode } = props;
  const { t } = useTranslation();
  const environments = useSelector((state: AppState) => state.environments.list);
  if (!form) {
    return null;
  }

  const isReadOnly = viewMode === 'view';
  const nonEditable = viewMode !== 'create';
  const subscriptionLede = ['create', 'edit'].includes(viewMode)
    ? 'tenants.form.subscriptionLede'
    : 'tenants.form.subscriptionId';

  return (
    <>
      <div className="form-body">
        <div className="form-lede">
          <PkiIcon icon={homeOutline} />
          <div>{t('tenants.form.lede')}</div>
        </div>
        <div className="col-container bottom-border">
          <div className="col form-group">
            <label htmlFor="tenantName" className={cx({ required: !isReadOnly })}>
              {t('tenants.form.tenantName')}
            </label>
            <div
              className={cx('form-control', {
                disabled: isProcessing || isReadOnly,
                invalid: showInvalidState(form.tenantName, isReadOnly),
              })}
            >
              <input
                type="text"
                id="tenantName"
                autoComplete="off"
                autoFocus
                disabled={isProcessing || isReadOnly}
                onBlur={() => props.onBlur('tenantName')}
                onChange={(event) => props.onChange('tenantName', event.target.value)}
                readOnly={isReadOnly}
                value={form.tenantName.value}
              />
            </div>
            <div className="form-message">
              {showInvalidState(form.tenantName, isReadOnly) && (
                <>
                  {form.tenantName.errors!.required && (
                    <div className="error">
                      <PkiIcon icon={warning} />
                      <span>{t('tenants.form.requiredTenantName')}</span>
                    </div>
                  )}
                  {form.tenantName.errors!.duplicate && (
                    <div className="error">
                      <PkiIcon icon={warning} />
                      <span>{t('tenants.form.duplicateTenantName')}</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="col form-group">
            <label htmlFor="companyName" className={cx({ required: !isReadOnly })}>
              {t('tenants.form.companyName')}
            </label>
            <div
              className={cx('form-control', {
                disabled: isProcessing || isReadOnly,
                invalid: showInvalidState(form.companyName, isReadOnly),
              })}
            >
              <input
                type="text"
                id="companyName"
                autoComplete="off"
                disabled={isProcessing || isReadOnly}
                onBlur={() => props.onBlur('companyName')}
                onChange={(event) => props.onChange('companyName', event.target.value)}
                readOnly={isReadOnly}
                value={form.companyName.value}
              />
            </div>
            <div className="form-message">
              {showInvalidState(form.companyName, isReadOnly) && (
                <div className="error">
                  <PkiIcon icon={warning} />
                  <span>{t('tenants.form.requiredCompanyName')}</span>
                </div>
              )}
            </div>
          </div>

          <div className="col form-group">
            <label htmlFor="dnsLabel" className={cx({ required: !nonEditable })}>
              {t('tenants.form.dnsLabel')}
            </label>
            <div
              className={cx('form-control', {
                disabled: isProcessing || isReadOnly || nonEditable,
                invalid: showInvalidState(form.dnsLabel, isReadOnly),
              })}
            >
              <input
                type="text"
                id="dnsLabel"
                autoComplete="off"
                disabled={isProcessing || isReadOnly || nonEditable}
                minLength={1}
                maxLength={63}
                onBlur={() => props.onBlur('dnsLabel')}
                onChange={(event) => props.onChange('dnsLabel', event.target.value)}
                readOnly={isReadOnly || nonEditable}
                value={form.dnsLabel.value}
              />
            </div>
            {viewMode === 'create' && (
              <div className={styles.tooltip}>
                <PkiIcon icon={infoOutline} />
                <span>{t('tooltips.dnsLabel')}</span>
              </div>
            )}
            <div className="form-message">
              {showInvalidState(form.dnsLabel, isReadOnly) && (
                <>
                  {form.dnsLabel.errors!.required && (
                    <div className="error">
                      <PkiIcon icon={warning} />
                      <span>{t('tenants.form.requiredDnsLabel')}</span>
                    </div>
                  )}
                  {form.dnsLabel.errors!.duplicate && (
                    <div className="error">
                      <PkiIcon icon={warning} />
                      <span>{t('tenants.form.duplicateDnsLabel')}</span>
                    </div>
                  )}
                  {form.dnsLabel.errors!.invalid && (
                    <div className="error">
                      <PkiIcon icon={warning} />
                      <span>{t('tenants.form.invalidDnsLabel')}</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-container bottom-border">
          <div className="col form-group">
            <label htmlFor="environmentId" className={cx({ required: !nonEditable })}>
              {t('tenants.form.environment')}
            </label>
            <PkiSelect
              className={cx({ invalid: showInvalidState(form.environmentId, isReadOnly) })}
              name="environmentId"
              disabled={isProcessing || isReadOnly || nonEditable}
              onBlur={() => props.onBlur('environmentId')}
              onChange={(event: React.ChangeEvent<HTMLElement>, child: any) => props.onChange('environmentId', child)}
              placeholder={t('pki:form.selectPlaceholder')}
              readonly={isReadOnly || nonEditable}
              value={form.environmentId.value}
            >
              {environments.map((e) => (
                <PkiSelectOption key={e.id} value={e.id}>
                  {e.name}
                </PkiSelectOption>
              ))}
            </PkiSelect>
            <div className="form-message">
              {showInvalidState(form.environmentId, isReadOnly) && (
                <>
                  {form.environmentId.errors!.required && (
                    <div className="error">
                      <PkiIcon icon={warning} />
                      <span>{t('tenants.form.requiredEnvironment')}</span>
                    </div>
                  )}
                  {form.environmentId.errors!.duplicate && (
                    <div className="error">
                      <PkiIcon icon={warning} />
                      <span>{t('tenants.form.duplicateEnvironment')}</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col form-group" />
          <div className="col form-group" />
        </div>

        <div className="col-container bottom-border">
          <div className="col form-group">
            <label htmlFor="orderNumber">{t('tenants.form.orderNumber')}</label>
            <div className={cx('form-control', { disabled: isProcessing || isReadOnly })}>
              <input
                type="text"
                id="orderNumber"
                autoComplete="off"
                disabled={isProcessing || isReadOnly}
                onBlur={() => props.onBlur('orderNumber')}
                onChange={(event) => props.onChange('orderNumber', event.target.value)}
                readOnly={isReadOnly}
                value={form.orderNumber.value}
              />
            </div>
          </div>
          <div className="col2 form-group">
            <label htmlFor="description">{t('tenants.form.description')}</label>
            <div className={cx('form-control', { disabled: isProcessing || isReadOnly })}>
              <textarea
                id="description"
                rows={3}
                disabled={isProcessing || isReadOnly}
                onBlur={() => props.onBlur('description')}
                onChange={(event) => props.onChange('description', event.target.value)}
                readOnly={isReadOnly}
                value={form.description.value}
              />
            </div>
            <div className="form-message" />
          </div>
        </div>

        <div className="col-container bottom-border">
          <div className="col form-group">
            <label htmlFor="userEmail" className={cx({ required: !nonEditable })}>
              {t('tenants.form.userEmail')}
            </label>
            <div
              className={cx('form-control', {
                disabled: isProcessing || isReadOnly || nonEditable,
                invalid: showInvalidState(form.userEmail, isReadOnly),
              })}
            >
              <input
                type="email"
                id="userEmail"
                autoComplete="off"
                disabled={isProcessing || isReadOnly || nonEditable}
                onBlur={() => props.onBlur('userEmail')}
                onChange={(event) => props.onChange('userEmail', event.target.value)}
                readOnly={isReadOnly || nonEditable}
                value={form.userEmail.value}
              />
            </div>
            <div className="form-message">
              {showInvalidState(form.userEmail, isReadOnly) && (
                <>
                  {form.userEmail.errors!.required && (
                    <div className="error">
                      <PkiIcon icon={warning} />
                      <span>{t('tenants.form.requiredUserEmail')}</span>
                    </div>
                  )}
                  {form.userEmail.errors!.invalid && (
                    <div className="error">
                      <PkiIcon icon={warning} />
                      <span>{t('tenants.form.invalidUserEmail')}</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col form-group" />
        </div>
      </div>

      <div className="form-body">
        <div className="form-lede">
          <PkiIcon icon={subscriptionSvg} />
          <div className="required">{t(subscriptionLede)}</div>
        </div>
        <div className="form-group">
          {['create', 'edit'].includes(viewMode) && !form.subscriptionId.value && (
            <label htmlFor="subscriptionId">{t('tenants.form.subscriptionSearch')}</label>
          )}
          <TenantSubscriptionPicker
            control={form.subscriptionId}
            isProcessing={isProcessing}
            name="subscriptionId"
            onBlur={props.onBlur}
            onChange={props.onChange}
            subscriptions={subscriptions}
            viewMode={viewMode}
          />
        </div>
      </div>
    </>
  );
};
TenantEntry.displayName = 'TenantEntry';
