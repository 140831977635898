import {
  DateRangePickerDay,
  DateRangePickerDayProps,
  LocalizationProvider,
  StaticDateRangePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LicenseInfo } from '@mui/x-license-pro';
import cx from 'classnames';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { PkiButton } from '../pki-button/pki-button';
import { PkiSelect, PkiSelectOption } from '../pki-select';
import { DateRange as PkiDateRange, DateRangeOption } from './pki-date-range-picker';
import styles from './pki-date-range-picker-menu.module.scss';
import './vendor-orderrides.scss';

LicenseInfo.setLicenseKey(
  '81fe79b15eb5bf8413a08a6f88bbf99fTz00NTE0NCxFPTE2ODYwMDk3MjQxNDYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const DATE_FORMAT = 'yyyy-LL-dd';

export type PkiDateRangePickerMenuProps = {
  calendarTitle?: string;
  cancelBtnLabel?: string;
  confirmBtnLabel?: string;
  disabled?: boolean;
  disableFuture?: boolean;
  end: Date | DateTime;
  isProcessing?: boolean;
  onClose: (isConfirmed: boolean, dateRange?: PkiDateRange) => void;
  open: boolean;
  readOnly?: boolean;
  rightAlign?: boolean;
  selectLabel?: string;
  selectOptions: DateRangeOption[];
  selectPlaceholder?: string;
  start: Date | DateTime;
};

export const PkiDateRangePickerMenu: React.FunctionComponent<React.PropsWithChildren<PkiDateRangePickerMenuProps>> = (
  props
) => {
  const {
    calendarTitle,
    cancelBtnLabel = 'Cancel',
    confirmBtnLabel = 'OK',
    disabled,
    disableFuture,
    isProcessing,
    open,
    readOnly,
    rightAlign,
    selectLabel,
    selectOptions,
    selectPlaceholder,
  } = props;

  let { start, end } = props;
  start = DateTime.isDateTime(start) ? start : DateTime.fromJSDate(start);
  end = DateTime.isDateTime(end) ? end : DateTime.fromJSDate(end);
  const [dateRange, setDateRange] = useState<PkiDateRange>([start, end]);
  const [priorDateRange, setPriorDateRange] = useState<PkiDateRange>([start, end]);

  const getSelectValue = (range: PkiDateRange): string => {
    if (selectOptions?.length) {
      try {
        const index = selectOptions.findIndex((e) => e.start?.equals(range[0]!) && e.end?.equals(range[1]!));
        return index > -1 ? selectOptions[index].value : 'custom';
      } catch (err) {
        return 'custom';
      }
    }
    return 'today';
  };

  const [selectValue, setSelectValue] = useState<string>(getSelectValue(dateRange));
  const [priorSelectValue, setPriorSelectValue] = useState<string>(getSelectValue(dateRange));

  const handleSelectionChange = (event: React.ChangeEvent<HTMLElement>) => {
    event.preventDefault();
    const index = selectOptions.findIndex((e) => e.value === (event.target as HTMLInputElement).value);
    if (index > -1) {
      const range: PkiDateRange = [selectOptions[index].start, selectOptions[index].end];
      // Set the selected option
      setPriorSelectValue(selectValue);
      setSelectValue(selectOptions[index].value);
      // set the actual range
      setPriorDateRange(dateRange);
      setDateRange(range);
    }
  };

  const handleCalendarChange = (range: PkiDateRange) => {
    if (range) {
      // Match up to a select option or select the 'custom' option if it exists
      let index = selectOptions.findIndex(
        (e) =>
          e.start?.toFormat(DATE_FORMAT) === range[0]?.toFormat(DATE_FORMAT) &&
          e.end?.toFormat(DATE_FORMAT) === range[1]?.toFormat(DATE_FORMAT)
      );

      if (index < 0) {
        index = selectOptions.findIndex((e) => e.value === 'custom');
      }
      if (index > -1) {
        setPriorSelectValue(selectValue);
        setSelectValue(selectOptions[index].value);
      }
      // Set the actual range
      setPriorDateRange(dateRange);
      setDateRange(range);
    }
  };

  const handleClose = (isConfirmed: boolean, event: React.MouseEvent) => {
    event.preventDefault();
    if (!isConfirmed) {
      setSelectValue(priorSelectValue);
      setDateRange(priorDateRange);
    }
    props.onClose(isConfirmed, dateRange);
  };

  const renderDay = (date: DateTime, componentProps: DateRangePickerDayProps<DateTime>) => {
    const { className, ...rest } = componentProps;
    return <DateRangePickerDay className={cx(className, styles.muiDayPickerOverride)} {...rest} />;
  };

  return (
    <div className={cx(styles.datePickerMenu, open && styles.open, rightAlign && styles.rightAlign)} role="listbox">
      <div className="">
        <div className={cx('form-group', styles.selectFormGroup)}>
          <label htmlFor="dateRangeSelect">{selectLabel}</label>
          <PkiSelect
            name="dateRangeSelect"
            disabled={isProcessing || readOnly}
            onChange={handleSelectionChange}
            placeholder={selectPlaceholder}
            readonly={readOnly}
            value={selectValue}
          >
            {selectOptions.map((e) => (
              <PkiSelectOption key={e.value} value={e.value}>
                {e.label}
              </PkiSelectOption>
            ))}
          </PkiSelect>
        </div>
      </div>

      <div className={cx('form-group', styles.datePickerLegend)}>
        <label htmlFor="dateRange">{calendarTitle}</label>
      </div>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <StaticDateRangePicker
          className={styles.dateRangePicker}
          disabled={disabled}
          disableFuture={disableFuture}
          displayStaticWrapperAs="desktop"
          renderDay={(date, componentProps) =>
            renderDay(date as DateTime, componentProps as DateRangePickerDayProps<DateTime>)
          }
          value={dateRange}
          onChange={(date) => handleCalendarChange(date as PkiDateRange)}
          renderInput={() => <></>}
        />
      </LocalizationProvider>

      <div className={styles.datePickerFooter}>
        <PkiButton
          label={cancelBtnLabel}
          onClick={(event) => handleClose(false, event)}
          size="small"
          variant="secondary"
        />
        <PkiButton
          label={confirmBtnLabel}
          onClick={(event) => handleClose(true, event)}
          size="small"
          variant="primary"
        />
      </div>
    </div>
  );
};
PkiDateRangePickerMenu.displayName = 'PkiDateRangePickerMenu';
