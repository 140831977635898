import { AuthServiceFactory } from '@nx-workspace/shared/auth';
import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpClientFactory } from '../http-client-factory';

/* ---------- IE Detection ---------- */

export const isIE11 = window.navigator.userAgent.indexOf('Trident') !== -1;
export const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;

/* ---------- Health-Check Handling ---------- */

const API_IDLE_TIMEOUT = 10 * 1000;

let apiError: any = null;
const setApiError = (error: any) => {
  if (error) {
    apiError = error;
  }
};

const ApiListener = {
  handle: -1,

  start() {
    this.stop();
    this.handle = window.setTimeout(() => {
      if (apiError) {
        // Send PING request to the server. We don't care about response here.
        Axios.create({ ...axiosConfig })
          .get<string>('ping')
          .then((r) => r);
      }
    }, API_IDLE_TIMEOUT);
  },

  stop() {
    if (this.handle) {
      window.clearTimeout(this.handle);
      this.handle = -1;
    }
  },
};

/* ---------- Axios Client ---------- */

/**
 * Defines configuration properties in addition to the Axios defaults.
 */
export const axiosConfig: AxiosRequestConfig = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    // Expires: '-1',
    // 'Cache-Control': 'no-store',
    'Content-Type': 'application/json',
  },
  maxBodyLength: 10485760, // 10MB
  maxContentLength: 10485760, // 10MB
  responseType: 'json',
};

/**
 * Utility function to create an Axios instance with a combination of default and passed
 * configurations and some application-wide interceptors. This function should be used
 * to create API instances by all APIs
 */
export const AxiosClientFactory: HttpClientFactory<AxiosInstance, AxiosRequestConfig> = {
  getInstance(config = {}, enableHealthCheck = true) {
    const requestConfig: AxiosRequestConfig = { ...axiosConfig, ...config };
    const instance = Axios.create(requestConfig);

    const identityProvider = process.env['NX_IDP_STRATEGY'] || '';
    const authService = AuthServiceFactory.getInstance(identityProvider);

    // Set request interceptors
    instance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (enableHealthCheck) {
          ApiListener.stop();
        }
        const newConfig = { ...config };

        // Despite "Cache-Control:no-store" IE11 and Edge return 403 Not Modified
        // The temporary solution is to add a unique parameter, like the current time.
        let ieParams = {};
        if (isIE11 || isEdge) {
          ieParams = { cacheFixIE: new Date().getTime() };
        }
        newConfig.params = { ...(newConfig.params || {}), ...ieParams };

        // If required, set an Authorization header
        // const ptn = new RegExp(String(API_TARGET));
        // const authRequired = ptn.test(config.baseURL || config.url || '');
        if (authService) {
          return authService.setAuthorizationHeader(newConfig);
        }
        return newConfig;
      },
      (error) => Promise.reject(error)
    );

    // Set response interceptors
    instance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (enableHealthCheck) {
          ApiListener.start();
          // Any successful response should reset the error
          setApiError(null);
        }
        // Test if we have our own ApiResponse object as the data property of the AxiosResponse
        if (response.data.data) {
          return response.data;
        }
        return response;
      },
      (error: AxiosError) => {
        if (enableHealthCheck) {
          ApiListener.start();
        }
        if (error.message === 'Cancel') {
          // Ignore user cancel action error
          return Promise.reject(error);
        }
        if (error.response) {
          const responseStatus = Number(error.response.status);
          switch (responseStatus) {
            case 401:
            case 403:
              // Our session or token expired. Fetch a new token.
              if (authService) {
                authService.setAuthorizationHeader(error.config).then();
              }
              if (enableHealthCheck) {
                setApiError(error);
              }
              break;
            case 500:
              if (enableHealthCheck) {
                setApiError(error);
              }
              break;
            default:
              break;
          }
        } else {
          if (enableHealthCheck) {
            // Treat all non-HTTP errors as global system errors
            setApiError(error);
          }
        }
        // Non-critical errors are not reported as globals, but they don't reset error state either.
        return Promise.reject(error);
      }
    );

    return instance;
  },
};
