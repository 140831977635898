import { NotificationServiceFactory } from '@nx-workspace/shared/notification';
import { User } from '@software-platforms/tenant-manager-ui/models';
import { UserService } from '@software-platforms/tenant-manager-ui/services';
import { getI18n } from 'react-i18next';
import { Epic, ofType } from 'redux-observable';
import { catchError, map, of, switchMap } from 'rxjs';
import { UserAction, UserActions, UserActionType } from '../actions/user.actions';

export const fetchUsersEpic: Epic = (action$, state$, { userService }) =>
  action$.pipe(
    ofType(UserActionType.FETCH_USERS),
    switchMap(() =>
      (userService as UserService).fetchUsers().pipe(
        map((list: User[]) => UserActions.fetchUsersSucceeded(list)),
        catchError((error) => of(UserActions.fetchUsersFailed(error)))
      )
    )
  );

export const fetchUserEpic: Epic = (action$, state$, { userService }) =>
  action$.pipe(
    ofType(UserActionType.FETCH_USER),
    switchMap((action: UserAction) =>
      (userService as UserService).fetchUser(action.meta!.id!).pipe(
        map((resource: User) => UserActions.fetchUserSucceeded(action.meta!.id!, [resource])),
        catchError((error: any) => of(UserActions.fetchUserFailed(action.meta!.id!, error)))
      )
    )
  );

export const createUserEpic: Epic = (action$, state$, { userService }) =>
  action$.pipe(
    ofType(UserActionType.CREATE_USER),
    switchMap((action: UserAction) =>
      (userService as UserService).createUser(action.meta!.formData!).pipe(
        map((resource: User) => {
          NotificationServiceFactory.getInstance().show(getI18n().t('users.form.inviteSuccess'), { type: 'success' });
          return UserActions.createUserSucceeded(action.meta!.formData!, [resource]);
        }),
        catchError((error: any) => {
          NotificationServiceFactory.getInstance().show(error.message, { type: 'error' });
          return of(UserActions.createUserFailed(action.meta!.formData!, error));
        })
      )
    )
  );

export const updateUserEpic: Epic = (action$, state$, { userService }) =>
  action$.pipe(
    ofType(UserActionType.UPDATE_USER),
    switchMap((action: UserAction) =>
      (userService as UserService).updateUser(action.meta!.id!, action.meta!.formData!).pipe(
        map((resource: User) => UserActions.updateUserSucceeded(action.meta!.id!, action.meta!.formData!, [resource])),
        catchError((error: any) => of(UserActions.updateUserFailed(action.meta!.id!, action.meta!.formData!, error)))
      )
    )
  );

export const deleteUserEpic: Epic = (action$, state$, { userService }) =>
  action$.pipe(
    ofType(UserActionType.DELETE_USER),
    switchMap((action: UserAction) =>
      (userService as UserService).deleteUser(action.meta!.id!).pipe(
        map(() => UserActions.deleteUserSucceeded(action.meta!.id!)),
        catchError((error: any) => of(UserActions.deleteUserFailed(action.meta!.id!, error)))
      )
    )
  );

export const deactivateUserEpic: Epic = (action$, state$, { userService }) =>
  action$.pipe(
    ofType(UserActionType.DEACTIVATE_USER),
    switchMap((action: UserAction) =>
      (userService as UserService).deactivateUser(action.meta!.id!).pipe(
        map(() => UserActions.deactivateUserSucceeded(action.meta!.id!)),
        catchError((error: any) => of(UserActions.deactivateUserFailed(action.meta!.id!, error)))
      )
    )
  );

export const reactivateUserEpic: Epic = (action$, state$, { userService }) =>
  action$.pipe(
    ofType(UserActionType.REACTIVATE_USER),
    switchMap((action: UserAction) =>
      (userService as UserService).reactivateUser(action.meta!.id!).pipe(
        map(() => UserActions.reactivateUserSucceeded(action.meta!.id!)),
        catchError((error: any) => of(UserActions.reactivateUserFailed(action.meta!.id!, error)))
      )
    )
  );
