import { useEffect } from 'react';
import { Observable } from 'rxjs';

export const useTypeAhead = (observable: Observable<string>, setter: Function) => {
  useEffect(() => {
    const subscription = observable.subscribe({ next: (token: string) => setter(token) });
    return () => {
      subscription.unsubscribe();
    };
  }, [observable, setter]);
};
