import { AuthState, initialAuthState } from './state/auth.state';
import { EnvironmentState, initialEnvironmentState } from './state/environment.state';
import { initialSubscriptionState, SubscriptionState } from './state/subscription.state';
import { initialTenantState, TenantState } from './state/tenant.state';
import { initialUserState, UserState } from './state/user.state';

export interface AppState {
  environments: EnvironmentState;
  subscriptions: SubscriptionState;
  tenants: TenantState;
  users: UserState;
  auth: AuthState;
}

export const INITIAL_STATE: AppState = {
  environments: initialEnvironmentState,
  subscriptions: initialSubscriptionState,
  tenants: initialTenantState,
  users: initialUserState,
  auth: initialAuthState,
};
