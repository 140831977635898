import {
  email,
  FormBuilder as fb,
  FormControl,
  password,
  PkiButton,
  PkiToggle,
  required,
  showInvalidState,
  updateValidity,
} from '@software-platforms/design-system-components';
import { PkiIcon, warning } from '@software-platforms/design-system-icons';
import cx from 'classnames';
import { i18n } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { CurrentUser, CurrentUserFormData, ProfileMessages } from '../models';
import { UserProfileProps } from '../user-profile';
import styles from './profile-security.module.scss';

type ProfileSecurityResources = {
  emailLabel: string;
  mfaRequired: string;
  passwordLabel: string;
  oldPasswordLabel: string;
  newPasswordLabel: string;
  repeatNewPasswordLabel: string;
  invalidEmailMessage: string;
  invalidPasswordMessage: string;
  requiredEmailMessage: string;
  requiredPasswordMessage: string;
  invalidPasswordMatchMessage: string;
  editBtnLabel: string;
  saveBtnLabel: string;
  cancelBtnLabel: string;
  changePassword: string;
  requestNewPasswordBtnLabel: string;
  requestNewPasswordSucceeded: string;
  requestNewPasswordFailed: string;
  title: string;
};

const getLocalizedResources = (i18n?: i18n): ProfileSecurityResources => {
  return {
    emailLabel: i18n ? i18n.t('pki:userProfile.security.email') : 'Email',
    mfaRequired: i18n ? i18n.t('pki:userProfile.security.mfaRequired') : 'Multi-Factor Authentication',

    passwordLabel: i18n ? i18n.t('pki:userProfile.security.password') : 'Password',
    oldPasswordLabel: i18n ? i18n.t('pki:userProfile.security.oldPassword') : 'Old password',
    newPasswordLabel: i18n ? i18n.t('pki:userProfile.security.newPassword') : 'New password',
    repeatNewPasswordLabel: i18n ? i18n.t('pki:userProfile.security.repeatNewPassword') : 'Repeat new password',
    invalidEmailMessage: i18n ? i18n.t('pki:userProfile.security.invalidEmail') : 'Invalid email address',
    invalidPasswordMessage: i18n ? i18n.t('pki:userProfile.security.invalidPassword') : 'Invalid password',
    requiredEmailMessage: i18n ? i18n.t('pki:userProfile.security.requiredEmail') : 'An email address is required',
    requiredPasswordMessage: i18n ? i18n.t('pki:userProfile.security.requiredPassword') : 'A password is required',
    invalidPasswordMatchMessage: i18n
      ? i18n.t('pki:userProfile.security.invalidPasswordMatch')
      : 'Passwords do not match',

    editBtnLabel: i18n ? i18n.t('pki:userProfile.security.editBtnLabel') : 'Edit',
    saveBtnLabel: i18n ? i18n.t('pki:userProfile.security.saveBtnLabel') : 'Save',
    cancelBtnLabel: i18n ? i18n.t('pki:userProfile.security.cancelBtnLabel') : 'Cancel',

    changePassword: i18n ? i18n.t('pki:userProfile.security.changePassword') : 'Change password',
    requestNewPasswordBtnLabel: i18n
      ? i18n.t('pki:userProfile.security.requestNewPasswordBtnLabel')
      : 'Request New Password',
    requestNewPasswordSucceeded: i18n
      ? i18n.t('pki:userProfile.security.requestNewPasswordSucceeded')
      : 'Request initiated, please check the email Inbox',
    requestNewPasswordFailed: i18n ? i18n.t('pki:userProfile.security.requestNewPasswordFailed') : 'Request failed',

    title: i18n ? i18n.t('pki:userProfile.security.title') : 'Security',
  };
};

type ProfileSecurityForm = {
  email: FormControl;
  mfaRequired: FormControl;
  password: FormControl;
  newPassword: FormControl;
  repeatNewPassword: FormControl;
};

/* ---------- Component Definition ---------- */

export const ProfileSecurity: React.FunctionComponent<UserProfileProps> = (props) => {
  const { currentUser, i18n } = props;
  const localizedText = React.useMemo(() => getLocalizedResources(i18n), [i18n]);

  const [editing, setEditing] = useState({
    password: false,
  });

  const initialValues = useRef({});
  const showChangePassword = false;
  const showChangePasswordRequest = true;
  const showMfaRequired = false;

  const getForm = (user: CurrentUser | undefined | null): ProfileSecurityForm => ({
    email: fb.getInstance(user?.appUser?.email || user?.authUser?.email || '', [required, email]),
    mfaRequired: fb.getInstance(user?.authUser?.mfaRequired ?? false, required),

    password: fb.getInstance('************', required),
    newPassword: fb.getInstance('', [required, password]),
    repeatNewPassword: fb.getInstance('', [required, password]),
  });

  const [form, setForm] = useState<ProfileSecurityForm>(getForm(currentUser));
  useEffect(() => {
    setForm(getForm(currentUser));
  }, [currentUser]);

  const handleBlur = (controlName: string) => {
    const clone = { ...form } as ProfileSecurityForm;
    clone[controlName].touched = true;
    updateValidity(clone[controlName]);
    if (controlName === 'newPassword' || controlName === 'repeatNewPassword') {
      const doNotMatch = form['newPassword'].value !== form['repeatNewPassword'].value;
      clone['newPassword'].errors = { ...(form['newPassword'].errors || {}), doNotMatch };
      clone['repeatNewPassword'].errors = { ...(form['repeatNewPassword'].errors || {}), doNotMatch };
    }
    setForm(clone);
  };
  const handleChange = (controlName: string, newValue: any) => {
    if (newValue !== form[controlName].value) {
      const clone = { ...form } as ProfileSecurityForm;
      clone[controlName].value = newValue;
      clone[controlName].dirty = true;
      setForm(clone);
    }
  };

  const handleEdit = (controlName: string) => {
    initialValues.current[controlName] = form[controlName].value;
    setEditing((e) => ({ ...e, [controlName]: true }));
  };
  const handleSave = (controlName: string) => {
    if (props.onUpdateCurrentUser) {
      let valueToUpdate = form[controlName].value;
      if (controlName === 'password') {
        //save the new password
        if (form['newPassword'].value !== form['repeatNewPassword'].value) {
          return;
        }
        valueToUpdate = form['newPassword'].value;
      } else {
        if (!form[controlName].dirty) {
          return;
        }
      }
      props.onUpdateCurrentUser({ [controlName]: valueToUpdate }).subscribe();
    }
    setEditing((e) => ({ ...e, [controlName]: false }));
  };

  const handleCancel = (controlName: string) => {
    initialValues.current[controlName] !== undefined && handleChange(controlName, initialValues.current[controlName]);
    setEditing((e) => ({ ...e, [controlName]: false }));
  };

  const handleRequestNewPassword = () => {
    if (props.onUpdateCurrentUser) {
      const formData: CurrentUserFormData = { sendChangePasswordEmail: true };
      const messages: ProfileMessages = {
        success: localizedText.requestNewPasswordSucceeded,
        failure: localizedText.requestNewPasswordFailed,
      };
      props.onUpdateCurrentUser(formData, messages).subscribe();
    }
  };

  return (
    <section className={styles.security}>
      <div className={styles.title}>{localizedText.title}</div>
      <div className={styles.formContainer}>
        <div className="form-group">
          <label htmlFor="email">{localizedText.emailLabel}</label>
          <div className={styles.btnRow}>
            <div className={cx('form-control', { invalid: showInvalidState(form.email, false) })}>
              <input
                type="text"
                id="email"
                autoComplete="off"
                onBlur={() => handleBlur('email')}
                onChange={(event) => handleChange('email', event.target.value)}
                value={form.email.value}
                disabled={true}
              />
            </div>
          </div>
          <div className="form-message">
            {showInvalidState(form.email, false) && (
              <>
                {form.email.errors?.required && (
                  <div className="error">
                    <PkiIcon icon={warning} />
                    <span>{localizedText.requiredEmailMessage}</span>
                  </div>
                )}
                {form.email.errors?.invalid && (
                  <div className="error">
                    <PkiIcon icon={warning} />
                    <span>{localizedText.invalidEmailMessage}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {showChangePasswordRequest && (
          <div className="form-group">
            <label htmlFor="changePassword">{localizedText.changePassword}</label>
            <div className={styles.btnRow}>
              <PkiButton
                label={localizedText.requestNewPasswordBtnLabel}
                onClick={() => handleRequestNewPassword()}
                size="small"
                variant="secondary"
              />
            </div>
          </div>
        )}

        {showChangePassword && (
          <div className="form-group">
            {!editing.password && <label htmlFor="password">{localizedText.passwordLabel}</label>}
            {editing.password && <label htmlFor="password">{localizedText.oldPasswordLabel}</label>}

            <div className={styles.btnRow}>
              <div className={cx('form-control', { invalid: false })}>
                <input
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onBlur={() => handleBlur('password')}
                  onChange={(event) => handleChange('password', event.target.value)}
                  value={form.password.value}
                  disabled={true}
                />
              </div>

              {!editing.password && (
                <PkiButton
                  label={localizedText.editBtnLabel}
                  onClick={() => handleEdit('password')}
                  size="small"
                  variant="secondary"
                />
              )}
              {editing.password && (
                <PkiButton
                  label={localizedText.cancelBtnLabel}
                  onClick={() => handleCancel('password')}
                  size="small"
                  variant="secondary"
                />
              )}
              {editing.password && (
                <PkiButton
                  label={localizedText.saveBtnLabel}
                  onClick={() => handleSave('password')}
                  size="small"
                  variant="secondary"
                  disabled={
                    !(
                      form['newPassword'].dirty &&
                      form['repeatNewPassword'].dirty &&
                      form['newPassword'].value &&
                      form['repeatNewPassword'].value
                    )
                  }
                />
              )}
            </div>

            <div>
              {editing.password && (
                <>
                  <label htmlFor="newPassword">{localizedText.newPasswordLabel}</label>
                  <div className={cx('form-control', { invalid: showInvalidState(form.newPassword, false) })}>
                    <input
                      type="password"
                      id="newPassword"
                      autoComplete="new-password"
                      onBlur={() => handleBlur('newPassword')}
                      onChange={(event) => handleChange('newPassword', event.target.value)}
                      value={form.newPassword.value}
                    />
                  </div>

                  <div className="form-message">
                    {showInvalidState(form.newPassword, false) && (
                      <>
                        {form.newPassword.errors?.required && (
                          <div className="error">
                            <PkiIcon icon={warning} />
                            <span>{localizedText.requiredPasswordMessage}</span>
                          </div>
                        )}
                        {form.newPassword.errors?.invalid && (
                          <div className="error">
                            <PkiIcon icon={warning} />
                            <span>{localizedText.invalidPasswordMessage}</span>
                          </div>
                        )}
                        {form.newPassword.errors?.doNotMatch && (
                          <div className="error">
                            <PkiIcon icon={warning} />
                            <span>{localizedText.invalidPasswordMatchMessage}</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <label htmlFor="repeatNewPassword">{localizedText.repeatNewPasswordLabel}</label>
                  <div className={cx('form-control', { invalid: showInvalidState(form.newPassword, false) })}>
                    <input
                      type="password"
                      id="repeatNewPassword"
                      autoComplete="new-password"
                      onBlur={() => handleBlur('repeatNewPassword')}
                      onChange={(event) => handleChange('repeatNewPassword', event.target.value)}
                      value={form.repeatNewPassword.value}
                    />
                  </div>

                  <div className="form-message">
                    {showInvalidState(form.repeatNewPassword, false) && (
                      <>
                        {form.repeatNewPassword.errors?.required && (
                          <div className="error">
                            <PkiIcon icon={warning} />
                            <span>{localizedText.requiredPasswordMessage}</span>
                          </div>
                        )}
                        {form.repeatNewPassword.errors?.invalid && (
                          <div className="error">
                            <PkiIcon icon={warning} />
                            <span>{localizedText.invalidPasswordMessage}</span>
                          </div>
                        )}

                        {form.repeatNewPassword.errors?.doNotMatch && (
                          <div className="error">
                            <PkiIcon icon={warning} />
                            <span>{localizedText.invalidPasswordMatchMessage}</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {showMfaRequired && (
          <div>
            {localizedText.mfaRequired}
            <PkiToggle
              checked={form.mfaRequired.value}
              disabled={true}
              className={styles.mfaRequiredToggle}
              onClick={() => {}}
            />
          </div>
        )}
      </div>
    </section>
  );
};
ProfileSecurity.displayName = 'ProfileSecurity';
