import React from 'react';

/**
 * Returns the given {@link React.ComponentType} as a JSX element.
 * @param Icon
 */
// @ts-ignore
export const renderIcon = (Icon: React.ComponentType<React.PropsWithChildren<any>> | undefined) => {
  if (Icon) {
    return <Icon />;
  }
};
