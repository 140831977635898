import { DateRange } from '@mui/lab';
import {
  DataGrid,
  GridColDef,
  GridComparatorFn,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { AuditEntry, AuditRequestParams, AuditService } from '@nx-workspace/shared/audit-trail';
import { NotificationServiceFactory } from '@nx-workspace/shared/notification';
import { PkiDateRangePicker, PkiMenu, PkiMenuItem, PkiSpinner } from '@software-platforms/design-system-components';
import { arrowBack, ellipsisHorizontal, PkiIcon } from '@software-platforms/design-system-icons';
import cx from 'classnames';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { finalize } from 'rxjs';
import { AuditDetailDialog } from './audit-detail-dialog/audit-detail-dialog';
import styles from './audit-trail.module.scss';
import { useDatePicker } from './use-date-picker';

const dateComparator: GridComparatorFn<DateTime> = (a, b) => a.toMillis() - b.toMillis();

export interface AuditTrailProps {
  auditService: AuditService;
  scope?: string;
}

export const AuditTrail: React.FunctionComponent<AuditTrailProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { getDateRangeOptions, getInitialDateRange } = useDatePicker();

  const [dateRange, setDateRange] = useState<DateRange<DateTime>>(getInitialDateRange(0));
  const [displayDateRange, setDisplayDateRange] = useState<DateRange<DateTime>>(getInitialDateRange(0));
  const handleApplyDateRangeFilter = (isConfirmed: boolean, range?: DateRange<DateTime>) => {
    if (isConfirmed && range) {
      setDateRange(range);
      setDisplayDateRange(range);
    }
  };

  const getRequestParams = useCallback((): AuditRequestParams => {
    // In-memory pagination: Get all audit records from API for dateRange
    // Since we get all records we don't care about the server sorting, we sort the grid here
    return {
      from: dateRange[0] ? dateRange[0].toFormat('yyyy-MM-dd') : undefined,
      to: dateRange[1] ? dateRange[1].toFormat('yyyy-MM-dd') : undefined,
      add: 'oldValue,newValue',
    };
  }, [dateRange]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [list, setList] = useState<AuditEntry[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const params = getRequestParams();
    props.auditService
      .fetchPage(params, props.scope)
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe({
        next: (page) => {
          setList(page.data || []);
        },
        error: (error: Error) => NotificationServiceFactory.getInstance().showError(error),
      });
  }, [dateRange, getRequestParams, props.auditService, props.scope]);

  /* ---------- Actions ---------- */

  const [openState, setOpenState] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<AuditEntry>();
  const handleOpenDetail = (selectedEntry: AuditEntry) => {
    if (selectedEntry) {
      setSelectedItem(selectedEntry);
      setOpenState(true);
    }
  };
  const handleCloseDetail = () => {
    setOpenState(false);
    setSelectedItem(undefined);
  };

  const handleGoBack = () => {
    navigate('/');
  };

  /* ---------- Data ---------- */

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'id', hide: true },
      {
        field: 'createdAt',
        flex: 1,
        headerName: t('audit.list.timestamp'),
        sortComparator: dateComparator,
        type: 'dateTime',
        valueGetter: (params: GridValueGetterParams<DateTime, AuditEntry>) => params.row.createdAt,
        valueFormatter: (params: GridValueFormatterParams<DateTime>) => {
          if (params.value) {
            return params.value.toUTC().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
          }
          return '';
        },
      },
      { field: 'createdBy', flex: 1, headerName: t('audit.list.username') },
      {
        field: 'action',
        flex: 1,
        headerName: t('audit.list.action'),
        valueGetter: ({ value }) => t(`audit.action.${value}`),
      },
      {
        field: 'entity',
        flex: 1,
        headerName: t('audit.list.entity'),
      },
      { field: 'ipAddress', headerName: t('audit.list.ipAddress'), width: 200 },
      {
        field: 'more',
        cellClassName: 'more-cell',
        headerName: '',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <PkiMenu
              classNames="menu-right"
              name={`audit-action-${params.id}`}
              icon={<PkiIcon icon={ellipsisHorizontal} />}
              iconSize="16px 16px"
            >
              <PkiMenuItem onClick={() => handleOpenDetail(params.row)}>{t('audit.list.openDetail')}</PkiMenuItem>
            </PkiMenu>
          );
        },
        sortable: false,
        width: 30,
      },
    ],
    [t]
  );

  /* ---------- Rendering ---------- */

  if (isLoading) {
    return <PkiSpinner size="large" />;
  }

  return (
    <>
      <Helmet title={t('audit.title')} />
      <header>
        <div className="pki-header-slot">
          <div className="pki-back-btn" onClick={handleGoBack} role="button">
            <PkiIcon icon={arrowBack} />
          </div>
          <div className="pki-page-title">{t('audit.title')}</div>
          <PkiDateRangePicker
            cancelBtnLabel={t('pki:form.cancel')}
            confirmBtnLabel={t('pki:form.ok')}
            disableFuture
            end={displayDateRange[1]!}
            onClose={handleApplyDateRangeFilter}
            selectOptions={getDateRangeOptions()}
            selectLabel={t('dateRange.selectTitle')}
            selectPlaceholder={t('pki:form.selectPlaceholder')}
            start={displayDateRange[0]!}
          />
        </div>
      </header>
      <div className={cx('content-inner-container', styles.contentInnerContainer)}>
        <section className="list">
          <div className="pki-data-grid">
            <DataGrid
              autoPageSize
              density="compact"
              columns={columns}
              rows={list}
              disableColumnMenu
              disableSelectionOnClick
              initialState={{ sorting: { sortModel: [{ field: 'createdAt', sort: 'desc' }] } }}
              loading={isLoading}
              pagination
            />
          </div>
        </section>
      </div>
      {openState && <AuditDetailDialog entry={selectedItem} open={openState} onClose={handleCloseDetail} />}
    </>
  );
};
AuditTrail.displayName = 'AuditTrail';
