import { chevronDown, chevronUp, cog, PkiIcon, pkiLogo } from '@software-platforms/design-system-icons';
import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PkiButton } from '../pki-button/pki-button';
import { PkiUserProfile } from '../pki-user-profile/pki-user-profile';
import { CurrentUser } from '../pki-utils';
import './pki-navigation.scss';

export interface FeatureConfig {
  id: string;
  feature: string;
  Icon?: React.ReactNode;
  label?: string;
  path: string;
  persona?: string | string[];
  isAdminFeature?: boolean;
  children?: FeatureConfig[];
}

export interface PkiNavigationProps {
  productFeatures: FeatureConfig[];
  coreFeatures: FeatureConfig[];
  coreFeaturesTitle: string;
  currentUser?: CurrentUser | null;
  onFeatureClick?: (feature: FeatureConfig) => void;
  productName: string;
  userProfilePath: string;
}

export const PkiNavigation: React.FunctionComponent<React.PropsWithChildren<PkiNavigationProps>> = (props) => {
  const {
    coreFeatures = [],
    coreFeaturesTitle,
    currentUser,
    productFeatures = [],
    productName,
    userProfilePath,
  } = props || {};
  const navigate = useNavigate();
  const location = useLocation();

  const getActiveFeature = (pathname: string): FeatureConfig | undefined => {
    const appFeatures = [...productFeatures, ...coreFeatures];
    if (pathname === '/') {
      return productFeatures[0];
    }
    const segments = pathname.split('/');
    if (segments.length > 1) {
      const feature = appFeatures.find((e: FeatureConfig) => e.feature === segments[1]);
      if (feature) {
        return feature;
      }
    }
    return undefined;
  };

  const [activeFeature, setActiveFeature] = useState<FeatureConfig>();
  useEffect(() => {
    const feature = getActiveFeature(location.pathname);
    setActiveFeature(feature);
  }, [location]);

  const [openCoreFeatures, setOpenCoreFeatures] = useState<boolean>(false);
  const toggleCoreFeatures = (event: any) => {
    event.preventDefault();
    setOpenCoreFeatures(!openCoreFeatures);
  };

  const handleClick = (feature: FeatureConfig, event: React.MouseEvent) => {
    if (event?.cancelable) {
      event.preventDefault();
    }
    setActiveFeature(feature);
    if (props.onFeatureClick) {
      props.onFeatureClick(feature);
    }
    if (feature.path) {
      navigate(feature.path);
    }
  };
  const handleKeyUp = (feature: FeatureConfig, event: React.KeyboardEvent) => {
    if (event?.cancelable) {
      event.preventDefault();
    }
    setActiveFeature(feature);
    if (props.onFeatureClick) {
      props.onFeatureClick(feature);
    }
    if (feature.path) {
      navigate(feature.path);
    }
  };

  const canAccessFeature = (feature: FeatureConfig): boolean =>
    !feature.isAdminFeature || (feature.isAdminFeature && currentUser?.appUser?.entitlements?.isAdmin);
  const isActiveFeature = (feature: FeatureConfig): boolean => feature === activeFeature;

  return (
    <nav className="nav">
      <div>
        <div className="nav-brand-container">
          <PkiIcon icon={pkiLogo} />
          <div className="nav-brand-name">{productName}</div>
        </div>
        {productFeatures.length > 0 && (
          <ul className="navbar features">
            {productFeatures.map((feature: FeatureConfig) => (
              <React.Fragment key={feature.label}>
                {canAccessFeature(feature) && (
                  <li className="navbar-item">
                    <PkiButton
                      label={feature.label!}
                      leftIcon={feature.Icon}
                      onClick={(event: React.MouseEvent) => handleClick(feature, event)}
                      onKeyUp={(event: React.KeyboardEvent) => handleKeyUp(feature, event)}
                      size="medium"
                      style={{ minWidth: '188px', width: '100%' }}
                      variant={isActiveFeature(feature) ? 'primary' : 'tertiary'}
                    />
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        )}
        <div className="navbar">
          {coreFeatures.length > 0 && (
            <>
              <li className="navbar-item">
                <PkiButton
                  classNames={cx('toggle', { open: openCoreFeatures })}
                  label={coreFeaturesTitle}
                  leftIcon={<PkiIcon icon={cog} />}
                  onClick={toggleCoreFeatures}
                  onKeyUp={toggleCoreFeatures}
                  rightIcon={openCoreFeatures ? <PkiIcon icon={chevronUp} /> : <PkiIcon icon={chevronDown} />}
                  size="small"
                  style={{ minWidth: '188px', width: '100%' }}
                  variant="tertiary"
                />
              </li>
              <div className={cx('accordion', { open: openCoreFeatures })}>
                {coreFeatures.map((feature: FeatureConfig) => (
                  <React.Fragment key={feature.label}>
                    {canAccessFeature(feature) && (
                      <li className="navbar-item inset">
                        <PkiButton
                          label={feature.label!}
                          leftIcon={feature.Icon}
                          onClick={(event: React.MouseEvent) => handleClick(feature, event)}
                          onKeyUp={(event: React.KeyboardEvent) => handleKeyUp(feature, event)}
                          size="small"
                          style={{ width: '170px' }}
                          variant={isActiveFeature(feature) ? 'primary' : 'tertiary'}
                        />
                      </li>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <PkiUserProfile path={userProfilePath} currentUser={currentUser} />
    </nav>
  );
};
PkiNavigation.displayName = 'PkiNavigation';

export default PkiNavigation;
