import { Action } from 'redux';

/**
 * Describes the shape of an Action message to be used by all actions in this framework. This interface is
 * more expressive than the base Action type described by Redux and permits non-payload-specific information to be
 * passed within the message. In particular, the `meta` property is a simple hash of key-value pairs to pass any
 * information you need, such as identifiers, query criteria or other variables needed by listeners or services
 * acting on this message.
 * @property type       The action type, a unique name to distinguish redux operations
 * @property meta       MetaData to pass non-payload information
 * @property payload    The message body
 * @property error      An error generated by the action execution and passed back to the UI
 * @typeParam Payload   The payload type
 * @typeParam Metadata  The action metadata type
 */
export interface StandardAction<Payload, Metadata> extends Action<string> {
  type: string;
  meta?: Metadata;
  payload?: Payload;
  error?: any;
}
