import { showInvalidState } from '@software-platforms/design-system-components';
import { infoOutline, PkiIcon, warning } from '@software-platforms/design-system-icons';
import { EnvironmentForm } from '@software-platforms/tenant-manager-ui/models';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { EnvironmentViewMode } from '../environments';
import styles from './environment-entry.module.scss';

type OwnProps = {
  form: EnvironmentForm;
  isProcessing?: boolean;
  onBlur: (controlName: string) => void;
  onChange: (controlName: string, newValue: any) => void;
  viewMode: EnvironmentViewMode;
};

export const EnvironmentEntry: React.FunctionComponent<OwnProps> = (props) => {
  const { form, isProcessing, viewMode } = props;
  const { t } = useTranslation();
  const isCreating = /create/i.test(useLocation().pathname);
  const isReadOnly = viewMode === 'view';
  const isNonEditable = !isCreating;

  return (
    <div className="form-body">
      <div className="form-group">
        <label htmlFor="name" className={cx({ required: !isReadOnly })}>
          {t('environments.form.name')}
        </label>
        <div
          className={cx('form-control', {
            disabled: isProcessing || isReadOnly,
            invalid: showInvalidState(form.name, isReadOnly),
          })}
        >
          <input
            type="text"
            id="name"
            autoComplete="off"
            autoFocus
            disabled={isProcessing || isReadOnly}
            onBlur={() => props.onBlur('name')}
            onChange={(event) => props.onChange('name', event.target.value)}
            readOnly={isReadOnly}
            value={form.name.value}
          />
        </div>
        <div className="form-message">
          {showInvalidState(form.name, isReadOnly) && (
            <>
              {form.name.errors!.required && (
                <div className="error">
                  <PkiIcon icon={warning} />
                  <span>{t('environments.form.requiredName')}</span>
                </div>
              )}
              {form.name.errors!.duplicate && (
                <div className="error">
                  <PkiIcon icon={warning} />
                  <span>{t('environments.form.duplicateName')}</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="domain" className={cx({ required: !isNonEditable })}>
          {t('environments.form.domain')}
        </label>
        <div
          className={cx('form-control', {
            disabled: isProcessing || isReadOnly || isNonEditable,
            invalid: showInvalidState(form.domain, isReadOnly),
          })}
        >
          <input
            type=" text"
            id=" domain"
            autoComplete=" off"
            disabled={isProcessing || isNonEditable}
            onBlur={() => props.onBlur('domain')}
            onChange={(event) => props.onChange('domain', event.target.value)}
            readOnly={isReadOnly}
            value={form.domain.value}
          />
        </div>
        {isCreating && (
          <div className={styles.tooltip}>
            <PkiIcon icon={infoOutline} />
            <span>{t('tooltips.dnsLabel')}</span>
          </div>
        )}
        <div className="form-message">
          {showInvalidState(form.domain, isReadOnly) && (
            <div className="">
              {form.domain.errors!.required && (
                <div className=" error">
                  <PkiIcon icon={warning} />
                  <span>{t('environments.form.requiredDomain')}</span>
                </div>
              )}
              {form.domain.errors!.invalid && (
                <div className=" error">
                  <PkiIcon icon={warning} />
                  <span>{t('environments.form.invalidDomain')}</span>
                </div>
              )}
              {form.domain.errors!.duplicate && (
                <div className=" error">
                  <PkiIcon icon={warning} />
                  <span>{t('environments.form.duplicateDomain')}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
EnvironmentEntry.displayName = 'EnvironmentEntry';
