import { Mapper } from '@nx-workspace/shared/models';
import { Tenant, TenantResource } from '@software-platforms/tenant-manager-ui/models';
import { DateTime } from 'luxon';

export const TenantMapper: Mapper<TenantResource, Tenant> = {
  from(resource: TenantResource): Tenant {
    return {
      id: resource.id,
      tenantName: resource.name,
      companyName: resource.companyName,
      contactInfo: resource.contactInfo,
      dnsLabel: resource.dnsLabel,
      description: resource.description,
      orderNumber: resource.orderNumber,
      environmentId: resource.environmentId,
      subscriptionId: resource.subscriptionId,
      status: resource.status,
      createdAt: DateTime.fromISO(resource.createdAt),
      createdBy: resource.createdBy,
      lastModifiedAt: DateTime.fromISO(resource.lastModifiedAt || resource.createdAt),
      lastModifiedBy: resource.lastModifiedBy || resource.createdBy,
    };
  },

  to(entity: Tenant): TenantResource {
    // Do not use this as the request payload is a different object
    return {
      id: entity.id,
      name: entity.tenantName,
      companyName: entity.companyName,
      contactInfo: entity.contactInfo,
      dnsLabel: entity.dnsLabel,
      description: entity.description,
      orderNumber: entity.orderNumber,
      environmentId: entity.environmentId,
      subscriptionId: entity.subscriptionId,
      status: entity.status,
      createdAt: entity.createdAt.toISO(),
      createdBy: entity.createdBy,
      lastModifiedAt: entity.lastModifiedAt.toISO(),
      lastModifiedBy: entity.lastModifiedBy,
    };
  },
};
