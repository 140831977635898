import React from 'react';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './notification.module.scss';

export const NotificationContainer: React.FunctionComponent<React.PropsWithChildren<ToastContainerProps>> = (props) => {
  const { position, closeOnClick, toastClassName, ...rest } = props;
  return (
    <ToastContainer position="bottom-right" closeOnClick={false} toastClassName={styles.pkiNotification} {...rest} />
  );
};
NotificationContainer.displayName = 'NotificationContainer';
