import { Reducer } from 'redux';
import { SubscriptionAction, SubscriptionActionType } from '../actions/subscription.actions';
import { initialSubscriptionState, SubscriptionState } from '../state/subscription.state';
import { getComparator } from '../utils/subscription.comparator';

export const subscriptionReducer: Reducer<SubscriptionState, SubscriptionAction> = (
  state = initialSubscriptionState,
  action
) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case SubscriptionActionType.FETCH_SUBSCRIPTIONS:
    case SubscriptionActionType.FETCH_SUBSCRIPTION:
      return { ...state, isLoading: true, readError: null };

    case SubscriptionActionType.FETCH_SUBSCRIPTIONS_SUCCEEDED: {
      const list = action.payload || [];
      const filteredList = [...(action.payload || [])].sort(getComparator('license'));
      return { ...state, list, filteredList, isLoading: false };
    }

    case SubscriptionActionType.FETCH_SUBSCRIPTION_SUCCEEDED:
      return { ...state, currentResource: action.payload![0], isLoading: false };

    case SubscriptionActionType.FETCH_SUBSCRIPTIONS_FAILED:
    case SubscriptionActionType.FETCH_SUBSCRIPTION_FAILED:
      return { ...state, readError: action.error, isLoading: false };

    case SubscriptionActionType.CREATE_SUBSCRIPTION:
      return { ...state, currentResource: null, isProcessing: true, writeError: null };

    case SubscriptionActionType.UPDATE_SUBSCRIPTION:
    case SubscriptionActionType.DELETE_SUBSCRIPTION:
    case SubscriptionActionType.DEACTIVATE_SUBSCRIPTION:
    case SubscriptionActionType.REACTIVATE_SUBSCRIPTION:
      return { ...state, isProcessing: true, writeError: null };

    case SubscriptionActionType.CREATE_SUBSCRIPTION_SUCCEEDED: {
      const resource = action.payload![0];
      const clonedList = [...state.list];
      clonedList.push(resource);
      const clonedFilteredList = [...state.filteredList];
      clonedFilteredList.push(resource);
      return {
        ...state,
        list: clonedList,
        filteredList: clonedFilteredList,
        currentResource: resource,
        isProcessing: false,
      };
    }

    case SubscriptionActionType.UPDATE_SUBSCRIPTION_SUCCEEDED:
    case SubscriptionActionType.DEACTIVATE_SUBSCRIPTION_SUCCEEDED:
    case SubscriptionActionType.REACTIVATE_SUBSCRIPTION_SUCCEEDED: {
      const resource = action.payload![0];
      const clonedList = [...state.list];
      let index = clonedList.findIndex((e) => e.id === action.meta!.id);
      if (index > -1) {
        clonedList[index] = resource;
      }
      const clonedFilteredList = [...state.filteredList];
      index = clonedFilteredList.findIndex((e) => e.id === action.meta!.id);
      if (index > -1) {
        clonedFilteredList[index] = resource;
      }
      return {
        ...state,
        list: clonedList,
        filteredList: clonedFilteredList,
        currentResource: resource,
        isProcessing: false,
      };
    }

    case SubscriptionActionType.DELETE_SUBSCRIPTION_SUCCEEDED: {
      const clonedList = [...state.list];
      let index = clonedList.findIndex((e) => e.id === action.meta!.id);
      if (index > -1) {
        clonedList.splice(index, 1);
      }
      const clonedFilteredList = [...state.filteredList];
      index = clonedFilteredList.findIndex((e) => e.id === action.meta!.id);
      if (index > -1) {
        clonedFilteredList.splice(index, 1);
      }
      return {
        ...state,
        list: clonedList,
        filteredList: clonedFilteredList,
        currentResource: null,
        isProcessing: false,
      };
    }

    case SubscriptionActionType.CREATE_SUBSCRIPTION_FAILED:
    case SubscriptionActionType.UPDATE_SUBSCRIPTION_FAILED:
    case SubscriptionActionType.DELETE_SUBSCRIPTION_FAILED:
    case SubscriptionActionType.DEACTIVATE_SUBSCRIPTION_FAILED:
    case SubscriptionActionType.REACTIVATE_SUBSCRIPTION_FAILED:
      return { ...state, writeError: action.error, isProcessing: false };

    case SubscriptionActionType.FLUSH:
      return { ...state, currentResource: null };

    default:
      return state;
  }
};
