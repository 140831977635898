import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp, GridSortModel } from '@mui/x-data-grid';
import { PkiMenu, PkiMenuItem } from '@software-platforms/design-system-components';
import { ellipsisHorizontal, PkiIcon } from '@software-platforms/design-system-icons';
import {
  Environment,
  Tenant,
  TenantQueryRequest,
  userHasAdministratorRole,
} from '@software-platforms/tenant-manager-ui/models';
import { ServiceFactory } from '@software-platforms/tenant-manager-ui/services';
import { AppState, EnvironmentActions } from '@software-platforms/tenant-manager-ui/store';
import cx from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteEnvironmentModal } from '../environment-modal/delete-environment-modal';
import { EnvironmentViewMode } from '../environments';

type OwnProps = {
  list: Environment[];
  isLoading: boolean;
  onSelectionChanged: (id: string) => void;
  viewMode: EnvironmentViewMode;
};

export const EnvironmentList: React.FunctionComponent<OwnProps> = (props) => {
  const { list, isLoading, viewMode } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const disableRowSelection = viewMode === 'create' || viewMode === 'edit';

  const currentUser = useSelector((state: AppState) => state.auth.currentUser);
  const isAdministrator = userHasAdministratorRole(currentUser?.authUser);

  const [selectedEnvironment, setSelectedEnvironment] = useState<Environment | null>(null);
  const [openConfirmAction, setOpenConfirmAction] = useState<boolean>(false);
  const [numTenants, setNumTenants] = useState<number>(0);
  const handleAction = (environment: Environment) => {
    setSelectedEnvironment(environment);
    if (!openConfirmAction) {
      // first check if any tenants are assigned to this environment.
      // getting only the tenant IDs, so we can count them is much faster
      // because the back-end can return the IDs from the index
      // without having to read each tenant document to get more attributes
      const query: TenantQueryRequest = { environmentId: environment.id, attributesToGet: 'id' };
      ServiceFactory.getServices()
        .tenantService.fetchTenants(query)
        .subscribe((tenantsForEnvironmentList: Tenant[]) => {
          setNumTenants((tenantsForEnvironmentList || []).length);
          setOpenConfirmAction(true);
        });
    }
  };
  const handleView = (environment: Environment) => {
    setSelectedEnvironment(environment);
    props.onSelectionChanged(environment.id);
  };

  const handleConfirmAction = (isConfirmed: boolean) => {
    setOpenConfirmAction(false);
    if (isConfirmed) {
      // if numTenants !== 0 and the user confirmed the environment deletion even though there are tenants
      // then we have to 'force' the deletion otherwise the back-end will reject it
      dispatch(EnvironmentActions.deleteEnvironment(selectedEnvironment!.id, !!numTenants));
      setSelectedEnvironment(null);
    }
  };

  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }]);
  const columns: GridColDef[] = [
    { field: 'id', hide: true },
    { field: 'name', flex: 1, headerName: t('environments.list.name') },
    { field: 'domain', flex: 3, headerName: t('environments.list.domain') },
    {
      field: 'action',
      cellClassName: 'more-cell',
      headerName: '',
      renderCell: (params: GridRenderCellParams<Environment>) => (
        <PkiMenu
          classNames="menu-right"
          name={`environment-action-${params.id}`}
          icon={<PkiIcon icon={ellipsisHorizontal} />}
          iconSize="16px 16px"
        >
          <PkiMenuItem onClick={() => handleView(params.row)}>
            {t(isAdministrator ? 'environments.list.viewAndEdit' : 'environments.list.view')}
          </PkiMenuItem>
          {isAdministrator && (
            <PkiMenuItem onClick={() => handleAction(params.row)}>{t('environments.deleteBtnLabel')}</PkiMenuItem>
          )}
        </PkiMenu>
      ),
      width: 40,
    },
  ];
  const rows: GridRowsProp = [...list];

  return (
    <section className="list">
      <div className={cx('pki-data-grid', { selectable: !disableRowSelection })}>
        <DataGrid
          autoPageSize
          columns={columns}
          density="compact"
          disableColumnMenu
          disableSelectionOnClick
          loading={isLoading}
          onSortModelChange={(model: GridSortModel) => setSortModel(model)}
          pagination
          rows={rows}
          sortModel={sortModel}
        />
      </div>
      {isAdministrator && (
        <DeleteEnvironmentModal
          currentResource={selectedEnvironment}
          numTenants={numTenants}
          onClose={handleConfirmAction}
          open={openConfirmAction}
        />
      )}
    </section>
  );
};
EnvironmentList.displayName = 'EnvironmentList';
