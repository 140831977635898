/**
 * Describes a facade object containing both IDP and application-specific user entities. This interface aligns with
 * the interface of the same name in the separate user-management library as part of the Separated Interface pattern
 * as defined by Martin Fowler.
 * @property authUser {@link AuthUser} The user as returned by the IDP (Auth0 or Cognito) client SDK library,
 *                    either parsed from the JWT token or queried from the IdP introspection endpoint (if the token
 *                    is opaque).
 * @property appUser: {@link AppUser} The latest User info using the '/user' (aka '/me') endpoint API.
 * @see libs/shared/user-management/src/lib/models.ts
 * @see Fowler, Martin, "Patterns of Enterprise Application Architecture." Pearson Education, Inc., Boston, 2003
 */
export interface CurrentUser {
  authUser?: AuthUser;
  appUser?: AppUser;
}

/**
 * Enumerates possible properties included in the JWT access-token.
 */
export enum JwtClaimProperty {
  ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  USERNAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
  MFA_REQUIRED = 'https://apps.perkinelmercloud.net/mfaRequired',
}

/**
 * Describes the decoded JWT access-token identifying user attributes common to both Auth0 and Cognito, many of
 * which are optional depending of the scopes required on Authentication. Additional properties that are IDP or
 * application specific are accepted.
 *
 * Scopes sent with the token request include:
 * - openId   (Required) Returns the subclaim, which uniquely identifies the user. In an IDToken, iss, aud, exp,
 *            iat, and at_hash claims will also be present.
 *
 * - profile  Returns claims that represent basic profile information, including name, family_name, given_name,
 *            middle_name, nickname, picture, and updated_at.
 *
 * - email    Returns the email claim, which contains the user's email address, and email_verified, which is a
 *            boolean indicating whether the email address was verified by the user.
 *
 * @property sub            The user identifier in the IDP system
 * @property family_name    The user's last name
 * @property given_name     The user's first name
 * @property name           The user's formatted name
 * @property nickname       The user's optional nickname
 * @property picture        The user's optional avatar, typically from a public url
 * @property updated_at     The timestamp of the last user update in the IDP
 * @property email          The user's email address
 * @property email_verified True if the user has confirmed their IDP invitation
 */
export interface AuthUserClaims {
  sub?: string;
  family_name?: string;
  given_name?: string;
  name?: string;
  nickname?: string;
  picture?: string;
  updated_at?: string;
  email?: string;
  email_verified?: string;
  [JwtClaimProperty.ROLE]?: string;
  [JwtClaimProperty.USERNAME]?: string;
  [JwtClaimProperty.MFA_REQUIRED]?: boolean;

  [key: string]: any;
}

export interface AuthUser extends AuthUserClaims {
  id?: string; // The IDP User identifier (the `sub` claim)
  email?: string;
  roles?: string[];
  mfaRequired?: boolean;
}

/**
 * Describes the server response to an HTTP request for the Current User that includes only application-specific data.
 * Additional application-specific attributes mya be included in the response.
 * @property givenName    The user first name
 * @property familyName   The user last name
 * @property email        The user email address
 * @property phoneNumber  The user phone number
 */
export interface AppUser {
  givenName?: string;
  familyName?: string;
  email?: string;
  phoneNumber?: string | null;

  [key: string]: any;
}

/**
 * Describes the body of an HTTP request to update the current user. Some properties may be forwarded to the IDP and
 * some processed by the application server. As such, this is a `Partial<AuthUser> | Partial<AppUser>`. All
 * properties are optional since a request may send individual data. Other properties are accepted. This interface
 * aligns with the interface of the same name in the separate user-management library as part of the Separated
 * Interface pattern as defined by Martin Fowler.
 * @property givenName                The user first name
 * @property familyName               The user last name
 * @property sendChangePasswordEmail  True if the server should send a request to the IDP to change the user email
 * @property picture                  An avatar file
 * @property phoneNumber              The user phone number, 'null' means 'no phone number'
 * @see libs/shared/user-management/src/lib/models.ts
 * @see Fowler, Martin, "Patterns of Enterprise Application Architecture." Pearson Education, Inc., Boston, 2003.
 */
export interface CurrentUserFormData {
  givenName?: string;
  familyName?: string;
  password?: string;
  sendChangePasswordEmail?: boolean;
  picture?: string;
  phoneNumber?: string | null;

  [key: string]: any;
}

/**
 * Describes the notification messages to display to the user upon updating their profile. This interface aligns
 * with the interface of the same name in the separate user-management library as part of the Separated Interface
 * pattern as defined by Martin Fowler.
 * @property success  The message to display when their profile was successfully updated, or true to display a
 *                    default message
 * @property failure  The message to display when the update operation failed, or true to display a default message
 * @see libs/shared/user-management/src/lib/models.ts
 * @see Fowler, Martin, "Patterns of Enterprise Application Architecture." Pearson Education, Inc., Boston, 2003.
 */
export interface ProfileMessages {
  success?: boolean | string;
  failure?: boolean | string;
}
