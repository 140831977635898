import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';
import './pki-layout.scss';

export type PkiLayoutProps = {
  className?: string;
  footer?: ReactNode;
  header?: ReactNode;
  sidebar?: ReactNode;
  style?: CSSProperties;
};

export const PkiLayout: React.FunctionComponent<React.PropsWithChildren<PkiLayoutProps>> = (props) => {
  const { className, style } = props;
  return (
    <div className="pki-layout-container">
      {props.sidebar}
      <div className="pki-layout-inner-container">
        <div className={cx('pki-layout-content', className)} style={style}>
          {props.header}
          {props.children}
          {props.footer}
        </div>
      </div>
    </div>
  );
};
PkiLayout.displayName = 'PkiLayout';
