import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { CSSProperties } from 'react';
import './pki-layout.scss';

export type PkiLayoutFooterProps = {
  className?: string;
  showBorder?: boolean;
  style?: CSSProperties;
};

export const PkiLayoutFooter: React.FunctionComponent<React.PropsWithChildren<PkiLayoutFooterProps>> = (props) => {
  const { className, showBorder, style } = props;
  return (
    <footer className={cx('pki-layout-footer', className, { border: showBorder })} style={style}>
      {props.children}
    </footer>
  );
};
PkiLayoutFooter.displayName = 'PkiLayoutFooter';

export default PkiLayoutFooter;
