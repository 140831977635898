import { PkiButton } from '@software-platforms/design-system-components';
import { EnvironmentForm } from '@software-platforms/tenant-manager-ui/models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentEntry } from '../environment-entry/environment-entry';
import { EnvironmentViewMode } from '../environments';

type OwnProps = {
  form: EnvironmentForm;
  isAdministrator: boolean;
  onCancel: (event: React.MouseEvent) => void;
  onEditEnvironment: (event: React.MouseEvent) => void;
  viewMode: EnvironmentViewMode;
};

export const EnvironmentView: React.FunctionComponent<OwnProps> = (props) => {
  const { form, isAdministrator } = props;
  const { t } = useTranslation();

  return (
    <section className="item form">
      <div className="form-header">
        <div className="form-title">{t('environments.form.title')}</div>
        <div className="form-actions">
          {isAdministrator && (
            <>
              <PkiButton
                id="cancel-btn"
                label={t('pki:form.cancel')}
                onClick={props.onCancel}
                size="small"
                variant="secondary"
              />
              <PkiButton
                id="edit-btn"
                label={t('pki:form.edit')}
                onClick={props.onEditEnvironment}
                size="small"
                variant="primary"
              />
            </>
          )}
          {!isAdministrator && (
            <PkiButton id="ok-btn" label={t('pki:form.ok')} onClick={props.onCancel} size="small" variant="secondary" />
          )}
        </div>
      </div>
      <EnvironmentEntry
        form={form}
        onBlur={() => {
          // Noop
        }}
        onChange={() => {
          // Noop
        }}
        viewMode="view"
      />
    </section>
  );
};
EnvironmentView.displayName = 'EnvironmentView';
