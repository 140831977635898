import { Environment, EnvironmentRequest } from '@software-platforms/tenant-manager-ui/models';
import { Observable } from 'rxjs';

export interface EnvironmentService {
  /**
   * Returns a list of {@link Environment} objects, or an empty list if none were found.
   */
  fetchEnvironments: () => Observable<Environment[]>;

  /**
   * Returns an {@link Environment} object matching the given identifier, or null if no environment could be found.
   * @param id
   */
  fetchEnvironment: (id: string) => Observable<Environment>;

  /**
   * Persists a environment from the given information.
   * @param formData
   */
  createEnvironment: (formData: EnvironmentRequest) => Observable<Environment>;

  /**
   * Updates the environment matching the given identifier with the given form data.
   * @param id
   * @param formData
   */
  updateEnvironment: (id: string, formData: EnvironmentRequest) => Observable<Environment>;

  /**
   * Deletes the environment matching the given identifier.
   * @param id
   * @param force
   */
  deleteEnvironment: (id: string, force: boolean) => Observable<any>;
}
