import { AuthUser, AuthUserClaims, CurrentUser, JwtClaimProperty } from '@software-platforms/tenant-manager-ui/models';
import { Services } from '@software-platforms/tenant-manager-ui/services';
import { Epic } from 'redux-observable';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { AuthAction, AuthActions } from '../actions/auth.actions';
import { AppState } from '../model.state';

const JWT_CUSTOM_CLAIMS_PROP = 'https://apps.perkinelmercloud.net/custom';

/**
 * Returns an {@link AuthUser} object mapped from the given access-token claims.
 * @param claims
 */
const token2authUser = (claims: Partial<AuthUserClaims> = {}): AuthUser => ({
  ...claims,
  id: claims.sub,
  email: claims[JwtClaimProperty.USERNAME],
  mfaRequired: claims[JwtClaimProperty.MFA_REQUIRED]
    ? String(claims[JwtClaimProperty.MFA_REQUIRED]) === 'true'
    : undefined,
});

export const fetchCurrentUserEpic: Epic<AuthAction, AuthAction, AppState, Services> = (
  action$,
  state$,
  { authService }
) =>
  action$.pipe(
    filter(AuthActions.fetchCurrentUser.match),
    switchMap(() => {
      return authService.fetchAuthUser().pipe(
        map((decodedToken: Partial<AuthUserClaims>) => {
          const authUser = token2authUser(decodedToken);
          if (authUser) {
            if (authUser[JWT_CUSTOM_CLAIMS_PROP]) {
              try {
                authUser.customClaims = JSON.parse(authUser[JWT_CUSTOM_CLAIMS_PROP]);
              } catch (err) {
                // Drop through
              }
            }
          }
          const currentUser: CurrentUser = { authUser };
          return AuthActions.fetchCurrentUserSucceeded(currentUser);
        }),
        catchError((error) => of(AuthActions.fetchCurrentUserFailed(error)))
      );
    })
  );
