import { CurrentUserFormData } from '@nx-workspace/shared/models';
import { axiosErrorHandler } from '@nx-workspace/shared/services';
import { CreateUserRequest, UpdateUserRequest, User, UserResource } from '@software-platforms/tenant-manager-ui/models';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { from, Observable } from 'rxjs';
import { UserService } from '../interfaces';
import { UserMapper } from '../mappers/user-mapper';

/**
 * Concrete implementation of the {@link UserService} for Axios.
 */
export class AxiosUserService implements UserService {
  readConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'read:user' } };
  writeConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'write:user' } };
  readonly currentUserWriteConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'profile email' } };

  constructor(private readonly http: AxiosInstance) {}

  createUser(formData: CreateUserRequest): Observable<User> {
    const url = '/users';
    return from(
      this.http
        .post<UserResource>(url, formData, this.writeConfig)
        .then((response) => UserMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  deactivateUser(userId: string): Observable<User> {
    const url = `/users/${userId}/deactivate`;
    return from(
      this.http
        .post<UserResource>(url, {}, this.writeConfig)
        .then((response) => UserMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  deleteUser(userId: string): Observable<any> {
    const url = `/users/${userId}`;
    return from(
      this.http
        .delete(url, this.writeConfig)
        .then(() => null)
        .catch((error) => axiosErrorHandler(error))
    );
  }

  fetchUser(userId: string): Observable<User> {
    const url = `/users/${userId}`;
    return from(
      this.http
        .get<UserResource>(url, this.readConfig)
        .then((response) => UserMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  fetchUsers(): Observable<User[]> {
    const url = '/users';
    return from(
      this.http
        .get<UserResource[]>(url, this.readConfig)
        .then((response) => response.data.map((e) => UserMapper.from(e)))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  reactivateUser(userId: string): Observable<User> {
    const url = `/users/${userId}/activate`;
    return from(
      this.http
        .post<UserResource>(url, {}, this.writeConfig)
        .then((response) => UserMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  updateUser(userId: string, formData: UpdateUserRequest): Observable<User> {
    const url = `/users/${userId}`;
    return from(
      this.http
        .patch<UserResource>(url, formData, this.writeConfig)
        .then((response) => {
          // throw new Error('PkiNotification test');
          return UserMapper.from(response.data);
        })
        .catch((error) => axiosErrorHandler(error))
    );
  }

  updateCurrentUser(formData: CurrentUserFormData): Observable<any> {
    const url = `/user`;
    return from(
      this.http
        .patch<UserResource>(url, formData, this.currentUserWriteConfig)
        .then((response) => UserMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }
}
