import { Mapper } from '@nx-workspace/shared/models';
import { Subscription, SubscriptionResponse } from '@software-platforms/tenant-manager-ui/models';
import { DateTime } from 'luxon';

export const SubscriptionMapper: Mapper<SubscriptionResponse, Subscription> = {
  from(resource: SubscriptionResponse): Subscription {
    return {
      id: resource.id,
      allowedTenants: Number(resource.allowedTenants),
      companyName: resource.companyName,
      createdAt: DateTime.fromISO(resource.createdAt),
      createdBy: resource.createdBy,
      lastModifiedAt: resource.lastModifiedAt
        ? DateTime.fromISO(resource.lastModifiedAt)
        : DateTime.fromISO(resource.createdAt),
      lastModifiedBy: resource.lastModifiedBy || resource.createdBy,
      license: resource.license,
      numTenants: Number(resource.numTenants) || 0,
      orgSubscriptionId: resource.orgSubscriptionId,
      status: resource.status,
    };
  },

  to(entity: Subscription): SubscriptionResponse {
    // Do not use - the request payload to the server is a different object.
    return {
      id: entity.id,
      allowedTenants: entity.allowedTenants.toString(),
      companyName: entity.companyName,
      createdAt: entity.createdAt.toISO(),
      createdBy: entity.createdBy,
      lastModifiedAt: entity.lastModifiedAt ? entity.lastModifiedAt.toISO() : entity.createdAt.toISO(),
      lastModifiedBy: entity.lastModifiedBy,
      license: entity.license,
      numTenants: entity.numTenants,
      orgSubscriptionId: entity.orgSubscriptionId,
      status: entity.status,
    };
  },
};
