import { PkiIcon, triangleDown } from '@software-platforms/design-system-icons';
import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, {
  CSSProperties,
  forwardRef,
  ReactChild,
  ReactFragment,
  ReactNode,
  ReactPortal,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { isDescendant } from '../pki-utils';
import { PkiSelectMenu } from './pki-select-menu';
import './pki-select.scss';

/* ---------- Component Definition ---------- */

/**
 * @property children         The inner components comprising the options to select
 * @property className        Optional CSS class selector to override the input style
 * @property menuClassName    Optional CSS class selector to override the dropdown style
 * @property disableAutoClose True if the component should not automatically close on selection. Default is false.
 * @property disabled         True if the component is disabled
 * @property icon             Optional left icon component
 * @property multiple         True if the dropdown should accept multiple selections. Default is false.
 * @property name             The name of the input component
 * @property onBlur           Optional callback when the component loses focus
 * @property onChange         Optional callback when a selection is made
 * @property onClose          Optional callback when the dropdown is closed
 * @property onFocus          Optional callback when the component gains focus
 * @property onOpen           Optional callback when the dropdown is opened
 * @property placeholder      Optional placeholder text when no selection has been made. Default is 'Select...'
 * @property readonly         True if the component is read only
 * @property inputRef         Optional ref for the input component
 * @property renderValue      Optional callback to customize the option list
 * @property size             Optional size of the dropdown: 'small', 'medium" and 'large'. Default is 'medium'
 * @property style            Optional object containing CSS style overrides for the input component
 * @property tabIndex         Optional tab index number
 * @property value            The selected value(s) of this component
 */
export interface PkiSelectProps {
  children?: ReactNode;
  className?: string | string[];
  menuClassName?: string;
  disableAutoClose?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  multiple?: boolean;
  name?: string;
  onBlur?: React.FocusEventHandler<any>;
  onChange?: (event: React.ChangeEvent<HTMLElement>, child: ReactNode) => void;
  onClose?: (event: React.ChangeEvent<Record<string, any>>) => void;
  onCloseFromOutside?: () => void;
  onFocus?: React.FocusEventHandler<any>;
  onOpen?: (event: React.ChangeEvent<Record<string, any>>) => void;
  placeholder?: string;
  readonly?: boolean;
  inputRef?: React.Ref<any>;
  renderValue?: (args: any) => ReactNode;
  size?: 'small' | 'medium' | 'large';
  style?: CSSProperties;
  tabIndex?: number;
  value: any;
}

/**
 * Returns a drop-down component for displaying and selecting options. This component will automatically open the
 * dropdown when it gains focus. The dropdown will automatically close either upon selecting an option or clicking
 * anywhere outside. To override this behavior, set a ref on the component and set the `disableAutoClose` prop to
 * `false`. Then, to manually close the dropdown, call the `ref.current.close()` method.
 *
 * Usage: The composed children can take any form. This example provides a simple list, but more complex components
 * can be composed.
 *
 * <pre>
 *   <PkiSelect {...myProps} value={myValue}>
 *     {myOptions.map(each => (
 *       <PkiSelectOption key={myUniqueKey} value={each.myValue}>{each.myLabel}</PkiSelectOption>
 *     )}
 *   </PkiSelect>
 * </pre>
 */
export const PkiSelect = forwardRef((props: PkiSelectProps, ref) => {
  const {
    children,
    className,
    disableAutoClose = false,
    disabled,
    icon,
    menuClassName,
    multiple,
    name,
    placeholder,
    readonly,
    inputRef,
    size = 'medium',
    style,
    tabIndex = 0,
    value,
  } = props;

  const classNames = Array.isArray(className) ? className : [className];
  const inputComponentRef = inputRef || useRef(null);

  /* ---------- Menu Handling ---------- */

  type MenuEvent = React.MouseEvent | React.KeyboardEvent;
  const [openState, setOpenState] = useState(false);
  const handleMenuEvent = (flag: boolean, event: MenuEvent) => {
    if (flag) {
      if (props.onOpen) {
        props.onOpen(event);
      }
    } else if (props.onClose) {
      props.onClose(event);
    }
    if (flag || (!flag && !disableAutoClose)) {
      setOpenState(flag);
    }
  };
  const handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    if (event.button !== 0) {
      return;
    }
    event.preventDefault();
    handleMenuEvent(true, event);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    handleMenuEvent(false, event);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!readonly) {
      const validKeys = [' ', 'ArrowUp', 'ArrowDown', 'Enter'];
      if (validKeys.indexOf(event.key) !== -1) {
        event.preventDefault();
        handleMenuEvent(true, event);
      }
    }
  };
  const handleCloseFromOutside = (event: React.MouseEvent<HTMLElement>) => {
    if (disableAutoClose && props.onCloseFromOutside) {
      props.onCloseFromOutside();
    } else {
      handleClose(event);
    }
  };

  // Called by the parent component when disableAutoClose is true.
  useImperativeHandle(ref, () => ({
    close() {
      setOpenState(false);
    },
  }));

  // Listen for mouse clicks outside the menu.
  useEffect(() => {
    const clickOutside = (event: any) => {
      const elem = document.getElementById(`select-${name}`);
      if (elem && !isDescendant(elem, event.target)) {
        handleCloseFromOutside(event);
      }
    };
    window.addEventListener('click', clickOutside);
    return () => {
      window.removeEventListener('click', clickOutside);
    };
  });

  /* ---------- Menu Item Handling ---------- */

  // Fetch the children prop as an array. We will add more props and behaviors below.
  const childrenArray = React.Children.toArray(children);

  // Handle a change event on the input element
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const index = childrenArray.map((child) => child.props.value).indexOf(event.target.value);
    if (index === -1) {
      return;
    }
    const child = childrenArray[index];
    // @ts-ignore
    if (child.props.disabled) {
      return;
    }
    // @ts-ignore
    inputComponentRef.current.value = child.props.value;
    if (props.onChange) {
      // @ts-ignore
      props.onChange(event, child.props.value);
    }
    setOpenState(false);
  };

  // Returns a click handler for each option
  const handleItemClick =
    (child: ReactChild | ReactFragment | ReactPortal): Function =>
    (event: React.MouseEvent<HTMLElement>) => {
      // @ts-ignore
      if (child.props.disabled) {
        return;
      }

      if (!multiple) {
        handleMenuEvent(false, event);
      }

      let newValue: any | any[];
      if (multiple) {
        newValue = Array.isArray(value) ? value.slice() : [];
        // @ts-ignore
        const itemIndex = (value as any[]).indexOf(child.props.value);
        if (itemIndex === -1) {
          // @ts-ignore
          (newValue as any[]).push(child.props.value);
        } else {
          (newValue as any[]).splice(itemIndex, 1);
        }
      } else {
        // @ts-ignore
        newValue = child.props.value;
      }

      // @ts-ignore
      if (child.props.onClick) {
        // @ts-ignore
        child.props.onClick(event);
      }

      if (value === newValue) {
        return;
      }
      // @ts-ignore
      inputComponentRef.current.value = newValue;

      if (props.onChange) {
        event.persist();
        Object.defineProperty(event, 'target', {
          writable: true,
          value: {
            value: newValue,
            name,
          },
        });
        props.onChange(event as unknown as React.ChangeEvent<HTMLElement>, newValue);
      }
      if (props.onBlur) {
        props.onBlur(event as any);
      }
    };

  // Set up the displayed selected value
  let computeDisplay = false;
  let display: ReactNode;
  let displaySingle: ReactNode;
  const displayMultiple: any[] = [];
  if (props.renderValue) {
    display = props.renderValue(value);
  } else {
    computeDisplay = true;
  }

  // Add props to the children nodes
  // @see https://frontarm.com/james-k-nelson/passing-data-props-children/
  const items = childrenArray.map((child: ReactChild | ReactFragment | ReactPortal) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    // 1. Set the selected option and read the option's label (the child's children prop).
    let isSelected;
    if (multiple) {
      if (!Array.isArray(value)) {
        throw new Error('The value prop must be an array');
      }
      isSelected = value.some((e) => e === child.props.value);
      if (isSelected && computeDisplay) {
        displayMultiple.push(child.props.children);
      }
    } else {
      isSelected = value === child.props.value;
      if (isSelected && computeDisplay) {
        displaySingle = child.props.children;
      }
    }

    // 2. Return a clone of the child with these additional props.
    return React.cloneElement<any>(child, {
      'aria-selected': isSelected ? 'true' : undefined,
      onClick: handleItemClick(child),
      onKeyUp: (event: React.KeyboardEvent<HTMLElement>) => {
        event.preventDefault();
        if (event.key === ' ' || event.code === 'Space') {
          event.preventDefault();
        }
        if (child.props.onKeyUp) {
          child.props.onKeyUp();
        }
      },
      role: 'option',
      selected: isSelected,
      // The value may not be a string. Assign it to a data property instead.
      value: undefined,
      'data-value': child.props.value,
    });
  });

  // Compute the displayed value if the user didn't define a renderValue prop.
  if (computeDisplay) {
    display = (multiple ? displayMultiple.join(', ') : displaySingle) || placeholder;
  }

  return (
    <div
      className={cx('pki-select', size, ...classNames)}
      id={name}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onKeyDown={handleKeyDown}
      onMouseDown={disabled || readonly ? undefined : handleMouseDown}
      role="button"
      style={style}
      tabIndex={disabled ? 0 : tabIndex}
      aria-disabled={disabled ? 'true' : undefined}
      aria-expanded={openState ? 'true' : undefined}
      aria-haspopup="listbox"
    >
      <input
        type="hidden"
        name={name}
        onChange={handleChange}
        ref={inputComponentRef}
        tabIndex={-1}
        value={Array.isArray(value) ? value.join(',') : value}
        aria-hidden
      />
      <div id={`select-${name}`} className={cx('pki-select-input', { disabled })}>
        {icon && <div className="pki-btn-left-icon">{icon}</div>}
        <div className="pki-select-placeholder">
          {display}
          <div className="drop-down-icon">
            <PkiIcon icon={triangleDown} />
          </div>
        </div>
      </div>
      <PkiSelectMenu classNames={cx(size, menuClassName)} name={name} open={openState} onClose={handleClose}>
        {items}
      </PkiSelectMenu>
    </div>
  );
});
PkiSelect.displayName = 'PkiSelect';
