import '@software-platforms/design-system-styles';
import cx from 'classnames';
import { Property } from 'csstype';
import React, { ComponentType, CSSProperties, RefObject } from 'react';
import { renderIcon } from '../pki-utils';
import './pki-badge.scss';

export type PkiBadgeProps = {
  classNames?: string;
  color?: Property.Color;
  disabled?: boolean;
  icon?: React.ReactNode;
  label: string;
  onClick?: (event: React.MouseEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onKeyUp?: (event: React.KeyboardEvent) => void;
  ref?: RefObject<HTMLDivElement>;
  size?: 'medium' | 'small';
  style?: CSSProperties;
  variant?: 'default' | 'outline';
};

export const PkiBadge: React.FunctionComponent<React.PropsWithChildren<PkiBadgeProps>> = (props) => {
  const { classNames, disabled, icon, label, ref, size = 'small', style, variant = 'default' } = props;
  const role = props.onClick !== undefined ? 'button' : undefined;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (props.onKeyDown) {
      props.onKeyDown(event);
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.currentTarget === event.target) {
      if (event.key === 'Escape' && ref?.current) {
        ref?.current.blur();
      }
    }
    if (props.onKeyUp) {
      props.onKeyUp(event);
    }
  };

  return (
    <div
      className={cx('pki-badge', classNames, size, variant)}
      onClick={props.onClick}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      ref={ref}
      role={role}
      style={style}
      aria-disabled={disabled}
    >
      {icon && <div className="pki-badge-icon">{icon}</div>}
      <div className="pki-badge-label">{label}</div>
    </div>
  );
};
PkiBadge.displayName = 'PkiBadge';
