import { Observable, of } from 'rxjs';
import { StorageService } from './storage.service';

/**
 * Wrapper for the window.sessionStorage methods.
 */
export const SessionStorageService: StorageService = {
  getItem: (key: string): Observable<any> => {
    const item = window.sessionStorage.getItem(key);
    if (item) {
      try {
        const value = JSON.parse(item);
        return of(value);
      } catch (e) {
        console.error('JSON parsing error', e);
      }
    }
    return of(null);
  },

  setItem: (key: string, newValue: any): Observable<any> => {
    const oldValue = SessionStorageService.getItem(key);
    window.sessionStorage.setItem(key, JSON.stringify(newValue));
    return oldValue;
  },

  removeItem: (key: string): Observable<any> => {
    const oldValue = SessionStorageService.getItem(key);
    window.sessionStorage.removeItem(key);
    return oldValue;
  },

  clear: (): Observable<any> => {
    window.sessionStorage.clear();
    return of(null);
  },
};
