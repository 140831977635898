import { i18n } from '@nx-workspace/shared/lang';
import { StandardAction } from '@nx-workspace/shared/models';
import { NotificationServiceFactory } from '@nx-workspace/shared/notification';
import { Subscription } from '@software-platforms/tenant-manager-ui/models';
import { Services } from '@software-platforms/tenant-manager-ui/services';
import { Epic, ofType } from 'redux-observable';
import { catchError, map, of, switchMap } from 'rxjs';
import { SubscriptionActions, SubscriptionActionType } from '../actions/subscription.actions';
import { AppState } from '../model.state';

export const fetchSubscriptionsEpic: Epic<StandardAction<any, any>, StandardAction<any, any>, AppState, Services> = (
  action$,
  state$,
  { subscriptionService }
) =>
  action$.pipe(
    ofType(SubscriptionActionType.FETCH_SUBSCRIPTIONS),
    switchMap(() =>
      subscriptionService.fetchSubscriptions().pipe(
        map((list: Subscription[]) => SubscriptionActions.fetchSubscriptionsSucceeded(list)),
        catchError((error) => {
          NotificationServiceFactory.getInstance().showError(error?.message);
          return of(SubscriptionActions.fetchSubscriptionsFailed(error));
        })
      )
    )
  );

export const fetchSubscriptionEpic: Epic<StandardAction<any, any>, StandardAction<any, any>, AppState, Services> = (
  action$,
  state$,
  { subscriptionService }
) =>
  action$.pipe(
    ofType(SubscriptionActionType.FETCH_SUBSCRIPTION),
    switchMap((action: StandardAction<any, any>) =>
      subscriptionService.fetchSubscription(action.meta!.id!).pipe(
        map((resource: Subscription) => SubscriptionActions.fetchSubscriptionSucceeded(action.meta!.id!, [resource])),
        catchError((error) => {
          NotificationServiceFactory.getInstance().showError(error?.message);
          return of(SubscriptionActions.fetchSubscriptionFailed(action.meta!.id!, error));
        })
      )
    )
  );

export const createSubscriptionEpic: Epic<StandardAction<any, any>, StandardAction<any, any>, AppState, Services> = (
  action$,
  state$,
  { subscriptionService }
) =>
  action$.pipe(
    ofType(SubscriptionActionType.CREATE_SUBSCRIPTION),
    switchMap((action: StandardAction<any, any>) =>
      subscriptionService.createSubscription(action.meta!.formData!).pipe(
        map((resource) => {
          const msg = i18n.t('subscriptions.form.createSuccess');
          NotificationServiceFactory.getInstance().show(msg, { type: 'success' });
          return SubscriptionActions.createSubscriptionSucceeded(action.meta!.formData!, [resource]);
        }),
        catchError((error: any) => {
          NotificationServiceFactory.getInstance().showError(error?.message);
          return of(SubscriptionActions.createSubscriptionFailed(action.meta!.formData!, error));
        })
      )
    )
  );

export const updateSubscriptionEpic: Epic<StandardAction<any, any>, StandardAction<any, any>, AppState, Services> = (
  action$,
  state$,
  { subscriptionService }
) =>
  action$.pipe(
    ofType(SubscriptionActionType.UPDATE_SUBSCRIPTION),
    switchMap((action: StandardAction<any, any>) =>
      subscriptionService.updateSubscription(action.meta!.id!, action.meta!.formData!).pipe(
        map((resource) => {
          const msg = i18n.t('subscriptions.form.updateSuccess');
          NotificationServiceFactory.getInstance().show(msg, { type: 'success' });
          return SubscriptionActions.updateSubscriptionSucceeded(action.meta!.id!, action.meta!.formData!, [resource]);
        }),
        catchError((error: any) => {
          NotificationServiceFactory.getInstance().showError(error?.message);
          return of(SubscriptionActions.updateSubscriptionFailed(action.meta!.id!, action.meta!.formData!, error));
        })
      )
    )
  );

export const deleteSubscriptionEpic: Epic<StandardAction<any, any>, StandardAction<any, any>, AppState, Services> = (
  action$,
  state$,
  { subscriptionService }
) =>
  action$.pipe(
    ofType(SubscriptionActionType.DELETE_SUBSCRIPTION),
    switchMap((action: StandardAction<any, any>) =>
      subscriptionService.deleteSubscription(action.meta!.id!, action.meta!.force || false).pipe(
        map(() => {
          const msg = i18n.t('subscriptions.delete.success');
          NotificationServiceFactory.getInstance().show(msg, { type: 'success' });
          return SubscriptionActions.deleteSubscriptionSucceeded(action.meta!.id!);
        }),
        catchError((error: any) => {
          NotificationServiceFactory.getInstance().showError(error?.message);
          return of(SubscriptionActions.deleteSubscriptionFailed(action.meta!.id!, error));
        })
      )
    )
  );

export const deactivateSubscriptionEpic: Epic<
  StandardAction<any, any>,
  StandardAction<any, any>,
  AppState,
  Services
> = (action$, state$, { subscriptionService }) =>
  action$.pipe(
    ofType(SubscriptionActionType.DEACTIVATE_SUBSCRIPTION),
    switchMap((action: StandardAction<any, any>) =>
      subscriptionService.deactivateSubscription(action.meta!.id!).pipe(
        map((resource) => {
          const msg = i18n.t('subscriptions.form.deactivateSuccess');
          NotificationServiceFactory.getInstance().show(msg, { type: 'success' });
          return SubscriptionActions.deactivateSubscriptionSucceeded(action.meta!.id!, [resource]);
        }),
        catchError((error: any) => {
          NotificationServiceFactory.getInstance().showError(error?.message);
          return of(SubscriptionActions.deactivateSubscriptionFailed(action.meta!.id!, error));
        })
      )
    )
  );

export const reactivateSubscriptionEpic: Epic<
  StandardAction<any, any>,
  StandardAction<any, any>,
  AppState,
  Services
> = (action$, state$, { subscriptionService }) =>
  action$.pipe(
    ofType(SubscriptionActionType.REACTIVATE_SUBSCRIPTION),
    switchMap((action: StandardAction<any, any>) =>
      subscriptionService.reactivateSubscription(action.meta!.id!).pipe(
        map((resource) => {
          const msg = i18n.t('subscriptions.form.activateSuccess');
          NotificationServiceFactory.getInstance().show(msg, { type: 'success' });
          return SubscriptionActions.reactivateSubscriptionSucceeded(action.meta!.id!, [resource]);
        }),
        catchError((error: any) => {
          NotificationServiceFactory.getInstance().showError(error?.message);
          return of(SubscriptionActions.reactivateSubscriptionFailed(action.meta!.id!, error));
        })
      )
    )
  );
