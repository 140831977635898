import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';

export type PkiComboBoxMenuProps = {
  children?: ReactNode; // We need this to make TypeScript happy
  className?: string;
  name: string;
  onClose?: {
    bivarianceHack(event: {}): void;
  }['bivarianceHack'];
  open: boolean;
  style?: CSSProperties;
};

export const PkiComboBoxMenu = React.forwardRef<HTMLDivElement, PkiComboBoxMenuProps>(function PkiComboBoxMenu(
  props,
  ref
) {
  const { children, className, name, open, style } = props;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      if (props.onClose) {
        props.onClose(event);
      }
    }
  };

  return (
    <div
      id={`owned-listbox-${name}`}
      className={cx('pki-combobox-menu', className, { open })}
      onKeyDown={handleKeyDown}
      ref={ref}
      role="listbox"
      style={style}
    >
      {children}
    </div>
  );
});
PkiComboBoxMenu.displayName = 'PkiComboBoxMenu';
