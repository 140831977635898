import { Subscription, SubscriptionRequest } from '@software-platforms/tenant-manager-ui/models';
import { Observable } from 'rxjs';

export interface SubscriptionService {
  /**
   * Returns a list of subscriptions, or an empty list if none were found.
   */
  fetchSubscriptions: () => Observable<Subscription[]>;

  /**
   * Returns a {@link Subscription} matching the given identifier.
   */
  fetchSubscription: (id: string) => Observable<Subscription>;

  /**
   * Persists the given {@link Subscription} form data.
   * @param formData
   */
  createSubscription: (formData: SubscriptionRequest) => Observable<Subscription>;

  /**
   * Updates the {@link Subscription} matching the given identifier with the given form data.
   * @param id
   * @param formData
   */
  updateSubscription: (id: string, formData: SubscriptionRequest) => Observable<Subscription>;

  /**
   * Deletes the {@link Subscription} matching the given identifier.
   */
  deleteSubscription: (id: string, force: boolean) => Observable<any>;

  /**
   * Inactivates the {@link Subscription} matching the given identifier.
   */
  deactivateSubscription: (id: string) => Observable<Subscription>;

  /**
   * Re-activates the {@link Subscription} matching the given identifier.
   */
  reactivateSubscription: (id: string) => Observable<Subscription>;
}
