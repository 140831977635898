import { combineEpics, Epic } from 'redux-observable';
import { fetchCurrentUserEpic } from './epics/auth.epics';
import {
  createEnvironmentEpic,
  deleteEnvironmentEpic,
  fetchEnvironmentEpic,
  fetchEnvironmentsEpic,
  updateEnvironmentEpic,
} from './epics/environment.epics';
import {
  createSubscriptionEpic,
  deactivateSubscriptionEpic,
  deleteSubscriptionEpic,
  fetchSubscriptionEpic,
  fetchSubscriptionsEpic,
  reactivateSubscriptionEpic,
  updateSubscriptionEpic,
} from './epics/subscription.epics';
import {
  createTenantEpic,
  deactivateTenantEpic,
  deleteTenantEpic,
  fetchTenantEpic,
  fetchTenantsEpic,
  reactivateTenantEpic,
  updateTenantEpic,
} from './epics/tenant.epics';
import {
  createUserEpic,
  deactivateUserEpic,
  deleteUserEpic,
  fetchUserEpic,
  fetchUsersEpic,
  reactivateUserEpic,
  updateUserEpic,
} from './epics/user.epics';

export const rootEpic: Epic = combineEpics(
  createEnvironmentEpic,
  createSubscriptionEpic,
  createTenantEpic,
  createUserEpic,
  deactivateSubscriptionEpic,
  deactivateTenantEpic,
  deactivateUserEpic,
  deleteEnvironmentEpic,
  deleteSubscriptionEpic,
  deleteTenantEpic,
  deleteUserEpic,
  fetchCurrentUserEpic,
  fetchEnvironmentEpic,
  fetchEnvironmentsEpic,
  fetchSubscriptionEpic,
  fetchSubscriptionsEpic,
  fetchTenantEpic,
  fetchTenantsEpic,
  fetchUserEpic,
  fetchUsersEpic,
  reactivateSubscriptionEpic,
  reactivateTenantEpic,
  reactivateUserEpic,
  updateEnvironmentEpic,
  updateSubscriptionEpic,
  updateTenantEpic,
  updateUserEpic
);
