import { Observable, of } from 'rxjs';
import { AbstractAuthService } from './abstract-auth.service';

/**
 * Concrete implementation of the {@link AuthService} when no authentication IDP is specified.
 */
export class PkiNoopAuthService extends AbstractAuthService {
  fetchAuthUser(): Observable<any> {
    return of({});
  }

  getAccessToken(scope?: string): Observable<string> {
    return of('');
  }

  isAuthenticated(): Observable<boolean> {
    return of(true);
  }

  logout() {
    // Noop
  }

  setAuthorizationHeader<P>(requestConfig: P): Promise<P> {
    return Promise.resolve(requestConfig);
  }
}
