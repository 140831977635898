import {
  PkiButton,
  PkiDialog,
  PkiDialogContent,
  PkiDialogFooter,
  PkiDialogHeader,
} from '@software-platforms/design-system-components';
import cx from 'classnames';
import humanize from 'humanize-string';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import { AuditEntry } from '@nx-workspace/shared/audit-trail';
import styles from './audit-detail-dialog.module.scss';

/**
 * Returns true if the given value represents a valid date, false otherwise. Milliseconds are not considered dates.
 * @param raw
 */
const isDate = (raw: string): boolean => (Number.isFinite(raw) ? false : DateTime.fromISO(raw).isValid);

type AuditAttribute = {
  id: string;
  property: string;
  oldValue: string;
  newValue: string;
};

/* ---------- Component Definition ---------- */

type OwnProps = {
  entry: AuditEntry | undefined;
  open: boolean;
  onClose: () => void;
};

export const AuditDetailDialog: React.FunctionComponent<OwnProps> = (props) => {
  const { entry, open } = props;
  const { t } = useTranslation();

  const attributesList = useMemo<AuditAttribute[]>(() => {
    if (!entry) {
      return [];
    }

    const attributes = [
      ...(entry.oldValue ? Object.keys(entry.oldValue) : []),
      ...(entry.newValue ? Object.keys(entry.newValue) : []),
    ];
    const allAttributes = attributes.filter((x, y) => attributes.indexOf(x) === y).sort();

    const noValue = `[${t('audit.detail.noValue')}]`;

    return allAttributes
      .map((property) => {
        let oldValue =
          entry.oldValue && entry.oldValue[property] !== undefined && entry.oldValue[property] !== ''
            ? JSON.stringify(entry.oldValue[property]).replace(/"/g, '')
            : noValue;
        if (isDate(oldValue)) {
          oldValue = DateTime.fromISO(oldValue).toUTC().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
        }

        let newValue =
          entry.newValue && entry.newValue[property] !== undefined && entry.newValue[property] !== ''
            ? JSON.stringify(entry.newValue[property]).replace(/"/g, '')
            : noValue;
        if (isDate(newValue)) {
          newValue = DateTime.fromISO(newValue).toUTC().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
        }

        if (entry.oldValue && entry.newValue) {
          //entity was modified
          //only show the modified attributes
          if (entry.newValue[property] === undefined || newValue === oldValue) {
            newValue = '';
          }
        }

        return {
          id: uuidV4().substring(0, 8),
          property,
          oldValue,
          newValue,
        };
      })
      .sort((a1, a2) => (entry.oldValue ? a2.newValue.localeCompare(a1.newValue) : 0));
  }, [entry, t]);

  if (!entry) {
    return null;
  }

  const columnSize = entry.oldValue && entry.newValue ? 45 : 60;

  return (
    <PkiDialog onClose={props.onClose} open={open} className={styles['auditDialog']} size="xl">
      <PkiDialogHeader
        type="info"
        title={t('audit.detail.title', {
          timestamp: entry.createdAt.toUTC().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS),
        })}
      />
      <PkiDialogContent className={styles['detailContent']}>
        <div className="col-container">
          <div className={cx('col', styles['entryLabel'])}>
            <div>{t('audit.detail.entity')}</div>
            <div>{t('audit.detail.action')}</div>
            <div>{t('audit.detail.user')}</div>
            <div>{t('audit.detail.ipAddress')}</div>
            <div>{t('audit.detail.timestamp')}</div>
          </div>
          <div className={cx('col', styles['entryValue'])}>
            <div>{entry.entity}</div>
            <div>{t(`audit.action.${entry.action}`)}</div>
            <div>{entry.createdBy}</div>
            <div>{entry.ipAddress}</div>
            <div>{(entry.createdAt as DateTime).toUTC().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}</div>
          </div>
        </div>

        <div className={cx('col-container', styles['detailContainer'])}>
          <div className={cx('col', styles['entryLabel'])}>
            <div className={styles['detailTitle']}>{t('audit.detail.property')}</div>
            {attributesList.map((attribute) => (
              <div key={attribute.id}>{humanize(attribute.property)}:</div>
            ))}
          </div>
          {entry.oldValue && (
            <div className={cx('col', styles['detailValue'])}>
              <div className={styles['detailTitle']}>
                {entry.newValue ? t('audit.detail.oldValues') : t('audit.detail.values')}
              </div>
              {attributesList.map((attribute) => (
                <div key={attribute.id} title={attribute.oldValue}>
                  {attribute.oldValue.substring(0, columnSize)}
                </div>
              ))}
            </div>
          )}
          {entry.newValue && (
            <div className={cx('col', styles['detailValue'])}>
              <div className={styles['detailTitle']}>
                {entry.oldValue ? t('audit.detail.newValues') : t('audit.detail.values')}
              </div>
              {attributesList.map((attribute) => (
                <div key={attribute.id} title={attribute.newValue}>
                  {attribute.newValue.substring(0, columnSize)}
                </div>
              ))}
            </div>
          )}
        </div>
      </PkiDialogContent>
      <PkiDialogFooter onClose={props.onClose} type="info">
        <PkiButton label={t('pki:form.ok')} onClick={props.onClose} variant="primary" />
      </PkiDialogFooter>
    </PkiDialog>
  );
};
AuditDetailDialog.displayName = 'AuditDetailDialog';
