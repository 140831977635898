/**
 * Returns the base URL as defined in the root `config.js` file. This file is injected during build time by the
 * build configuration (development or production).
 */
export const getBaseUrl = (): string => {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  const appConfig = window['appConfig'];
  if (!appConfig) {
    throw new Error('API endpoint not found.');
  }
  return appConfig.api.apiEndpoint;
};
