import { DropDownOption } from '@nx-workspace/shared/models';
import { PkiButton, PkiChip, PkiSelect, PkiSelectOption } from '@software-platforms/design-system-components';
import { addCircle, PkiIcon } from '@software-platforms/design-system-icons';
import { SearchFilter, UserStatus, isFilterSelected } from '@software-platforms/tenant-manager-ui/models';
import cx from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatFilterLabel } from '../../utils';
import { UserViewMode } from '../users';
import styles from './user-header.module.scss';

type OwnProps = {
  filters: SearchFilter[];
  isAdministrator: boolean;
  onCreateUser: (event: React.MouseEvent) => void;
  onAddFilter: (event: React.ChangeEvent<any>) => void;
  onClearFilters: () => void;
  onDeleteFilter: (filter: SearchFilter) => void;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  search: string;
  viewMode: UserViewMode;
};

export const UserHeader: React.FunctionComponent<OwnProps> = (props) => {
  const { filters, isAdministrator, search, viewMode } = props;
  const { t } = useTranslation();
  const disableActions = ['create', 'edit'].includes(viewMode);

  const USER_STATUS_OPTIONS: DropDownOption[] = useMemo<DropDownOption[]>(
    () =>
      Object.keys(UserStatus).map((key) => ({
        value: UserStatus[key],
        label: t(`users.status.${UserStatus[key]}`),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [UserStatus]
  );

  return (
    <>
      <header>
        <div className="pki-header-slot">
          <div className="pki-page-title">{t('users.title')}</div>
          {isAdministrator && (
            <PkiButton
              disabled={disableActions}
              id="add-btn"
              label={t('users.addBtnLabel')}
              leftIcon={<PkiIcon icon={addCircle} />}
              onClick={props.onCreateUser}
              size="small"
              variant="primary"
            />
          )}
        </div>
        <div className="pki-header-slot end">
          <PkiSelect
            readonly={disableActions}
            name="filter"
            onClose={props.onAddFilter}
            placeholder={t('users.filter.title')}
            style={{ width: '160px' }}
            value={filters}
          >
            <div className="col-container" style={{ width: '100px', gap: '10px', margin: '0 2px 2px' }}>
              <div className="col">
                <div className="pki-select-group">{t('users.filter.status')}</div>
                {USER_STATUS_OPTIONS.map((e) => (
                  <PkiSelectOption
                    key={e.value}
                    className="filter"
                    group="status"
                    value={e.value}
                    role="option"
                    selected={isFilterSelected('status', e.value, filters)}
                    aria-selected={isFilterSelected('status', e.value, filters) ? 'true' : undefined}
                  >
                    {e.label}
                  </PkiSelectOption>
                ))}
              </div>
            </div>
          </PkiSelect>
          <div className={cx('form-control', 'search')} style={{ width: '250px' }}>
            <input
              type="text"
              disabled={disableActions}
              placeholder={t('pki:form.searchPlaceholder')}
              onChange={props.onSearch}
              value={search}
            />
          </div>
        </div>
      </header>
      {filters.length > 0 && (
        <section className={styles.filters}>
          {filters.map((filter) => (
            <div key={`filter-${filter.field}${filter.value}`}>
              <PkiChip
                className={styles.pkiChip}
                label={formatFilterLabel(filter)}
                rightDeleteIcon
                onClick={() => props.onDeleteFilter(filter)}
                onDelete={() => props.onDeleteFilter(filter)}
              />
            </div>
          ))}
          <PkiButton
            classNames={styles.clearFilterBtn}
            label={t('clearFiltersBtnLabel')}
            onClick={props.onClearFilters}
            variant="text-only"
          />
        </section>
      )}
    </>
  );
};
UserHeader.displayName = 'UserHeader';
