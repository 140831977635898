import { AuditTrail } from '@nx-workspace/shared/audit-trail-ui';
import { ServiceFactory } from '@software-platforms/tenant-manager-ui/services';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from './components/not-found/not-found';
import Environments from './environments/environments';
import Subscriptions from './subscriptions/subscriptions';
import Tenants from './tenants/tenants';
import { Users } from './users/users';

export const FeatureModule: React.FunctionComponent = () => {
  const { auditService } = ServiceFactory.getServices();
  return (
    <Routes>
      <Route path="/*" element={<Tenants />} />
      <Route path="subscriptions/*" element={<Subscriptions />} />
      <Route path="tenants/*" element={<Tenants />} />
      <Route path="users/*" element={<Users />} />
      <Route path="environments/*" element={<Environments />} />
      <Route path="audit-trail/*" element={<AuditTrail auditService={auditService} scope="read:audit" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
FeatureModule.displayName = 'FeatureModule';
