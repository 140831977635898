import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { CSSProperties } from 'react';
import './pki-layout.scss';

export type PkiLayoutSidebarProps = {
  className?: string;
  style?: CSSProperties;
};

export const PkiLayoutSidebar: React.FunctionComponent<React.PropsWithChildren<PkiLayoutSidebarProps>> = (props) => {
  const { className, style } = props;
  return (
    <section className={cx('pki-layout-sidebar', className)} style={style}>
      {props.children}
    </section>
  );
};
PkiLayoutSidebar.displayName = 'PkiLayoutSidebar';

export default PkiLayoutSidebar;
