import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { CSSProperties } from 'react';
import './pki-menu.scss';

export type PkiMenuItemProps = {
  disabled?: boolean;
  icon?: React.ReactNode;
  iconSize?: string;
  onClick?: { bivarianceHack(event: Object): void }['bivarianceHack'];
  style?: CSSProperties;
  [key: string]: any;
};

export const PkiMenuItem: React.FunctionComponent<React.PropsWithChildren<PkiMenuItemProps>> = (props) => {
  const { children, icon, iconSize, onClick, ...rest } = props;

  let height;
  let width;
  if (iconSize) {
    const parts = iconSize.split(' ');
    height = parts[0];
    width = parts?.length > 1 ? parts[1] : parts[0];
  }

  return (
    <div className={cx('pki-dropdown-item', { selected: rest['aria-selected'] })} {...rest} onClick={onClick}>
      {icon ? (
        <span className="pki-dropdown-item-icon" style={{ height, width }}>
          {icon}
        </span>
      ) : null}
      {children}
    </div>
  );
};
PkiMenuItem.displayName = 'PkiMenuItem';

export default PkiMenuItem;
