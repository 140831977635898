import { NotificationServiceFactory } from '@nx-workspace/shared/notification';
import {
  FormBuilder as fb,
  FormControl,
  isFormDirty,
  isFormValid,
  PkiButton,
  PkiDialog,
  required,
  updateValidity,
  validateForm,
  ValidationErrors,
} from '@software-platforms/design-system-components';
import { addCircle, PkiIcon } from '@software-platforms/design-system-icons';
import {
  Environment,
  EnvironmentForm,
  EnvironmentRequest,
  Tenant,
  TenantQueryRequest,
  userHasAdministratorRole,
} from '@software-platforms/tenant-manager-ui/models';
import { ServiceFactory } from '@software-platforms/tenant-manager-ui/services';
import { AppState, EnvironmentActions } from '@software-platforms/tenant-manager-ui/store';
import cx from 'classnames';
import React from 'react';
import { Helmet } from 'react-helmet';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { WithRouter, withRouter } from '../components';
import { testGuid } from '../utils';
import { pqdn } from '../utils/validators';
import { EmptyEnvironment } from './empty-environment/empty-environment';
import { EnvironmentCreate } from './environment-create/environment-create';
import { EnvironmentEdit } from './environment-edit/environment-edit';
import { EnvironmentList } from './environment-list/environment-list';
import { DeleteEnvironmentModal } from './environment-modal/delete-environment-modal';
import { EnvironmentView } from './environment-view/environment-view';
import styles from './environments.module.scss';

export type EnvironmentViewMode = 'create' | 'edit' | 'view' | '';

const getPageTitle = (path: string): string => {
  if (/create/i.test(path)) {
    return 'environments.page.create';
  }
  if (/edit/i.test(path)) {
    return 'environments.page.edit';
  }
  if (/environments\/\w+/i.test(path)) {
    return 'environments.page.view';
  }
  return 'environments.page.list';
};

/* ---------- Component Definition ---------- */

type OwnProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WithRouter &
  WithTranslation;

type OwnState = {
  currentResource: Environment | null;
  form: EnvironmentForm;
  openConfirmCancel: boolean;
  openConfirmDelete: boolean;
  openConfirmSave: boolean;
  viewMode: EnvironmentViewMode;
  numTenants: number;
};

class Environments extends React.Component<OwnProps, OwnState> {
  constructor(props: OwnProps) {
    super(props);
    this.getForm = this.getForm.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validateDomain = this.validateDomain.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirmCancel = this.handleConfirmCancel.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleConfirmSave = this.handleConfirmSave.bind(this);
    this.handleCreateEnvironment = this.handleCreateEnvironment.bind(this);
    this.handleDeleteEnvironment = this.handleDeleteEnvironment.bind(this);
    this.handleEditEnvironment = this.handleEditEnvironment.bind(this);
    this.handleSaveEnvironment = this.handleSaveEnvironment.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);

    this.state = {
      currentResource: null,
      form: this.getForm(),
      openConfirmCancel: false,
      openConfirmDelete: false,
      openConfirmSave: false,
      viewMode: '',
      numTenants: 0,
    };
  }

  componentDidMount() {
    this.props.fetchEnvironments();
    const matches = testGuid(this.props.location.pathname);
    if (matches && matches.length) {
      const environmentId = matches[0];
      this.props.fetchEnvironment(environmentId);
    }
  }

  componentDidUpdate(prevProps: Readonly<OwnProps>) {
    const { currentEnvironment, error, isProcessing } = this.props;
    if (currentEnvironment && !prevProps.currentEnvironment) {
      this.setState({ currentResource: currentEnvironment, form: this.getForm(currentEnvironment) });
    }
    if (!isProcessing && prevProps.isProcessing) {
      this.props.navigate('/environments');
    }
    if (error && prevProps.error) {
      NotificationServiceFactory.getInstance().show(error, { type: 'error' });
    }
  }

  /* ---------- Form Methods ---------- */

  getForm(environment?: Environment): EnvironmentForm {
    return {
      name: fb.getInstance(environment?.name || '', [required, this.validateName]),
      domain: fb.getInstance(environment?.domain || '', [required, pqdn, this.validateDomain]),
    };
  }

  handleBlur(controlName: string) {
    this.setState((prevState) => {
      const clonedForm = { ...prevState.form! } as EnvironmentForm;
      updateValidity(clonedForm[controlName]);
      clonedForm[controlName].touched = true;
      return { ...prevState, form: clonedForm };
    });
  }

  handleChange(controlName: string, newValue: any) {
    this.setState((prevState) => {
      if (newValue !== prevState.form![controlName].value) {
        const clonedForm = { ...prevState.form } as EnvironmentForm;
        clonedForm[controlName].value = newValue;
        clonedForm[controlName].dirty = true;
        return { ...prevState, form: clonedForm };
      }
      return prevState;
    });
  }

  validateName(control: FormControl): ValidationErrors | null {
    if (control.value) {
      const { list } = this.props;
      const { currentResource } = this.state;
      const isDuplicate = list.some((e) => e.name === control.value && e.id !== currentResource?.id);
      if (isDuplicate) {
        return { duplicate: true };
      }
    }
    return null;
  }

  validateDomain(control: FormControl): ValidationErrors | null {
    if (control.value) {
      const { list } = this.props;
      const { currentResource } = this.state;
      const isDuplicate = list.some((e) => e.domain === control.value && e.id !== currentResource?.id);
      if (isDuplicate) {
        return { duplicate: true };
      }
    }
    return null;
  }

  /* ---------- Actions ---------- */

  handleCancel(event: React.MouseEvent) {
    if (event.cancelable) {
      event.preventDefault();
    }
    if (isFormDirty(this.state.form!)) {
      this.setState({ openConfirmCancel: true });
    } else {
      this.setState({ currentResource: null, form: this.getForm(), viewMode: '' });
      this.props.navigate('/environments');
    }
  }

  handleConfirmCancel(isConfirmed: boolean) {
    this.setState({ openConfirmCancel: false });
    if (isConfirmed) {
      this.setState({ currentResource: null, form: this.getForm(), viewMode: '' });
      this.props.navigate('/environments');
    }
  }

  handleConfirmDelete(isConfirmed: boolean) {
    this.setState({ openConfirmDelete: false });

    if (isConfirmed) {
      // if numTenants !== 0 and the user confirmed the environment deletion even though there are tenants
      // then we have to 'force' the deletion otherwise the back-end will reject it
      this.props.deleteEnvironment(this.state.currentResource!.id, !!this.state.numTenants);
      this.setState({ currentResource: null, form: this.getForm(), viewMode: '' });
    }
  }

  handleConfirmSave(isConfirmed: boolean) {
    this.setState({ openConfirmSave: false });
    if (isConfirmed) {
      const { currentResource, form } = this.state;
      if (form) {
        let formData: EnvironmentRequest;
        if (currentResource) {
          formData = { name: form.name.value };
          this.props.updateEnvironment(currentResource.id, formData);
        } else {
          formData = {
            name: form.name.value,
            dnsFqdn: form.domain.value,
          };
          this.props.createEnvironment(formData);
        }
        // Clear the current resource and form, and return to list mode.
        this.setState({ currentResource: null, form: this.getForm(), viewMode: '' });
      }
    }
  }

  handleCreateEnvironment(event: React.MouseEvent) {
    if (event.cancelable) {
      event.preventDefault();
    }
    this.setState({ currentResource: null, form: this.getForm(), viewMode: 'create' });
    this.props.navigate('/environments/create');
  }

  handleDeleteEnvironment(event: React.MouseEvent) {
    if (event.cancelable) {
      event.preventDefault();
    }
    if (this.state.currentResource && !this.state.openConfirmDelete) {
      // first check if any tenants are assigned to this environment.
      // getting only the tenant IDs so we can count them is much faster
      // because the back-end can return the IDs from the index
      // without having to read each tenant document to get more attributes
      const query: TenantQueryRequest = { environmentId: this.state.currentResource.id, attributesToGet: 'id' };
      ServiceFactory.getServices()
        .tenantService.fetchTenants(query)
        .subscribe((list: Tenant[]) => {
          this.setState({ openConfirmDelete: true, numTenants: (list || []).length });
        });
    }
  }

  handleEditEnvironment(event: React.MouseEvent) {
    if (event.cancelable) {
      event.preventDefault();
    }
    const { currentResource } = this.state;
    if (currentResource) {
      this.setState({ viewMode: 'edit' });
      this.props.navigate(`/environments/${currentResource.id}/edit`);
    }
  }

  handleSaveEnvironment(event: React.MouseEvent) {
    if (event.cancelable) {
      event.preventDefault();
    }
    const { form } = this.state;
    if (isFormDirty(form)) {
      validateForm(form);
      this.setState({ form }, () => {
        if (isFormValid(form)) {
          this.setState({ openConfirmSave: true });
        }
      });
    }
  }

  handleSelectionChanged(environmentId: string) {
    if (environmentId) {
      const found = this.props.list.find((e) => e.id === environmentId);
      if (found) {
        this.setState({ currentResource: found, form: this.getForm(found), viewMode: 'view' });
        this.props.navigate(`/environments/${found.id}`);
      }
    }
  }

  /* ---------- Rendering ---------- */

  render() {
    const { currentUser, isLoading, isProcessing, list, location, t } = this.props;
    const { form, openConfirmCancel, openConfirmDelete, openConfirmSave, viewMode, currentResource, numTenants } =
      this.state;
    const disableCreateBtn = viewMode === 'create' || viewMode === 'edit';
    const isAdministrator = userHasAdministratorRole(currentUser?.authUser);

    return (
      <>
        <Helmet title={t(getPageTitle(location.pathname))} />
        <header>
          <div className="pki-header-slot">
            <div className="pki-page-title">{t('environments.title')}</div>
            {isAdministrator && (
              <PkiButton
                disabled={disableCreateBtn}
                id="add-btn"
                label={t('environments.addBtnLabel')}
                leftIcon={<PkiIcon icon={addCircle} />}
                onClick={this.handleCreateEnvironment}
                size="small"
                variant="primary"
              />
            )}
          </div>
        </header>
        <div className={cx('content-inner-container', styles.contentInnerContainer)}>
          <EnvironmentList
            list={list}
            isLoading={isLoading}
            onSelectionChanged={this.handleSelectionChanged}
            viewMode={viewMode}
          />
          <Routes>
            <Route
              path="create"
              element={
                <EnvironmentCreate
                  form={form}
                  isProcessing={isProcessing}
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onCancel={this.handleCancel}
                  onCreate={this.handleSaveEnvironment}
                  viewMode={viewMode}
                />
              }
            />
            <Route
              path=":environmentId/edit"
              element={
                <EnvironmentEdit
                  form={form}
                  isProcessing={isProcessing}
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onCancel={this.handleCancel}
                  onSave={this.handleSaveEnvironment}
                  viewMode={viewMode}
                />
              }
            />
            <Route
              path=":environmentId"
              element={
                <EnvironmentView
                  form={form}
                  isAdministrator={isAdministrator}
                  onCancel={this.handleCancel}
                  onEditEnvironment={this.handleEditEnvironment}
                  viewMode={viewMode}
                />
              }
            />
            <Route path="*" element={<EmptyEnvironment />} />
          </Routes>
        </div>
        {isAdministrator && (
          <>
            <PkiDialog
              confirmation
              message={t('pki:unsavedChanges.message')}
              onClose={this.handleConfirmCancel}
              open={openConfirmCancel}
              size="sm"
              title={t('pki:unsavedChanges.title')}
              type="warning"
            />
            <PkiDialog
              confirmation
              confirmBtnLabel={t('environments.confirmSave.confirmBtnLabel')}
              message={t('environments.confirmSave.message')}
              onClose={this.handleConfirmSave}
              open={openConfirmSave}
              size="sm"
              title={t('environments.confirmSave.title')}
              type="info"
            />
            <DeleteEnvironmentModal
              currentResource={currentResource}
              numTenants={numTenants}
              onClose={this.handleConfirmDelete}
              open={openConfirmDelete}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  currentUser: state.auth.currentUser,
  currentEnvironment: state.environments.currentResource,
  list: state.environments.list,
  isLoading: state.environments.isLoading,
  isProcessing: state.environments.isProcessing,
  error: state.environments.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchEnvironments: EnvironmentActions.fetchEnvironments,
      fetchEnvironment: EnvironmentActions.fetchEnvironment,
      createEnvironment: EnvironmentActions.createEnvironment,
      updateEnvironment: EnvironmentActions.updateEnvironment,
      deleteEnvironment: EnvironmentActions.deleteEnvironment,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Environments)));
