import { DateTime } from 'luxon';

export interface AuditItems {
  items: AuditEntry[];
}

/**
 * Describes an audit entry.
 * @property id         The unique identifier of this audit entry
 * @property ipAddress  The user's IP address
 * @property createdAt  The timestamp of the audit event
 * @property createdBy  The user identifier
 * @property action     The application-specific audit event name
 * @property entity     The entity created or mutated by this event
 * @property oldValue   The entity's original value, as a JSON string
 * @property newValue   The entity's new value, as a JSON string
 */
export interface AuditEntry {
  id: string;
  ipAddress: string;
  createdBy: string;
  createdAt: DateTime;
  action: string;
  entity: string;
  oldValue?: any;
  newValue?: any;
}

/**
 * Describes the parameters required by the server for an audit trail query.
 * @property from           Optional start date of the date range as an ISO string
 * @property to             Optional end date for the date-range as an ISO string
 * @property lastQueryToken A token returned by the DynamoDB service to indicate that there are more items to be
 *                          fetched via an additional call
 * @property lastQueryItem  The last retrieved item
 * @property size           The number of entries to fetch
 * @property sort           Optional sort order. Default = 'DESC'
 * @property add            Optional comma-delimited list of extra fields to include
 */
export interface AuditRequestParams {
  from?: string;
  to?: string;
  lastQueryToken?: string;
  lastQueryItem?: string;
  size?: number;
  sort?: 'ASC' | 'DESC';
  add?: string;
}

/**
 * Describes the object returned by the server from an audit trail request.
 * @property meta   The request parameters
 * @property data   The list of audit entries returned by the query
 */
export interface AuditPageResource {
  meta: AuditRequestParams;
  data: AuditEntryResource[];
}

/**
 * Describes the object representing an audit entry as returned by the server.
 * @property action     The application-specific audit event name
 * @property createdAt  The timestamp of the audit event as an ISO string
 * @property createdBy  The identifier of the user
 * @property entity     The entity created or mutated by this event
 * @property id         The entry identifier
 * @property newValue   The entity's new value, as a JSON string
 * @property oldValue   The entity's original value, as a JSON string
 */
export interface AuditEntryResource {
  action: string;
  createdAt: string;
  createdBy: string;
  entity: string;
  id: string;
  newValue?: string;
  oldValue?: string;
}
