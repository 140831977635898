import { PkiDialog } from '@software-platforms/design-system-components';
import { Tenant } from '@software-platforms/tenant-manager-ui/models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './tenant-action-modal.module.scss';

export type TenantActionModalProps = {
  action: string;
  open: boolean;
  onClose: (isConfirmed: boolean) => void;
  selectedTenant: Tenant;
};

export const TenantActionModal: React.FunctionComponent<TenantActionModalProps> = (props) => {
  const { action, open, selectedTenant } = props;
  const { t } = useTranslation();

  return (
    <PkiDialog
      confirmation
      confirmBtnLabel={t(`tenants.${action}BtnLabel`)}
      message={
        <>
          <div>{t(`tenants.${action}.message`)}</div>
          <div className={styles.tenantName}>{selectedTenant?.tenantName}</div>
        </>
      }
      onClose={props.onClose}
      open={open}
      size="sm"
      title={t(`tenants.${action}.title`)}
    />
  );
};
TenantActionModal.displayName = 'TenantActionModal';
