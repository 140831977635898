import { StandardAction } from '@nx-workspace/shared/models';
import { createAction } from '@reduxjs/toolkit';

export type AuthAction = StandardAction<any, any>;

export enum AuthActionType {
  FETCH_CURRENT_USER = 'auth/FETCH_CURRENT_USER',
  FETCH_CURRENT_USER_SUCCEEDED = 'auth/FETCH_CURRENT_USER_SUCCEEDED',
  FETCH_CURRENT_USER_FAILED = 'auth/FETCH_CURRENT_USER_FAILED',
  SET_CURRENT_USER = 'auth/SET_CURRENT_USER',
  UPDATE_CURRENT_USER = 'auth/UPDATE_CURRENT_USER',
  FLUSH = 'auth/FLUSH',
}

export const AuthActions = {
  fetchCurrentUser: createAction(AuthActionType.FETCH_CURRENT_USER),

  fetchCurrentUserSucceeded: createAction(AuthActionType.FETCH_CURRENT_USER_SUCCEEDED, (payload: any) => ({ payload })),

  fetchCurrentUserFailed: createAction(AuthActionType.FETCH_CURRENT_USER_FAILED, (error) => ({
    payload: undefined,
    error,
  })),

  setCurrentUser: (payload: any): AuthAction => ({
    type: AuthActionType.SET_CURRENT_USER,
    payload,
  }),

  updateCurrentUser: (payload: any): AuthAction => ({
    type: AuthActionType.UPDATE_CURRENT_USER,
    payload,
  }),

  flush: createAction(AuthActionType.FLUSH),
};
