export interface SearchFilter {
  field: string;
  label: string;
  value: string | number | boolean;
}

/**
 * Returns true if the given filter field and value match on eof the elements in the given filter list, false otherwise.
 * @param group
 * @param value
 * @param filters
 */
export const isFilterSelected = (group: string, value: string | number | boolean, filters: SearchFilter[]): boolean => {
  if (filters.length) {
    return filters.some((e) => e.field === group && e.value === value);
  }
  return false;
};
