import { AuthService } from './auth.service';
import { PkiAuth0AuthService } from './pki-auth0-auth.service';
// import { PkiCognitoAuthService } from './pki-cognito-auth.service';
import { PkiNoopAuthService } from './pki-noop-auth.service';

/**
 * Enumerates the supported identity providers in this library.
 */
export enum IdentityProvider {
  AUTH0 = 'Auth0',
  COGNITO = 'Cognito',
}

/**
 * Returns a singleton instance of an AuthService.
 */
export class AuthServiceFactory {
  private static instance: AuthService;

  public static getInstance(identityProvider, options?: any): AuthService {
    if (!AuthServiceFactory.instance) {
      let instance: AuthService;
      switch (identityProvider) {
        case IdentityProvider.COGNITO:
        // instance = new PkiCognitoAuthService();
        // break;
        case IdentityProvider.AUTH0:
          instance = new PkiAuth0AuthService(options);
          break;
        default:
          instance = new PkiNoopAuthService();
          break;
      }
      AuthServiceFactory.instance = instance;
    }
    return AuthServiceFactory.instance;
  }
}
