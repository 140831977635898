import { Subscription } from '@software-platforms/tenant-manager-ui/models';

export interface SubscriptionState {
  list: Subscription[];
  filteredList: Subscription[];
  currentResource: Subscription | null;
  isLoading: boolean;
  isProcessing: boolean;
  readError: any;
  writeError: any;
  sortKey: string;
  sortDirection: string;
}

export const initialSubscriptionState: SubscriptionState = {
  list: [],
  filteredList: [],
  currentResource: null,
  isLoading: false,
  isProcessing: false,
  readError: null,
  writeError: null,
  sortKey: 'companyName',
  sortDirection: 'asc',
};
