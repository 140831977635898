import { StandardAction } from '@nx-workspace/shared/models';
import { combineReducers } from 'redux';
import { AppState } from './model.state';
import { authReducer } from './reducers/auth.reducer';
import { environmentReducer } from './reducers/environment.reducer';
import { subscriptionReducer } from './reducers/subscription.reducer';
import { tenantReducer } from './reducers/tenant.reducer';
import { userReducer } from './reducers/user.reducer';

/**
 * Defines the root reducer object using the AppState interface.
 * @see src/app/core/store/state/model.state.ts
 */
const appReducer = combineReducers({
  environments: environmentReducer,
  subscriptions: subscriptionReducer,
  tenants: tenantReducer,
  users: userReducer,
  auth: authReducer,
});

/**
 * Defines the main reducer used by the Redux framework.
 * @param state
 * @param action
 */
export const rootReducer = (state: any, action: StandardAction<any, any>): AppState => appReducer(state, action);
