import { NotificationServiceFactory } from '@nx-workspace/shared/notification';
import {
  email,
  FormBuilder as fb,
  FormControl,
  isFormDirty,
  isFormValid,
  PkiDialog,
  required,
  updateValidity,
  validateForm,
  ValidationErrors,
} from '@software-platforms/design-system-components';
import {
  CreateUserRequest,
  SearchFilter,
  UpdateUserRequest,
  User,
  UserForm,
  userHasAdministratorRole,
  UserStatus,
} from '@software-platforms/tenant-manager-ui/models';
import { ServiceFactory } from '@software-platforms/tenant-manager-ui/services';
import { AppState, UserActions } from '@software-platforms/tenant-manager-ui/store';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { useTypeAhead } from '../components/hooks/use-typeahead';
import { DeactivateUser } from './deactivate-user-modal/deactivate-user';
import { EmptyUser } from './empty-user/empty-user';
import { UserCreate } from './user-create/user-create';
import { UserEdit } from './user-edit/user-edit';
import { UserHeader } from './user-header/user-header';
import { UserList } from './user-list/user-list';
import { UserView } from './user-view/user-view';
import styles from './users.module.scss';

export type UserViewMode = 'create' | 'edit' | 'view' | '';

const SEARCH_FIELDS = ['firstName', 'lastName', 'email', 'division', 'department'];

const getPageTitle = (viewMode: UserViewMode): string => {
  switch (viewMode) {
    case 'create':
      return 'users.page.create';
    case 'edit':
      return 'users.page.edit';
    case 'view':
      return 'users.page.view';
    default:
      return 'users.page.list';
  }
};

/* ---------- Component Definition ---------- */

export const Users: React.FunctionComponent = () => {
  const { list, isLoading, isProcessing, error } = useSelector((state: AppState) => state.users);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentUser = useSelector((state: AppState) => state.auth.currentUser);
  const isAdministrator = userHasAdministratorRole(currentUser?.authUser);

  useEffect(() => {
    dispatch(UserActions.fetchUsers());
  }, [dispatch]);

  /* ---------- Search ---------- */

  const [search, setSearch] = useState<string>('');
  const [searchToken, setSearchToken] = useState<string>('');
  const search$ = useMemo(() => {
    return new BehaviorSubject<string>('').pipe(
      map((token) => token.trim()),
      distinctUntilChanged(),
      debounceTime(200)
    );
  }, []);
  useTypeAhead(search$, setSearchToken);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearch(event.target.value || '');
    (search$ as BehaviorSubject<string>).next(event.target.value || '');
  };

  /* ---------- Selection ---------- */

  const [viewMode, setViewMode] = useState<UserViewMode>('');
  const [selectedUser, setSelectedUser] = useState<User>();
  useEffect(
    () => {
      if (!isProcessing) {
        let message = '';
        if (error) {
          NotificationServiceFactory.getInstance().show(error.message || error, { type: 'error' });
          setViewMode('');
        } else if (selectedUser && viewMode === 'edit') {
          message = 'users.notification.edit';
        } else if (viewMode === 'create') {
          message = 'users.notification.create';
        }
        if (message) {
          NotificationServiceFactory.getInstance().show(t(message), { type: 'success' });
          setViewMode('');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedUser, error, isProcessing]
  );

  const handleSelectionChange = (userId: string) => {
    if (userId) {
      const user = list.find((e) => e.userId === userId);
      if (user) {
        ServiceFactory.getServices()
          .userService.fetchUser(user.userId)
          .subscribe({
            next: (latest: User) => {
              setSelectedUser(latest);
              setForm(getForm(latest));
              setViewMode('view');
            },
          });
      }
    }
  };

  /* ---------- Form Methods ---------- */

  const validateEmail = (control: FormControl): ValidationErrors | null => {
    if (control.value) {
      const isDuplicate = list.some((e) => e.email === control.value && e.userId !== selectedUser?.userId);
      if (isDuplicate) {
        return { duplicate: true };
      }
    }
    return null;
  };

  const getForm = (user?: User): UserForm => {
    const role = user?.roles.length ? user.roles[0].toLowerCase() : '';
    return {
      firstName: fb.getInstance(user?.firstName || '', required),
      lastName: fb.getInstance(user?.lastName || '', required),
      email: fb.getInstance(user?.email || '', [required, email, validateEmail]),
      role: fb.getInstance(role, required),
      division: fb.getInstance(user?.division || ''),
      department: fb.getInstance(user?.department || ''),
    };
  };

  const [form, setForm] = useState<UserForm>(getForm());
  const handleBlur = (controlName: string) => {
    const clone = { ...form } as UserForm;
    if (typeof clone[controlName].value === 'string') {
      const currVal = clone[controlName].value || '';
      clone[controlName].value = currVal.trim();
    }
    updateValidity(clone[controlName]);
    clone[controlName].touched = true;
    setForm(clone);
  };
  const handleChange = (controlName: string, newValue: any) => {
    if (newValue !== form[controlName].value) {
      const clone = { ...form } as UserForm;
      clone[controlName].dirty = true;
      clone[controlName].value = newValue;
      setForm(clone);
    }
  };

  /* ---------- Filtering ---------- */

  const [filters, setFilters] = useState<SearchFilter[]>([]);
  const handleAddFilter = (event: React.ChangeEvent<HTMLDivElement>) => {
    const label: string = event.target.innerText;
    const { group, value } = event.target.dataset;
    if (group && value) {
      const index = filters.findIndex((e) => e.field === group && e.value === value);
      if (index < 0) {
        const clone = [...filters];
        clone.push({ field: group, value, label });
        setFilters(clone);
      }
    }
  };
  const handleClearFilters = () => {
    setFilters([]);
  };
  const handleDeleteFilter = (filter: SearchFilter) => {
    const index = filters.findIndex((e) => e === filter);
    if (index > -1) {
      const clone = [...filters];
      clone.splice(index, 1);
      setFilters(clone);
    }
  };

  const [filteredList, setFilteredList] = useState<User[]>([]);
  useEffect(() => {
    let newList: User[] = list.length ? [...list] : [];
    if (searchToken?.length) {
      const term = searchToken.toLowerCase();
      newList = newList.filter((e) => SEARCH_FIELDS.some((field) => String(e[field]).toLowerCase().includes(term)));
    }
    if (filters.length) {
      newList = newList.filter((e) => filters.some((filter) => e[filter.field] === filter.value));
    }
    setFilteredList(newList);
  }, [list, filters, searchToken]);

  /* ---------- Actions ---------- */

  const handleCreateUser = () => {
    setForm(getForm());
    setViewMode('create');
  };
  const handleEditUser = () => {
    if (selectedUser) {
      setForm(getForm(selectedUser));
      setViewMode('edit');
    }
  };

  const [openConfirmDeactivate, setOpenConfirmDeactivate] = useState<boolean>(false);
  const handleDeactivateUser = () => {
    if (selectedUser) {
      setOpenConfirmDeactivate(true);
    }
  };
  const handleConfirmDeactivate = (isConfirmed: boolean) => {
    setOpenConfirmDeactivate(false);
    if (isConfirmed && selectedUser) {
      dispatch(UserActions.deactivateUser(selectedUser.userId));
      const clonedUser = { ...selectedUser };
      clonedUser.status = UserStatus.INACTIVE;
      setSelectedUser(clonedUser);
    }
  };

  const handleInviteUser = () => {
    validateForm(form);
    // Need to update the form in order to get any error messages to display (if we don't, they won't appear
    // until the next time handleBlur() is called for a given Control).
    const clone = { ...form } as UserForm;
    setForm(clone);
    if (form && isFormDirty(form) && isFormValid(form)) {
      const formData: CreateUserRequest = {
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        division: form.division.value,
        department: form.department.value,
        email: form.email.value,
        emailVerified: false,
        verifyEmail: false,
        password: undefined,
        sendChangePasswordEmail: true, //changing the password will also validate the email
        status: UserStatus.INVITED,
        roles: [form.role.value],
      };
      dispatch(UserActions.createUser(formData));
    }
  };
  const handleReactivateUser = () => {
    if (selectedUser) {
      dispatch(UserActions.reactivateUser(selectedUser.userId));
      const clonedUser = { ...selectedUser };
      clonedUser.status = UserStatus.ACTIVE;
      setSelectedUser(clonedUser);
    }
  };
  const handleReInviteUser = () => {
    // TODO Implement re-invite a user whose invitation has expired
    throw new Error('Method not implemented');
  };
  const handleUpdateUser = () => {
    if (selectedUser && form && isFormDirty(form) && isFormValid(form)) {
      const formData: UpdateUserRequest = {
        ...selectedUser,
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        roles: [form.role.value],
        division: form.division.value,
        department: form.department.value,
      };
      dispatch(UserActions.updateUser(selectedUser.userId, formData));
    }
  };

  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false);
  const handleCancel = () => {
    if (isFormDirty(form)) {
      setOpenConfirmCancel(true);
    } else {
      setForm(getForm());
      setViewMode('');
    }
  };
  const handleConfirmCancel = (isConfirmed: boolean) => {
    setOpenConfirmCancel(false);
    if (isConfirmed) {
      setForm(getForm());
      setViewMode('');
    }
  };

  /* ---------- Rendering ---------- */

  return (
    <>
      <Helmet title={t(getPageTitle(viewMode))} />
      <UserHeader
        filters={filters}
        isAdministrator={isAdministrator}
        onCreateUser={handleCreateUser}
        onAddFilter={handleAddFilter}
        onClearFilters={handleClearFilters}
        onDeleteFilter={handleDeleteFilter}
        onSearch={handleSearch}
        search={search}
        viewMode={viewMode}
      />
      <div className={cx('content-inner-container', styles.contentInnerContainer)}>
        <UserList
          filteredList={filteredList}
          isLoading={isLoading}
          onSelectionChanged={handleSelectionChange}
          viewMode={viewMode}
        />
        {isAdministrator && (
          <>
            {viewMode === 'create' && (
              <UserCreate
                form={form}
                isProcessing={isProcessing}
                onBlur={handleBlur}
                onChange={handleChange}
                onCancel={handleCancel}
                onSendInvite={handleInviteUser}
              />
            )}
            {viewMode === 'edit' && (
              <UserEdit
                form={form}
                isProcessing={isProcessing}
                onBlur={handleBlur}
                onChange={handleChange}
                onCancel={handleCancel}
                onSave={handleUpdateUser}
              />
            )}
          </>
        )}
        {viewMode === 'view' && (
          <UserView
            form={form}
            isAdministrator={isAdministrator}
            onCancel={handleCancel}
            onEditUser={handleEditUser}
            onDeactivateUser={handleDeactivateUser}
            onReactivateUser={handleReactivateUser}
            onRenewInviteUser={handleReInviteUser}
            userStatus={selectedUser?.status || 'inactive'}
          />
        )}
        {viewMode === '' && <EmptyUser />}
      </div>
      {isAdministrator && (
        <>
          <PkiDialog
            confirmation
            message={t('pki:unsavedChanges.message')}
            onClose={handleConfirmCancel}
            open={openConfirmCancel}
            size="sm"
            title={t('pki:unsavedChanges.title')}
          />
          <DeactivateUser onClose={handleConfirmDeactivate} open={openConfirmDeactivate} selectedUser={selectedUser} />
        </>
      )}
    </>
  );
};
Users.displayName = 'Users';
