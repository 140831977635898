import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React from 'react';
import './pki-select.scss';

export type PkiSelectOptionProps = {
  className?: string;
  disabled?: boolean;
  group?: string;
  value: any;
  icon?: React.ReactNode;
  iconSize?: string;
  [key: string]: any;
};

export const PkiSelectOption: React.FunctionComponent<React.PropsWithChildren<PkiSelectOptionProps>> = (props) => {
  const { children, className, group, icon, iconSize, value, ...rest } = props;

  let height;
  let width;
  if (iconSize) {
    const parts = iconSize.split(' ');
    height = parts[0];
    width = parts?.length > 1 ? parts[1] : parts[0];
  }

  return (
    <div
      className={cx('pki-select-option', className, { selected: rest['aria-selected'] })}
      data-group={group}
      data-value={value}
      {...rest}
    >
      {icon ? (
        <span className="pki-select-option-icon" style={{ height, width }}>
          {icon}
        </span>
      ) : null}
      {children}
    </div>
  );
};
PkiSelectOption.displayName = 'PkiSelectOption';
