import { errorOutline, PkiIcon } from '@software-platforms/design-system-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NotFound: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="single-page">
      <div className="header-container">
        <div className="alert-icon">
          <PkiIcon icon={errorOutline} />
        </div>
        <h1>{t('pki:notFound.title')}</h1>
      </div>
      <p>{t('pki:notFound.message1')}</p>
      <p>{t('pki:notFound.message2')}</p>
      <p className="url">{window.location.toString()}</p>
    </div>
  );
};
NotFound.displayName = 'NotFound';
