import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React from 'react';
import './pki-avatar.scss';

export type PkiAvatarVariant = 'extra-large' | 'large' | 'medium' | 'small' | 'tiny';

export type PkiAvatarProps = {
  className?: string;
  firstName?: string;
  surname?: string;
  picture?: string; // This should be a URI
  style?: React.CSSProperties;
  variant?: PkiAvatarVariant;
};

export const PkiAvatar: React.FunctionComponent<React.PropsWithChildren<PkiAvatarProps>> = (props) => {
  const { className, firstName, picture, surname, style, variant = 'large' } = props;

  const label = `${firstName?.trim().substring(0, 1)}${surname?.trim().substring(0, 1)}` || 'Unknown';

  return (
    <div className={cx('pki-avatar', className, variant)} style={style}>
      {picture ? (
        <img className={cx('picture', variant)} src={picture} alt={label} />
      ) : (
        <span className={cx('label', variant)}>{label}</span>
      )}
    </div>
  );
};
PkiAvatar.displayName = 'PkiAvatar';
