import { AuditServiceFactory } from '@nx-workspace/shared/audit-trail';
import { AuthServiceFactory } from '@nx-workspace/shared/auth';
import { AxiosClientFactory } from '@nx-workspace/shared/services';
import { AxiosRequestConfig } from 'axios';
import { AxiosEnvironmentService, AxiosSubscriptionService, AxiosTenantService, AxiosUserService } from './axios';
import { Services } from './interfaces';
import { getBaseUrl } from './utils';

/**
 * Singleton class to inject concrete instances.
 */
export class ServiceFactory {
  private static services: Services;

  static getServices(authOptions?: any): Services {
    if (!ServiceFactory.services) {
      const identityProvider = process.env['NX_IDP_STRATEGY'] || 'Auth0';
      const requestConfig: AxiosRequestConfig = { baseURL: getBaseUrl() };
      const httpClient = AxiosClientFactory.getInstance(requestConfig, false);

      ServiceFactory.services = {
        authService: AuthServiceFactory.getInstance(identityProvider, authOptions),
        auditService: AuditServiceFactory.getInstance(httpClient),
        environmentService: new AxiosEnvironmentService(httpClient),
        subscriptionService: new AxiosSubscriptionService(httpClient),
        tenantService: new AxiosTenantService(httpClient),
        userService: new AxiosUserService(httpClient),
      };
    }
    return ServiceFactory.services;
  }
}
