import { NotificationServiceFactory } from '@nx-workspace/shared/notification';
import { isFormDirty, PkiButton, PkiDialog } from '@software-platforms/design-system-components';
import { Subscription, SubscriptionForm, SubscriptionStatus } from '@software-platforms/tenant-manager-ui/models';
import { SubscriptionActions } from '@software-platforms/tenant-manager-ui/store';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SubscriptionEntry } from '../subscription-entry/subscription-entry';
import { ActivationSubscriptionModal } from '../subscription-modal/activation-subscription-modal';
import { SubscriptionViewMode } from '../subscriptions';
import styles from './subscription-edit.module.scss';

type OwnProps = {
  currentResource: Subscription | null;
  error: any;
  form?: SubscriptionForm;
  isProcessing: boolean;
  onBlur: (controlName: string) => void;
  onChange: (controlName: string, newValue: any) => void;
  onSave: (event: React.MouseEvent) => void;
  viewMode: SubscriptionViewMode;
};

export const SubscriptionEdit: React.FunctionComponent<OwnProps> = (props) => {
  const { currentResource, error, form, isProcessing, viewMode } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  useEffect(
    () => {
      const { subscriptionId } = params;
      if (subscriptionId && subscriptionId !== currentResource?.id) {
        dispatch(SubscriptionActions.fetchSubscription(subscriptionId));
      }
      if (!isProcessing) {
        if (isUpdating) {
          setIsUpdating(false);
          if (error) {
            NotificationServiceFactory.getInstance().show(error.message, { type: 'error' });
          } else {
            navigate('/subscriptions');
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProcessing, currentResource, error, isUpdating]
  );

  /* ---------- Cancel Actions ---------- */

  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false);
  const handleCancel = (event: React.MouseEvent) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    if (isFormDirty(form)) {
      setOpenConfirmCancel(true);
    } else {
      navigate('/subscriptions');
    }
  };
  const handleConfirmCancel = (isConfirmed: any) => {
    setOpenConfirmCancel(false);
    if (typeof isConfirmed === 'boolean' && isConfirmed) {
      navigate('/subscriptions');
    }
  };

  /* ---------- Activation Actions ---------- */

  const activateAction = currentResource?.status === 'active' ? 'deactivate' : 'activate';
  const [openConfirmAction, setOpenConfirmAction] = useState<boolean>(false);
  const handleAction = (event: React.MouseEvent) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    if (activateAction === 'deactivate') {
      setOpenConfirmAction(true);
    } else {
      // TODO Do we need a confirmation to reactivate a subscription?
      setIsUpdating(true);
      dispatch(SubscriptionActions.reactivateSubscription(currentResource!.id));
    }
  };
  const handleConfirmAction = (isConfirmed: any) => {
    setOpenConfirmAction(false);
    if (typeof isConfirmed === 'boolean' && isConfirmed) {
      setIsUpdating(true);
      dispatch(SubscriptionActions.deactivateSubscription(currentResource!.id));
    }
  };

  return (
    <>
      <header>
        <div className="pki-page-title">{t('subscriptions.page.edit')}</div>
        <div className="pki-header-slot end">
          <PkiButton
            id="cancel-btn"
            label={t('pki:form.cancel')}
            onClick={handleCancel}
            size="small"
            variant="secondary"
          />
          <PkiButton
            id="inactivate-btn"
            label={t(`subscriptions.${activateAction}BtnLabel`)}
            onClick={handleAction}
            size="small"
            variant="secondary"
          />
          <PkiButton id="save-btn" label={t('pki:form.save')} onClick={props.onSave} size="small" variant="primary" />
        </div>
      </header>
      <div className={cx('content-inner-container', 'form', styles.contentInnerContainer)}>
        <section className={cx('item', styles.item)}>
          <SubscriptionEntry
            form={form}
            numTenants={currentResource?.numTenants || 0}
            onBlur={props.onBlur}
            onChange={props.onChange}
            isProcessing={isProcessing}
            viewMode={viewMode}
          />
        </section>
      </div>
      {openConfirmCancel && (
        <PkiDialog
          confirmation
          message={t('pki:unsavedChanges.message')}
          onClose={handleConfirmCancel}
          open={openConfirmCancel}
          size="sm"
          title={t('pki:unsavedChanges.title')}
        />
      )}
      {openConfirmAction && currentResource && (
        <ActivationSubscriptionModal
          currentResource={currentResource}
          onClose={handleConfirmAction}
          open={openConfirmAction}
          operation={currentResource.status === SubscriptionStatus.ACTIVE ? 'deactivate' : 'activate'}
          tenantList={[]}
        />
      )}
    </>
  );
};
SubscriptionEdit.displayName = 'SubscriptionEdit';
