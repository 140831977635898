import {
  FormBuilder as fb,
  isFormValid,
  PkiButton,
  required,
  updateValidity,
} from '@software-platforms/design-system-components';
import { PkiIcon, warning } from '@software-platforms/design-system-icons';
import { showInvalidState, Validator } from '@software-platforms/form-control';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  CurrentUserFormData,
  ProfileDetailForm,
  ProfileElementInputProps,
  ProfileElementProperty,
  ProfileElementProps,
} from '../models';
import styles from './profile-details.module.scss';

type ProfileDetailResources = {
  [key in keyof ProfileElementProperty]: string;
};

/**
 * This component represents a form element with which to set a single current user attribute. It offers a default HTML
 * input field of type `text` which can be substituted with any injected component.
 * @param props
 */
export const ProfileElement: React.FunctionComponent<ProfileElementProps> = (props) => {
  const {
    appUserProperty,
    authUserProperty,
    controlName,
    currentUser,
    i18n,
    isRequired,
    resources = [],
    validator,
  } = props;

  const getLocalizedResources = (): Partial<ProfileDetailResources> => {
    const obj: Partial<ProfileDetailResources> = {};
    resources.forEach((e) => {
      obj[e.prop] = i18n ? i18n.t(e.key) : e.default;
    });
    return obj;
  };
  const [localizedText, setLocalizedText] = useState<Partial<ProfileDetailResources>>({});
  useEffect(
    () => setLocalizedText(getLocalizedResources()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resources]
  );

  const [isEditing, setEditing] = useState<boolean>(false);
  const [originalValue, setOriginalValue] = useState<any>('');

  /* ---------- Form Handling ---------- */

  let initialValue: any;
  if (currentUser?.appUser && currentUser.appUser[appUserProperty!]) {
    initialValue = currentUser.appUser[appUserProperty!];
  } else if (currentUser?.authUser) {
    // For apps that populate this form via AuthUser (as opposed to AppUser), phone number is buried in the
    // customClaims, so we need to do some digging to extract it.
    const parts = authUserProperty?.split('.') || [];
    if (parts.length === 1) {
      initialValue = currentUser.authUser[parts[0]];
    } else if (parts.length > 1) {
      try {
        // Because appUser is asyncronous, apps that store their data there will end up in this authUser section
        // until appUser gets filled in. Their authUser objects don't use the customClaims convention that
        // authUser-only apps do, so when it tries to access customClaims.user it will throw errors. It's safe to
        // ignore the error and return an empty string, because we know the field will get properly populated
        // when appUser completes.
        initialValue = authUserProperty?.split('.').reduce((o, i) => o[i], currentUser.authUser);
      } catch {
        initialValue = '';
      }
    }
  } else {
    initialValue = '';
  }

  const getForm = (): ProfileDetailForm => {
    const validators: Validator[] = [];
    if (isRequired) {
      validators.push(required);
    }
    if (validator) {
      const validatorArr = Array.isArray(validator) ? validator : [validator];
      validators.push(...validatorArr);
    }
    return {
      [controlName]: fb.getInstance(initialValue, validators),
    };
  };
  const [form, setForm] = useState<{}>(getForm());
  useEffect(
    () => {
      if (currentUser) {
        setForm(getForm());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const handleBlur = () => {
    const clone = { ...form } as ProfileDetailForm;
    if (typeof clone[controlName].value === 'string') {
      const currVal = clone[controlName].value || '';
      clone[controlName].value = currVal.trim();
    }
    clone[controlName].touched = true;
    updateValidity(clone[controlName]);
    setForm(clone);
  };

  const handleChange = (newValue: any) => {
    if (newValue !== form[controlName].value) {
      const clone = { ...form } as ProfileDetailForm;
      clone[controlName].value = newValue;
      clone[controlName].dirty = true;
      setForm(clone);
    }
  };

  /* ---------- Actions ---------- */

  const handleEdit = () => {
    setOriginalValue(form[controlName].value || '');
    setEditing(true);
  };

  const handleSave = () => {
    if (form[controlName].dirty && isFormValid(form)) {
      if (props.onUpdateCurrentUser) {
        const formData: CurrentUserFormData = { [controlName]: form[controlName].value };
        if ((form[controlName].value === '' || !form[controlName].value) && controlName === 'phoneNumber') {
          formData.phoneNumber = null; //'null' === 'no phone number'
        }
        props.onUpdateCurrentUser(formData).subscribe({
          next: () => {
            getForm();
            setOriginalValue('');
          },
        });
      }
      setEditing(false);
    }
  };

  const handleCancel = () => {
    handleChange(originalValue);
    if (props.onCancel) {
      props.onCancel(originalValue);
    }
    handleBlur();
    setEditing(false);
  };

  /* ---------- Rendering ---------- */

  const renderInput = (): React.ReactNode => {
    if (props.renderInput) {
      const inputProps: ProfileElementInputProps = {
        controlName,
        isEditing,
        onBlur: handleBlur,
        onChange: handleChange,
        form,
      };
      return props.renderInput(inputProps);
    }
    return (
      <input
        type="text"
        id={controlName}
        autoComplete="off"
        onBlur={() => handleBlur()}
        onChange={(event) => handleChange(event.target.value)}
        value={form[controlName].value}
        disabled={!isEditing}
      />
    );
  };

  return (
    <div className="form-group">
      <label htmlFor={controlName} className={cx({ required: isRequired })}>
        {localizedText['label']}
      </label>
      <div className={styles.btnRow}>
        <div className={cx('form-control', { invalid: showInvalidState(form[controlName], false) })}>
          {renderInput()}
        </div>
        {!isEditing && (
          <PkiButton label={localizedText['editBtn']} onClick={() => handleEdit()} size="small" variant="secondary" />
        )}
        {isEditing && (
          <>
            <PkiButton
              label={localizedText['cancelBtn']}
              onClick={() => handleCancel()}
              size="small"
              variant="secondary"
            />
            <PkiButton
              disabled={form[controlName].value === originalValue}
              label={localizedText['saveBtn']}
              onClick={() => handleSave()}
              size="small"
              variant="secondary"
            />
          </>
        )}
      </div>
      <div className="form-message">
        {Object.keys(form[controlName].errors || {}).map((e) => (
          <div key={e} className="error">
            <PkiIcon icon={warning} />
            <span>{localizedText[e]}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
ProfileElement.displayName = 'ProfileElement';
