import { Auth0ContextInterface, Auth0ProviderOptions, GetTokenSilentlyOptions } from '@auth0/auth0-react';
import createAuth0Client, { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { from, map, Observable, of, switchMap } from 'rxjs';
import { AbstractAuthService } from './abstract-auth.service';

/**
 * Returns the given Auth0 provider options transformed into Auth0 client options.
 * @see @auth0/auth0-react/src/auth0-provider.tsx
 * @param options
 */
const toAuth0ClientOptions = (options: Auth0ProviderOptions): Auth0ClientOptions => {
  const { clientId, redirectUri, ...rest } = options;
  return {
    ...rest,
    client_id: clientId,
    redirect_uri: redirectUri,
  };
};

/**
 * Concrete implementation of the {@link AuthService} for Auth0.
 */
export class PkiAuth0AuthService extends AbstractAuthService {
  private readonly client$: Observable<Auth0Client> | null = null;
  private context: Auth0ContextInterface | null = null;
  private options: Auth0ClientOptions | null = null;

  constructor(options: Auth0ProviderOptions) {
    super();
    if (options) {
      const clientOptions = toAuth0ClientOptions(options);
      this.options = clientOptions;
      this.client$ = from(createAuth0Client(clientOptions));
    }
  }

  setContext(context: Auth0ContextInterface) {
    this.context = context;
  }

  fetchAuthUser(): Observable<any> {
    this.validate();
    if (this.context) {
      return of(this.context.user);
    }
    return this.client$!.pipe(switchMap((client) => from(client.getUser())));
  }

  getAccessToken(scope?: string): Observable<string> {
    const options: GetTokenSilentlyOptions = {};
    if (scope) {
      options.scope = scope;
    }
    if (this.options?.audience) {
      options.audience = this.options.audience;
    }
    if (this.options?.connection) {
      options.connection = this.options.connection;
    }

    this.validate();
    if (this.context) {
      return from(this.context.getAccessTokenSilently(options));
    }
    return this.client$!.pipe(switchMap((client) => from(client.getTokenSilently(options))));
  }

  isAuthenticated(): Observable<boolean> {
    this.validate();
    if (this.context) {
      return of(!!this.context.user);
    }
    return this.client$!.pipe(switchMap((client) => from(client.getUser()).pipe(map((user) => !!user))));
  }

  logout() {
    this.validate();
    if (this.context) {
      return this.context.logout();
    }
    this.client$!.subscribe({
      next: (client) => client.logout(),
    });
  }

  private validate() {
    if (!this.client$ && !this.context) {
      throw new Error('No context or client configuration provided to the Auth0 service.');
    }
  }
}
