import { Mapper } from '@nx-workspace/shared/models';
import { Environment, EnvironmentResponse } from '@software-platforms/tenant-manager-ui/models';
import { DateTime } from 'luxon';

export const EnvironmentMapper: Mapper<EnvironmentResponse, Environment> = {
  from(resource: EnvironmentResponse): Environment {
    return {
      id: resource.id,
      name: resource.name,
      domain: resource.dnsFqdn,
      status: resource.status,
      tier: resource.tier,
      createdAt: DateTime.fromISO(resource.createdAt),
      createdBy: resource.createdBy,
      lastModifiedAt: resource.lastModifiedAt
        ? DateTime.fromISO(resource.lastModifiedAt)
        : DateTime.fromISO(resource.createdAt),
      lastModifiedBy: resource.lastModifiedBy || resource.createdBy,
    };
  },

  to(entity: Environment): EnvironmentResponse {
    // WARNING: This should not be used as the request payload is a different interface
    return {
      id: entity.id,
      name: entity.name,
      dnsFqdn: entity.domain,
      status: entity.status || 'active',
      tier: entity.tier || 'basic',
      createdAt: entity.createdAt.toISO(),
      createdBy: entity.createdBy,
      lastModifiedAt: entity.lastModifiedAt ? entity.lastModifiedAt.toISO() : entity.createdAt.toISO(),
      lastModifiedBy: entity.lastModifiedBy,
    };
  },
};
