import { FormControl, Validator } from '@software-platforms/form-control';
import { i18n } from 'i18next';
import React from 'react';
import { Observable } from 'rxjs';

/**
 * Describes a facade object wrapping a User entity provided by an IDP with a different User entity provided by an
 * application. This interface follows the Separated Interface pattern as defined by Martin Fowler. Note that the
 * two properties are typed as `any` types: It is intended that the developers of the consuming application be
 * responsible for defining these types.
 * @see Fowler, Martin, "Patterns of Enterprise Application Architecture." Pearson Education, Inc., Boston, 2003,
 * pp. 476-479.
 */
export interface CurrentUser {
  authUser?: any;
  appUser?: any;
}

/**
 * Describes the form data payload to be sent to the server to update a User. This interface follows the Separated
 * Interface pattern as defined by Martin Fowler.Other properties provided by the consuming application are accepted.
 * @see Fowler, Martin, "Patterns of Enterprise Application Architecture." Pearson Education, Inc., Boston, 2003.
 */
export interface CurrentUserFormData {
  givenName?: string;
  familyName?: string;
  password?: string;
  picture?: string;
  phoneNumber?: string | null;

  [key: string]: any; // More user attributes that are application-specific
}

/**
 * Describes a User Profile feature.
 * @property component            The React component
 * @property i18n                 Optional localization framework
 * @property icon                 Optional icon to render with the component
 * @property onUpdateCurrentUser  Optional handler for updating of the current user. Must return an observable.
 * @property props                Properties of the React component
 * @property title                The rendered feature title
 */
export interface ProfileFeature {
  component?: React.ComponentType<React.PropsWithChildren<any>>;
  i18n?: i18n;
  icon?: any;
  onUpdateCurrentUser?: (formData: CurrentUserFormData, messages?: ProfileMessages) => Observable<any>;
  props: ProfileFeatureProps;
  title?: string;
}

/**
 * Describes the minimum properties of a User Profile feature. Other properties required by the consuming
 * application are accepted.
 * @property currentTab           The component's current tab
 * @property currentUser          The current user wrapper
 * @property customElements       Optional array of custom form elements
 * @property i18n                 Optional localization framework
 * @property onUpdateCurrentUser  Handler for updating the current user. Must return an observable.
 * @property tab                  The tab name of this feature
 */
export interface ProfileFeatureProps {
  currentTab?: string;
  currentUser?: CurrentUser | null;
  customElements?: ProfileElementProps[];
  i18n?: i18n;
  onUpdateCurrentUser?: (formData: CurrentUserFormData, messages?: ProfileMessages) => Observable<any>;
  tab: string;

  [key: string]: any;
}

/**
 * Describes the notification messages to display to the user upon updating their profile.
 * @property success  The message to display when their profile was successfully updated, or true to display a
 *                    default message
 * @property failure  The message to display when the update operation failed, or true to display a default message
 */
export interface ProfileMessages {
  success?: boolean | string;
  failure?: boolean | string;
}

/* ---------- Profile Element ---------- */

/**
 * Describes the properties of a ProfileElement component, which represents a form control by which set a single
 * attribute of the current user.
 * @property controlName          The name of this form control
 * @property order                The numerical order of this component in the collection
 * @property appUserProperty      The name of the {@link CurrentUser.appUser} property to reference
 * @property authUserProperty     The name of the {@link CurrentUser.authUser} property to reference
 * @property currentUser          The {@link CurrentUser} object
 * @property i18n                 Localization framework
 * @property isRequired           True if this component is required
 * @property onCancel             Optioanl callback to cancel the current data entry of this component
 * @property onUpdateCurrentUser  Optional callback to save the value of this component
 * @property inputComponent       Optional substitute input control.
 * @property resources            Array of {@link ProfileElementText} objects setting resource keys and default text
 */
export interface ProfileElementProps {
  controlName: string;
  order: number;
  appUserProperty?: string;
  authUserProperty?: string;
  currentUser?: CurrentUser | null;
  i18n?: i18n;
  isRequired?: boolean;
  onCancel?: (originalValue: string) => void;
  onUpdateCurrentUser?: (formData: ProfileDetailForm, messages?: ProfileMessages) => Observable<any>;
  renderInput?: (options: ProfileElementInputProps) => React.ReactNode;
  resources?: ProfileElementText[];
  validator?: Validator | Validator[];
}

/**
 * Describes properties and callbacks required by an optional input component.
 */
export interface ProfileElementInputProps {
  controlName: string;
  isEditing: boolean;
  isProcessing?: boolean;
  onBlur: () => void;
  onChange: (newValue: any) => void;
  ref?: React.RefObject<any>;
  form: ProfileDetailForm;
}

/**
 * Describes the resource key and default value for a ProfileElement artifact that contains localized text.
 */
export interface ProfileElementText {
  prop: ProfileElementProperty | string;
  key: string;
  default: string;
}

/**
 * Identifies the ProfileElement artifact that contains localized text.
 */
export type ProfileElementProperty = 'label' | 'editBtn' | 'cancelBtn' | 'saveBtn' | 'required';

/**
 * Describes the form for a ProfileElement component. The property name will be the `controlName` value defined
 * in the ProfileElement props.
 */
export type ProfileDetailForm = {
  [key: string]: FormControl;
};
