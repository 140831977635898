import { PkiIcon, spinner } from '@software-platforms/design-system-icons';
import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React from 'react';
import './pki-spinner.scss';

export type PkiSpinnerVariant = 'large' | 'regular' | 'small' | 'tiny';

export type PkiSpinnerProps = {
  size?: PkiSpinnerVariant;
  title?: string;
};

export const PkiSpinner: React.FunctionComponent<React.PropsWithChildren<PkiSpinnerProps>> = (props) => {
  const { size = 'regular', title } = props;
  return (
    <div className="pki-spinner-container">
      <div className={cx('pki-spinner', size)}>
        <PkiIcon icon={spinner} />
      </div>
      {title && <div className="pki-spinner-title">{title}</div>}
    </div>
  );
};
PkiSpinner.displayName = 'PkiSpinner';
