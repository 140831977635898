import { useAuth0 } from '@auth0/auth0-react';
import { PkiNoAccess, PkiSpinner } from '@software-platforms/design-system-components';
import { i18n } from 'i18next';
import React, { ReactNode } from 'react';

export type PkiAuth0ApplicationProps = {
  children: ReactNode;
  i18n?: i18n;
};

export const PkiAuth0Application: React.FunctionComponent<PkiAuth0ApplicationProps> = (props) => {
  const { children, i18n } = props;
  const auth = useAuth0();

  if (auth.isLoading) {
    return <PkiSpinner title={i18n ? i18n.t('pki:auth.loading') : 'Authenticating...'} />;
  }
  if (auth.error) {
    return (
      <PkiNoAccess
        pageTitle={i18n ? i18n.t('pki:noAccess.title') : 'Access Prohibited'}
        message={i18n ? i18n.t('pki:noAccess.message') : 'You are not authorized to access this application'}
        loginBtnLabel={i18n ? i18n.t('pki:noAccess.btnLabel') : 'Login'}
      />
    );
  }
  if (!auth.isAuthenticated) {
    auth.loginWithRedirect().then();
    return null;
  }

  return <span>{children}</span>;
};
PkiAuth0Application.displayName = 'PkiAuth0Application';
