import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, {
  ReactChild,
  ReactFragment,
  ReactNode,
  ReactPortal,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { v4 as uuidV4 } from 'uuid';
import { PkiChip } from '../pki-chip/pki-chip';
import { isDescendant, isEqual } from '../pki-utils';
import { PkiComboBoxMenu } from './pki-combobox-menu';

export interface PkiComboBoxOptionType {
  label: string;

  [key: string]: any;
}

export type PkiComboBoxFilter = {
  ignoreCase?: boolean;
  trim?: boolean;
  getFilterValue?: (option: any) => string;
};

type MenuEvent = React.MouseEvent | React.KeyboardEvent;

/* ---------- Component Definition ---------- */

export type PkiComboBoxProps = {
  children?: ReactNode; // We need this to make TypeScript happy
  className?: string;
  customFilter?: PkiComboBoxFilter;
  disabled?: boolean;
  disableInputAfterSelection?: boolean;
  icon?: ReactNode;
  multiple?: boolean;
  name: string;
  onFocus?: React.FocusEventHandler<any>;
  onBlur?: React.FocusEventHandler<any>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, child: ReactNode) => void;
  onOpen?: (event: React.ChangeEvent<{}>) => void;
  onClose?: {
    bivarianceHack(event: {}): void;
  }['bivarianceHack'];
  readOnly?: boolean;
  placeholder?: string;
  renderValue?: (value: any) => ReactNode;
  style?: React.CSSProperties;
  tabIndex?: number;
  value?: any | any[];
  valueClassName?: string;
  valueStyle?: React.CSSProperties;
};

export const PkiComboBox = React.forwardRef<HTMLElement, PkiComboBoxProps>(function PkiComboBox(props, ref) {
  const {
    children,
    className,
    customFilter,
    disabled,
    disableInputAfterSelection,
    icon,
    multiple,
    name,
    placeholder,
    readOnly,
    style,
    tabIndex: tabIndexProp,
    value: valueProp,
    valueClassName,
    valueStyle,
  } = props;
  const tabIndex = tabIndexProp !== undefined ? tabIndexProp : disabled ? undefined : 0;
  const inputRef = useRef<HTMLInputElement>(null);
  const displayRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const childrenArray = React.Children.toArray(children);

  // Add more functions or properties here to expose to a parent component.
  // @see https://reactjs.org/docs/hooks-reference.html#useimperativehandle
  useImperativeHandle(
    ref,
    () =>
      ({
        focus: () => {
          if (inputRef.current) {
            inputRef.current?.focus();
          }
        },
      } as HTMLElement)
  );

  const [value, setValue] = useState<any>(valueProp);
  const [filteredChildren, setFilteredChildren] = useState<(ReactChild | ReactFragment | ReactPortal)[]>([]);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  /* ---------- Menu Handling ---------- */

  const [openState, setOpenState] = useState<boolean>(false);
  const handleMenuEvent = (flag: boolean, event: MenuEvent) => {
    if (flag) {
      if (props.onOpen) {
        props.onOpen(event);
      }
    } else if (props.onClose) {
      props.onClose(event);
    }
    setOpenState(flag);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    // Ignore everything except left-clicks.
    if (event.button !== 0) {
      return;
    }
    displayRef.current!.focus();
    handleMenuEvent(true, event);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!readOnly) {
      const validKeys = [' ', 'ArrowUp', 'ArrowDown', 'Enter'];
      if (validKeys.indexOf(event.key) > -1) {
        handleMenuEvent(true, event);
      }
    }
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    handleMenuEvent(false, event);
  };

  // Listen for mouse clicks outside this component.
  useEffect(() => {
    const clickOutside = (event: any) => {
      const elem = document.getElementById(name);
      if (elem && !isDescendant(elem, event.target)) {
        handleClose(event);
      }
    };
    window.addEventListener('click', clickOutside);
    return () => {
      window.removeEventListener('click', clickOutside);
    };
  }, [name]);

  const handleBlur = (event: React.FocusEvent) => {
    if (!openState && props.onBlur) {
      event.persist();
      Object.defineProperty(event, 'target', {
        writable: true,
        value: { value, name },
      });
      props.onBlur(event);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    // Format the input value
    let term = customFilter?.trim !== false ? inputValue.trim() : inputValue;
    if (customFilter?.ignoreCase !== false) {
      term = term.toLowerCase();
    }
    const filteredList = childrenArray.filter((child) => {
      // Test the input against the transformed and formatted child value.
      if (term) {
        let target = (child as ReactPortal).props.value;
        if (customFilter?.getFilterValue) {
          target = customFilter.getFilterValue((child as ReactPortal).props.value);
        }
        if (typeof target === 'string') {
          if (customFilter?.ignoreCase !== false) {
            target = target.toLowerCase();
          }
          return target.indexOf(term) > -1;
        }
        const msg = 'Option values must be a string. Use the "customFilter" attribute to define the comparison string';
        throw new Error(msg);
      }
      return true;
    });
    setIsFiltered(true);
    setFilteredChildren(filteredList);
  };

  /* ---------- Menu Item Handling ---------- */

  /**
   * Returns a function to handle click events on a menu item.
   * @param child
   */
  const handleItemClick = (child: ReactChild | ReactFragment | ReactPortal) => {
    return (event: React.MouseEvent<HTMLElement>) => {
      if (!multiple) {
        // Close the menu if we are in single-option mode.
        setOpenState(false);
      }
      if ((child as ReactPortal).props.disabled) {
        return;
      }
      // Compute the new value.
      let newValue: any | any[];
      if (multiple) {
        newValue = Array.isArray(value) ? value.slice() : [];
        const index = value?.indexOf((child as ReactPortal).props.value);
        if (index === -1) {
          newValue.push((child as ReactPortal).props.value);
        }
      } else {
        newValue = (child as ReactPortal).props.value;
      }
      // Fire the child's onClick event if it is defined.
      if ((child as ReactPortal).props.onClick) {
        (child as ReactPortal).props.onClick(event);
      }
      // Test for any change.
      if (value === newValue) {
        return;
      }
      // Set the new value
      setValue(newValue);
      if (inputRef.current) {
        inputRef.current.value = '';
        inputRef.current.focus();
      }
      // Fire the onChange event if it is defined. We need to mutate the click event into an onchange event.
      if (props.onChange) {
        event.persist();
        Object.defineProperty(event, 'target', {
          writable: true,
          value: {
            value: newValue,
            name,
          },
        });
        props.onChange(event as unknown as React.ChangeEvent<HTMLInputElement>, newValue);
      }
    };
  };

  /**
   * Returns a function to handle keyup events on a menu item.
   * @param child
   */
  const handleItemKeyUp = (child: ReactChild | ReactFragment | ReactPortal) => {
    return (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === ' ' || event.code === 'Space') {
        event.preventDefault();
      }
      if ((child as ReactPortal).props.onKeyUp) {
        (child as ReactPortal).props.onKeyUp();
      }
    };
  };

  /**
   * Handles the default operation when the user clicks on the delete icon of a selected option's tag.
   * @param event
   * @param item
   */
  const handleDeleteTag = (event: React.MouseEvent, item: any) => {
    event.stopPropagation();
    if (Array.isArray(value) && value.length) {
      const index = value.indexOf(item);
      if (index > -1) {
        // Remove the item from the value list
        const clone = [...value];
        clone.splice(index, 1);
        setValue(clone);
        // Fire the onChange event if it is defined. We need to mutate the click event into an onchange event.
        if (props.onChange) {
          event.persist();
          Object.defineProperty(event, 'target', {
            writable: true,
            value: {
              value: clone,
              name,
            },
          });
          props.onChange(event as unknown as React.ChangeEvent<HTMLInputElement>, clone);
        }
      }
    }
  };

  /* ---------- Menu Positioning ---------- */

  const flipMenu = () => {
    if (openState && displayRef.current && menuRef.current) {
      const rootRect = document.getElementById('root')!.getBoundingClientRect();
      const displayRect = displayRef.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();
      const availableDropUpHeight = Math.max(displayRect.top - rootRect.top, 0);
      const availableDropDownHeight = Math.max(rootRect.bottom - displayRect.bottom, 0);

      if (availableDropDownHeight >= menuRect.height) {
        // There is room to dropdown.
        menuRef.current.style.top = `${displayRect.height}px`;
      } else if (availableDropUpHeight >= menuRect.height) {
        // There is room to drop up.
        menuRef.current.style.top = `${-menuRect.height - 2}px`;
      } else {
        // Default is a drop-down.
        menuRef.current.style.top = `${displayRect.height}px`;
      }
    }
  };
  useLayoutEffect(() => {
    window.addEventListener('resize', flipMenu);
    flipMenu();
    return () => window.removeEventListener('resize', flipMenu);
  });

  /* ---------- Rendering ---------- */

  /**
   * Returns a {@link JSX.Element} for the given value as part of the rendering of multiple selections, or null.
   * This is the default rendering operation. Define the "renderValue" attribute of this component to override this
   * method.
   * @param item
   */
  const defaultRenderValue = (item: string | {}) => {
    if (item) {
      const key = uuidV4().substring(0, 8);
      const label = typeof item === 'string' ? item : (item as PkiComboBoxOptionType).label || String(item);
      return (
        <PkiChip
          key={key}
          className={cx('pki-combobox-tag', valueClassName)}
          label={label}
          onDelete={(event) => handleDeleteTag(event, item)}
          rightDeleteIcon
          style={valueStyle}
        />
      );
    }
    return null;
  };

  let displaySingle: any;
  const displayMultiple: any[] = [];
  let computeDisplay = false;

  let display;
  if (props.renderValue) {
    display = props.renderValue(value);
  } else {
    if (multiple) {
      display = value.map((e: any) => defaultRenderValue(e));
    } else {
      computeDisplay = true;
    }
  }

  /**
   * Returns a list of options to be used in lieu of the given option list. These objects are assigned additional
   * properties so that they can operate within this component.
   */
  const items = (isFiltered ? filteredChildren : childrenArray).map(
    (child: ReactChild | ReactFragment | ReactPortal) => {
      if (!React.isValidElement(child)) {
        return null;
      }
      // Test if the child is the selected item.
      let isSelected = false;
      if (multiple) {
        if (!Array.isArray(value)) {
          throw new Error('The value property must be an array if using multiple');
        }
        isSelected = value.some((e) => isEqual(e, (child as ReactPortal).props.value));
        if (isSelected && computeDisplay) {
          displayMultiple.push((child as ReactPortal).props.children);
        }
      } else {
        isSelected = isEqual(value, (child as ReactPortal).props.value);
        if (isSelected && computeDisplay) {
          displaySingle = (
            <div className={cx('pki-combobox-single-tag', valueClassName)} style={valueStyle}>
              {(child as ReactPortal).props.children}
            </div>
          );
        }
      }
      // Return a clone of the child with additional properties.
      return React.cloneElement<any>(child, {
        onClick: handleItemClick(child),
        onKeyUp: handleItemKeyUp(child),
        role: 'option',
        selected: isSelected,
        'aria-selected': isSelected ? 'true' : undefined,
        value: undefined, // The value might not be a string. Assign it to a data property instead.
        'data-value': (child as ReactPortal).props.value,
      });
    }
  );

  if (computeDisplay) {
    display = multiple ? displayMultiple : displaySingle;
  }
  flipMenu();

  return (
    <div
      className={cx('pki-combobox', 'form-control', className)}
      id={name}
      ref={displayRef}
      onBlur={handleBlur}
      onFocus={props.onFocus}
      onKeyDown={disabled || readOnly ? undefined : handleKeyDown}
      onMouseDown={disabled || readOnly ? undefined : handleMouseDown}
      role="combobox"
      style={style}
      tabIndex={tabIndex}
      aria-controls={`owned-listbox-${name}`}
      aria-disabled={disabled ? 'true' : undefined}
      aria-expanded={openState ? 'true' : undefined}
      aria-haspopup="listbox"
      aria-owns={`owned-listbox-${name}`}
    >
      {display}
      {(multiple || !display || !disableInputAfterSelection) && (
        <>
          {icon && <div className="pki-combobox-icon">{icon}</div>}
          <input
            type="text"
            autoComplete="off"
            className="pki-combobox-input"
            disabled={disabled}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={inputRef}
            tabIndex={-1}
            aria-controls={`owned-listbox-${name}`}
          />
        </>
      )}
      <PkiComboBoxMenu ref={menuRef} name={name} onClose={handleClose} open={openState}>
        {items}
      </PkiComboBoxMenu>
    </div>
  );
});
PkiComboBox.displayName = 'PkiComboBox';

export default PkiComboBox;
