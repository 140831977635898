import { NotificationServiceFactory } from '@nx-workspace/shared/notification';
import {
  FormBuilder as fb,
  FormControl,
  isFormDirty,
  isFormValid,
  required,
  updateValidity,
  validateForm,
  ValidationErrors,
} from '@software-platforms/design-system-components';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { v4 as uuidV4 } from 'uuid';
import {
  SubscriptionForm,
  SubscriptionRequest,
  userHasAdministratorRole,
} from '@software-platforms/tenant-manager-ui/models';
import { AppState, SubscriptionActions } from '@software-platforms/tenant-manager-ui/store';
import { WithRouter, withRouter } from '../components';
import { SubscriptionCreate } from './subscription-create/subscription-create';
import { SubscriptionEdit } from './subscription-edit/subscription-edit';
import { SubscriptionList } from './subscription-list/subscription-list';
import { SubscriptionView } from './subscription-view/subscription-view';

export type SubscriptionViewMode = 'list' | 'create' | 'edit' | 'view' | 'create-tenant';

export type SubscriptionLocationState = {
  viewMode: SubscriptionViewMode;
  id: string;
};

const getPageTitle = (path: string): string => {
  if (/create-tenant/.test(path)) {
    return 'subscriptions.page.createTenant';
  }
  if (/create/.test(path)) {
    return 'subscriptions.page.create';
  }
  if (/edit/.test(path)) {
    return 'subscriptions.page.edit';
  }
  if (/subscriptions\/\w+/.test(path)) {
    return 'subscriptions.page.view';
  }
  return 'subscriptions.page.list';
};

const MIN_LIMIT = 1;
const MAX_LIMIT = 999;

/* ---------- Component Definition ---------- */

type OwnProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WithRouter &
  WithTranslation;

type OwnState = {
  form?: SubscriptionForm;
  viewMode: SubscriptionViewMode;
};

class Subscriptions extends React.Component<OwnProps, OwnState> {
  constructor(props) {
    super(props);
    this.getForm = this.getForm.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateLicense = this.validateLicense.bind(this);
    this.validateSubscriptionId = this.validateSubscriptionId.bind(this);
    this.validateCompanyName = this.validateCompanyName.bind(this);
    this.validateAllowedTenants = this.validateAllowedTenants.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleCreate = this.handleCreate.bind(this);

    this.state = { viewMode: 'list' };
  }

  componentDidMount() {
    const { location } = this.props;
    const viewMode: SubscriptionViewMode = (location.state as SubscriptionLocationState)?.viewMode || 'list';
    this.setState({ form: this.getForm(), viewMode });
    this.props.fetchSubscriptions();
  }

  componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>) {
    const { currentSubscription, isProcessing, location, writeError } = this.props;
    if (currentSubscription !== prevProps.currentSubscription) {
      this.setState({ form: this.getForm() });
    }
    if (!isProcessing && prevProps.isProcessing && !writeError) {
      // Return to the list after editing/creating a subscription
      this.props.navigate('/subscriptions');
    } else {
      const viewMode: SubscriptionViewMode = (location.state as SubscriptionLocationState)?.viewMode || 'list';
      if (viewMode !== prevState.viewMode) {
        this.setState({ form: this.getForm(), viewMode });
      }
    }
  }

  /* ---------- Form Methods ---------- */

  getForm(): SubscriptionForm {
    const { currentSubscription } = this.props;
    const { viewMode } = this.state || {};

    // TODO Need subscription license and subscription definitions. Change from GUID to ???
    const initialLicense = viewMode === 'create' ? uuidV4() : '';
    const initialSubscriptionId = viewMode === 'create' ? uuidV4() : '';

    return {
      license: fb.getInstance(currentSubscription?.license || initialLicense, [required, this.validateLicense]),
      orgSubscriptionId: fb.getInstance(currentSubscription?.orgSubscriptionId || initialSubscriptionId, [
        required,
        this.validateSubscriptionId,
      ]),
      companyName: fb.getInstance(currentSubscription?.companyName || '', [required, this.validateCompanyName]),
      allowedTenants: fb.getInstance(currentSubscription?.allowedTenants || 1, this.validateAllowedTenants),
    };
  }

  handleBlur(controlName: string) {
    this.setState((prevState) => {
      const clonedForm = { ...prevState.form } as SubscriptionForm;
      if (typeof clonedForm[controlName].value === 'string') {
        const currVal = clonedForm[controlName].value || '';
        clonedForm[controlName].value = currVal.trim();
      }
      updateValidity(clonedForm[controlName]);
      clonedForm[controlName].touched = true;
      return { ...prevState, form: clonedForm };
    });
  }

  handleChange(controlName: string, newValue: any) {
    this.setState((prevState) => {
      if (newValue !== prevState.form![controlName].value) {
        const clonedForm = { ...prevState.form } as SubscriptionForm;
        clonedForm[controlName].value = newValue;
        clonedForm[controlName].dirty = true;
        return { ...prevState, form: clonedForm };
      }
      return prevState;
    });
  }

  validateLicense(control: FormControl): ValidationErrors | null {
    if (control) {
      const { currentSubscription, list } = this.props;
      const duplicate = list.find((e) => e.license === control.value && e.id !== currentSubscription?.id);
      if (duplicate) {
        return { duplicate: true };
      }
    }
    return null;
  }

  validateSubscriptionId(control: FormControl): ValidationErrors | null {
    if (control) {
      const { currentSubscription, list } = this.props;
      const duplicate = list.find((e) => e.orgSubscriptionId === control.value && e.id !== currentSubscription?.id);
      if (duplicate) {
        return { duplicate: true };
      }
    }
    return null;
  }

  validateCompanyName(control: FormControl): ValidationErrors | null {
    if (control && control.value) {
      const { currentSubscription, list } = this.props;
      const value = String(control.value).trim().toLowerCase();
      const duplicate = list.find(
        (e) => e.companyName.trim().toLowerCase() === value && e.id !== currentSubscription?.id
      );
      if (duplicate) {
        return { duplicate: true };
      }
    }
    return null;
  }

  validateAllowedTenants(control: FormControl): ValidationErrors | null {
    const { currentSubscription } = this.props;
    if (control && control.value) {
      const value = Number(control.value);
      // Don't allow the user to enter a number lower than the number of any existing tenants.
      if (Number.isNaN(value) || value < (currentSubscription?.numTenants || MIN_LIMIT) || value > MAX_LIMIT) {
        return { invalid: true };
      } else {
        // Make sure we have an integer. Not happy about side effects but this is the only place to do this other
        // than an uglier solution in the `handleChange` method that results in a worse user experience.
        control.value = Math.round(value);
      }
    }
    return null;
  }

  /* ---------- Actions ---------- */

  handleSave(event: React.MouseEvent) {
    if (event.cancelable) {
      event.preventDefault();
    }
    const { form, viewMode } = this.state;
    if (form && isFormDirty(form)) {
      validateForm(form);
      this.setState({ form }, () => {
        if (isFormValid(form)) {
          if (viewMode === 'edit') {
            this.handleUpdate(form);
          } else {
            this.handleCreate(form);
          }
        }
      });
    }
  }

  private handleUpdate(form: SubscriptionForm) {
    const { currentSubscription } = this.props;
    if (currentSubscription) {
      const formData = {
        orgSubscriptionId: form.orgSubscriptionId.value,
        companyName: String(form.companyName.value).trim(),
        allowedTenants: Number(form.allowedTenants.value),
        license: form.license.value,
        status: currentSubscription.status,
      } as SubscriptionRequest;
      this.props.updateSubscription(currentSubscription.id, formData);
    }
  }

  private handleCreate(form: SubscriptionForm) {
    const formData = {
      orgSubscriptionId: form.orgSubscriptionId.value,
      companyName: String(form.companyName.value).trim(),
      allowedTenants: Number(form.allowedTenants.value),
      license: form.license.value,
      status: 'active',
    } as SubscriptionRequest;
    this.props.createSubscription(formData);
  }

  /* ---------- Rendering ---------- */

  render() {
    const { currentSubscription, currentUser, isLoading, isProcessing, location, list, t, writeError } = this.props;
    const { form, viewMode } = this.state;
    const isAdministrator = userHasAdministratorRole(currentUser?.authUser);

    return (
      <>
        <Helmet title={t(getPageTitle(location.pathname))} />
        <Routes>
          <Route path="/" element={<SubscriptionList list={list} isLoading={isLoading} />} />
          <Route
            path="create"
            element={
              <SubscriptionCreate
                currentResource={currentSubscription}
                error={writeError}
                form={form}
                isProcessing={isProcessing}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onSave={this.handleSave}
                viewMode={viewMode}
              />
            }
          />
          <Route
            path=":subscriptionId/edit"
            element={
              <SubscriptionEdit
                currentResource={currentSubscription}
                error={writeError}
                form={form}
                isProcessing={isProcessing}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onSave={this.handleSave}
                viewMode={viewMode}
              />
            }
          />
          <Route
            path=":subscriptionId"
            element={
              <SubscriptionView
                currentResource={currentSubscription}
                error={writeError}
                form={form}
                isAdministrator={isAdministrator}
                isProcessing={isProcessing}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                viewMode={viewMode}
              />
            }
          />
        </Routes>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  list: state.subscriptions.list,
  filteredList: state.subscriptions.filteredList,
  currentSubscription: state.subscriptions.currentResource,
  isLoading: state.subscriptions.isLoading,
  isProcessing: state.subscriptions.isProcessing,
  readError: state.subscriptions.readError,
  writeError: state.subscriptions.writeError,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchSubscriptions: SubscriptionActions.fetchSubscriptions,
      createSubscription: SubscriptionActions.createSubscription,
      updateSubscription: SubscriptionActions.updateSubscription,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Subscriptions)));
