import { Mapper } from '@nx-workspace/shared/models';
import { DateTime } from 'luxon';
import { PkiPage } from '@nx-workspace/shared/models';
import { AuditEntry, AuditEntryResource } from '../models';

export const AuditPageMapper: Mapper<PkiPage<AuditEntryResource>, PkiPage<AuditEntry>> = {
  from(resource: PkiPage<AuditEntryResource>): PkiPage<AuditEntry> {
    return {
      meta: { ...resource.meta },
      data: resource.data.map((e) => AuditMapper.from(e)),
    };
  },

  to(): PkiPage<AuditEntryResource> {
    throw new Error('Mapper method not implemented');
  },
};

export interface UserIdentity {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  ipAddress?: string;
}

const parseUserIdentity = (userIdentity: string): UserIdentity => {
  //all user attr '*' delimited
  const parts: string[] = userIdentity.split('*');
  return {
    id: parts[0],
    firstName: parts.length > 1 ? parts[1] : undefined,
    lastName: parts.length > 2 ? parts[2] : undefined,
    email: parts.length > 3 ? parts[3] : undefined,
    phoneNumber: parts.length > 4 ? parts[4] : undefined,
    ipAddress: parts.length > 5 ? parts[5] : undefined,
  };
};

export const AuditMapper: Mapper<AuditEntryResource, AuditEntry> = {
  from(resource: AuditEntryResource): AuditEntry {
    let oldValue: any;
    let newValue: any;
    try {
      oldValue = resource.oldValue ? JSON.parse(resource.oldValue) : null;
      newValue = resource.newValue ? JSON.parse(resource.newValue) : null;
    } catch (error) {
      throw new Error('JSON parse error');
    }
    const userIdentity: UserIdentity = parseUserIdentity(resource.createdBy);
    return {
      id: resource.id,
      action: resource.action,
      entity: resource.entity,
      createdAt: DateTime.fromISO(resource.createdAt),
      createdBy: `${userIdentity.firstName} ${userIdentity.lastName}`,
      ipAddress: userIdentity.ipAddress || '',
      newValue,
      oldValue,
    };
  },

  to(entity: AuditEntry): AuditEntryResource {
    throw new Error('Mapper method not implemented');
  },
};
