import { pageResponseTransformer, PkiPage } from '@nx-workspace/shared/models';
import { AxiosClientFactory, axiosErrorHandler } from '@nx-workspace/shared/services';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { from, Observable } from 'rxjs';
import { AuditEntry, AuditEntryResource, AuditRequestParams } from '../models';
import { AuditPageMapper } from './audit.mapper';
import { AuditService } from './audit.service';

/**
 * Concrete implementation of the {@link AuditService} for Axios HTTP requests.
 */
export class AxiosAuditService implements AuditService {
  basePath = '/audit';
  http: AxiosInstance;

  constructor(httpClient?: AxiosInstance) {
    if (httpClient) {
      this.http = httpClient;
    } else {
      const baseURL =
        process.env.NX_API_TARGET || window['appConfig']?.api?.apiEndpoint || `https://api-${window.location.hostname}`;
      this.http = AxiosClientFactory.getInstance({ baseURL });
    }
  }

  /**
   * Returns a list of {@link AuditEntry} objects or an empty array if no matches were found.
   * @param params  Request params
   * @param scope   Optional Auth0 scope
   */
  fetchPage(params: AuditRequestParams, scope?: string): Observable<PkiPage<AuditEntry>> {
    const queries = { ...params };
    const url = `${this.basePath}?${qs.stringify(queries)}`;

    const config: AxiosRequestConfig = { transformResponse: pageResponseTransformer };
    if (scope) {
      config.headers = { 'x-auth-scope': scope };
    }

    return from(
      this.http
        .get<PkiPage<AuditEntryResource>>(url, config)
        .then((response) => AuditPageMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }
}
