import { Environment } from '@software-platforms/tenant-manager-ui/models';

export interface EnvironmentState {
  list: Environment[];
  currentResource: Environment | null;
  isLoading: boolean;
  isProcessing: boolean;
  error: any;
}

export const initialEnvironmentState: EnvironmentState = {
  list: [],
  currentResource: null,
  isLoading: false,
  isProcessing: false,
  error: null,
};
