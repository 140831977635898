import { isFormDirty, PkiButton, PkiDialog } from '@software-platforms/design-system-components';
import { Subscription, SubscriptionForm } from '@software-platforms/tenant-manager-ui/models';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SubscriptionEntry } from '../subscription-entry/subscription-entry';
import { SubscriptionLocationState, SubscriptionViewMode } from '../subscriptions';
import styles from './subscription-create.module.scss';

type OwnProps = {
  currentResource: Subscription | null;
  error: any;
  form?: SubscriptionForm;
  isProcessing: boolean;
  onBlur: (controlName: string) => void;
  onChange: (controlName: string, newValue: any) => void;
  onSave: (event: React.MouseEvent) => void;
  viewMode: SubscriptionViewMode;
};

export const SubscriptionCreate: React.FunctionComponent<OwnProps> = (props) => {
  const { currentResource, form, isProcessing, viewMode } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isProcessing && currentResource) {
      const state: SubscriptionLocationState = { id: currentResource.id, viewMode: 'view' };
      navigate(`/subscriptions/${currentResource.id}`, { state });
    }
  }, [isProcessing, currentResource, navigate, t]);

  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false);
  const handleCancel = (event: React.MouseEvent) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    if (isFormDirty(form)) {
      setOpenConfirmCancel(true);
    } else {
      navigate('/subscriptions');
    }
  };
  const handleConfirmCancel = (isConfirmed: any) => {
    setOpenConfirmCancel(false);
    if (typeof isConfirmed === 'boolean' && isConfirmed) {
      navigate('/subscriptions');
    }
  };

  return (
    <>
      <header>
        <div className="pki-page-title">{t('subscriptions.page.create')}</div>
        <div className="pki-header-slot end">
          <PkiButton
            id="cancel-btn"
            label={t('pki:form.cancel')}
            onClick={handleCancel}
            size="small"
            variant="secondary"
          />
          <PkiButton id="save-btn" label={t('pki:form.save')} onClick={props.onSave} size="small" variant="primary" />
        </div>
      </header>
      <div className={cx('content-inner-container subscription form', styles.contentInnerContainer)}>
        <section className={cx('item', styles.item)}>
          <SubscriptionEntry
            form={form}
            numTenants={0}
            onBlur={props.onBlur}
            onChange={props.onChange}
            isProcessing={isProcessing}
            viewMode={viewMode}
          />
        </section>
      </div>
      {openConfirmCancel && (
        <PkiDialog
          confirmation
          message={t('pki:unsavedChanges.message')}
          onClose={handleConfirmCancel}
          open={openConfirmCancel}
          size="sm"
          title={t('pki:unsavedChanges.title')}
        />
      )}
    </>
  );
};
SubscriptionCreate.displayName = 'SubscriptionCreate';
