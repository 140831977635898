import { Reducer } from 'redux';
import { TenantAction, TenantActionType } from '../actions/tenant.actions';
import { initialTenantState, TenantState } from '../state/tenant.state';

export const tenantReducer: Reducer<TenantState, TenantAction> = (state = initialTenantState, action): TenantState => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case TenantActionType.FETCH_TENANTS:
    case TenantActionType.FETCH_TENANT:
      return { ...state, readError: null, isLoading: true };

    case TenantActionType.FETCH_TENANTS_SUCCEEDED:
      return { ...state, list: action.payload || [], filteredList: action.payload || [], isLoading: false };

    case TenantActionType.FETCH_TENANT_SUCCEEDED:
      return { ...state, currentResource: action.payload![0], isLoading: false };

    case TenantActionType.FETCH_TENANTS_FAILED:
    case TenantActionType.FETCH_TENANT_FAILED:
      return { ...state, readError: action.error, isLoading: false };

    case TenantActionType.CREATE_TENANT:
      return { ...state, currentResource: null, writeError: null, isProcessing: true };

    case TenantActionType.UPDATE_TENANT:
    case TenantActionType.DELETE_TENANT:
    case TenantActionType.DEACTIVATE_TENANT:
    case TenantActionType.REACTIVATE_TENANT:
      return { ...state, writeError: null, isProcessing: true };

    case TenantActionType.CREATE_TENANT_SUCCEEDED: {
      const resource = action.payload![0];
      const clonedList = [...state.list];
      clonedList.push(resource);
      const clonedFilteredList = [...state.filteredList];
      clonedFilteredList.push(resource);
      return {
        ...state,
        list: clonedList,
        filteredList: clonedFilteredList,
        currentResource: resource,
        isProcessing: false,
      };
    }

    case TenantActionType.UPDATE_TENANT_SUCCEEDED:
    case TenantActionType.DEACTIVATE_TENANT_SUCCEEDED:
    case TenantActionType.REACTIVATE_TENANT_SUCCEEDED: {
      const resource = action.payload![0];
      const clonedList = [...state.list];
      let index = clonedList.findIndex((e) => e.id === action.meta!.id);
      if (index > -1) {
        clonedList[index] = resource;
      }
      const clonedFilteredList = [...state.filteredList];
      index = clonedFilteredList.findIndex((e) => e.id === action.meta!.id);
      if (index > -1) {
        clonedFilteredList[index] = resource;
      }
      return {
        ...state,
        list: clonedList,
        filteredList: clonedFilteredList,
        currentResource: resource,
        isProcessing: false,
      };
    }

    case TenantActionType.DELETE_TENANT_SUCCEEDED: {
      const clonedList = [...state.list];
      let index = clonedList.findIndex((e) => e.id === action.meta!.id);
      if (index > -1) {
        clonedList.splice(index, 1);
      }
      const clonedFilteredList = [...state.filteredList];
      index = clonedFilteredList.findIndex((e) => e.id === action.meta!.id);
      if (index > -1) {
        clonedFilteredList.splice(index, 1);
      }
      return {
        ...state,
        list: clonedList,
        filteredList: clonedFilteredList,
        currentResource: null,
        isProcessing: false,
      };
    }

    case TenantActionType.CREATE_TENANT_FAILED:
    case TenantActionType.UPDATE_TENANT_FAILED:
    case TenantActionType.DELETE_TENANT_FAILED:
    case TenantActionType.DEACTIVATE_TENANT_FAILED:
    case TenantActionType.REACTIVATE_TENANT_FAILED:
      return { ...state, writeError: action.error, isProcessing: false };

    case TenantActionType.FLUSH:
      return { ...state, currentResource: null, readError: null, writeError: null };

    default:
      return state;
  }
};
