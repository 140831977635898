import * as addCircleSvg from './assets/add_circle_24px.svg';
import * as addCircleOutlineSvg from './assets/add_circle_24px_outlined.svg';
import * as arrowBackSvg from './assets/arrow_back_24px.svg';
import * as triangleDownSvg from './assets/arrow_drop_down_24px_outlined.svg';
import * as autoRenewSvg from './assets/autorenew_24px_outlined.svg';
import * as baselineCorrectionSvg from './assets/baseline-correction.svg';
import * as bellSvg from './assets/bell.svg';
import * as biotechSvg from './assets/biotech.svg';
import * as cancelCircleSvg from './assets/cancel-circle.svg';
import * as checkCircleSvg from './assets/check_circle_24px_outlined.svg';
import * as checkCircleFilledSvg from './assets/check_circle_24px_rounded.svg';
import * as closeSvg from './assets/close.svg';
import * as compressSvg from './assets/compress-solid.svg';
import * as pencilSvg from './assets/create_24px_outlined.svg';
import * as calendarSvg from './assets/date_range_24px_outlined.svg';
import * as trashCanSvg from './assets/delete_24px.svg';
import * as deviceHubSvg from './assets/device_hub_24px.svg';
import * as checkSvg from './assets/done_24px_outlined.svg';
import * as emailSvg from './assets/email_24px.svg';
import * as errorOutlineSvg from './assets/error_24px_outlined.svg';
import * as expandSvg from './assets/expand-solid.svg';
import * as chevronUpSvg from './assets/expand_less_24px.svg';
import * as chevronDownSvg from './assets/expand_more_24px.svg';
import * as exportSvg from './assets/export.svg';
import * as filterSvg from './assets/filter.svg';
import * as downloadSvg from './assets/get_app_24px_rounded.svg';
import * as graphPeakSvg from './assets/graph-peak.svg';
import * as homeOutlineSvg from './assets/home-outline.svg';
import * as homeSvg from './assets/home.svg';
import * as homeOutlineSharpSvg from './assets/home_24px.svg';
import * as infoOutlineSvg from './assets/info_24px.svg';
import * as chevronLeftSvg from './assets/keyboard_arrow_left_24px.svg';
import * as chevronRightSvg from './assets/keyboard_arrow_right_24px.svg';
import * as lensSvg from './assets/lens_24px_rounded.svg';
import * as libraryBooksSvg from './assets/library_books_24px.svg';
import * as lightningBoltSvg from './assets/lightning-bolt.svg';
import * as pkiLogoSvg from './assets/logo.svg';
import * as maximizeSvg from './assets/maximize.svg';
import * as minimizeSvg from './assets/minimize.svg';
import * as ellipsisHorizontalSvg from './assets/more_horiz_24px.svg';
import * as nerveCellImageSvg from './assets/nerve-cell-860x484_tcm137-182701-2 1.svg';
import * as noAccessSvg from './assets/no-access.svg';
import * as notificationSvg from './assets/notification_important_24px_outlined.svg';
import * as notificationsSvg from './assets/notifications_24px.svg';
import * as pauseSvg from './assets/pause_24px_rounded.svg';
import * as pauseCircleFilledSvg from './assets/pause_circle_filled_24px_rounded.svg';
import * as personOutlineSvg from './assets/person_outline_24px.svg';
import * as phoneSvg from './assets/phone_android_24px.svg';
import * as pieChartSvg from './assets/pie-chart.svg';
import * as playSvg from './assets/play_arrow_24px_rounded.svg';
import * as playCircleFilledSvg from './assets/play_circle_filled_24px_rounded.svg';
import * as roomPreferencesSvg from './assets/room_preferences.svg';
import * as searchSvg from './assets/search_24px.svg';
import * as cogSvg from './assets/settings_24px.svg';
import * as spinnerSvg from './assets/spinner_24px.svg';
import * as subscriptionsSvg from './assets/subscriptions_24px_outlined.svg';
import * as peopleOutlineSvg from './assets/users.svg';
import * as videoCamSvg from './assets/videocam_24px_rounded.svg';
import * as keySvg from './assets/vpn_key_24px_outlined.svg';
import * as warningSvg from './assets/warning_24px_outlined.svg';
import * as warningOutlineSvg from './assets/warning_amber_24px_outlined.svg';
import * as webPageSvg from './assets/web_24px.svg';
import * as zoomInSvg from './assets/zoom-in.svg';
import { PkiSvg } from './pki-icon';

export { PkiIcon } from './pki-icon';

export const addCircle: PkiSvg = {
  name: 'add-circle',
  svgPath: addCircleSvg,
};
export const addCircleOutline: PkiSvg = {
  name: 'add-circle-outline',
  svgPath: addCircleOutlineSvg,
};
export const analytics: PkiSvg = {
  name: 'analytics',
  svgPath: zoomInSvg,
};
export const arrowBack: PkiSvg = {
  name: 'arrow-back',
  svgPath: arrowBackSvg,
};
export const autoRenew: PkiSvg = {
  name: 'auto-renew',
  svgPath: autoRenewSvg,
};
export const bell: PkiSvg = {
  name: 'bell',
  svgPath: bellSvg,
};
export const biotech: PkiSvg = {
  name: 'biotech',
  svgPath: biotechSvg,
};
export const calendar: PkiSvg = {
  name: 'calendar',
  svgPath: calendarSvg,
};
export const cancelCircle: PkiSvg = {
  name: 'cancel-circle',
  svgPath: cancelCircleSvg,
};
export const check: PkiSvg = {
  name: 'check',
  svgPath: checkSvg,
};
export const checkCircle: PkiSvg = {
  name: 'check-circle',
  svgPath: checkCircleSvg,
};
export const checkCircleFilled: PkiSvg = {
  name: 'check-circle-filled',
  svgPath: checkCircleFilledSvg,
};
export const chevronDown: PkiSvg = {
  name: 'chevron-down',
  svgPath: chevronDownSvg,
};
export const chevronUp: PkiSvg = {
  name: 'chevronUp',
  svgPath: chevronUpSvg,
};
export const chevronLeft: PkiSvg = {
  name: 'chevron-left',
  svgPath: chevronLeftSvg,
};
export const chevronRight: PkiSvg = {
  name: 'chevron-right',
  svgPath: chevronRightSvg,
};
export const close: PkiSvg = {
  name: 'close',
  svgPath: closeSvg,
};
export const cog: PkiSvg = {
  name: 'cog',
  svgPath: cogSvg,
};
export const compress: PkiSvg = {
  name: 'compress',
  svgPath: compressSvg,
};
export const deviceHub: PkiSvg = {
  name: 'device-hub',
  svgPath: deviceHubSvg,
};
export const download: PkiSvg = {
  name: 'download',
  svgPath: downloadSvg,
};
export const ellipsisHorizontal: PkiSvg = {
  name: 'ellipsis-horizontal',
  svgPath: ellipsisHorizontalSvg,
};
export const email: PkiSvg = {
  name: 'email',
  svgPath: emailSvg,
};
export const errorOutline: PkiSvg = {
  name: 'error-outline',
  svgPath: errorOutlineSvg,
};
export const expand: PkiSvg = {
  name: 'expand',
  svgPath: expandSvg,
};
export const filter: PkiSvg = {
  name: 'filter',
  svgPath: filterSvg,
};
export const graphPeak: PkiSvg = {
  name: 'graph-peak',
  svgPath: graphPeakSvg,
};
export const home: PkiSvg = {
  name: 'home',
  svgPath: homeSvg,
};
export const homeOutline: PkiSvg = {
  name: 'home-outline',
  svgPath: homeOutlineSvg,
};
export const infoOutline: PkiSvg = {
  name: 'info-outline',
  svgPath: infoOutlineSvg,
};
export const homeOutlineAlt: PkiSvg = {
  name: 'home-outline-alt',
  svgPath: homeOutlineSharpSvg,
};
export const key: PkiSvg = {
  name: 'key',
  svgPath: keySvg,
};
export const lens: PkiSvg = {
  name: 'lens',
  svgPath: lensSvg,
};
export const libraryBooks: PkiSvg = {
  name: 'library-books',
  svgPath: libraryBooksSvg,
};
export const lightningBolt: PkiSvg = {
  name: 'lightning-bolt',
  svgPath: lightningBoltSvg,
};
export const logout: PkiSvg = {
  name: 'logout',
  svgPath: exportSvg,
};
export const maximize: PkiSvg = {
  name: 'maximize',
  svgPath: maximizeSvg,
};
export const minimize: PkiSvg = {
  name: 'minimize',
  svgPath: minimizeSvg,
};
export const nerveCellImage: PkiSvg = {
  name: 'nerve-cell-image',
  svgPath: nerveCellImageSvg,
};
export const noAccess: PkiSvg = {
  name: 'no-access',
  svgPath: noAccessSvg,
};
export const notification: PkiSvg = {
  name: 'notification',
  svgPath: notificationSvg,
};
export const notifications: PkiSvg = {
  name: 'notifications',
  svgPath: notificationsSvg,
};
export const pause: PkiSvg = {
  name: 'pause',
  svgPath: pauseSvg,
};
export const pauseCircleFilled: PkiSvg = {
  name: 'pause-circle-filled',
  svgPath: pauseCircleFilledSvg,
};
export const pencil: PkiSvg = {
  name: 'pencil',
  svgPath: pencilSvg,
};
export const peopleOutline: PkiSvg = {
  name: 'people-outline',
  svgPath: peopleOutlineSvg,
};
export const personOutline: PkiSvg = {
  name: 'person-outline',
  svgPath: personOutlineSvg,
};
export const phone: PkiSvg = {
  name: 'phone',
  svgPath: phoneSvg,
};
export const pieChart: PkiSvg = {
  name: 'pie-chart',
  svgPath: pieChartSvg,
};
export const pkiLogo: PkiSvg = {
  name: 'pki-logo',
  svgPath: pkiLogoSvg,
};
export const play: PkiSvg = {
  name: 'play',
  svgPath: playSvg,
};
export const playCircleFilled: PkiSvg = {
  name: 'play-circle-filled',
  svgPath: playCircleFilledSvg,
};
export const roomPreferences: PkiSvg = {
  name: 'room-preferences',
  svgPath: roomPreferencesSvg,
};
export const search: PkiSvg = {
  name: 'search',
  svgPath: searchSvg,
};
export const spinner: PkiSvg = {
  name: 'spinner',
  svgPath: spinnerSvg,
};
export const status: PkiSvg = {
  name: 'status',
  svgPath: baselineCorrectionSvg,
};
export const subscriptions: PkiSvg = {
  name: 'subscriptions',
  svgPath: subscriptionsSvg,
};
export const trashCan: PkiSvg = {
  name: 'trash-can',
  svgPath: trashCanSvg,
};
export const triangleDown: PkiSvg = {
  name: 'triangle-down',
  svgPath: triangleDownSvg,
};
export const videoCam: PkiSvg = {
  name: 'video-cam',
  svgPath: videoCamSvg,
};
export const warningOutline: PkiSvg = {
  name: 'warning-outline',
  svgPath: warningOutlineSvg,
};
export const warning: PkiSvg = {
  name: 'warning',
  svgPath: warningSvg,
};
export const webPage: PkiSvg = {
  name: 'web-page',
  svgPath: webPageSvg,
};
