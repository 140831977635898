import { calendar, PkiIcon } from '@software-platforms/design-system-icons';
import cx from 'classnames';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { isDescendant } from '../pki-utils';
import { PkiDateRangePickerMenu } from './pki-date-range-picker-menu';
import styles from './pki-date-range-picker.module.scss';

export type DateRangeOption = { value: string; label: string; start: DateTime | null; end: DateTime | null };
export type DateRange = [DateTime | null, DateTime | null];

export type PkiDateRangePickerProps = {
  calendarTitle?: string;
  cancelBtnLabel?: string;
  confirmBtnLabel?: string;
  className?: string;
  disabled?: boolean;
  disableFuture?: boolean;
  onClose: (isConfirmed: boolean, dateRange?: DateRange) => void;
  onOpen?: (event: React.ChangeEvent<Record<string, any>>) => void;
  readOnly?: boolean;
  start: DateTime;
  end: DateTime;
  rightAlign?: boolean;
  selectLabel?: string;
  selectOptions: DateRangeOption[];
  selectPlaceholder?: string;
  style?: React.CSSProperties;
};

/**
 * Renders a date range picker with a dropdown selector.
 * @param props
 * @constructor
 */
export const PkiDateRangePicker: React.FunctionComponent<React.PropsWithChildren<PkiDateRangePickerProps>> = (
  props
) => {
  const {
    calendarTitle = 'Calendar',
    cancelBtnLabel = 'Cancel',
    className,
    confirmBtnLabel = 'OK',
    disabled,
    disableFuture,
    end,
    readOnly,
    rightAlign,
    selectOptions,
    selectLabel = 'Select Date Range',
    selectPlaceholder = 'Select...',
    start,
    style,
  } = props;

  /* ---------- Dialog Handling ---------- */

  const [openState, setOpenState] = useState<boolean>(false);
  const handleOpenEvent = (flag: boolean, event: React.MouseEvent | React.KeyboardEvent) => {
    if (flag) {
      if (props.onOpen) {
        props.onOpen(event);
      }
    } else if (props.onClose) {
      props.onClose(flag);
    }
    setOpenState(flag);
  };
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.button !== 0) {
      return;
    }
    event.preventDefault();
    handleOpenEvent(true, event);
  };
  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    handleOpenEvent(false, event);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!readOnly) {
      const validKeys = [' ', 'ArrowUp', 'ArrowDown', 'Enter'];
      if (validKeys.indexOf(event.key) !== -1) {
        event.preventDefault();
        handleOpenEvent(true, event);
      }
    }
  };

  // Listen for mouse clicks outside the dialog.
  useEffect(() => {
    const clickOutside = (event: any) => {
      const elem = document.getElementById('pki-date-range-picker');
      if (elem && !isDescendant(elem, event.target)) {
        handleClose(event);
      }
    };
    window.addEventListener('click', clickOutside);
    return () => {
      window.removeEventListener('click', clickOutside);
    };
  }, []);

  /* ---------- Calendar close handler ---------- */

  const handleConfirmClose = (isConfirmed: boolean, dateRange?: DateRange) => {
    setOpenState(false);
    props.onClose(isConfirmed, dateRange);
  };

  /* ---------- Rendering ---------- */

  const formatDateRange = () => {
    return `${start?.toLocaleString(DateTime.DATE_MED)} - ${end?.toLocaleString(DateTime.DATE_MED)}`;
  };

  return (
    <div
      id="pki-date-range-picker"
      className={cx(styles.datePickerContainer, className, { disabled })}
      onKeyDown={disabled || readOnly ? undefined : handleKeyDown}
      onMouseDown={disabled || readOnly ? undefined : handleMouseDown}
      role="button"
      style={style}
      aria-disabled={disabled ? 'true' : undefined}
      aria-expanded={openState ? 'true' : undefined}
      aria-haspopup="listbox"
    >
      <div className={cx('form-control', styles.valueText, { disabled })}>
        <PkiIcon icon={calendar} />
        <div className={styles.inputText}>{formatDateRange()}</div>
      </div>
      <PkiDateRangePickerMenu
        calendarTitle={calendarTitle}
        cancelBtnLabel={cancelBtnLabel}
        confirmBtnLabel={confirmBtnLabel}
        disableFuture={disableFuture}
        end={end}
        onClose={handleConfirmClose}
        open={openState}
        rightAlign={rightAlign || undefined}
        selectOptions={selectOptions}
        selectLabel={selectLabel}
        selectPlaceholder={selectPlaceholder}
        start={start}
      />
    </div>
  );
};
PkiDateRangePicker.displayName = 'PkiDateRangePicker';
