import axios, { AxiosError } from 'axios';
import { EMPTY, Observable, of } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import { PkiNotification, PkiNotificationOptions } from './pki-notification';

const getMessageFromError = (error?: Error | string): string => {
  let message = 'An unknown error occurred';
  if (error) {
    if (typeof error === 'string') {
      message = error;
    } else if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<any>;
      message = axiosError.response?.data || axiosError.message;
    } else {
      message = error.message;
    }
  }
  return message;
};

class NotificationService {
  notifications: Map<string, PkiNotification> = new Map<string, PkiNotification>();

  getDefaultOptions(): PkiNotificationOptions {
    return {
      id: uuidV4(),
      message: '',
      type: 'default',
      autoClose: 5000,
      position: 'bottom-right',
    };
  }

  show(message: string, options?: Partial<PkiNotificationOptions>): Observable<string> {
    return this.showWithDelay(message, 0, options);
  }

  showWithDelay(message: string, millis: number, options?: Partial<PkiNotificationOptions>): Observable<string> {
    const config = { ...this.getDefaultOptions(), ...options };
    config.message = message;

    // Console the message if it is an error
    if (config?.type === 'error') {
      console.error(message);
    }

    let notificationId;
    if (!config.notificationId) {
      notificationId = uuidV4();
    } else {
      notificationId = config.notificationId;
      const oldNotification = this.notifications.get(notificationId);
      if (oldNotification) {
        oldNotification.dismiss();
      }
    }
    const notification = new PkiNotification(notificationId, config);
    notification.onClose = () => this.notifications.delete(notificationId);

    // Now render the notification
    if (millis > 0) {
      notification.showWithDelay(millis);
    } else {
      notification.show();
    }
    return of(notificationId);
  }

  showError(error: Error | string): Observable<string> {
    const options = this.getDefaultOptions();
    options.type = 'error';
    options.autoClose = false;
    return this.show(getMessageFromError(error), options);
  }

  showReportableError(error: Error | string, errorInfo?: string): Observable<string> {
    const message = errorInfo || getMessageFromError(error);
    const options = this.getDefaultOptions();
    options.notificationId = 'reportableError';
    options.type = 'error';
    options.autoClose = false;
    options.buttonLabel = 'Report';
    options.buttonCallback = () => {
      let report: string;
      if (typeof error === 'string') {
        report = error;
      } else if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.data instanceof Error) {
          const nestedError = axiosError.response?.data as Error;
          report = nestedError.stack || nestedError.message;
        } else {
          report = axiosError.stack || axiosError.message;
        }
      } else {
        report = error.stack || error.message;
      }
      // Console the report
      console.error(report);
      this.dismiss('reportableError');
    };
    return this.show(message, options);
  }

  update(notificationId: string, options: PkiNotificationOptions): Observable<any> {
    const found = this.notifications.get(notificationId);
    if (found) {
      found.update(options);
    }
    return EMPTY;
  }

  dismiss(notificationId: string): Observable<any> {
    const found = this.notifications.get(notificationId);
    if (found) {
      found.dismiss();
      this.notifications.delete(notificationId);
    }
    return EMPTY;
  }
}

export class NotificationServiceFactory {
  private static instance: NotificationService;

  static getInstance(): NotificationService {
    if (!NotificationServiceFactory.instance) {
      NotificationServiceFactory.instance = new NotificationService();
    }
    return NotificationServiceFactory.instance;
  }
}
