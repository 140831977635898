import { io, Socket } from 'socket.io-client';

export class WsClient {
  private socket: Socket;

  constructor() {
    this.socket = io('http://localhost:3000');

    this.socket.on('connect', () => {
      console.log(`Socket client connected: ${this.socket.id}`);
      this.socket.emit('join', this.socket.id);
    });

    this.socket.on('disconnect', (message: Socket.DisconnectReason) => {
      console.log(`Socket client disconnect: ${message}`);
    });

    this.socket.on('message', (message: any) => {
      console.log(`Socket client message: ${message}`);
    });

    this.socket.on('random', (message: any) => {
      console.log(`Socket client random: ${message}`);
    });
  }
}
