import { Observable, of } from 'rxjs';
import { StorageService } from './storage.service';

/**
 * Wrapper for the window.localStorage methods.
 */
export const LocalStorageService: StorageService = {
  getItem: (key: string): Observable<any> => {
    const item = window.localStorage.getItem(key);
    if (item) {
      try {
        const value = JSON.parse(item);
        return of(value);
      } catch (e) {
        console.error('JSON parsing error', e);
      }
    }
    return of(null);
  },

  setItem: (key: string, newValue: any): Observable<any> => {
    const oldValue = LocalStorageService.getItem(key);
    window.localStorage.setItem(key, JSON.stringify(newValue));
    return oldValue;
  },

  removeItem: (key: string): Observable<any> => {
    const oldValue = LocalStorageService.getItem(key);
    window.localStorage.removeItem(key);
    return oldValue;
  },

  clear: (): Observable<any> => {
    window.localStorage.clear();
    return of(null);
  },
};
