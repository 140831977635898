import { DateTime } from 'luxon';

export enum SubscriptionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

/**
 * Describes a subscription in the context of this SaaS application.
 * @property id                 The database primary key.
 * @property orgSubscriptionId  A reference to an external identifier.
 * @property companyName        The name of the customer.
 * @property license            A reference to an external license number.
 * @property status             The subscription status, i.e. active or inactive.
 * @property allowedTenants     The number of tenants permitted by this subscription.
 * @property numTenants         The number of connected tenants
 */
export interface Subscription {
  id: string;
  orgSubscriptionId: string;
  companyName: string;
  license: string;
  status: SubscriptionStatus;
  allowedTenants: number;
  numTenants: number;
  createdAt: DateTime;
  createdBy: string;
  lastModifiedAt?: DateTime;
  lastModifiedBy?: string;
}

/**
 * Describes the information necessary for the server to create or update a subscription.
 */
export interface SubscriptionRequest {
  orgSubscriptionId?: string;
  companyName: string;
  license?: string;
  status: SubscriptionStatus;
  allowedTenants: number;
}

/**
 * Describes the object returned by the server from a {@link SubscriptionRequest}.
 */
export interface SubscriptionResponse {
  id: string;
  orgSubscriptionId: string;
  companyName: string;
  license: string;
  status: SubscriptionStatus;
  allowedTenants: string;
  numTenants: number;
  createdAt: string;
  createdBy: string;
  lastModifiedAt?: string;
  lastModifiedBy?: string;
}
