import { AuthAction, AuthActionType } from '../actions/auth.actions';
import { AuthState, initialAuthState } from '../state/auth.state';

export const authReducer = (state: AuthState = initialAuthState, action: AuthAction): AuthState => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case AuthActionType.FETCH_CURRENT_USER:
      return { ...state, isLoading: true };

    case AuthActionType.FETCH_CURRENT_USER_SUCCEEDED:
      return { ...state, currentUser: action.payload, isLoading: false };

    case AuthActionType.FETCH_CURRENT_USER_FAILED:
      return { ...state, isLoading: false, error: action.error };

    case AuthActionType.SET_CURRENT_USER:
      return { ...state, currentUser: action.payload };

    case AuthActionType.UPDATE_CURRENT_USER: {
      // When updating the currentUser, the response from the API doesn't come in the form of an AuthUser.
      // So, map any relevant updates from the response to the AuthUser format used by the UI.
      const clonedUser = state.currentUser;
      if (action.payload.firstName && clonedUser?.authUser.given_name) {
        clonedUser.authUser.given_name = action.payload.firstName;
      }
      if (action.payload.lastName && clonedUser?.authUser.family_name) {
        clonedUser.authUser.family_name = action.payload.lastName;
      }
      if (action.payload.phoneNumber && clonedUser?.authUser.customClaims?.user?.phoneNumber) {
        clonedUser.authUser.customClaims.user.phoneNumber = action.payload.phoneNumber;
      }
      return { ...state, currentUser: clonedUser };
    }
    case AuthActionType.FLUSH:
      return { ...initialAuthState };

    default:
      return state;
  }
};
