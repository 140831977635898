import * as pki_de from './lib/pki.de.json';
import * as pki_en from './lib/pki.en.json';
import * as pki_es from './lib/pki.es.json';
import * as pki_fi from './lib/pki.fi.json';
import * as pki_ja from './lib/pki.ja.json';

export { pki_en, pki_es, pki_fi, pki_ja, pki_de };

export { getInitOptions } from './lib/config';
export { default as i18n } from './lib/config';
export type { LocaleResource } from './lib/config';
