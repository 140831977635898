import { Comparator } from '@nx-workspace/shared/models';
import { User } from '@software-platforms/tenant-manager-ui/models';

const firstNameAscComparator: Comparator<User> = (a, b): number => a.firstName.localeCompare(b.firstName);
const firstNameDescComparator: Comparator<User> = (a, b): number => b.firstName.localeCompare(a.firstName);

const lastNameAscComparator: Comparator<User> = (a, b): number => a.lastName.localeCompare(b.lastName);
const lastNameDescComparator: Comparator<User> = (a, b): number => b.lastName.localeCompare(a.lastName);

const statusAscComparator: Comparator<User> = (a, b): number => a.status.localeCompare(b.status);
const statusDescComparator: Comparator<User> = (a, b): number => b.status.localeCompare(a.status);

/**
 * Returns a {@link Comparator} fo a list of {@link User} instances.
 * @param property      The property name. Options are 'firstName', 'lastName', 'role' and 'status'.
 * @param isDescending
 */
export const getComparator = (property: string, isDescending: boolean): Comparator<User> => {
  switch (property) {
    case 'firstName':
      return isDescending ? firstNameDescComparator : firstNameAscComparator;
    case 'status':
      return isDescending ? statusDescComparator : statusAscComparator;
    case 'lastName':
    default:
      return isDescending ? lastNameDescComparator : lastNameAscComparator;
  }
};
