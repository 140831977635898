import { axiosErrorHandler } from '@nx-workspace/shared/services';
import { Subscription, SubscriptionRequest, SubscriptionResponse } from '@software-platforms/tenant-manager-ui/models';
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { from, Observable } from 'rxjs';
import { SubscriptionService } from '../interfaces';
import { SubscriptionMapper } from '../mappers/subscription.mapper';

/**
 * Concrete implementation of the {@link SubscriptionService} for Axios.
 */
export class AxiosSubscriptionService implements SubscriptionService {
  readConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'read:subscription' } };
  writeConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'write:subscription' } };
  activationConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'write:subscription write:tenant' } };

  constructor(private readonly http: AxiosInstance) {}

  fetchSubscriptions(): Observable<Subscription[]> {
    const url = '/subscriptions';
    return from(
      this.http
        .get<SubscriptionResponse[]>(url, this.readConfig)
        .then((response) => response.data.map((e) => SubscriptionMapper.from(e)))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  fetchSubscription(id: string): Observable<Subscription> {
    const url = `/subscriptions/${id}`;
    return from(
      this.http
        .get<SubscriptionResponse>(url, this.readConfig)
        .then((response) => SubscriptionMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  createSubscription(formData: SubscriptionRequest): Observable<Subscription> {
    const url = '/subscriptions';
    return from(
      this.http
        .post<SubscriptionResponse>(url, formData, this.writeConfig)
        .then((response) => SubscriptionMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  updateSubscription(id: string, formData: SubscriptionRequest): Observable<Subscription> {
    const url = `/subscriptions/${id}`;
    return from(
      this.http
        .patch<SubscriptionResponse>(url, formData, this.writeConfig)
        .then((response) => SubscriptionMapper.from(response.data))
        .catch((error: AxiosError) => axiosErrorHandler(error))
    );
  }

  deleteSubscription(id: string, force: boolean): Observable<any> {
    const url = `/subscriptions/${id}` + (force ? '?force=true' : '');
    return from(
      this.http
        .delete<any>(url, this.writeConfig)
        .then(() => null)
        .catch((error) => axiosErrorHandler(error))
    );
  }

  deactivateSubscription(id: string): Observable<Subscription> {
    const url = `/subscriptions/${id}/deactivate`;
    return from(
      this.http
        .post<SubscriptionResponse>(url, {}, this.activationConfig)
        .then((response) => SubscriptionMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  reactivateSubscription(id: string): Observable<Subscription> {
    const url = `/subscriptions/${id}/activate`;
    return from(
      this.http
        .post<SubscriptionResponse>(url, {}, this.activationConfig)
        .then((response) => SubscriptionMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }
}
