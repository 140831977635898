import Axios, { AxiosError } from 'axios';

/**
 * Returns a parsed error message from the given Axios error. This function looks for an appropriate error message
 * in various properties of the response object, and attempts to parse JSON or otherwise extract a text message.
 * @param error
 */
const parseErrorMessage = (error: AxiosError): string => {
  let message = 'Unknown Error';
  if (error.response?.data) {
    if (typeof error.response.data === 'string') {
      try {
        return JSON.parse(error.response.data);
      } catch (ex) {
        // Drop through
      }
    } else if (typeof error.response.data === 'object') {
      const str = Object.values(error.response.data)
        .filter((e) => typeof e !== 'object')
        .join('');
      try {
        const obj = JSON.parse(str);
        if (typeof obj === 'object' && obj.message) {
          return obj.message;
        }
        return String(obj);
      } catch (ex) {
        // Drop through
      }
      return str;
    }
    message = String(error.response.data);
  } else if (error.request.response) {
    if (typeof error.request.response === 'string') {
      try {
        return JSON.parse(error.request.response);
      } catch (ex) {
        // Drop through
      }
    }
    message = String(error.request.response);
  } else if (error.request.responseText) {
    try {
      return JSON.parse(error.request.responseText);
    } catch (ex) {
      // Drop through
    }
    message = error.request.responseText;
  } else if (error.message) {
    message = error.message;
  }
  return message;
};

/**
 * Converts the given error, which might be an {@link AxiosError}, into a stock JavaScript Error.
 * @param error
 */
export const axiosErrorHandler = (error: Error | AxiosError) => {
  if (Axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<any>;
    const message = parseErrorMessage(axiosError);
    const newError = new Error(message);
    if (process.env['NODE_ENV'] === 'development') {
      // Include the stack if we are in development mode
      newError.stack = axiosError.stack || '';
    }
    throw newError;
  }
  throw error;
};
