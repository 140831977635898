import { FormControl } from '@software-platforms/design-system-components';

export interface UserForm {
  firstName: FormControl;
  lastName: FormControl;
  email: FormControl;
  role: FormControl;
  division: FormControl;
  department: FormControl;
}
