import { PkiPage } from '@nx-workspace/shared/models';
import { Observable } from 'rxjs';
import { AuditEntry, AuditRequestParams } from '../models';

export interface AuditService {
  /**
   * Returns a list of {@link AuditEntry} objects or an empty array if no matches were found.
   */
  fetchPage(params: AuditRequestParams, scope?: string): Observable<PkiPage<AuditEntry>>;
}
