import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { CSSProperties } from 'react';
import { PkiButton } from '../pki-button/pki-button';
import './pki-dialog.scss';

export type PkiDialogFooterProps = {
  cancelBtnLabel?: string;
  cancelBtnVariant?: 'primary' | 'secondary' | 'tertiary' | '';
  className?: string;
  confirmBtnLabel?: string;
  confirmation?: boolean;
  onClose: { bivarianceHack(event: Object): void }['bivarianceHack'];
  type: 'info' | 'warning';
};

/**
 * Renders a modal dialog footer.
 *
 * If the `confirmation` property is true, the component will ignore any composed
 * children and render a row with cancel and confirm buttons. The buttons can be passed custom labels, otherwise the
 * labels will default to 'Cancel' and 'OK', respectively, in English. The cancel button variant can be set,
 * otherwise it will default to 'secondary'.
 *
 * If the 'confirmation' property is false or undefined, this component will render its children.
 * @param props
 * @constructor
 */
export const PkiDialogFooter: React.FunctionComponent<React.PropsWithChildren<PkiDialogFooterProps>> = (props) => {
  const {
    cancelBtnLabel = 'Cancel',
    cancelBtnVariant = 'secondary',
    children,
    className,
    confirmBtnLabel = 'OK',
    confirmation,
    type = 'info',
  } = props;

  const cancelBtnStyle: CSSProperties = {};
  if (type === 'warning') {
    cancelBtnStyle.borderColor = 'rgba(183, 18, 52, 1)';
    cancelBtnStyle.color = 'rgba(183, 18, 52, 1)';
  }

  return (
    <div className={cx('pki-dialog-footer', className, type)}>
      {confirmation ? (
        <div className="pki-dialog-btn-row">
          <PkiButton
            label={cancelBtnLabel}
            onClick={() => props.onClose(false)}
            size="small"
            style={cancelBtnStyle}
            variant={cancelBtnVariant}
          />
          <PkiButton label={confirmBtnLabel} onClick={() => props.onClose(true)} size="small" variant="primary" />
        </div>
      ) : (
        children
      )}
    </div>
  );
};
PkiDialogFooter.displayName = 'PkiDialogFooter';
