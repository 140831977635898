import { Auth0ProviderOptions, AppState as Auth0State } from '@auth0/auth0-react/dist/auth0-provider';
import { createBrowserHistory } from 'history';

// eslint-disable-next-line @typescript-eslint/dot-notation
const appConfig = window['appConfig'];

const onRedirectCallback = (state: Auth0State) =>
  createBrowserHistory().push(state?.returnTo ? state.returnTo : window.location.pathname);

const redirectUri = `${window.location.origin}/${process.env.NX_AUTH0_LOGIN_CALLBACK_PAGE || ''}`.replace(/\/$/, '');

export const auth0ProviderOptions: Auth0ProviderOptions = {
  ...(appConfig || {}).auth0,
  onRedirectCallback,
  redirectUri,
  tenantId: appConfig?.tenant.id, //pass the tenantId with the Auth0 auth QueryString, pass it to further lock-up
  // the app
};
