import { Environment } from '@software-platforms/tenant-manager-ui/models';
import { EnvironmentService } from '@software-platforms/tenant-manager-ui/services';
import { Epic, ofType } from 'redux-observable';
import { catchError, map, of, switchMap } from 'rxjs';
import { EnvironmentActions, EnvironmentActionType } from '../actions/environment.actions';

export const fetchEnvironmentsEpic: Epic = (action$, state$, { environmentService }) =>
  action$.pipe(
    ofType(EnvironmentActionType.FETCH_ENVIRONMENTS),
    switchMap(() =>
      (environmentService as EnvironmentService).fetchEnvironments().pipe(
        map((list: Environment[]) => EnvironmentActions.fetchEnvironmentsSucceeded(list)),
        catchError((error: any) => of(EnvironmentActions.fetchEnvironmentsFailed(error)))
      )
    )
  );

export const fetchEnvironmentEpic: Epic = (action$, state$, { environmentService }) =>
  action$.pipe(
    ofType(EnvironmentActionType.FETCH_ENVIRONMENT),
    switchMap((action) =>
      (environmentService as EnvironmentService).fetchEnvironment(action.meta!.id!).pipe(
        map((resource: Environment) => EnvironmentActions.fetchEnvironmentSucceeded(action.meta!.id!, [resource])),
        catchError((error: any) => of(EnvironmentActions.fetchEnvironmentFailed(action.meta!.id!, error)))
      )
    )
  );

export const createEnvironmentEpic: Epic = (action$, state$, { environmentService }) =>
  action$.pipe(
    ofType(EnvironmentActionType.CREATE_ENVIRONMENT),
    switchMap((action) =>
      (environmentService as EnvironmentService).createEnvironment(action.meta!.formData!).pipe(
        map((resource: Environment) =>
          EnvironmentActions.createEnvironmentSucceeded(action.meta!.formData!, [resource])
        ),
        catchError((error: any) => of(EnvironmentActions.createEnvironmentFailed(action.meta!.formData!, error)))
      )
    )
  );

export const updateEnvironmentEpic: Epic = (action$, state$, { environmentService }) =>
  action$.pipe(
    ofType(EnvironmentActionType.UPDATE_ENVIRONMENT),
    switchMap((action) =>
      (environmentService as EnvironmentService).updateEnvironment(action.meta!.id!, action.meta!.formData!).pipe(
        map((resource: Environment) =>
          EnvironmentActions.updateEnvironmentSucceeded(action.meta!.id!, action.meta!.formData!, [resource])
        ),
        catchError((error: any) =>
          of(EnvironmentActions.updateEnvironmentFailed(action.meta!.id!, action.meta!.formData!, error))
        )
      )
    )
  );

export const deleteEnvironmentEpic: Epic = (action$, state$, { environmentService }) =>
  action$.pipe(
    ofType(EnvironmentActionType.DELETE_ENVIRONMENT),
    switchMap((action) =>
      (environmentService as EnvironmentService).deleteEnvironment(action.meta!.id!, action.meta!.force).pipe(
        map(() => EnvironmentActions.deleteEnvironmentSucceeded(action.meta!.id!)),
        catchError((error: any) => of(EnvironmentActions.deleteEnvironmentFailed(action.meta!.id!, error)))
      )
    )
  );
