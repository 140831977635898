import { FormControl, ValidationErrors, Validator } from './form-control';

const IPV4_REGEXP = /^((25[0-5]|(2[0-4]|1?[0-9])?[0-9])\.){3}(25[0-5]|(2[0-4]|1?[0-9])?[0-9])$/;
// eslint-disable-next-line max-len
const IPV6_REGEXP = new RegExp(
  `(([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]+|::(ffff(:0{1,4})?:)?((25[0-5]|(2[0-4]|1?[0-9])?[0-9])\\.){3}(25[0-5]|(2[0-4]|1?[0-9])?[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1?[0-9])?[0-9])\\.){3}(25[0-5]|(2[0-4]|1?[0-9])?[0-9]))`
);

/* ---------- Some commonly used validators ---------- */

/**
 * Validates that the given form control contains a non-null, non-undefined value.
 * @param control
 */
export const required: Validator = (control: FormControl): ValidationErrors | null => {
  if (!control.value || (Array.isArray(control.value) && control.value.length === 0)) {
    return { required: true };
  }
  return null;
};

/**
 * Validates that the given form control contains only alphanumeric, underscore or space characters, with the first
 * character containing only a letter.
 * @param control
 */
export const alphanumeric: Validator = (control: FormControl): ValidationErrors | null => {
  const ptn = /^[a-zA-Z][\w\s]*$/;
  if (control.value && !ptn.test(control.value)) {
    return { alphanumeric: true };
  }
  return null;
};

/**
 * Validates that the given form control contains only alphanumeric characters from the basic Latin alphabet,
 * including the underscore.
 * @param control
 */
export const word: Validator = (control: FormControl): ValidationErrors | null => {
  const ptn = /\W+/;
  if (control.value && ptn.test(control.value)) {
    return { word: true };
  }
  return null;
};

/**
 * Validates the given hostname 'label'. RFC1123 defines labels as following the rules for ARPANET host names. They
 * must be no more than 63 characters in length, start with an ASCII letter or digit, end with a letter or digit,
 * and have as interior characters only letters, digits or a hyphen.
 * @param control
 */
export const dnsLabel: Validator = (control: FormControl): ValidationErrors | null => {
  const ptn = /^[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/i;
  if (control.value && !ptn.test(control.value)) {
    return { invalid: true };
  }
  return null;
};

/**
 * Validates the given email address according to RFC5322.
 * @see https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
 * @param control
 */
export const email: Validator = (control: FormControl): ValidationErrors | null => {
  const ptn =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
  if (control.value && !ptn.test(control.value)) {
    return { invalid: true };
  }
  return null;
};

/**
 * Validates the given URI component.
 * @see https://mathiasbynens.be/demo/url-regex
 * @see https://gist.github.com/dperini/729294
 * @param control
 */
export const url: Validator = (control: FormControl): ValidationErrors | null => {
  const ptn = new RegExp(
    '^' +
      // protocol identifier (optional)
      // short syntax // still required
      // '(?:(?:(?:https?|ftp):)?\\/\\/)' +
      // user:pass BasicAuth (optional)
      '(?:\\S+(?::\\S*)?@)?' +
      '(?:' +
      // IP address exclusion
      // private & local networks
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broadcast addresses
      // (first & last IP address of each class)
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
      '|' +
      // host & domain names, may end with dot
      // can be replaced by a shorter alternative
      // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
      '(?:' +
      '(?:' +
      '[a-z0-9\\u00a1-\\uffff]' +
      '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
      ')?' +
      '[a-z0-9\\u00a1-\\uffff]\\.' +
      ')+' +
      // TLD identifier name, may end with dot
      '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
      ')' +
      // port number (optional)
      '(?::\\d{2,5})?' +
      // resource path (optional)
      '(?:[/?#]\\S*)?' +
      '$',
    'i'
  );
  if (control.value && !ptn.test(control.value)) {
    return { invalid: true };
  }
  return null;
};

/**
 * Returns a {@link ValidationErrors} object if the value in the given {@link FormControl} is not a valid IPv4 or
 * IPv6 address, null otherwise.
 * @see https://stackoverflow.com/questions/53497/regular-expression-that-matches-valid-ipv6-addresses
 * @param control
 */
export const ipAddress = (control: FormControl): ValidationErrors | null => {
  if (control && control.value) {
    const isIPv4 = IPV4_REGEXP.test(control.value);
    const isIPv6 = IPV6_REGEXP.test(control.value);
    if (isIPv4 || isIPv6) {
      return null;
    }
    return { invalid: true };
  }
  return null;
};

/**
 * Returns a {@link ValidationErrors} object if the value in the given {@link FormControl} is not a positive number
 * equal or greater than zero, null otherwise.
 * @param control
 */
export const positiveNumber = (control: FormControl): ValidationErrors | null => {
  if (control && control.value !== undefined && control.value !== null) {
    const value = Number(control.value);
    if (Number.isNaN(value) || !Number.isFinite(value) || value < 0) {
      return { invalid: true };
    }
  }
  return null;
};

/**
 * Returns a {@link ValidationErrors} object if the value in the given {@link FormControl} is not a positive integer
 * equal or greater than zero, null otherwise.
 * @param control
 */
export const positiveInteger = (control: FormControl): ValidationErrors | null => {
  if (control && control.value !== undefined && control.value !== null) {
    const value = Number(control.value);
    if (Number.isNaN(value) || !Number.isFinite(value) || !Number.isInteger(value) || value < 0) {
      return { invalid: true };
    }
  }
  return null;
};

/**
 * Returns a {@link ValidationErrors} object if the value in the given {@link FormControl} is not a port number in
 * the range of 0 to 65353, null otherwise.
 * @param control
 */
export const port = (control: FormControl): ValidationErrors | null => {
  if (control && control.value !== undefined && control.value !== null) {
    const value = Number(control.value);
    if (Number.isNaN(value) || !Number.isFinite(value) || value < 0 || value > 65353) {
      return { invalid: true };
    }
  }
  return null;
};

/**
 * Validates that the given form control contains a password that meets a strength that meets the following
 * characteristics:
 * - Non-empty, at least 12 characters long
 * - Contain lower-case letters, upper-case letters, and digits
 * - Contain at least one special character ()`~!@#$%^&*-+=|\{}[]:;"'<>,.?/_.
 * @param control
 */
export const password: Validator = (control: FormControl): ValidationErrors | null => {
  if (control && control.value) {
    // Use lookaheads
    const ptn = /^(?=.*[a-z]+.*)(?=.*[A-Z]+.*)(?=.*[0-9]+.*)(?=.*[()`~!@#$%^&*-+=|\\{}[\]:;"'<>,.?/_.]+.*).{12,}$/g;
    if (!ptn.test(control.value)) {
      return { invalid: true };
    }
  }
  return null;
};
