import { DateTime } from 'luxon';

export enum EventAction {
  CREATE_SUBSCRIPTION = 'createSubscription',
  EDIT_SUBSCRIPTION = 'editSubscription',
  DELETE_SUBSCRIPTION = 'deleteSubscription',
  DEACTIVATE_SUBSCRIPTION = 'deactivateSubscription',
  REACTIVATE_SUBSCRIPTION = 'reactivateSubscription',
  CREATE_TENANT = 'createTenant',
  EDIT_TENANT = 'editTenant',
  DELETE_TENANT = 'deleteTenant',
  DEACTIVATE_TENANT = 'deactivateTenant',
  REACTIVATE_TENANT = 'reactivateTenant',
  CREATE_USER = 'createUser',
  EDIT_USER = 'editUser',
  DELETE_USER = 'deleteUser',
  DEACTIVATE_USER = 'deactivateUser',
  REACTIVATE_USER = 'reactivateUser',
  CREATE_ENVIRONMENT = 'createEnvironment',
  EDIT_ENVIRONMENT = 'editEnvironment',
  DELETE_ENVIRONMENT = 'deleteEnvironment',
}

export interface AuditItems {
  items: AuditEntry[];
}

export interface AuditItemsResource {
  queryParams?: AuditQueryRequest;
  items: AuditEntryResource[];
}

export interface AuditEntry {
  id: string;
  ipAddress: string;
  createdBy: string;
  createdAt: DateTime;
  action: string;
  entity: string;
  oldValue?: any;
  newValue?: any;
}

/**
 * Describes an audit query request
 * @property from           The earliest date in the given ate range (inclusive)
 * @property to             The latest date in the given date range (inclusive)
 * @property lastQueryToken ???
 * @property pageSize       The number of entries per page???
 * @property order          Ascending or descending order (default is descending)
 * @property getValues      True if the original and new values should be included in the response
 */
export interface AuditQueryRequest {
  from?: string;
  to?: string;
  lastQueryToken?: string;
  pageSize?: string; // a number.toString
  order?: 'ASC' | 'DESC'; //undefined === DESC (default)
  getValues?: 'true' | 'false'; //undefined === "false" (default)
}

export interface AuditEntryResource {
  id: string;
  createdBy: string;
  createdAt: string;
  action: string;
  entity: string;
  oldValue?: string;
  newValue?: string;
}
