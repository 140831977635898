import { AuthServiceFactory } from '@nx-workspace/shared/auth';
import { PkiButton } from '@software-platforms/design-system-components';
import { PkiIcon } from '@software-platforms/design-system-icons';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { ProfileFeature } from '../models';
import styles from './profile.sidebar.module.scss';

type OwnProps = {
  currentTab: string;
  features: ProfileFeature[];
  logoutBtnLabel?: string;
  onLogout: () => void;
  onTabSelection: (tab: string) => void;
};

export const ProfileSidebar: React.FunctionComponent<React.PropsWithChildren<OwnProps>> = (props) => {
  const { currentTab, features, logoutBtnLabel = 'Logout' } = props;

  const handleLogout = () => {
    props.onLogout();
    // console.log('logging out');
    // const authService = AuthServiceFactory.getInstance();
    // authService.logout();
  };

  return (
    <section className={styles.routesContainer}>
      <div className={styles.routes}>
        {features.map((feature) => (
          <PkiButton
            key={uuidV4().substring(0, 8)}
            leftIcon={<PkiIcon icon={feature.icon} />}
            label={feature.title || ''}
            onClick={() => props.onTabSelection(feature.props.tab)}
            size="small"
            variant={currentTab === feature.props.tab ? 'primary' : 'tertiary'}
          />
        ))}
      </div>
      <PkiButton
        classNames={styles.logoutBtn}
        label={logoutBtnLabel}
        onClick={handleLogout}
        size="small"
        variant="secondary"
      />
    </section>
  );
};
ProfileSidebar.displayName = 'ProfileSidebar';
