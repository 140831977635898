import { PkiButton, PkiButtonProps } from '@software-platforms/design-system-components';
import React from 'react';
import { getI18n } from 'react-i18next';
import { toast } from 'react-toastify';
import styles from './notification.module.scss';

export type NotificationType = 'info' | 'success' | 'warning' | 'error' | 'default';
export type NotificationPosition =
  | 'top-right'
  | 'top-center'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-center'
  | 'bottom-left';

/**
 * Describes the properties of the PkiNotification
 * @property id               The unique identifier of this message
 * @property message          The message text
 * @property type             The type of message, i.e. success, warning, error, info or default
 * @property autoClose        The milliseconds before the notification is dismissed. A boolean false indicates
 *                            that the notification should remain open. (default - 5000)
 * @property bodyClassName    Optional class selector for the notification body
 * @property bodyStyle        Optional inline styles for the notification body
 * @property buttonLabel      Label for an optional button
 * @property buttonCallback   Callback method for an optional button
 * @property className        Optional class selector for the notification container
 * @property closeOnClick     Enables the onClick method (default = true)
 * @property data             Optional data to include with the message, e.g. a stack trace
 * @property hideProgressBar  Shows or hides the progress bar (default = false)
 * @property notificationId   Optional notification identifier
 * @property onClose          Optional method to invoke on closing the notification
 * @property onOpen           Optional method to invoke when the notification is first rendered
 * @property position         The position of the notification within the window, i.e. top-left, top-center,
 *                            top-right, bottom-left, bottom-center or bottom-right
 * @property progress         Optional percentage to control the progress bar
 * @property style            Optional inline styles for the notification container
 */
export interface PkiNotificationOptions {
  id: string;
  message: string;
  type: NotificationType;
  autoClose: number | false;
  bodyClassName?: string;
  bodyStyle?: React.CSSProperties;
  buttonLabel?: string;
  buttonCallback?: () => void;
  className?: string;
  closeOnClick?: boolean;
  data?: any;
  hideProgressBar?: boolean;
  notificationId?: string;
  onClose?: (args: any) => void;
  onOpen?: (args: any) => void;
  position?: NotificationPosition;
  progress?: number;
  render?: React.ReactNode;
  style?: React.CSSProperties;
}

/* ---------- Class Definition ---------- */

/**
 * Wrapper class for a React Toast notification.
 */
export class PkiNotification {
  handler = -1;
  toastId: React.ReactText | undefined;

  constructor(public notificationId: string, public options: PkiNotificationOptions) {}

  getOptions(): PkiNotificationOptions {
    if (typeof this.options.autoClose === 'undefined') {
      this.options.autoClose = 5000;
    }
    if (typeof this.options.closeOnClick === 'undefined') {
      this.options.closeOnClick = true;
    }
    const options = this.options;
    options.render = this.render();
    return options;
  }

  show() {
    const options = this.getOptions();
    options.id = this.notificationId;
    this.toastId = toast(options.render, options);
  }

  showWithDelay(millis: number) {
    this.handler = window.setTimeout(() => this.show(), millis);
  }

  update(options: PkiNotificationOptions) {
    this.options = { ...this.options, ...options };
  }

  dismiss() {
    if (this.handler > 0) {
      window.clearTimeout(this.handler);
    }
    if (this.toastId) {
      toast.dismiss(this.toastId);
    }
  }

  onClose() {
    // Noop - to be overridden by the notification service.
  }

  render() {
    const { message } = this.options;
    if (!message) {
      throw new Error('A notification message is required');
    }

    // Include a button if configured
    let button;
    if (this.options.buttonLabel && this.options.buttonCallback) {
      const props: PkiButtonProps = {
        classNames: styles.reportButton,
        id: 'notification-btn',
        label: String(getI18n().t(this.options.buttonLabel)),
        onClick: this.options.buttonCallback,
        size: 'small',
        style: { padding: '2px 4px', margin: '0 4px' },
        type: 'button',
        variant: 'secondary',
      };
      button = React.createElement(PkiButton, props);
    }

    // Return the rendered content
    return React.createElement(
      'div',
      { className: styles.messageContainer },
      React.createElement('span', { className: styles.notificationMessage }, String(getI18n().t(message))),
      button
    );
  }
}
