import { check, close, PkiIcon } from '@software-platforms/design-system-icons';
import cx from 'classnames';
import React from 'react';
import './pki-toggle.scss';

export interface PkiToggleProps {
  checked: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  onClick: (event: any, checked: boolean) => void;
  style?: React.CSSProperties;
}

export const PkiToggle: React.FunctionComponent<React.PropsWithChildren<PkiToggleProps>> = (props) => {
  const { className, checked, disabled, id, style } = props;

  const handleChange = (event: any) => {
    const newValue = !checked;
    props.onClick(event, newValue);
  };

  return (
    <div
      className={cx('pki-toggle', className, { disabled, checked })}
      id={id}
      onClick={handleChange}
      role="button"
      style={style}
    >
      <input type="hidden" checked={checked} onChange={handleChange} />
      {checked && (
        <>
          <div className="checked">
            <PkiIcon icon={check} />
          </div>
          <div className="circle" />
        </>
      )}
      {!checked && (
        <>
          <div className="circle" />
          <div className="unchecked">
            <PkiIcon icon={close} />
          </div>
        </>
      )}
    </div>
  );
};
