import { isFormDirty, PkiButton, PkiDialog } from '@software-platforms/design-system-components';
import { Subscription, SubscriptionStatus, TenantForm } from '@software-platforms/tenant-manager-ui/models';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { TenantEntry } from '../tenant-entry/tenant-entry';
import styles from './tenant-create.module.scss';

type OwnProps = {
  form?: TenantForm;
  isProcessing: boolean;
  onBlur: (controlName: string) => void;
  onChange: (controlName: string, newValue: any) => void;
  onSave: (event: React.MouseEvent) => void;
  subscriptions: Subscription[];
  viewMode: string;
};

export const TenantCreate: React.FunctionComponent<OwnProps> = (props) => {
  const { form, isProcessing, subscriptions } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(
    () => {
      // Handles tenant creation from a subscription
      if (location.state && typeof location.state === 'string') {
        const found = subscriptions.find((e) => e.id === location.state);
        if (found) {
          props.onChange('subscriptionId', found);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscriptions]
  );

  /* ---------- Cancel Actions ---------- */

  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false);
  const handleCancel = (event: React.MouseEvent) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    if (isFormDirty(form)) {
      setOpenConfirmCancel(true);
    } else {
      navigate(-1);
    }
  };
  const handleConfirmCancel = (isConfirmed: any) => {
    setOpenConfirmCancel(false);
    if (typeof isConfirmed === 'boolean' && isConfirmed) {
      navigate(-1);
    }
  };

  return (
    <>
      <header>
        <div className="pki-page-title">{t('tenants.page.create')}</div>
        <div className="pki-header-slot end">
          <PkiButton
            id="cancel-btn"
            label={t('pki:form.cancel')}
            onClick={handleCancel}
            size="small"
            variant="secondary"
          />
          <PkiButton id="save-btn" label={t('pki:form.save')} onClick={props.onSave} size="small" variant="primary" />
        </div>
      </header>
      <div className={cx('content-inner-container', styles.contentInnerContainer)}>
        <section className={styles.item}>
          <TenantEntry
            form={form}
            isProcessing={isProcessing}
            onBlur={props.onBlur}
            onChange={props.onChange}
            subscriptions={subscriptions.filter((subscription) => subscription.status === SubscriptionStatus.ACTIVE)}
            viewMode="create"
          />
        </section>
      </div>
      {openConfirmCancel && (
        <PkiDialog
          confirmation
          message={t('pki:unsavedChanges.message')}
          onClose={handleConfirmCancel}
          open={openConfirmCancel}
          size="sm"
          title={t('pki:unsavedChanges.title')}
        />
      )}
    </>
  );
};
TenantCreate.displayName = 'TenantCreate';
