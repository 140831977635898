import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { PkiBadge } from '@software-platforms/design-system-components';
import { User, UserStatus } from '@software-platforms/tenant-manager-ui/models';
import cx from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserViewMode } from '../users';
import styles from './user-list.module.scss';

type OwnProps = {
  filteredList: User[];
  isLoading: boolean;
  onSelectionChanged: (id: string) => void;
  viewMode: UserViewMode;
};

export const UserList: React.FunctionComponent<OwnProps> = (props) => {
  const { filteredList, isLoading, viewMode } = props;
  const { t } = useTranslation();
  const disableRowSelection = viewMode === 'create' || viewMode === 'edit';

  const [selectedRow, setSelectedRow] = useState<GridRowId>('');
  const handleSelection = (selectionModel: GridSelectionModel) => {
    const selectedRowId = selectionModel?.length ? selectionModel[0] : '';
    if (selectedRowId && selectedRowId !== selectedRow) {
      setSelectedRow(selectedRowId);
      props.onSelectionChanged(String(selectedRowId));
    }
  };

  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'lastName', sort: 'asc' }]);
  const columns: GridColDef[] = [
    { field: 'id', hide: true },
    { field: 'lastName', flex: 2, headerName: t('users.list.lastName') },
    { field: 'firstName', flex: 2, headerName: t('users.list.firstName') },
    {
      field: 'status',
      flex: 1,
      headerName: t('users.list.status'),
      renderCell: (params: GridRenderCellParams<any, User>) => {
        let statusStyle;
        switch (params.row.status) {
          case UserStatus.ACTIVE:
            statusStyle = styles.active;
            break;
          case UserStatus.INVITED:
            statusStyle = styles.inviteSent;
            break;
          case UserStatus.EXPIRED:
          case UserStatus.INACTIVE:
          default:
            statusStyle = styles.inactive;
            break;
        }
        return (
          <PkiBadge classNames={cx(styles.pkiBadge, statusStyle)} label={t(`users.status.${params.row.status}`)} />
        );
      },
    },
  ];

  const rows: GridRowsProp = filteredList.map((e: User) => {
    const { userId, roles, ...rest } = e;
    const role = roles?.length ? roles[0].toLowerCase() : '';
    return { id: userId, roles: role, ...rest };
  });

  return (
    <section className="list">
      <div className={cx('pki-data-grid', { selectable: !disableRowSelection })}>
        <DataGrid
          autoPageSize
          columns={columns}
          density="compact"
          disableColumnMenu
          disableSelectionOnClick={disableRowSelection}
          loading={isLoading}
          onSelectionModelChange={handleSelection}
          onSortModelChange={(model: GridSortModel) => setSortModel(model)}
          pagination
          rows={rows}
          sortModel={sortModel}
        />
      </div>
    </section>
  );
};
UserList.displayName = 'UserList';
