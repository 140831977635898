import { DropDownOption } from '@nx-workspace/shared/models';
import {
  PkiButton,
  PkiChip,
  PkiDialog,
  PkiSelect,
  PkiSelectOption,
} from '@software-platforms/design-system-components';
import { addCircle, home, PkiIcon } from '@software-platforms/design-system-icons';
import {
  Environment,
  SearchFilter,
  isFilterSelected,
  Tenant,
  TenantStatus,
  userHasAdministratorRole,
} from '@software-platforms/tenant-manager-ui/models';
import { AppState, TenantActions } from '@software-platforms/tenant-manager-ui/store';
import cx from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { useTypeAhead } from '../../components/hooks/use-typeahead';
import { formatFilterLabel } from '../../utils';
import { TenantListGrid } from '../tenant-list-grid/tenant-list-grid';
import { TenantActionModal } from '../tenant-modal/tenant-action-modal';
import { TenantLocationState } from '../tenants';
import styles from './tenant-list.module.scss';

const SEARCH_FIELDS = ['tenantName', 'subscriptionId', 'companyName'];

/* ---------- Component Definition ---------- */

type OwnProps = {
  list: Tenant[];
  environments: Environment[];
  isLoading: boolean;
};

export const TenantList: React.FunctionComponent<OwnProps> = (props) => {
  const { list, environments, isLoading } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector((state: AppState) => state.auth.currentUser);
  const isAdministrator = userHasAdministratorRole(currentUser?.authUser);

  const TENANT_STATUS_OPTIONS: DropDownOption[] = useMemo<DropDownOption[]>(
    () =>
      Object.keys(TenantStatus).map((key) => ({
        value: TenantStatus[key],
        label: t(`tenants.status.${TenantStatus[key]}`),
      })),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [TenantStatus]
  );
  const ENVIRONMENT_OPTIONS: DropDownOption[] = environments.map((e) => ({ value: e.id, label: e.name }));

  const handleCreateTenant = (event: React.MouseEvent) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    dispatch(TenantActions.flush());
    navigate('/tenants/create', { state: { viewMode: 'create' } as TenantLocationState });
  };

  /* ---------- Search and Filtering ---------- */

  const [search, setSearch] = useState<string>('');
  const [searchToken, setSearchToken] = useState<string>('');
  const search$ = useMemo(() => {
    return new BehaviorSubject('').pipe(
      map((token) => token.trim()),
      distinctUntilChanged(),
      debounceTime(200)
    );
  }, []);
  useTypeAhead(search$, setSearchToken);

  const [filters, setFilters] = useState<SearchFilter[]>([]);
  const handleFilter = (event: React.ChangeEvent<{}>) => {
    const target = (event as React.ChangeEvent<HTMLDivElement>).target;
    const label: string = target.innerText;
    const { group, value } = target.dataset;
    if (group && value) {
      const hasFilter = filters.some((e) => e.field === group && e.value === value);
      if (!hasFilter) {
        setFilters([...filters, { field: group, value, label }]);
      }
    }
  };
  const handleDeleteFilter = (filter: SearchFilter) => {
    const updatedFilters = [...filters];
    const index = updatedFilters.findIndex((e) => e === filter);
    if (index > -1) {
      updatedFilters.splice(index, 1);
      setFilters(updatedFilters);
    }
  };
  const handleClearFilters = () => {
    setFilters([]);
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearch(event.target.value);
    (search$ as BehaviorSubject<string>).next(event.target.value || '');
  };

  /* ---------- Grid Row Actions ---------- */

  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
  const [openConfirmAction, setOpenConfirmAction] = useState<boolean>(false);
  const handleAction = (selectedItem: Tenant) => {
    if ([TenantStatus.ACTIVE.toString(), TenantStatus.INACTIVE.toString()].includes(selectedItem.status)) {
      setSelectedTenant(selectedItem);
      if (!openConfirmAction) {
        setOpenConfirmAction(true);
      }
    }
  };
  const handleConfirmAction = (isConfirmed: boolean) => {
    setOpenConfirmAction(false);
    if (isConfirmed && selectedTenant) {
      if (selectedTenant?.status === 'inactive') {
        dispatch(TenantActions.reactivateTenant(selectedTenant.id));
      } else {
        dispatch(TenantActions.deactivateTenant(selectedTenant.id));
      }
    }
  };

  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const handleDelete = (tenant: Tenant) => {
    setSelectedTenant(tenant);
    if (!openConfirmDelete) {
      setOpenConfirmDelete(true);
    }
  };
  const handleConfirmDelete = (isConfirmed: boolean) => {
    setOpenConfirmDelete(false);
    if (isConfirmed && selectedTenant) {
      dispatch(TenantActions.deleteTenant(selectedTenant.id));
    }
  };

  const handleView = (selectedItem: Tenant) => {
    setSelectedTenant(selectedItem);
    navigate(`/tenants/${selectedItem.id}`, {
      state: { viewMode: 'view', id: selectedItem.id } as TenantLocationState,
    });
  };

  const action = selectedTenant ? (selectedTenant.status === 'inactive' ? 'activate' : 'deactivate') : undefined;

  /* ---------- Data ---------- */

  let filteredList: Tenant[] = [...(list || [])];
  if (searchToken?.length) {
    const term = searchToken.toLowerCase();
    filteredList = filteredList.filter((e) =>
      SEARCH_FIELDS.some((field) => String(e[field]).toLowerCase().includes(term))
    );
  }
  if (filters.length) {
    filteredList = filteredList.filter((tenant) => {
      return filters.some((filter) => {
        //TODO: Tenant list sorts on 'environment' OR 'status'. Investigate AND.
        switch (filter.field) {
          case 'status': {
            return tenant.status === filter.value;
          }
          case 'environment': {
            return tenant.environmentId === filter.value;
          }
          default:
            return false;
        }
      });
    });
  }

  /* ---------- Rendering ---------- */

  return (
    <>
      <header>
        <div className="pki-header-slot">
          <div className="pki-page-title">{t('tenants.title')}</div>
          {isAdministrator && (
            <PkiButton
              id="add-btn"
              label={t('tenants.addBtnLabel')}
              leftIcon={<PkiIcon icon={addCircle} />}
              onClick={handleCreateTenant}
              size="small"
              variant="primary"
            />
          )}
        </div>
        <div className="pki-header-slot end">
          <PkiSelect
            name="filter"
            multiple
            onClose={handleFilter}
            placeholder={t('tenants.filter.title')}
            style={{ width: '160px' }}
            value={filters}
          >
            <div className="col-container" style={{ width: '328px', gap: '10px', margin: '0 2px 2px' }}>
              <div className="col">
                <div className="pki-select-group">{t('tenants.filter.status')}</div>
                {TENANT_STATUS_OPTIONS.map((e) => (
                  <PkiSelectOption
                    key={e.value}
                    className="filter"
                    group="status"
                    role="option"
                    selected={isFilterSelected('status', e.value, filters)}
                    aria-selected={isFilterSelected('status', e.value, filters) ? 'true' : undefined}
                    value={e.value}
                  >
                    {e.label}
                  </PkiSelectOption>
                ))}
              </div>
              <div className="col">
                <div className="pki-select-group">{t('tenants.filter.environment')}</div>
                {ENVIRONMENT_OPTIONS.map((e) => (
                  <PkiSelectOption
                    key={e.value}
                    className="filter"
                    group="environment"
                    role="option"
                    selected={isFilterSelected('environment', e.value, filters)}
                    aria-selected={isFilterSelected('environment', e.value, filters) ? 'true' : undefined}
                    value={e.value}
                  >
                    {e.label}
                  </PkiSelectOption>
                ))}
              </div>
            </div>
          </PkiSelect>
          <div className={cx('form-control')} style={{ width: '250px' }}>
            <input
              type="text"
              placeholder={t('pki:form.searchPlaceholder')}
              onChange={handleSearch}
              value={search || ''}
            />
          </div>
        </div>
      </header>
      {filters.length > 0 && (
        <section className={styles.filters}>
          {filters.map((filter) => (
            <div key={`filter-${filter.field}${filter.value}`}>
              <PkiChip
                className={styles.pkiChip}
                label={formatFilterLabel(filter)}
                rightDeleteIcon
                onClick={() => handleDeleteFilter(filter)}
                onDelete={() => handleDeleteFilter(filter)}
              />
            </div>
          ))}
          <PkiButton
            classNames={styles.clearFilterBtn}
            label={t('clearFiltersBtnLabel')}
            onClick={handleClearFilters}
            variant="text-only"
          />
        </section>
      )}
      <div className={cx('content-inner-container', styles.contentInnerContainer)}>
        <section className="list">
          {list.length > 0 || isLoading ? (
            <div className="pki-data-grid">
              <TenantListGrid
                isAdministrator={isAdministrator}
                isLoading={isLoading}
                list={filteredList}
                onAction={handleAction}
                onDelete={handleDelete}
                onView={handleView}
              />
            </div>
          ) : (
            <div className={styles.noDataContainer}>
              <div className={styles.captionContainer}>
                <PkiIcon icon={home} />
                <div className={styles.caption}>{t('tenants.caption')}</div>
                {isAdministrator && (
                  <PkiButton
                    id="add-btn-2"
                    label={t('tenants.addBtnLabel')}
                    leftIcon={<PkiIcon icon={addCircle} />}
                    onClick={handleCreateTenant}
                    size="small"
                    variant="primary"
                  />
                )}
              </div>
            </div>
          )}
        </section>
      </div>
      {isAdministrator && (
        <>
          {openConfirmAction && action !== undefined && (
            <TenantActionModal
              action={action}
              open={openConfirmAction}
              onClose={handleConfirmAction}
              selectedTenant={selectedTenant!}
            />
          )}
          {openConfirmDelete && (
            <PkiDialog
              confirmation
              confirmBtnLabel={t('tenants.confirmDelete.confirmBtnLabel')}
              message={t('tenants.confirmDelete.message')}
              onClose={handleConfirmDelete}
              open={openConfirmDelete}
              size="sm"
              title={t('tenants.confirmDelete.title')}
            />
          )}
        </>
      )}
    </>
  );
};
TenantList.displayName = 'TenantList';
