import { Tenant } from '@software-platforms/tenant-manager-ui/models';

export interface TenantState {
  list: Tenant[];
  filteredList: Tenant[];
  currentResource: Tenant | null;
  isLoading: boolean;
  isProcessing: boolean;
  readError: any;
  writeError: any;
  sortKey: string;
  sortDirection: string;
}

export const initialTenantState: TenantState = {
  list: [],
  filteredList: [],
  currentResource: null,
  isLoading: false,
  isProcessing: false,
  readError: null,
  writeError: null,
  sortKey: 'tenantName',
  sortDirection: 'asc',
};
