import { DateTime } from 'luxon';

export type EnvironmentTier = 'basic' | 'standard' | 'premium' | 'superpremium';
export type EnvironmentStatus = 'active' | 'inactive' | 'deploying' | 'closedown' | 'failed';

/**
 * Describes a deployed environment. This is a database entry only and has no operational use in the actual
 * provisioning (or de-provisioning) of the deployment.
 * @property id       The database primary key (GUID).
 * @property name     An arbitrary name to identify the environment within this application.
 * @property domain   A valid domain 'label' that corresponds to the actual deployed environment.
 * @property status   The current state of the environment.
 * @property tier     The capabilities of the environment and how many tenants share it.
 */
export interface Environment {
  id: string;
  name: string;
  domain: string;
  status?: EnvironmentStatus;
  tier?: EnvironmentTier;
  createdAt: DateTime;
  createdBy: string;
  lastModifiedAt: DateTime;
  lastModifiedBy: string;
}

/**
 * Describes the information required in the request payload to create or update an environment.
 * @property name     An arbitrary name to identify the environment within this application.
 * @property dnsFqdn  A valid qualified domain name that corresponds to the actual deployed environment, consisting
 *                    of one or more DNS labels followed by a TLD. Non-editable once the environment is created.
 */
export interface EnvironmentRequest {
  name: string;
  dnsFqdn?: string;
}

/**
 * Describes the server response from an {@link EnvironmentRequest}.
 */
export interface EnvironmentResponse {
  id: string;
  name: string;
  dnsFqdn: string;
  status: EnvironmentStatus;
  tier: EnvironmentTier;
  createdAt: string;
  createdBy: string;
  lastModifiedAt?: string;
  lastModifiedBy?: string;
}
