import { StandardAction } from '@nx-workspace/shared/models';
import { CreateUserRequest, UpdateUserRequest, User } from '@software-platforms/tenant-manager-ui/models';

type MetaData = {
  id?: string;
  formData?: CreateUserRequest | UpdateUserRequest;
  sortKey?: string;
  sortDirection?: string;
  token?: string;
};

export type UserAction = StandardAction<User[], MetaData>;

export enum UserActionType {
  FETCH_USERS = 'users/FETCH_USERS',
  FETCH_USERS_SUCCEEDED = 'users/FETCH_USERS_SUCCEEDED',
  FETCH_USERS_FAILED = 'users/FETCH_USERS_FAILED',
  FETCH_USER = 'users/FETCH_USER',
  FETCH_USER_SUCCEEDED = 'users/FETCH_USER_SUCCEEDED',
  FETCH_USER_FAILED = 'users/FETCH_USER_FAILED',
  CREATE_USER = 'users/CREATE_USER',
  CREATE_USER_SUCCEEDED = 'users/CREATE_USER_SUCCEEDED',
  CREATE_USER_FAILED = 'users/CREATE_USER_FAILED',
  UPDATE_USER = 'users/UPDATE_USER',
  UPDATE_USER_SUCCEEDED = 'users/UPDATE_USER_SUCCEEDED',
  UPDATE_USER_FAILED = 'users/UPDATE_USER_FAILED',
  DELETE_USER = 'users/DELETE_USER',
  DELETE_USER_SUCCEEDED = 'users/DELETE_USER_SUCCEEDED',
  DELETE_USER_FAILED = 'users/DELETE_USER_FAILED',
  DEACTIVATE_USER = 'users/DEACTIVATE_USER',
  DEACTIVATE_USER_SUCCEEDED = 'users/DEACTIVATE_USER_SUCCEEDED',
  DEACTIVATE_USER_FAILED = 'users/DEACTIVATE_USER_FAILED',
  REACTIVATE_USER = 'users/REACTIVATE_USER',
  REACTIVATE_USER_SUCCEEDED = 'users/REACTIVATE_USER_SUCCEEDED',
  REACTIVATE_USER_FAILED = 'users/REACTIVATE_USER_FAILED',
  SORT_LIST = 'users/SORT_LIST',
}

/* ---------- Action Creators ---------- */

export const UserActions = {
  // Fetch resource list
  fetchUsers: (): UserAction => ({
    type: UserActionType.FETCH_USERS,
  }),
  fetchUsersSucceeded: (payload: User[]): UserAction => ({
    type: UserActionType.FETCH_USERS_SUCCEEDED,
    payload,
  }),
  fetchUsersFailed: (error: any): UserAction => ({
    type: UserActionType.FETCH_USERS_FAILED,
    error,
  }),

  // Fetch resource
  fetchUser: (id: string): UserAction => ({
    type: UserActionType.FETCH_USER,
    meta: { id },
  }),
  fetchUserSucceeded: (id: string, payload: User[]): UserAction => ({
    type: UserActionType.FETCH_USER_SUCCEEDED,
    meta: { id },
    payload,
  }),
  fetchUserFailed: (id: string, error: any): UserAction => ({
    type: UserActionType.FETCH_USER_FAILED,
    meta: { id },
    error,
  }),

  // Send invite
  createUser: (formData: CreateUserRequest): UserAction => ({
    type: UserActionType.CREATE_USER,
    meta: { formData },
  }),
  createUserSucceeded: (formData: CreateUserRequest, payload: User[]): UserAction => ({
    type: UserActionType.CREATE_USER_SUCCEEDED,
    meta: { formData },
    payload,
  }),
  createUserFailed: (formData: CreateUserRequest, error: any): UserAction => ({
    type: UserActionType.CREATE_USER_FAILED,
    meta: { formData },
    error,
  }),

  // Update user
  updateUser: (id: string, formData: UpdateUserRequest): UserAction => ({
    type: UserActionType.UPDATE_USER,
    meta: { id, formData },
  }),
  updateUserSucceeded: (id: string, formData: UpdateUserRequest, payload: User[]): UserAction => ({
    type: UserActionType.UPDATE_USER_SUCCEEDED,
    meta: { id, formData },
    payload,
  }),
  updateUserFailed: (id: string, formData: UpdateUserRequest, error: any): UserAction => ({
    type: UserActionType.UPDATE_USER_FAILED,
    meta: { id, formData },
    error,
  }),

  // Delete user
  deleteUser: (id: string): UserAction => ({
    type: UserActionType.DELETE_USER,
    meta: { id },
  }),
  deleteUserSucceeded: (id: string): UserAction => ({
    type: UserActionType.DELETE_USER_SUCCEEDED,
    meta: { id },
  }),
  deleteUserFailed: (id: string, error: any): UserAction => ({
    type: UserActionType.DELETE_USER_FAILED,
    meta: { id },
    error,
  }),

  // Deactivate user
  deactivateUser: (id: string): UserAction => ({
    type: UserActionType.DEACTIVATE_USER,
    meta: { id },
  }),
  deactivateUserSucceeded: (id: string): UserAction => ({
    type: UserActionType.DEACTIVATE_USER_SUCCEEDED,
    meta: { id },
  }),
  deactivateUserFailed: (id: string, error: any): UserAction => ({
    type: UserActionType.DEACTIVATE_USER_FAILED,
    meta: { id },
    error,
  }),

  // Reactivate user
  reactivateUser: (id: string): UserAction => ({
    type: UserActionType.REACTIVATE_USER,
    meta: { id },
  }),
  reactivateUserSucceeded: (id: string): UserAction => ({
    type: UserActionType.REACTIVATE_USER_SUCCEEDED,
    meta: { id },
  }),
  reactivateUserFailed: (id: string, error: any): UserAction => ({
    type: UserActionType.REACTIVATE_USER_FAILED,
    meta: { id },
    error,
  }),

  // Other actions
  sort: (sortKey: string, sortDirection: string, payload: User[]): UserAction => ({
    type: UserActionType.SORT_LIST,
    meta: { sortDirection, sortKey },
    payload,
  }),
};
