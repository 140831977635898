import { useAuth0 } from '@auth0/auth0-react';
import { noAccess, PkiIcon } from '@software-platforms/design-system-icons';
import '@software-platforms/design-system-styles';
import React from 'react';
import { PkiButton } from '../pki-button/pki-button';

export type PkiNoAccessProps = {
  pageTitle: string;
  message: string;
  loginBtnLabel: string;
};

export const PkiNoAccess: React.FunctionComponent<React.PropsWithChildren<PkiNoAccessProps>> = (props) => {
  const { loginBtnLabel, message, pageTitle } = props;
  const { error, loginWithRedirect } = useAuth0();

  return (
    <div className="single-page">
      <div className="header-container">
        <div className="alert-icon">
          <PkiIcon icon={noAccess} />
        </div>
        <h1>{pageTitle}</h1>
      </div>
      <p>{error?.message || message}</p>
      <p />
      <div>
        <PkiButton variant="primary" label={loginBtnLabel} onClick={() => loginWithRedirect()} />
      </div>
    </div>
  );
};
PkiNoAccess.displayName = 'PkiNoAccess';
