import {
  PkiDialog,
  PkiDialogContent,
  PkiDialogFooter,
  PkiDialogHeader,
} from '@software-platforms/design-system-components';
import { Environment } from '@software-platforms/tenant-manager-ui/models';
import React from 'react';
import { useTranslation } from 'react-i18next';

type OwnProps = {
  currentResource: Environment | null;
  onClose: (isConfirmed: boolean) => void;
  open: boolean;
  numTenants: number;
};

export const DeleteEnvironmentModal: React.FunctionComponent<OwnProps> = (props) => {
  const { open, numTenants } = props;
  const { t } = useTranslation();

  return (
    <PkiDialog disableBackdropClick onClose={props.onClose} open={open} size="sm" type="warning">
      <PkiDialogHeader type="warning" title={t('environments.confirmDelete.title')} />
      <PkiDialogContent>
        {numTenants === 0 ? (
          <>
            <div>{t('environments.confirmDelete.message1')}</div>
            <div>{t('environments.confirmDelete.noTenants')}</div>
          </>
        ) : (
          <>
            <div>{t('environments.confirmDelete.message2', { numTenants })}</div>{' '}
            <div>{t('environments.confirmDelete.continue')}</div>
          </>
        )}
      </PkiDialogContent>
      <PkiDialogFooter
        confirmation
        type="warning"
        onClose={props.onClose}
        cancelBtnLabel={t('pki:form.cancel')}
        confirmBtnLabel={t('environments.confirmDelete.confirmBtnLabel')}
      />
    </PkiDialog>
  );
};
DeleteEnvironmentModal.displayName = 'DeleteEnvironmentModal';
