import { DropDownOption } from '@nx-workspace/shared/models';
import {
  FormControl,
  PkiSelect,
  PkiSelectOption,
  showInvalidState,
} from '@software-platforms/design-system-components';
import { personOutline, PkiIcon, warning } from '@software-platforms/design-system-icons';
import { UserForm, UserRole } from '@software-platforms/tenant-manager-ui/models';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import { UserViewMode } from '../users';

export const ROLE_OPTIONS: DropDownOption[] = [
  {
    value: UserRole.ADMINISTRATOR,
    label: 'role.administrator',
    icon: <PkiIcon icon={personOutline} />,
    iconSize: '16px',
  },
  { value: UserRole.USER, label: 'role.user', icon: <PkiIcon icon={personOutline} />, iconSize: '16px' },
];

/**
 * Returns the locale key for the given email form control error, or an empty string if no error exists.
 * @param control
 */
const getEmailError = (control: FormControl): string => {
  if (control.errors) {
    if (control.errors.required) {
      return 'requiredEmail';
    } else if (control.errors.invalid) {
      return 'invalidEmail';
    } else if (control.errors.duplicate) {
      return 'duplicateEmail';
    }
  }
  return '';
};

/* ---------- Component Definition ---------- */

type OwnProps = {
  form: UserForm;
  isProcessing?: boolean;
  onBlur: (controlName: string) => void;
  onChange: (controlName: string, newValue: any) => void;
  viewMode: UserViewMode;
};

export const UserEntry: React.FunctionComponent<OwnProps> = (props) => {
  const { form, isProcessing, viewMode } = props;
  const { t } = useTranslation();
  const isReadOnly = viewMode === 'view';

  if (!form) {
    return null;
  }

  return (
    <div className="form-body">
      <div className="form-group">
        <label htmlFor="firstName" className={cx({ required: !isReadOnly })}>
          {t('users.form.firstName')}
        </label>
        <div
          className={cx('form-control', {
            disabled: isProcessing || isReadOnly,
            invalid: showInvalidState(form.firstName, isReadOnly),
          })}
        >
          <input
            type="text"
            id="firstName"
            autoComplete="off"
            autoFocus
            disabled={isProcessing}
            onBlur={() => props.onBlur('firstName')}
            onChange={(event) => props.onChange('firstName', event.target.value)}
            readOnly={isReadOnly}
            value={form.firstName.value}
          />
        </div>
        <div className="form-message">
          {showInvalidState(form.firstName, isReadOnly) && (
            <div className="error">
              <PkiIcon icon={warning} />
              <span>{t('users.form.requiredFirstName')}</span>
            </div>
          )}
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="lastName" className={cx({ required: !isReadOnly })}>
          {t('users.form.lastName')}
        </label>
        <div
          className={cx('form-control', {
            disabled: isProcessing || isReadOnly,
            invalid: showInvalidState(form.lastName, isReadOnly),
          })}
        >
          <input
            type="text"
            id="lastName"
            autoComplete="off"
            disabled={isProcessing}
            onBlur={() => props.onBlur('lastName')}
            onChange={(event) => props.onChange('lastName', event.target.value)}
            readOnly={isReadOnly}
            value={form.lastName.value}
          />
        </div>
        <div className="form-message">
          {showInvalidState(form.lastName, isReadOnly) && (
            <div className="error">
              <PkiIcon icon={warning} />
              <span>{t('users.form.requiredLastName')}</span>
            </div>
          )}
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="email" className={cx({ required: !isReadOnly })}>
          {t('users.form.email')}
        </label>
        <div
          className={cx('form-control', {
            disabled: isProcessing || isReadOnly || viewMode === 'edit',
            invalid: showInvalidState(form.email, isReadOnly),
          })}
        >
          <input
            type="email"
            id="email"
            autoComplete="off"
            disabled={isProcessing}
            onBlur={() => props.onBlur('email')}
            onChange={(event) => props.onChange('email', event.target.value)}
            readOnly={isReadOnly || viewMode === 'edit'}
            value={form.email.value}
          />
        </div>
        <div className="form-message">
          {showInvalidState(form.email, isReadOnly) && (
            <div className="error">
              <PkiIcon icon={warning} />
              <span>{t(`users.form.${getEmailError(form.email)}`)}</span>
            </div>
          )}
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="role" className={cx({ required: !isReadOnly })}>
          {t('users.form.role')}
        </label>
        <PkiSelect
          className={cx({ invalid: showInvalidState(form.role, isReadOnly) })}
          name="role"
          disabled={isProcessing || isReadOnly}
          onBlur={() => props.onBlur('role')}
          onChange={(event: React.ChangeEvent<HTMLElement>, child: any) => props.onChange('role', child)}
          placeholder={t('pki:form.selectPlaceholder')}
          readonly={isReadOnly}
          value={form.role.value}
        >
          {ROLE_OPTIONS.map((item) => (
            <PkiSelectOption
              key={uuidV4().substring(0, 8)}
              iconSize={item.iconSize}
              icon={item.icon}
              value={item.value}
            >
              {t(item.label)}
            </PkiSelectOption>
          ))}
        </PkiSelect>
        <div className="form-message">
          {showInvalidState(form.role, isReadOnly) && (
            <div className="error">
              <PkiIcon icon={warning} />
              <span>{t('users.form.requiredRole')}</span>
            </div>
          )}
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="division" className={cx({ disabled: isProcessing })}>
          {t('users.form.division')}
        </label>
        <div className={cx('form-control', { disabled: isProcessing || isReadOnly })}>
          <input
            type="text"
            id="division"
            autoComplete="off"
            disabled={isProcessing || isReadOnly}
            onBlur={() => props.onBlur('division')}
            onChange={(event) => props.onChange('division', event.target.value)}
            readOnly={isReadOnly}
            value={form.division.value}
          />
        </div>
        <div className="form-message" />
      </div>

      <div className="form-group">
        <label htmlFor="department" className={cx({ disabled: isProcessing })}>
          {t('users.form.department')}
        </label>
        <div className={cx('form-control', { disabled: isProcessing || isReadOnly })}>
          <input
            type="text"
            id="department"
            autoComplete="off"
            disabled={isProcessing || isReadOnly}
            onBlur={() => props.onBlur('department')}
            onChange={(event) => props.onChange('department', event.target.value)}
            readOnly={isReadOnly}
            value={form.department.value}
          />
        </div>
        <div className="form-message" />
      </div>
    </div>
  );
};
UserEntry.displayName = 'UserEntry';
