import {
  PkiDialog,
  PkiDialogContent,
  PkiDialogFooter,
  PkiDialogHeader,
} from '@software-platforms/design-system-components';
import { Subscription, Tenant, TenantQueryRequest } from '@software-platforms/tenant-manager-ui/models';
import { ServiceFactory } from '@software-platforms/tenant-manager-ui/services';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './activation-subscription-modal.module.scss';

type OwnProps = {
  currentResource: Subscription;
  onClose: (isConfirmed: boolean) => void;
  open: boolean;
  operation: string;
  tenantList?: Tenant[];
};

export const ActivationSubscriptionModal: React.FunctionComponent<OwnProps> = (props) => {
  const { currentResource, open, operation } = props;
  const { t } = useTranslation();

  const [tenants, setTenants] = useState<Tenant[]>([]);
  useEffect(() => {
    if (currentResource) {
      const query: TenantQueryRequest = { subscriptionId: currentResource.id, attributesToGet: 'id,name' };
      ServiceFactory.getServices()
        .tenantService.fetchTenants(query)
        .subscribe({
          next: (list) => setTenants(list),
        });
    }
  }, [currentResource]);

  return (
    <PkiDialog disableBackdropClick onClose={props.onClose} open={open} size="sm" type="warning">
      <PkiDialogHeader type="warning" title={t(`subscriptions.${operation}.title`)} />
      <PkiDialogContent>
        <div>{t(`subscriptions.${operation}.message1`)}</div>
        <div className={styles.dialogList}>
          {tenants?.length > 0 ? (
            tenants.map((e) => <div key={e.id}>{e.tenantName}</div>)
          ) : (
            <div>{t(`subscriptions.${operation}.noTenants`)}</div>
          )}
        </div>
        <div>{t(`subscriptions.${operation}.message2`)}</div>
      </PkiDialogContent>
      <PkiDialogFooter
        confirmation
        type="warning"
        onClose={props.onClose}
        cancelBtnLabel={t('pki:form.cancel')}
        confirmBtnLabel={t(`subscriptions.${operation}BtnLabel`)}
      />
    </PkiDialog>
  );
};
ActivationSubscriptionModal.displayName = 'ActivationSubscriptionModal';
