import { DateRange } from '@mui/lab';
import { DateRangeOption } from '@software-platforms/design-system-components';
import { DateTime } from 'luxon';
import { getI18n } from 'react-i18next';

export const useDatePicker = (): {
  getInitialDateRange: (defaultDaysBeforeToday: number) => DateRange<DateTime>;
  getDateRangeOptions: () => DateRangeOption[];
} => {
  const getInitialDateRange = (defaultDaysBeforeToday: number): DateRange<DateTime> => {
    const start = DateTime.now().startOf('day');
    const end = DateTime.now().endOf('day');
    const last30start = start!.minus({ days: defaultDaysBeforeToday });
    return [last30start, end];
  };

  /**
   * Returns an array of options for the DateRange picker.
   */
  const getDateRangeOptions = (): DateRangeOption[] => {
    const start = DateTime.now().startOf('day');
    const end = DateTime.now().endOf('day');
    const { t } = getI18n();
    return [
      { value: 'today', label: t('dateRange.today'), start: start!, end: end! },
      { value: 'last7', label: t('dateRange.last7'), start: start!.minus({ days: 7 }), end: end! },
      { value: 'last30', label: t('dateRange.last30'), start: start!.minus({ days: 30 }), end: end! },
      { value: 'last60', label: t('dateRange.last60'), start: start!.minus({ days: 60 }), end: end! },
      { value: 'last90', label: t('dateRange.last90'), start: start!.minus({ days: 90 }), end: end! },
      { value: 'custom', label: t('dateRange.custom'), start: start!, end: end! }, // Initial value is today
      { value: 'all', label: t('dateRange.all'), start: DateTime.fromMillis(0), end: end! },
    ];
  };

  return { getInitialDateRange, getDateRangeOptions };
};
