import { FormControl, ValidationErrors, Validator } from '@software-platforms/design-system-components';

/**
 * Validates that the given form control contains a password that meets a strength that meets the following
 * characteristics:
 * - Non-empty
 * - Contain lower-case letters, upper-case letters, and digits
 * - Contain at least one special character (~!@#$%^&*)
 * @param control
 */
export const password: Validator = (control: FormControl): ValidationErrors | null => {
  if (control && control.value) {
    // Use lookaheads
    const ptn = /^(?=.*[a-z]+.*)(?=.*[A-Z]+.*)(?=.*[0-9]+.*)(?=.*[~!@#$%^&*]+.*).{8,}$/g;
    if (!ptn.test(control.value)) {
      return { invalid: true };
    }
  }
  return null;
};

/**
 * Validates that the given form control contains a partially qualified domain name consisting of one or more DNS
 * labels and a final top-level-domain.
 * @param control
 */
export const pqdn: Validator = (control: FormControl): ValidationErrors | null => {
  if (control && control.value) {
    const labelPtn = /^[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/i;
    const tldPtn = /^[a-zA-Z]{2,6}$/i;
    const parts = String(control.value).split('.');

    const result = parts.every((label, index) => {
      if (index < parts.length - 1) {
        return labelPtn.test(label);
      } else {
        return tldPtn.test(label);
      }
    });
    if (!result) {
      return { invalid: true };
    }
  }
  return null;
};
