import { peopleOutline, PkiIcon } from '@software-platforms/design-system-icons';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './empty-user.module.scss';

export const EmptyUser: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <section className={cx('item', styles.empty)}>
      <div className={styles.captionContainer}>
        <PkiIcon icon={peopleOutline} />
        <div className="caption">{t('users.action')}</div>
      </div>
    </section>
  );
};
EmptyUser.displayName = 'EmptyUser';
