import { infoOutline, PkiIcon, warning } from '@software-platforms/design-system-icons';
import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React from 'react';
import './pki-dialog.scss';

export type PkiDialogHeaderProps = {
  title?: string;
  type: 'warning' | 'info';
  headerIcon?: React.ReactNode;
};

/**
 * Renders a modal dialog header.
 * @param props
 * @constructor
 */
export const PkiDialogHeader: React.FunctionComponent<React.PropsWithChildren<PkiDialogHeaderProps>> = (props) => {
  const { children, title, type = 'warning', headerIcon } = props;
  return (
    <div id="pki-dialog-header" className="pki-dialog-header">
      <div className={cx('pki-dialog-header-icon', type)}>
        {headerIcon ? headerIcon : <PkiIcon icon={type === 'info' ? infoOutline : warning} />}
      </div>
      {title && <div className="pki-dialog-header-title">{title}</div>}
      {children && <div>{children}</div>}
    </div>
  );
};
PkiDialogHeader.displayName = 'PkiDialogHeader';

export default PkiDialogHeader;
