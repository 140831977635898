import { PkiAvatar } from '@software-platforms/design-system-components';
import cx from 'classnames';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { ProfileElementProps, ProfileElementText, ProfileFeatureProps } from '../models';
import styles from './profile-details.module.scss';
import { ProfileElement } from './profile-element';

const elementsComparator = (a: ProfileElementProps, b: ProfileElementProps) => a.order - b.order;

const DEFAULT_RESOURCES: ProfileElementText[] = [
  { prop: 'editBtn', key: 'pki:userProfile.details.editBtnLabel', default: 'Edit' },
  { prop: 'cancelBtn', key: 'pki:userProfile.details.cancelBtnLabel', default: 'Cancel' },
  { prop: 'saveBtn', key: 'pki:userProfile.details.saveBtnLabel', default: 'Save' },
];

/* ---------- Component Definition ---------- */

export const ProfileDetails: React.FunctionComponent<ProfileFeatureProps> = (props) => {
  const { currentUser, customElements = [], i18n, onUpdateCurrentUser } = props;
  if (!currentUser) {
    return null;
  }

  const defaultElements: ProfileElementProps[] = [
    {
      order: 1,
      appUserProperty: 'givenName',
      authUserProperty: 'given_name',
      controlName: 'givenName',
      currentUser,
      i18n,
      isRequired: true,
      onUpdateCurrentUser,
      resources: [
        { prop: 'label', key: 'pki:userProfile.details.firstName', default: 'First Name' },
        { prop: 'required', key: 'pki:userProfile.details.requiredFirstName', default: 'A first name is required' },
        ...DEFAULT_RESOURCES,
      ],
    },
    {
      order: 2,
      appUserProperty: 'familyName',
      authUserProperty: 'family_name',
      controlName: 'familyName',
      currentUser,
      i18n,
      isRequired: true,
      onUpdateCurrentUser,
      resources: [
        { prop: 'label', key: 'pki:userProfile.details.lastName', default: 'Last Name' },
        { prop: 'required', key: 'pki:userProfile.details.requiredLastName', default: 'A last name is required' },
        ...DEFAULT_RESOURCES,
      ],
    },
    {
      order: 3,
      appUserProperty: 'phoneNumber',
      authUserProperty: 'customClaims.user.phoneNumber',
      controlName: 'phoneNumber',
      currentUser,
      i18n,
      onUpdateCurrentUser,
      resources: [
        { prop: 'label', key: 'pki:userProfile.details.phoneNumber', default: 'Phone Number' },
        ...DEFAULT_RESOURCES,
      ],
    },
  ];

  // Merge any consumer-defined additional form elements or customizations to the default form elements.
  const elements = customElements.reduce(
    (acc, curr) => {
      const index = acc.findIndex((e) => e.controlName === curr.controlName);
      if (index < 0) {
        // Add the new form element
        acc.push(curr);
      } else {
        // Merge the customization into the default form element
        acc[index] = {
          ...acc[index],
          ...curr,
          resources: [...(acc[index].resources || []), ...(curr.resources || [])],
        };
      }
      return acc;
    },
    [...defaultElements]
  );

  const title = i18n ? i18n.t('pki:userProfile.details.title') : 'User Information';
  const photoLabel = i18n ? i18n.t('pki:userProfile.details.photo') : 'Profile Photo';

  return (
    <section className={styles.userProfile}>
      <div className={styles.title}>{title}</div>

      <div className={cx('form-group', styles.pictureContainer)}>
        <label htmlFor="">{photoLabel}</label>
        <PkiAvatar className={styles.picture} picture={currentUser?.authUser?.picture} />
      </div>

      <div className={styles.formContainer}>
        {elements.sort(elementsComparator).map((elementProps) => (
          <ProfileElement key={uuidV4().substring(0, 8)} {...elementProps} />
        ))}
      </div>
    </section>
  );
};
ProfileDetails.displayName = 'ProfileDetails';
