import { LocaleResource } from '@nx-workspace/shared/lang';
import en from './en.json';
import es from './es.json';
import de from './de.json';
import fi from './fi.json';
import jp from './ja.json';

export const resources: LocaleResource[] = [
  { name: 'common', lang: 'en', props: en },
  { name: 'common', lang: 'de', props: de },
  { name: 'common', lang: 'es', props: es },
  { name: 'common', lang: 'fi', props: fi },
  { name: 'common', lang: 'jp', props: jp },
];
