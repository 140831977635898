import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { ReactChild, ReactFragment, useEffect, useState } from 'react';
import { isDescendant } from '../pki-utils';
import { PkiMenuList } from './pki-menu-list';
import './pki-menu.scss';

type MenuEvent = React.MouseEvent | React.KeyboardEvent;

/* ---------- Component Definition ---------- */

export type PkiMenuProps = {
  classNames?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconSize?: string;
  label?: string;
  name?: string;
  onClose?: { bivarianceHack(event: Object): void }['bivarianceHack'];
  onOpen?: (event: React.ChangeEvent<{}>) => void;
  readonly?: boolean;
};

export const PkiMenu: React.FunctionComponent<React.PropsWithChildren<PkiMenuProps>> = (props) => {
  const { children, classNames, disabled, icon, iconSize, label, name, readonly } = props;

  /* ---------- Menu Handling ---------- */

  const [openState, setOpenState] = useState<boolean>(false);
  const handleMenuEvent = (flag: boolean, event: MenuEvent) => {
    if (flag) {
      if (props.onOpen) {
        props.onOpen(event);
      }
    } else if (props.onClose) {
      props.onClose(event);
    }
    setOpenState(flag);
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.button !== 0) {
      return;
    }
    handleMenuEvent(true, event);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!readonly) {
      const validKeys = [' ', 'ArrowUp', 'ArrowDown', 'Enter'];
      if (validKeys.indexOf(event.key) !== -1) {
        event.preventDefault();
        handleMenuEvent(true, event);
      }
    }
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    handleMenuEvent(false, event);
  };

  // Listen for mouse clicks outside the menu.
  useEffect(() => {
    const clickOutside = (event: any) => {
      const elem = document.getElementById(`dropdown-${name}`);
      if (elem && !isDescendant(elem, event.target)) {
        handleClose(event);
      }
    };
    window.addEventListener('click', clickOutside);
    return () => {
      window.removeEventListener('click', clickOutside);
    };
  });

  /* ---------- Menu Item Handling ---------- */

  const handleItemClick =
    (child: ReactChild | ReactFragment): Function =>
    (event: React.MouseEvent<HTMLElement>) => {
      // @ts-ignore
      if (child.props.disabled) {
        return;
      }
      handleMenuEvent(false, event);

      // @ts-ignore
      if (child.props.onClick) {
        // @ts-ignore
        child.props.onClick(event);
      }
    };

  // Fetch the children prop as an array. We add more props and behaviors below.
  const childrenArray = React.Children.toArray(children);
  const items = childrenArray.map((child: ReactChild | ReactFragment) => {
    if (!React.isValidElement(child)) {
      return null;
    }
    // Return a clone of the child with addition props
    return React.cloneElement<any>(child, {
      onClick: handleItemClick(child),
      role: 'option',
    });
  });

  // Compute the icon dimensions
  let iconHeight;
  let iconWidth;
  if (iconSize) {
    const parts = iconSize?.split(' ');
    iconHeight = parts[0];
    iconWidth = parts?.length > 1 ? parts[1] : parts[0];
  }

  return (
    <div
      id={name}
      className={cx('pki-dropdown', classNames)}
      onKeyDown={handleKeyDown}
      onMouseDown={disabled || readonly ? undefined : handleMouseDown}
      role="button"
      aria-disabled={disabled ? 'true' : undefined}
      aria-expanded={openState ? 'true' : undefined}
      aria-haspopup="listbox"
    >
      <div id={`dropdown-${name}`} className={cx('pki-dropdown-button', disabled ? 'disabled' : undefined)}>
        {icon && (
          <div className="pki-btn-icon" style={{ height: iconHeight, width: iconWidth }}>
            {icon}
          </div>
        )}
        {label && <div className="pki-dropdown-label">{label}</div>}
      </div>
      <PkiMenuList name={name} onClose={handleClose} open={openState}>
        {items}
      </PkiMenuList>
    </div>
  );
};
PkiMenu.displayName = 'PkiMenu';

export default PkiMenu;
