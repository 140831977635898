import { StandardAction } from '@nx-workspace/shared/models';
import { Environment, EnvironmentRequest } from '@software-platforms/tenant-manager-ui/models';

type MetaData = {
  id?: string;
  formData?: EnvironmentRequest;
  force?: boolean;
};

export type EnvironmentAction = StandardAction<Environment[], MetaData>;

export enum EnvironmentActionType {
  FETCH_ENVIRONMENTS = 'environments/FETCH_ENVIRONMENTS',
  FETCH_ENVIRONMENTS_SUCCEEDED = 'environments/FETCH_ENVIRONMENTS_SUCCEEDED',
  FETCH_ENVIRONMENTS_FAILED = 'environments/FETCH_ENVIRONMENTS_FAILED',
  FETCH_ENVIRONMENT = 'environments/FETCH_ENVIRONMENT',
  FETCH_ENVIRONMENT_SUCCEEDED = 'environments/FETCH_ENVIRONMENT_SUCCEEDED',
  FETCH_ENVIRONMENT_FAILED = 'environments/FETCH_ENVIRONMENT_FAILED',
  CREATE_ENVIRONMENT = 'users/CREATE_ENVIRONMENT',
  CREATE_ENVIRONMENT_SUCCEEDED = 'users/CREATE_ENVIRONMENT_SUCCEEDED',
  CREATE_ENVIRONMENT_FAILED = 'users/CREATE_ENVIRONMENT_FAILED',
  UPDATE_ENVIRONMENT = 'users/UPDATE_ENVIRONMENT',
  UPDATE_ENVIRONMENT_SUCCEEDED = 'users/UPDATE_ENVIRONMENT_SUCCEEDED',
  UPDATE_ENVIRONMENT_FAILED = 'users/UPDATE_ENVIRONMENT_FAILED',
  DELETE_ENVIRONMENT = 'users/DELETE_ENVIRONMENT',
  DELETE_ENVIRONMENT_SUCCEEDED = 'users/DELETE_ENVIRONMENT_SUCCEEDED',
  DELETE_ENVIRONMENT_FAILED = 'users/DELETE_ENVIRONMENT_FAILED',
}

/* ---------- Action Creators ---------- */

export const EnvironmentActions = {
  // Fetch resource list
  fetchEnvironments: (): EnvironmentAction => ({
    type: EnvironmentActionType.FETCH_ENVIRONMENTS,
  }),
  fetchEnvironmentsSucceeded: (payload: Environment[]): EnvironmentAction => ({
    type: EnvironmentActionType.FETCH_ENVIRONMENTS_SUCCEEDED,
    payload,
  }),
  fetchEnvironmentsFailed: (error: any): EnvironmentAction => ({
    type: EnvironmentActionType.FETCH_ENVIRONMENTS_FAILED,
    error,
  }),

  // Fetch resource
  fetchEnvironment: (id: string): EnvironmentAction => ({
    type: EnvironmentActionType.FETCH_ENVIRONMENT,
    meta: { id },
  }),
  fetchEnvironmentSucceeded: (id: string, payload: Environment[]): EnvironmentAction => ({
    type: EnvironmentActionType.FETCH_ENVIRONMENT_SUCCEEDED,
    meta: { id },
    payload,
  }),
  fetchEnvironmentFailed: (id: string, error: any): EnvironmentAction => ({
    type: EnvironmentActionType.FETCH_ENVIRONMENT_FAILED,
    meta: { id },
    error,
  }),

  // Create resource
  createEnvironment: (formData: EnvironmentRequest): EnvironmentAction => ({
    type: EnvironmentActionType.CREATE_ENVIRONMENT,
    meta: { formData },
  }),
  createEnvironmentSucceeded: (formData: EnvironmentRequest, payload: Environment[]): EnvironmentAction => ({
    type: EnvironmentActionType.CREATE_ENVIRONMENT_SUCCEEDED,
    meta: { formData },
    payload,
  }),
  createEnvironmentFailed: (formData: EnvironmentRequest, error: any): EnvironmentAction => ({
    type: EnvironmentActionType.CREATE_ENVIRONMENT_FAILED,
    meta: { formData },
    error,
  }),

  // Update resource
  updateEnvironment: (id: string, formData: EnvironmentRequest): EnvironmentAction => ({
    type: EnvironmentActionType.UPDATE_ENVIRONMENT,
    meta: { id, formData },
  }),
  updateEnvironmentSucceeded: (
    id: string,
    formData: EnvironmentRequest,
    payload: Environment[]
  ): EnvironmentAction => ({
    type: EnvironmentActionType.UPDATE_ENVIRONMENT_SUCCEEDED,
    meta: { id, formData },
    payload,
  }),
  updateEnvironmentFailed: (id: string, formData: EnvironmentRequest, error: any): EnvironmentAction => ({
    type: EnvironmentActionType.UPDATE_ENVIRONMENT_FAILED,
    meta: { id, formData },
    error,
  }),

  // Delete resource
  deleteEnvironment: (id: string, force: boolean): EnvironmentAction => ({
    type: EnvironmentActionType.DELETE_ENVIRONMENT,
    meta: { id, force },
  }),
  deleteEnvironmentSucceeded: (id: string): EnvironmentAction => ({
    type: EnvironmentActionType.DELETE_ENVIRONMENT_SUCCEEDED,
    meta: { id },
  }),
  deleteEnvironmentFailed: (id: string, error: any): EnvironmentAction => ({
    type: EnvironmentActionType.DELETE_ENVIRONMENT_FAILED,
    meta: { id },
    error,
  }),
};
