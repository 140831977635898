import { axiosErrorHandler } from '@nx-workspace/shared/services';
import { Environment, EnvironmentRequest, EnvironmentResponse } from '@software-platforms/tenant-manager-ui/models';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { from, Observable } from 'rxjs';
import { EnvironmentService } from '../interfaces';
import { EnvironmentMapper } from '../mappers/environment.mapper';

/**
 * Concrete implementation of a {@link EnvironmentService} for Axios.
 */
export class AxiosEnvironmentService implements EnvironmentService {
  offboardConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'offboard:environment' } };
  onboardConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'onboard:environment' } };
  readConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'read:environment' } };
  writeConfig: AxiosRequestConfig = { headers: { 'x-auth-scope': 'write:environment' } };

  constructor(private readonly http: AxiosInstance) {}

  fetchEnvironments(): Observable<Environment[]> {
    const url = '/environments';
    return from(
      this.http
        .get<EnvironmentResponse[]>(url, this.readConfig)
        .then((response) => response.data.map((e) => EnvironmentMapper.from(e)))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  fetchEnvironment(id: string): Observable<Environment> {
    const url = `/environments/${id}`;
    return from(
      this.http
        .get<EnvironmentResponse>(url, this.readConfig)
        .then((response) => EnvironmentMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  createEnvironment(formData: EnvironmentRequest): Observable<Environment> {
    const url = '/environments/onboard';
    return from(
      this.http
        .post<EnvironmentResponse>(url, formData, this.onboardConfig)
        .then((response) => EnvironmentMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  updateEnvironment(id: string, formData: EnvironmentRequest): Observable<Environment> {
    const url = `/environments/${id}`;
    return from(
      this.http
        .patch<EnvironmentResponse>(url, formData, this.writeConfig)
        .then((response) => EnvironmentMapper.from(response.data))
        .catch((error) => axiosErrorHandler(error))
    );
  }

  deleteEnvironment(id: string, force: boolean): Observable<any> {
    const url = `/environments/${id}/offboard` + (force ? '?force=true' : '');
    return from(
      this.http
        .post<any>(url, {}, this.offboardConfig)
        .then(() => null) // The returned payload is an object { message: string }
        .catch((error) => axiosErrorHandler(error))
    );
  }
}
