import { isFormDirty, PkiButton, PkiDialog } from '@software-platforms/design-system-components';
import { Subscription, TenantForm } from '@software-platforms/tenant-manager-ui/models';
import cx from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TenantEntry } from '../tenant-entry/tenant-entry';
import styles from './tenant-edit.module.scss';

type OwnProps = {
  form?: TenantForm;
  isProcessing: boolean;
  onBlur: (controlName: string) => void;
  onChange: (controlName: string, newValue: any) => void;
  onSave: (event: React.MouseEvent) => void;
  subscriptions: Subscription[];
  viewMode: string;
};

export const TenantEdit: React.FunctionComponent<OwnProps> = (props) => {
  const { form, isProcessing, subscriptions, viewMode } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* ---------- Cancel Actions ---------- */

  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false);
  const handleCancel = (event: React.MouseEvent) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    if (isFormDirty(form)) {
      setOpenConfirmCancel(true);
    } else {
      navigate('/tenants');
    }
  };
  const handleConfirmCancel = (isConfirmed: any) => {
    setOpenConfirmCancel(false);
    if (typeof isConfirmed === 'boolean' && isConfirmed) {
      navigate('/tenants');
    }
  };

  return (
    <>
      <header>
        <div className="pki-page-title">{t('tenants.page.edit')}</div>
        <div className="pki-header-slot end">
          <PkiButton
            id="cancel-btn"
            label={t('pki:form.cancel')}
            onClick={handleCancel}
            size="small"
            variant="secondary"
          />
          <PkiButton id="save-btn" label={t('pki:form.save')} onClick={props.onSave} size="small" variant="primary" />
        </div>
      </header>
      <div className={cx('content-inner-container', styles.contentInnerContainer)}>
        <section className={styles.item}>
          <TenantEntry
            form={form}
            isProcessing={isProcessing}
            onBlur={props.onBlur}
            onChange={props.onChange}
            subscriptions={subscriptions}
            viewMode={viewMode}
          />
        </section>
      </div>
      {openConfirmCancel && (
        <PkiDialog
          confirmation
          message={t('pki:unsavedChanges.message')}
          onClose={handleConfirmCancel}
          open={openConfirmCancel}
          size="sm"
          title={t('pki:unsavedChanges.title')}
        />
      )}
    </>
  );
};
TenantEdit.displayName = 'TenantEdit';
