import { close, PkiIcon } from '@software-platforms/design-system-icons';
import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { useRef } from 'react';
import { renderIcon } from '../pki-utils';
import './pki-chip.scss';

export type PkiChipProps = {
  avatar?: React.ComponentType<React.PropsWithChildren<any>>;
  className?: string;
  leftDeleteIcon?: boolean;
  rightDeleteIcon?: boolean;
  disabled?: boolean;
  label?: string;
  onClick?: (event: React.MouseEvent) => void;
  onDelete?: React.EventHandler<any>;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onKeyUp?: (event: React.KeyboardEvent) => void;
  ref?: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
  type?: 'filled' | 'outline' | '';
  variant?: 'primary' | 'secondary' | '';
};

export const PkiChip: React.FunctionComponent<React.PropsWithChildren<PkiChipProps>> = (props) => {
  const {
    avatar,
    className,
    leftDeleteIcon,
    disabled,
    label,
    ref,
    rightDeleteIcon,
    style,
    type = 'filled',
    variant = '',
  } = props;
  const chipRef = useRef<HTMLDivElement>(null);
  const handleRef = ref || chipRef;

  let clickable = false;
  let canDelete = false;
  if (!disabled) {
    clickable = !!props.onClick;
    canDelete = !!(props.onDelete && (rightDeleteIcon || leftDeleteIcon));
  }

  const handleDeleteIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (props.onDelete) {
      props.onDelete(event);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.currentTarget === event.target && ['Backspace', 'Delete'].includes(event.key)) {
      event.preventDefault();
    }
    if (props.onKeyDown) {
      props.onKeyDown(event);
    }
  };
  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.currentTarget === event.target) {
      if (props.onDelete && ['Backspace', 'Delete'].includes(event.key)) {
        props.onDelete(event);
      } else if (event.key === 'Escape' && handleRef.current) {
        handleRef.current.blur();
      }
    }
    if (props.onKeyUp) {
      props.onKeyUp(event);
    }
  };

  return (
    <div
      className={cx('pki-chip', className, type, variant, { clickable })}
      onClick={clickable ? props.onClick : undefined}
      onKeyDown={clickable ? handleKeyDown : undefined}
      onKeyUp={clickable ? handleKeyUp : undefined}
      ref={handleRef}
      role={clickable || props.onDelete ? 'button' : undefined}
      style={style}
      tabIndex={clickable || props.onDelete ? 0 : undefined}
      aria-disabled={disabled ? true : undefined}
    >
      {leftDeleteIcon && (
        <span className="pki-chip-delete-icon-left" onClick={canDelete ? handleDeleteIconClick : undefined}>
          <PkiIcon icon={close} />
        </span>
      )}
      {avatar && <span className="pki-chip-avatar">{renderIcon(avatar)}</span>}
      <span>{label}</span>
      {rightDeleteIcon && (
        <span className="pki-chip-delete-icon-right" onClick={canDelete ? handleDeleteIconClick : undefined}>
          <PkiIcon icon={close} />
        </span>
      )}
    </div>
  );
};
PkiChip.displayName = 'PkiChip';
