import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React from 'react';

export type PkiMenuListProps = {
  classNames?: string;
  name?: string;
  onClose: { bivarianceHack(event: Object): void }['bivarianceHack'];
  open: boolean;
};

export const PkiMenuList: React.FunctionComponent<React.PropsWithChildren<PkiMenuListProps>> = (props) => {
  const { children, classNames, name, open } = props;

  return (
    <div
      id={`menu-${name}`}
      className={cx('pki-dropdown-menu', classNames, { open })}
      onClick={props.onClose}
      role="listbox"
    >
      {children}
    </div>
  );
};
PkiMenuList.displayName = 'PkiMenuList';

export default PkiMenuList;
