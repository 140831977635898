import React from 'react';

/**
 * Describes a drop-down option.
 * @property value      Any value
 * @property label      Locale-key
 * @property icon       Optional icon component
 * @property iconSize   Icon size (ex. "16px" or "12px 14px")
 */
export type DropDownOption = { value: any; label: string; icon?: React.ReactNode; iconSize?: string };

/**
 * Describes a drop-down option that includes a `name` mapping.
 * @property value      Any value
 * @property label      Locale-key
 * @property name       A string, typically used to map to another configuration object or enum
 */
export type MappedDropDownOption = DropDownOption & { name: string };

/**
 * Groups of drop-down options
 */
export type OptionGroup = {
  groupName: string;
  options: DropDownOption[];
};

/**
 * Find the group name for the selected option
 * @param option option to look for
 * @param groups groups of options to look through
 * @returns group name for the option
 */
export const getGroupName = (option: DropDownOption, groups: OptionGroup[]): string => {
  let retval = 'Not found';
  if (option && groups && groups.length > 0) {
    const grp = groups.find((e) => e.options.some((opt) => opt.value === option.value && opt.label === option.label));
    if (grp) {
      retval = grp.groupName;
    }
  }
  return retval;
};
