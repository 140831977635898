import { Tenant, TenantQueryRequest, TenantRequest } from '@software-platforms/tenant-manager-ui/models';
import { Observable } from 'rxjs';

export interface TenantService {
  /**
   * Returns a list of {@link Tenant} objects, or an empty list if none were found.
   * @param query  Optional TenantQueryRequest identifier
   */
  fetchTenants(query?: TenantQueryRequest): Observable<Tenant[]>;

  /**
   * Returns a {@link Tenant} object matching the given identifier, or null if none were found.
   */
  fetchTenant(id: string): Observable<Tenant>;

  /**
   * Creates a {@link Tenant} with the given information.
   * @param formData
   */
  createTenant(formData: TenantRequest): Observable<Tenant>;

  /**
   * Updates the {@link Tenant} matching the given identifier with the given information.
   * @param id
   * @param formData
   */
  updateTenant(id: string, formData: TenantRequest): Observable<Tenant>;

  /**
   * Deletes the {@link Tenant} matching the given identifier.
   * @param id
   */
  deleteTenant(id: string): Observable<any>;

  /**
   * Inactivates the {@link Tenant} matching the given identifier.
   * @param id
   */
  deactivateTenant(id: string): Observable<Tenant>;

  /**
   * Reactivates the {@link Tenant} matching the given identifier.
   * @param id
   */
  reactivateTenant(id: string): Observable<Tenant>;
}
