import { AxiosResponseTransformer } from 'axios';

/**
 * Describes a paginated list.
 * @property meta   MetaData information about the pagination
 * @property data   The list of entities
 * @type T The entity type
 */
export interface PkiPage<T> {
  meta: PkiPageMetaData;
  data: T[];
}

/**
 * Describes the metadata of a paginated list.
 * @property currentPage      The current page number
 * @property firstPage        The first page number
 * @property firstPageUrl     The URL of the first page
 * @property lastPage         The last page number
 * @property lastPageUrl      The URL of the last page
 * @property perPage          The number of entities to return per page
 * @property previousPageUrl  The URL of the previous page
 * @property total            The total number of records in the query
 */
export interface PkiPageMetaData {
  currentPage: number;
  firstPage: number;
  firstPageUrl: string;
  lastPage: number;
  lastPageUrl: string;
  nextPageUrl: string | null;
  perPage: number;
  previousPageUrl: string | null;
  total: number;
}

/**
 * Returns the parsed JSON data of an Axios HTTP response. Use this in the Axios request configuration.
 * This is a necessary configuration for the PkiPage response since we have multiple nested `data` properties.
 * @param response  The raw (un-transformed) Axios response
 * @type T          The entity type
 */
export const pageResponseTransformer = <T>(response: any): AxiosResponseTransformer => {
  try {
    return {
      ...response,
      data: JSON.parse(response) as T,
    } as AxiosResponseTransformer;
  } catch (error) {
    throw Error(`[pageTransformer] Error parsingJSON data - ${JSON.stringify(error)}`);
  }
};
