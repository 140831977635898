export interface Constraint {
  isValid: (value: any) => boolean;
}

export enum GraphType {
  DIRECTED = 'directed',
  UNDIRECTED = 'undirected',
}

export class ConstrainedGraph<V, C extends Constraint> {
  private adjacencies: Map<V, Map<V, C>> = new Map<V, Map<V, C>>();

  constructor(private type: GraphType = GraphType.UNDIRECTED) {}

  getValidDestinations(source: V, value: any): V[] {
    const result: V[] = [];
    const destinations = this.adjacencies.get(source);
    if (destinations) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [destination, constraint] of destinations.entries()) {
        if (constraint.isValid(value)) {
          result.push(destination);
        }
      }
    }
    return result;
  }

  addVertex(vertex: V) {
    if (!this.adjacencies.has(vertex)) {
      this.adjacencies.set(vertex, new Map<V, C>());
    }
  }

  getAdjacencies(): Map<V, Map<V, C>> {
    return new Map<V, Map<V, C>>(this.adjacencies);
  }

  removeVertex(vertex: V) {
    if (this.adjacencies.has(vertex)) {
      const adjacencies = this.adjacencies.get(vertex);
      adjacencies?.forEach((value: C, destination: V) => {
        this.removeEdge(vertex, destination);
      });
      this.adjacencies.delete(vertex);
    }
  }

  addEdge(source: V, destination: V, constraint: C) {
    this.adjacencies.get(source)?.set(destination, constraint);
    if (this.type === GraphType.UNDIRECTED) {
      this.adjacencies.get(destination)?.set(source, constraint);
    }
  }

  removeEdge(source: V, destination: V) {
    this.adjacencies.get(source)?.delete(destination);
    if (this.type === GraphType.UNDIRECTED) {
      this.adjacencies.get(destination)?.delete(source);
    }
  }

  dfs(target: V): V[] {
    const result: V[] = [];
    const visited: Set<V> = new Set<V>();

    const helper = (vertex: V) => {
      if (!vertex) {
        return null;
      }
      visited.add(vertex);
      result.push(vertex);
      const neighbors = this.adjacencies.get(vertex);
      if (neighbors) {
        // eslint-disable-next-line no-restricted-syntax
        for (const neighbor of neighbors.keys()) {
          if (!visited.has(neighbor)) {
            return helper(neighbor);
          }
        }
      }
    };

    helper(target);
    return result;
  }

  bfs(start: V): V[] {
    const queue: V[] = [start];
    const result: V[] = [];
    const visited: Set<V> = new Set<V>();

    while (queue.length) {
      const current = queue.shift();
      if (current) {
        visited.add(current);
        result.push(current);
        const neighbors = this.adjacencies.get(current);
        if (neighbors) {
          // eslint-disable-next-line no-restricted-syntax
          for (const neighbor of neighbors.keys()) {
            if (!visited.has(neighbor)) {
              visited.add(neighbor);
              queue.push(neighbor);
            }
          }
        }
      }
    }
    return result;
  }
}
