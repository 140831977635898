import { PkiButton } from '@software-platforms/design-system-components';
import { EnvironmentForm } from '@software-platforms/tenant-manager-ui/models';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentEntry } from '../environment-entry/environment-entry';
import { EnvironmentViewMode } from '../environments';
import styles from './environment-create.module.scss';

type OwnProps = {
  form: EnvironmentForm;
  isProcessing: boolean;
  onBlur: (controlName: string) => void;
  onChange: (controlName: string, newValue: any) => void;
  onCancel: (event: React.MouseEvent) => void;
  onCreate: (event: React.MouseEvent) => void;
  viewMode: EnvironmentViewMode;
};

export const EnvironmentCreate: React.FunctionComponent<OwnProps> = (props) => {
  const { form, isProcessing } = props;
  const { t } = useTranslation();

  return (
    <section className={cx('item', 'form', styles.item)}>
      <div className="form-header">
        <div className="form-title">{t('environments.form.title')}</div>
        <div className="form-actions">
          <PkiButton
            id="cancel-btn"
            label={t('pki:form.cancel')}
            onClick={props.onCancel}
            size="small"
            variant="secondary"
          />
          <PkiButton id="save-btn" label={t('pki:form.save')} onClick={props.onCreate} size="small" variant="primary" />
        </div>
      </div>
      <EnvironmentEntry
        form={form}
        isProcessing={isProcessing}
        onBlur={props.onBlur}
        onChange={props.onChange}
        viewMode="create"
      />
    </section>
  );
};
EnvironmentCreate.displayName = 'EnvironmentCreate';
