import { DateTime } from 'luxon';

export enum TenantStatus {
  DEPLOYING = 'deploying',
  DESTROYING = 'destroying',
  FAILED = 'failed',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

/**
 * Describes a tenant in the context of this application.
 * @property tenantName       The name of this tenant.
 * @property companyName      The company name pf this tenant. Does not have to match the subscription company name.
 * @property dnsLabel         The portion of the deployed application URL that identifies this tenant.
 * @property orderNumber      Reference to an external sales order number.
 * @property environmentId    Foreign key to the environment to which this tenant is deployed.
 * @property subscriptionId   Foreign key to the subscription to which this tenant is a member.
 * @property status           The deployment status of this tenant.
 */
export interface Tenant {
  id: string;
  tenantName: string;
  companyName: string;
  dnsLabel: string;
  description?: string;
  orderNumber?: string;
  environmentId: string;
  subscriptionId: string;
  status: TenantStatus;
  contactInfo: string;
  createdAt: DateTime;
  createdBy: string;
  lastModifiedAt: DateTime;
  lastModifiedBy: string;
}

/**
 * Describes the information needed by the server to create or update a tenant.
 * @property dnsLabel           The portion of the deployed application URL that identifies this tenant. To be
 *                              included in an instance of this request only when creating a new tenant.
 * @property firstTenantAdmin   Information about the tenant's primary user to be created and managed by the
 *                              application identify provider. To be included in an instance of this request only when
 *                              creating a new tenant.
 */
export interface TenantRequest {
  name?: string;
  companyName?: string;
  contactInfo?: string;
  description?: string;
  orderNumber?: string;
  environmentId?: string;
  subscriptionId?: string;
  status?: TenantStatus;
  dnsLabel?: string;
  firstTenantAdmin?: any;
}

/**
 * Describes the server response payload from a {@link TenantRequest}.
 */
export interface TenantResource {
  id: string;
  name: string;
  companyName: string;
  dnsLabel: string;
  description?: string;
  orderNumber?: string;
  environmentId: string;
  subscriptionId: string;
  status: TenantStatus;
  contactInfo: string;
  createdAt: string;
  createdBy: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
}

/**
 * Describes a query to retrieve tenants
 * @property subscriptionId      The subscriptionId of the tenants to fetch.
 * @property environmentId       The environmentId of the tenants to fetch.
 * @property attributesToGet     The tenant properties/attributes to get, comma delimited
 */

export interface TenantQueryRequest {
  subscriptionId?: string;
  environmentId?: string;
  attributesToGet?: string;
}
