import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { CSSProperties } from 'react';

export type PkiSelectMenuProps = {
  classNames?: string;
  name?: string;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
  style?: CSSProperties;
};

export const PkiSelectMenu: React.FunctionComponent<React.PropsWithChildren<PkiSelectMenuProps>> = (props) => {
  const { children, classNames, name, open, style } = props;
  return (
    <div
      id={`menu-${name}`}
      className={cx('pki-select-menu', classNames, { open })}
      onClick={props.onClose}
      role="listbox"
      style={style}
    >
      {children}
    </div>
  );
};
PkiSelectMenu.displayName = 'PkiSelectMenu';
