import { StandardAction } from '@nx-workspace/shared/models';
import { Subscription, SubscriptionRequest } from '@software-platforms/tenant-manager-ui/models';

type MetaData = {
  id?: string;
  formData?: SubscriptionRequest;
  sortKey?: string;
  sortDirection?: string;
  force?: boolean;
};

export type SubscriptionAction = StandardAction<Subscription[], MetaData>;

export enum SubscriptionActionType {
  FETCH_SUBSCRIPTIONS = 'subscriptions/FETCH_SUBSCRIPTIONS',
  FETCH_SUBSCRIPTIONS_SUCCEEDED = 'subscriptions/FETCH_SUBSCRIPTIONS_SUCCEEDED',
  FETCH_SUBSCRIPTIONS_FAILED = 'subscriptions/FETCH_SUBSCRIPTIONS_FAILED',
  FETCH_SUBSCRIPTION = 'subscriptions/FETCH_SUBSCRIPTION',
  FETCH_SUBSCRIPTION_SUCCEEDED = 'subscriptions/FETCH_SUBSCRIPTION_SUCCEEDED',
  FETCH_SUBSCRIPTION_FAILED = 'subscriptions/FETCH_SUBSCRIPTION_FAILED',
  CREATE_SUBSCRIPTION = 'subscriptions/CREATE_SUBSCRIPTION',
  CREATE_SUBSCRIPTION_SUCCEEDED = 'subscriptions/CREATE_SUBSCRIPTION_SUCCEEDED',
  CREATE_SUBSCRIPTION_FAILED = 'subscriptions/CREATE_SUBSCRIPTION_FAILED',
  UPDATE_SUBSCRIPTION = 'subscriptions/UPDATE_SUBSCRIPTION',
  UPDATE_SUBSCRIPTION_SUCCEEDED = 'subscriptions/UPDATE_SUBSCRIPTION_SUCCEEDED',
  UPDATE_SUBSCRIPTION_FAILED = 'subscriptions/UPDATE_SUBSCRIPTION_FAILED',
  DELETE_SUBSCRIPTION = 'subscriptions/DELETE_SUBSCRIPTION',
  DELETE_SUBSCRIPTION_SUCCEEDED = 'subscriptions/DELETE_SUBSCRIPTION_SUCCEEDED',
  DELETE_SUBSCRIPTION_FAILED = 'subscriptions/DELETE_SUBSCRIPTION_FAILED',
  DEACTIVATE_SUBSCRIPTION = 'subscriptions/DEACTIVATE_SUBSCRIPTION',
  DEACTIVATE_SUBSCRIPTION_SUCCEEDED = 'subscriptions/DEACTIVATE_SUBSCRIPTION_SUCCEEDED',
  DEACTIVATE_SUBSCRIPTION_FAILED = 'subscriptions/DEACTIVATE_SUBSCRIPTION_FAILED',
  REACTIVATE_SUBSCRIPTION = 'subscriptions/REACTIVATE_SUBSCRIPTION',
  REACTIVATE_SUBSCRIPTION_SUCCEEDED = 'subscriptions/REACTIVATE_SUBSCRIPTION_SUCCEEDED',
  REACTIVATE_SUBSCRIPTION_FAILED = 'subscriptions/REACTIVATE_SUBSCRIPTION_FAILED',
  FLUSH = 'subscriptions/FLUSH',
}

/* ---------- Action Creators ---------- */

export const SubscriptionActions = {
  // Fetch resource list
  fetchSubscriptions: (): SubscriptionAction => ({
    type: SubscriptionActionType.FETCH_SUBSCRIPTIONS,
  }),
  fetchSubscriptionsSucceeded: (payload: Subscription[]): SubscriptionAction => ({
    type: SubscriptionActionType.FETCH_SUBSCRIPTIONS_SUCCEEDED,
    payload,
  }),
  fetchSubscriptionsFailed: (error: any): SubscriptionAction => ({
    type: SubscriptionActionType.FETCH_SUBSCRIPTIONS_FAILED,
    error,
  }),

  // Fetch resource
  fetchSubscription: (id: string): SubscriptionAction => ({
    type: SubscriptionActionType.FETCH_SUBSCRIPTION,
    meta: { id },
  }),
  fetchSubscriptionSucceeded: (id: string, payload: Subscription[]): SubscriptionAction => ({
    type: SubscriptionActionType.FETCH_SUBSCRIPTION_SUCCEEDED,
    meta: { id },
    payload,
  }),
  fetchSubscriptionFailed: (id: string, error: any): SubscriptionAction => ({
    type: SubscriptionActionType.FETCH_SUBSCRIPTION_FAILED,
    meta: { id },
    error,
  }),

  // Create resource
  createSubscription: (formData: SubscriptionRequest): SubscriptionAction => ({
    type: SubscriptionActionType.CREATE_SUBSCRIPTION,
    meta: { formData },
  }),
  createSubscriptionSucceeded: (formData: SubscriptionRequest, payload: Subscription[]): SubscriptionAction => ({
    type: SubscriptionActionType.CREATE_SUBSCRIPTION_SUCCEEDED,
    meta: { formData },
    payload,
  }),
  createSubscriptionFailed: (formData: SubscriptionRequest, error: any): SubscriptionAction => ({
    type: SubscriptionActionType.CREATE_SUBSCRIPTION_FAILED,
    meta: { formData },
    error,
  }),

  // Update resource
  updateSubscription: (id: string, formData: SubscriptionRequest): SubscriptionAction => ({
    type: SubscriptionActionType.UPDATE_SUBSCRIPTION,
    meta: { id, formData },
  }),
  updateSubscriptionSucceeded: (
    id: string,
    formData: SubscriptionRequest,
    payload: Subscription[]
  ): SubscriptionAction => ({
    type: SubscriptionActionType.UPDATE_SUBSCRIPTION_SUCCEEDED,
    meta: { id, formData },
    payload,
  }),
  updateSubscriptionFailed: (id: string, formData: SubscriptionRequest, error: any): SubscriptionAction => ({
    type: SubscriptionActionType.UPDATE_SUBSCRIPTION_FAILED,
    meta: { id, formData },
    error,
  }),

  // Delete resource
  deleteSubscription: (id: string, force: boolean): SubscriptionAction => ({
    type: SubscriptionActionType.DELETE_SUBSCRIPTION,
    meta: { id, force },
  }),
  deleteSubscriptionSucceeded: (id: string): SubscriptionAction => ({
    type: SubscriptionActionType.DELETE_SUBSCRIPTION_SUCCEEDED,
    meta: { id },
  }),
  deleteSubscriptionFailed: (id: string, error: any): SubscriptionAction => ({
    type: SubscriptionActionType.DELETE_SUBSCRIPTION_FAILED,
    meta: { id },
    error,
  }),

  // Deactivate resource
  deactivateSubscription: (id: string): SubscriptionAction => ({
    type: SubscriptionActionType.DEACTIVATE_SUBSCRIPTION,
    meta: { id },
  }),
  deactivateSubscriptionSucceeded: (id: string, payload: Subscription[]): SubscriptionAction => ({
    type: SubscriptionActionType.DEACTIVATE_SUBSCRIPTION_SUCCEEDED,
    meta: { id },
    payload,
  }),
  deactivateSubscriptionFailed: (id: string, error: any): SubscriptionAction => ({
    type: SubscriptionActionType.DEACTIVATE_SUBSCRIPTION_FAILED,
    meta: { id },
    error,
  }),

  // Re-activate resource
  reactivateSubscription: (id: string): SubscriptionAction => ({
    type: SubscriptionActionType.REACTIVATE_SUBSCRIPTION,
    meta: { id },
  }),
  reactivateSubscriptionSucceeded: (id: string, payload: Subscription[]): SubscriptionAction => ({
    type: SubscriptionActionType.REACTIVATE_SUBSCRIPTION_SUCCEEDED,
    meta: { id },
    payload,
  }),
  reactivateSubscriptionFailed: (id: string, error: any): SubscriptionAction => ({
    type: SubscriptionActionType.REACTIVATE_SUBSCRIPTION_FAILED,
    meta: { id },
    error,
  }),

  // Other actions
  flush: (): SubscriptionAction => ({ type: SubscriptionActionType.FLUSH }),
};
