import { CurrentUserFormData } from '@nx-workspace/shared/models';
import { CreateUserRequest, UpdateUserRequest, User } from '@software-platforms/tenant-manager-ui/models';
import { Observable } from 'rxjs';

export interface UserService {
  /**
   * Returns a list of users, or an empty list if none were found.
   */
  fetchUsers(): Observable<User[]>;

  /**
   * Returns a {@link User} instance matching the given identifier, or null if no user could be found.
   * @param id
   */
  fetchUser(id: string): Observable<User>;

  /**
   * Creates a new {@link User} with the given data.
   * @param formData
   */
  createUser(formData: CreateUserRequest): Observable<User>;

  /**
   * Updates the {@link User} matching the given identifier with the given data.
   * @param id
   * @param formData
   */
  updateUser(id: string, formData: UpdateUserRequest): Observable<User>;

  updateCurrentUser(formData: CurrentUserFormData): Observable<any>;

  /**
   * Deletes the {@link User} matching the given identifier.
   * @param id
   */
  deleteUser(id: string): Observable<any>;

  /**
   * Deactivates the {@link User} matching the given identifier.
   * @param id
   */
  deactivateUser(id: string): Observable<User>;

  /**
   * Reactivates the {@link User} matching the given identifier.
   * @param id
   */
  reactivateUser(id: string): Observable<User>;
}
