import { Auth0ProviderOptions } from '@auth0/auth0-react/dist/auth0-provider';
import { PkiAuth0Provider } from '@nx-workspace/shared/auth-ui';
import { getInitOptions, i18n } from '@nx-workspace/shared/lang';
import { StandardAction } from '@nx-workspace/shared/models';
import { NotificationContainer, NotificationServiceFactory } from '@nx-workspace/shared/notification';
import { LocalStorageService } from '@nx-workspace/shared/services';
import { applyMiddleware, configureStore, ConfigureStoreOptions, EnhancedStore } from '@reduxjs/toolkit';
import { PkiSpinner } from '@software-platforms/design-system-components';
import { ErrorBoundary } from '@software-platforms/tenant-manager-ui/features';
import { ServiceFactory } from '@software-platforms/tenant-manager-ui/services';
import { AppState, INITIAL_STATE, rootEpic, rootReducer } from '@software-platforms/tenant-manager-ui/store';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { createEpicMiddleware } from 'redux-observable';
import { Application } from './app/application';
import { auth0ProviderOptions } from './auth0.config';
import { resources } from './locales';
import './styles.scss';

/* ---------- Localization ---------- */

const i18nOptions = getInitOptions(resources);
i18n.init(i18nOptions).then();

/* ---------- Error Handling ---------- */

// For errors that occur within the ErrorBoundary
const handleUnexpectedError = (error: Error, errorInfo?: React.ErrorInfo) => {
  if (process.env['NODE_ENV'] === 'development') {
    NotificationServiceFactory.getInstance().showReportableError(error, errorInfo?.componentStack);
  } else {
    NotificationServiceFactory.getInstance().showError(error);
  }
};
// For other errors
window.addEventListener('error', (event: Event & Partial<ErrorEvent>) => {
  event.stopImmediatePropagation();
  event.preventDefault();
  handleUnexpectedError(event.error ? event.error : event);
});

/* ---------- Authentication ---------- */

const pkiAuthOptions: Auth0ProviderOptions = {
  audience: auth0ProviderOptions.audience,
  clientId: auth0ProviderOptions.clientId,
  connection: auth0ProviderOptions.connection,
  domain: auth0ProviderOptions.domain,
  redirectUri: auth0ProviderOptions.redirectUri,
  tenantId: auth0ProviderOptions.tenantId,
};

/* ---------- State Management ---------- */

const epicMiddleware = createEpicMiddleware({ dependencies: ServiceFactory.getServices(pkiAuthOptions) });
const enhancer = applyMiddleware(epicMiddleware);
const storeOptions: ConfigureStoreOptions = {
  enhancers: [enhancer],
  preloadedState: INITIAL_STATE,
  reducer: rootReducer,
  // We add this middleware configuration as a development workaround for luxon DateTime objects, which are
  // identified as non-serializable by the Redux Toolkit. This is reported to be a non-issue in production but
  // produces console errors and warnings in development.
  // @see https://stackoverflow.com/questions/62241708/how-to-overcome-a-non-serializable-value-detection
  // @see https://redux-toolkit.js.org/api/immutabilityMiddleware
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
};
export const store: EnhancedStore<AppState, StandardAction<any, any>> = configureStore(storeOptions);
epicMiddleware.run(rootEpic);

/* ---------- Rendering ---------- */

/**
 * Returns the root body element to which this application is appended. This function queries the user's
 * LocalStorage and assigns the persisted theme setting to the HTML element, defaulting to the `light` theme.
 */
export const getRoot = (): HTMLElement => {
  const html: HTMLHtmlElement | null = document.querySelector('html');
  if (html && !html.dataset['theme']) {
    LocalStorageService.getItem('theme').subscribe((theme) => {
      if (theme) {
        html.dataset['theme'] = `theme-${theme}`;
      } else {
        const newTheme = 'light';
        LocalStorageService.setItem('theme', newTheme).subscribe(() => (html.dataset['theme'] = `theme-${newTheme}`));
      }
    });
  }
  return document.getElementById('root') as HTMLElement;
};

const container = getRoot();
const root = createRoot(container!);
root.render(
  <React.Suspense fallback={<PkiSpinner />}>
    <ErrorBoundary onError={handleUnexpectedError}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <PkiAuth0Provider i18n={i18n} {...pkiAuthOptions}>
            <Application />
          </PkiAuth0Provider>
        </BrowserRouter>
      </ReduxProvider>
    </ErrorBoundary>
    <NotificationContainer />
  </React.Suspense>
);
