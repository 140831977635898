import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React from 'react';
import './pki-dialog.scss';

export type PkiDialogContentProps = {
  className?: string;
};

export const PkiDialogContent: React.FunctionComponent<React.PropsWithChildren<PkiDialogContentProps>> = (props) => {
  const { children, className } = props;

  return (
    <div id="pki-dialog-content" className={cx('pki-dialog-content', className)}>
      {children}
    </div>
  );
};
PkiDialogContent.displayName = 'PkiDialogContent';
