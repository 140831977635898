/**
 * Describes the shape of a form control object capable of managing its own state and validity separate from
 * application state.
 * @property value      The user-entered value of the HTML input element. May be a primitive, object or array.
 * @property dirty      Flag to indicate that the form field value has changed from its persistent state. This
 *                      property is typically set to true in response to the HTML input element's 'onchange' event.
 * @property touched    Flag to indicate that the user has inspected the form field. This property is typically set to
 *                      true in response to the HTML input element's `onblur` event.
 * @property status     Internal flag indicating the FormControl object's state of validity.
 * @property errors     A {@link ValidationErrors} object containing a user-defined error name and value, or null if
 *                      the FormControl object value is valid.
 * @property validator  Optional function to execute validation.
 */
export interface FormControl {
  value: any;
  dirty: boolean;
  touched: boolean;
  status: 'valid' | 'invalid' | 'disabled';
  errors: ValidationErrors | null;
  validator: Validator | null;
}

/**
 * Describes a map of {@link FormControl} objects.
 */
export interface FormGroup {
  [key: string]: FormControl;
}

/**
 * A {@link FormControl} factory. Its only function initializes a form control object with a value and optional
 * validator(s) and sets the control's initial validity state.
 */
export const FormBuilder = {
  getInstance: (value: any, validator?: Validator | Validator[]): FormControl => {
    const newInstance: FormControl = {
      value,
      dirty: false,
      touched: false,
      status: FormStatus.VALID,
      errors: null,
      validator: mergeValidators(validator),
    };
    updateValidity(newInstance);
    return newInstance;
  },
};

/* ---------- Validation and Errors ---------- */

/**
 * Enumerates the validation state of a {@link FormControl}.
 */
export enum FormStatus {
  VALID = 'valid',
  INVALID = 'invalid',
  DISABLED = 'disabled',
}

/**
 * Describes a validator function. Validators, when used, are injected into a {@link FormControl} on instantiation.
 * Validator execution occurs during a call to {@link updateValidity}, typically in response to an `onblur` event
 * fired by the form input element. Multiple validators assigned to a `FormControl` object are composed into a
 * single function. The result of validation is either a single {@link ValidationErrors} object representing a
 * failure or null representing pass.
 */
export type Validator = (control: FormControl) => ValidationErrors | null;

/**
 * Describes a form control error.
 * @property key  The name of the error. The property value can be of any type needed for further processing or
 *                rendering. Typically, the value is a simple boolean or a message string. One or more keys may be
 *                assigned to the error depending on the need.
 */
export type ValidationErrors = {
  [key: string]: any;
};

/* ---------- Form Functions ---------- */

/**
 * Returns true if the view mode is not read only and the given {@link FormControl} has been touched and has errors,
 * otherwise false.
 * @param control
 * @param isReadOnly
 */
export const showInvalidState = (control: FormControl | undefined, isReadOnly: boolean): boolean =>
  control !== undefined && !isReadOnly && control.errors !== null && control.touched;

/**
 * Returns the given validator, or null if no validator is provided. If an array of validators
 * is provided, the method returns a new validator that merges the results of the given array.
 * @param newValidator
 */
const mergeValidators = (newValidator?: Validator | Validator[]): Validator | null => {
  if (newValidator) {
    if (Array.isArray(newValidator)) {
      // eslint-disable-next-line func-names
      return function (control: FormControl): ValidationErrors | null {
        const mergedErrors = newValidator.reduce((acc: ValidationErrors, curr: Validator) => {
          const errors = curr(control);
          if (errors) {
            return Object.keys(acc).length ? { ...acc, ...errors } : errors;
          }
          return acc;
        }, {});
        return Object.keys(mergedErrors).length ? mergedErrors : null;
      };
    }
    return newValidator;
  }
  return null;
};

/**
 * Returns the computed status of the given form control.
 * @param control
 */
const calculateStatus = (control: FormControl): 'disabled' | 'valid' | 'invalid' => {
  if (control.status === FormStatus.DISABLED) {
    return FormStatus.DISABLED;
  }
  return control.errors ? FormStatus.INVALID : FormStatus.VALID;
};

/**
 * Executes the validator on the given form control, if any, and updates its status.
 * @param control
 */
export const updateValidity = (control: FormControl) => {
  if (control) {
    if (control.status !== FormStatus.DISABLED) {
      // eslint-disable-next-line no-param-reassign
      control.errors = control.validator ? control.validator(control) : null;
    }
    // eslint-disable-next-line no-param-reassign
    control.status = calculateStatus(control);
  }
};

/**
 * Sets all the {@link FormControl} objects on the given source to 'touched'.
 * @param source
 */
export const markAllAsTouched = (source: any) => {
  if (source && typeof source === 'object') {
    const props = Object.getOwnPropertyNames(source);
    // For individual FormControl objects
    if (props.includes('touched')) {
      // eslint-disable-next-line no-param-reassign
      (source as FormControl).touched = true;
      return;
    }
    // For FormGroup objects and objects that contain FormControls
    for (let i = 0, len = props.length; i < len; i += 1) {
      const prop = props[i];
      if (Array.isArray(source[prop])) {
        source[prop].forEach((e: any) => markAllAsTouched(e));
      } else {
        markAllAsTouched(source[prop]);
      }
    }
  }
};

/**
 * Executes validators on every {@link FormControl} object in the given source.
 * @param source
 * @param updateTouched
 */
export const validateForm = (source: any, updateTouched = true) => {
  if (source && typeof source === 'object') {
    if (updateTouched) {
      markAllAsTouched(source);
    }
    const props = Object.getOwnPropertyNames(source);
    // For individual FormControl objects
    if (props.includes('status')) {
      updateValidity(source);
      return;
    }
    // For FormGroup objects and objects that contain FormControls
    for (let i = 0, len = props.length; i < len; i += 1) {
      const prop = props[i];
      if (Array.isArray(source[prop])) {
        source[prop].forEach((e: any) => validateForm(e, false));
      } else {
        validateForm(source[prop], false);
      }
    }
  }
};

/**
 * Returns true if the given source data, presumed to be an object with {@link FormControl} properties or an
 * individual FormControl, is valid, false otherwise. This method runs recursively to handle nested objects and
 * arrays, returns on the first failed evaluation, and will ignore primitives or objects that do not implement the
 * FormControl interface.
 * @param source
 */
export const isFormValid = (source: any): boolean => {
  if (source) {
    if (typeof source === 'object') {
      const props = Object.getOwnPropertyNames(source);
      // For individual FormControl objects
      if (props.includes('status')) {
        return (source as FormControl).status !== 'invalid';
      }
      // For FormGroup objects and objects that contain FormControls
      for (let i = 0, len = props.length; i < len; i += 1) {
        const prop = props[i];
        if (Array.isArray(source[prop])) {
          if (!source[prop].every((e: any) => isFormValid(e))) {
            return false;
          }
        }
        if (!isFormValid(source[prop])) {
          return false;
        }
      }
    }
  }
  return true;
};

/**
 * Returns true if the given source data, presumed to be an object with {@link FormControl} properties or an individual
 * FormControl, is dirty, false otherwise. This method runs recursively to handle nested objects and arrays, returns
 * on the first dirty evaluation, and will ignore primitives or objects that do not implement the FormControl interface.
 * @param source
 */
export const isFormDirty = (source: any): boolean => {
  if (source) {
    if (typeof source === 'object') {
      const props = Object.getOwnPropertyNames(source);
      // For individual FormControl objects
      if (props.includes('dirty')) {
        return (source as FormControl).dirty;
      }
      // For FormGroup objects and objects that contain FormControls
      for (let i = 0, len = props.length; i < len; i += 1) {
        const prop = props[i];
        if (Array.isArray(source[prop])) {
          if (source[prop].some((e: any) => isFormDirty(e))) {
            return true;
          }
        }
        if (isFormDirty(source[prop])) {
          return true;
        }
      }
    }
  }
  // For all other inputs, e.g. primitives
  return false;
};
