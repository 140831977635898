import '@software-platforms/design-system-styles';
import cx from 'classnames';
import React, { CSSProperties } from 'react';
import './pki-button.scss';

export type PkiButtonProps = {
  disabled?: boolean;
  id?: string;
  label: string;
  name?: string;
  onClick: (event: React.MouseEvent) => void;
  onKeyUp?: (event: React.KeyboardEvent) => void;
  style?: CSSProperties;
  type?: 'button' | 'reset' | 'submit';
  value?: string;

  classNames?: string | string[];
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  size?: 'small' | 'medium' | 'large' | 'extra-large';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text-only' | '';
};

export const PkiButton: React.FunctionComponent<React.PropsWithChildren<PkiButtonProps>> = (props: PkiButtonProps) => {
  const {
    label,
    name,
    onClick,
    value,
    classNames,
    id,
    size = 'medium',
    style,
    type = 'button',
    variant,
    onKeyUp,
  } = props;

  const renderChildren = () => {
    const { leftIcon, rightIcon } = props;
    return (
      <>
        {leftIcon !== undefined && <span className="pki-btn-left-icon">{leftIcon}</span>}
        <span className="pki-btn-label">{label}</span>
        {rightIcon !== undefined && <span className="pki-btn-right-icon">{rightIcon}</span>}
      </>
    );
  };

  return (
    <button
      className={cx('pki-button', classNames, size, variant, { disabled: props.disabled })}
      disabled={props.disabled}
      id={id}
      name={name}
      onClick={onClick}
      onKeyUp={onKeyUp}
      style={style}
      type={type}
      value={value}
      aria-disabled={props.disabled}
      aria-label={label}
    >
      {renderChildren()}
    </button>
  );
};
PkiButton.displayName = 'PkiButton';
