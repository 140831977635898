import { NotificationServiceFactory } from '@nx-workspace/shared/notification';
import {
  dnsLabel,
  email,
  FormBuilder as fb,
  FormControl,
  isFormDirty,
  isFormValid,
  PkiDialog,
  required,
  updateValidity,
  validateForm,
  ValidationErrors,
} from '@software-platforms/design-system-components';
import {
  CreateUserRequest,
  Subscription as TenantSubscription,
  SubscriptionStatus,
  TenantForm,
  TenantRequest,
  TenantStatus,
  userHasAdministratorRole,
  UserStatus,
} from '@software-platforms/tenant-manager-ui/models';
import {
  AppState,
  EnvironmentActions,
  SubscriptionActions,
  TenantActions,
} from '@software-platforms/tenant-manager-ui/store';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map, Subscription } from 'rxjs';
import { WithRouter, withRouter } from '../components';
import { SubscriptionLocationState } from '../subscriptions/subscriptions';
import { TenantCreate } from './tenant-create/tenant-create';
import { TenantEdit } from './tenant-edit/tenant-edit';
import { TenantList } from './tenant-list/tenant-list';
import { TenantView } from './tenant-view/tenant-view';

export type TenantViewMode = 'list' | 'create' | 'edit' | 'view';
export type TenantLocationState = {
  id: string;
  viewMode: TenantViewMode;
};

const getPageTitle = (path: string): string => {
  if (/create/i.test(path)) {
    return 'tenants.page.create';
  }
  if (/edit/i.test(path)) {
    return 'tenants.page.edit';
  }
  if (/tenants\/\w+/i.test(path)) {
    return 'tenants.page.view';
  }
  return 'tenants.page.list';
};

/* ---------- Component Definition ---------- */

type OwnProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WithRouter &
  WithTranslation;

type OwnState = {
  form?: TenantForm;
  openConfirmSave: boolean;
  openConfirmUpdate: boolean;
  search: string;
  viewMode: TenantViewMode;
};

class Tenants extends React.Component<OwnProps, OwnState> {
  search$ = new BehaviorSubject<string>('').pipe(
    map((token: string) => token.trim()),
    distinctUntilChanged(),
    debounceTime(200)
  );
  typeahead: Subscription | undefined;

  constructor(props) {
    super(props);
    this.getForm = this.getForm.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateDnsLabel = this.validateDnsLabel.bind(this);
    this.validateEnvironment = this.validateEnvironment.bind(this);
    this.validateTenantName = this.validateTenantName.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleConfirmSave = this.handleConfirmSave.bind(this);
    this.handleConfirmUpdate = this.handleConfirmUpdate.bind(this);

    this.state = {
      openConfirmSave: false,
      openConfirmUpdate: false,
      search: '',
      viewMode: 'list',
    };
  }

  componentDidMount() {
    this.typeahead = this.search$.subscribe({ next: (token: string) => this.setState({ search: token }) });
    this.props.fetchTenants();
    this.setState({ form: this.getForm() });

    const viewMode: TenantViewMode = this.props.location.state
      ? (this.props.location.state as TenantLocationState).viewMode
      : 'list';
    if (viewMode !== 'list') {
      this.props.fetchSubscriptions();
      this.props.fetchEnvironments();
      this.setState({ openConfirmSave: false, openConfirmUpdate: false, viewMode });
    }
  }

  componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>) {
    const { currentTenant, writeError, isProcessing } = this.props;
    if (currentTenant !== prevProps.currentTenant) {
      this.setState({ form: this.getForm() });
    }

    const viewMode: TenantViewMode = this.props.location.state
      ? (this.props.location.state as TenantLocationState).viewMode
      : 'list';
    if (!isProcessing && prevProps.isProcessing && !writeError) {
      if (this.props.currentSubscription) {
        // Navigate back to subscriptions view if that's where we came from!
        const subscriptionId = this.props.currentSubscription.id;
        const state: SubscriptionLocationState = { id: subscriptionId, viewMode: 'list' };
        this.props.navigate(`/subscriptions/${subscriptionId}`, { state });
      } else {
        this.props.navigate('/tenants', { state: 'list' });
      }
    }
    if (viewMode !== prevState.viewMode) {
      this.setState({ viewMode });
    }
  }

  componentWillUnmount() {
    this.typeahead?.unsubscribe();
  }

  /* ---------- Form Methods ---------- */

  getForm(): TenantForm {
    const { currentTenant } = this.props;
    return {
      dnsLabel: fb.getInstance(currentTenant?.dnsLabel || '', [required, dnsLabel, this.validateDnsLabel]),
      companyName: fb.getInstance(currentTenant?.companyName || '', required),
      description: fb.getInstance(currentTenant?.description || ''),
      environmentId: fb.getInstance(currentTenant?.environmentId || '', [required, this.validateEnvironment]),
      orderNumber: fb.getInstance(currentTenant?.orderNumber || ''),
      subscriptionId: fb.getInstance(currentTenant?.subscriptionId || '', required),
      tenantName: fb.getInstance(currentTenant?.tenantName || '', [required, this.validateTenantName]),
      userEmail: fb.getInstance(currentTenant?.contactInfo || '', [required, email]),
    } as TenantForm;
  }

  handleBlur(controlName: string) {
    this.setState((prevState) => {
      const clonedForm = { ...prevState.form } as TenantForm;
      if (clonedForm[controlName]) {
        if (typeof clonedForm[controlName].value === 'string') {
          const currVal = clonedForm[controlName].value || '';
          clonedForm[controlName].value = currVal.trim();
        }
        updateValidity(clonedForm[controlName]);
        clonedForm[controlName].touched = true;
        return { ...prevState, form: clonedForm };
      }
      return prevState;
    });
  }

  handleChange(controlName: string, newValue: any) {
    this.setState((prevState) => {
      if (prevState.form![controlName] && newValue !== prevState.form![controlName].value) {
        const clonedForm = { ...prevState.form } as TenantForm;
        clonedForm[controlName].value = newValue;
        clonedForm[controlName].dirty = true;
        return { ...prevState, form: clonedForm };
      }
      return prevState;
    });
  }

  /**
   * Returns a {@link ValidationErrors} object if a tenant exists with the same dnsLabel and environment.
   * @param control
   */
  validateDnsLabel(control: FormControl): ValidationErrors | null {
    if (control) {
      const { currentTenant, list } = this.props;
      const environmentId = this.state.form?.environmentId.value;
      const duplicate = list.some(
        (e) => e.dnsLabel === control.value && e.environmentId === environmentId && e.id !== currentTenant?.id
      );
      if (duplicate) {
        return { duplicate: true };
      }
    }
    return null;
  }

  /**
   * Returns a {@link ValidationErrors} object if a tenant exists with the same environment and dnsLabel.
   * @param control
   */
  validateEnvironment(control: FormControl): ValidationErrors | null {
    if (control) {
      const { currentTenant, list } = this.props;
      const value = this.state.form?.dnsLabel.value;
      const duplicate = list.some(
        (e) => e.environmentId === control.value && e.dnsLabel === value && e.id !== currentTenant?.id
      );
      if (duplicate) {
        return { duplicate: true };
      }
    }
    return null;
  }

  /**
   * Returns a {@link ValidationErrors} object if a tenant exists with the same tenant name and environment.
   * @param control
   */
  validateTenantName(control: FormControl): ValidationErrors | null {
    if (control) {
      const { currentTenant, list } = this.props;
      const environmentId = this.state.form?.environmentId.value;
      const duplicate = list.some(
        (e) => e.tenantName === control.value && e.environmentId === environmentId && e.id !== currentTenant?.id
      );
      if (duplicate) {
        return { duplicate: true };
      }
    }
    return null;
  }

  /* ---------- Actions ---------- */

  handleSave() {
    const { form, viewMode } = this.state;
    const { currentTenant, subscriptions, t } = this.props;

    if (form && isFormDirty(form)) {
      validateForm(form);
      this.setState({ form }, () => {
        if (isFormValid(form)) {
          if (viewMode === 'create') {
            this.setState({ openConfirmSave: true });
            return;
          }
          // Confirm an updated tenant only if its subscription id changed and that subscription can accept an
          // additional tenant.
          const subscriptionId =
            typeof form.subscriptionId.value === 'string'
              ? form.subscriptionId.value
              : (form.subscriptionId.value as TenantSubscription).id;
          if (subscriptionId !== currentTenant?.subscriptionId) {
            const newSubscription = subscriptions.find((subscription) => subscription.id === subscriptionId);
            if (newSubscription) {
              if (newSubscription.status !== SubscriptionStatus.ACTIVE) {
                NotificationServiceFactory.getInstance().showError(t('tenants.edit.subscriptionNotActive'));
              } else if (newSubscription.numTenants >= newSubscription.allowedTenants) {
                NotificationServiceFactory.getInstance().showError(t('tenants.edit.subscriptionFull'));
              } else {
                this.setState({ openConfirmUpdate: true });
              }
            }
          } else {
            this.handleConfirmUpdate(true);
          }
        }
      });
    }
  }

  handleConfirmSave(isConfirmed: boolean) {
    this.setState({ openConfirmSave: false });
    if (isConfirmed) {
      const { form } = this.state;
      if (form) {
        // Create the tenant primary user and register it with the identity provider
        const user: Partial<CreateUserRequest> = {
          email: form.userEmail.value,
          emailVerified: false,
          verifyEmail: false,
          password: undefined,
          sendChangePasswordEmail: true, //changing the password will also validate the email
          status: UserStatus.INVITED,
        };
        const subscriptionId =
          typeof form.subscriptionId.value === 'string'
            ? form.subscriptionId.value
            : (form.subscriptionId.value as TenantSubscription).id;
        const formData: TenantRequest = {
          companyName: form.companyName.value,
          contactInfo: form.userEmail.value,
          dnsLabel: form.dnsLabel.value,
          environmentId: form.environmentId.value,
          description: form.description.value,
          orderNumber: form.orderNumber.value,
          subscriptionId,
          name: form.tenantName.value,
          status: TenantStatus.DEPLOYING,
          firstTenantAdmin: user,
        };
        this.props.createTenant(formData);
      }
    }
  }

  handleConfirmUpdate(isConfirmed: boolean) {
    this.setState({ openConfirmUpdate: false });
    if (isConfirmed) {
      const { currentTenant, t } = this.props;
      const { form } = this.state;
      if (currentTenant && form) {
        const subscriptionId =
          typeof form.subscriptionId.value === 'string'
            ? form.subscriptionId.value
            : (form.subscriptionId.value as TenantSubscription).id;
        const formData: TenantRequest = {
          name: currentTenant?.tenantName === form.tenantName.value ? undefined : form.tenantName.value,
          companyName: currentTenant?.companyName === form.companyName.value ? undefined : form.companyName.value,
          description: currentTenant?.description === form.description.value ? undefined : form.description.value,
          orderNumber: currentTenant?.orderNumber === form.orderNumber.value ? undefined : form.orderNumber.value,
          subscriptionId: currentTenant?.subscriptionId === subscriptionId ? undefined : subscriptionId,
        };
        if (Object.keys(formData).every((prop) => formData[prop] === undefined)) {
          // It is possible to have a dirty form with no changes.
          NotificationServiceFactory.getInstance().show(t('pki:form.noChanges'), { type: 'info' });
        } else {
          this.props.updateTenant(currentTenant.id, formData);
        }
      }
    }
  }

  /* ---------- Rendering ---------- */

  render() {
    const {
      currentTenant,
      currentUser,
      isLoading,
      isProcessing,
      list,
      location,
      subscriptions,
      environments,
      t,
      writeError,
    } = this.props;
    const { form, openConfirmSave, openConfirmUpdate, viewMode } = this.state;
    const isAdministrator = userHasAdministratorRole(currentUser?.authUser);

    return (
      <>
        <Helmet title={t(getPageTitle(location?.pathname))} />
        <Routes>
          <Route path="*" element={<TenantList list={list} environments={environments} isLoading={isLoading} />} />
          <Route
            path="create"
            element={
              <TenantCreate
                form={form}
                isProcessing={isProcessing}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onSave={this.handleSave}
                subscriptions={subscriptions}
                viewMode={viewMode}
              />
            }
          />
          <Route
            path=":tenantId/edit"
            element={
              <TenantEdit
                form={form}
                isProcessing={isProcessing}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onSave={this.handleSave}
                subscriptions={subscriptions}
                viewMode={viewMode}
              />
            }
          />
          <Route
            path=":tenantId"
            element={
              <TenantView
                currentResource={currentTenant}
                error={writeError}
                isAdministrator={isAdministrator}
                isProcessing={isProcessing}
                form={form}
                subscriptions={subscriptions}
                viewMode={viewMode}
              />
            }
          />
        </Routes>
        {isAdministrator && (
          <>
            {openConfirmSave && (
              <PkiDialog
                confirmation
                confirmBtnLabel={t('tenants.save.saveBtnLabel')}
                message={t('tenants.save.message')}
                onClose={this.handleConfirmSave}
                open={openConfirmSave}
                size="md"
                title={t('tenants.save.title')}
                type="info"
              />
            )}
            {openConfirmUpdate && (
              <PkiDialog
                confirmation
                confirmBtnLabel={t('pki:form.save')}
                message={t('tenants.edit.message')}
                onClose={this.handleConfirmUpdate}
                open={openConfirmUpdate}
                size="md"
                title={t('tenants.edit.title')}
                type="warning"
              />
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  subscriptions: state.subscriptions.list,
  currentSubscription: state.subscriptions.currentResource,
  environments: state.environments.list,
  list: state.tenants.list,
  filteredList: state.tenants.filteredList,
  currentTenant: state.tenants.currentResource,
  isLoading: state.tenants.isLoading,
  isProcessing: state.tenants.isProcessing,
  writeError: state.tenants.writeError,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchEnvironments: EnvironmentActions.fetchEnvironments,
      fetchSubscriptions: SubscriptionActions.fetchSubscriptions,
      fetchTenants: TenantActions.fetchTenants,
      createTenant: TenantActions.createTenant,
      updateTenant: TenantActions.updateTenant,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Tenants)));
