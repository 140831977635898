import { NotificationServiceFactory } from '@nx-workspace/shared/notification';
import { PkiButton, PkiDialog } from '@software-platforms/design-system-components';
import { arrowBack, PkiIcon } from '@software-platforms/design-system-icons';
import { Subscription, Tenant, TenantForm, TenantStatus } from '@software-platforms/tenant-manager-ui/models';
import { TenantActions } from '@software-platforms/tenant-manager-ui/store';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { TenantEntry } from '../tenant-entry/tenant-entry';
import { TenantLocationState, TenantViewMode } from '../tenants';
import styles from './tenant-view.module.scss';

type OwnProps = {
  currentResource: Tenant | null;
  error: any;
  isAdministrator: boolean;
  isProcessing: boolean;
  form?: TenantForm;
  subscriptions: Subscription[];
  viewMode: TenantViewMode;
};

export const TenantView: React.FunctionComponent<OwnProps> = (props) => {
  const { currentResource, error, form, isAdministrator, isProcessing, subscriptions, viewMode } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(
    () => {
      const tenantId = (location.state as TenantLocationState).id;
      dispatch(TenantActions.fetchTenant(tenantId));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [location.state]
  );

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  useEffect(
    () => {
      if (!isProcessing && isUpdating) {
        setIsUpdating(false);
        if (error) {
          NotificationServiceFactory.getInstance().show(error.message, { type: 'error' });
        } else {
          NotificationServiceFactory.getInstance().show(t('tenants.form.updateSuccess'), { type: 'success' });
          navigate('/tenants');
        }
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [error, isProcessing]
  );

  /* ---------- Inactivate/Activate Actions ---------- */

  const activateAction = currentResource?.status === 'inactive' ? 'activate' : 'deactivate';
  const disableActionBtn = ![TenantStatus.ACTIVE.toString(), TenantStatus.INACTIVE.toString()].includes(
    currentResource?.status || ''
  );
  const [openConfirmAction, setOpenConfirmAction] = useState<boolean>(false);
  const handleAction = (event: React.MouseEvent) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    setOpenConfirmAction(true);
  };
  const handleConfirmAction = (isConfirmed: boolean) => {
    setOpenConfirmAction(false);
    if (isConfirmed) {
      setIsUpdating(true);
      if (activateAction === 'deactivate') {
        dispatch(TenantActions.deactivateTenant(currentResource!.id));
      } else if (activateAction === 'activate') {
        dispatch(TenantActions.reactivateTenant(currentResource!.id));
      }
    }
  };

  /* ---------- Other Actions ---------- */

  const handleCancel = (event: React.MouseEvent) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    navigate(-1);
  };
  const handleEdit = () => {
    navigate(`/tenants/${currentResource!.id}/edit`, {
      state: { id: currentResource!.id, viewMode: 'edit' } as TenantLocationState,
    });
  };
  const noopAction = () => {};

  return (
    <>
      <header>
        <div className="pki-header-slot">
          <div className="pki-back-btn" onClick={handleCancel} role="button">
            <PkiIcon icon={arrowBack} />
          </div>
          <div className="pki-page-title">{t(isAdministrator ? 'tenants.page.viewAndEdit' : 'tenants.page.view')}</div>
        </div>
        <div className="pki-header-slot end">
          {isAdministrator && (
            <>
              <PkiButton
                id="cancel-btn"
                label={t('pki:form.cancel')}
                onClick={handleCancel}
                size="small"
                variant="secondary"
              />
              <PkiButton
                id="action-btn"
                disabled={disableActionBtn}
                label={t(`tenants.${activateAction}BtnLabel`)}
                onClick={handleAction}
                size="small"
                variant="secondary"
              />
              <PkiButton id="edit-btn" label={t('pki:form.edit')} onClick={handleEdit} size="small" variant="primary" />
            </>
          )}
          {!isAdministrator && (
            <PkiButton id="ok-btn" label={t('pki:form.ok')} onClick={handleCancel} size="small" variant="secondary" />
          )}
        </div>
      </header>
      <div className={cx('content-inner-container', styles.contentInnerContainer)}>
        <section className={styles.item}>
          <TenantEntry
            form={form}
            isProcessing={false}
            onBlur={() => noopAction()}
            onChange={() => noopAction()}
            subscriptions={subscriptions}
            viewMode={viewMode}
          />
        </section>
      </div>
      {isAdministrator && (
        <PkiDialog
          confirmation
          message={t(`tenants.${activateAction}.message`)}
          onClose={handleConfirmAction}
          open={openConfirmAction}
          size="sm"
          title={t(`tenants.${activateAction}.title`)}
        />
      )}
    </>
  );
};
TenantView.displayName = 'TenantView';
