import { webPage, PkiIcon } from '@software-platforms/design-system-icons';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './empty-environment.module.scss';

export const EmptyEnvironment: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <section className={cx('item', styles.item)}>
      <div className={styles.captionContainer}>
        <PkiIcon icon={webPage} />
        <div className={styles.caption}>{t('environments.action')}</div>
      </div>
    </section>
  );
};
EmptyEnvironment.displayName = 'EmptyEnvironment';
